import React from 'react'
import Header from 'components/Headers/Header.js'
import { Editor } from 'react-draft-wysiwyg'
import './link.css'
import {
    Button,
    Card, CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Label,
    Modal,
    Row
} from 'reactstrap'
import MaterialTable, { MTableToolbar } from 'material-table'
import { withSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import ReactHtmlParser from 'react-html-parser'
import { customChunkRenderer } from 'draft-js-helpers'
import LinkForm from '../../components/Forms/LinkForm'
import ReactPlayer from 'react-player/youtube'
import { TagsInput } from 'react-tag-input-component'
import { useTable } from 'react-table'
import * as XLSX from 'xlsx/xlsx.mjs'
import ScrollToTop from 'react-scroll-to-top'
import arrowDown from '../../assets/img/arrowdown.png'
import LinkQuizTabs from '../../components/Forms/LinkQuiz/LinkQuizTabs'
import LessonNewForm from '../../components/Forms/LessonNewForm'
import Select from 'react-select'
import LessonRemarksTabs from '../../components/Forms/LessonRemarksTabs'
import ArticleNewForm from '../../components/Forms/ArticleNewForm'


const Services = require('../../RemoteServices/RemoteServices.js')

class ArticleNew extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filteredData: [],
            selectedCategory: '',
            descriptionDetails: '',
            category: '',
            tag: '',
            tags: '',
            link_image: '',
            link_slug: '',
            addFormView: false,
            editFormView: false,
            data: [],
            columns: [
                {
                    Header: 'Column 1',
                    accessor: 'first'
                }
            ],
            courseList: [],
            dropdownOptions: [],
            selectedOption: null,
            selectedOptionId: null,
            selectedRow: {},
            formModal: false,
            loading: true,
            lessonEditModal: false,
            lessonNo: '',
            title: '',
            views: '',
            linkId: '',
            description: '',
            videoLink: '',
            editorState: EditorState.createEmpty(),
            editorStateCopied: EditorState.createEmpty(),
            editorContentHtml: '',
            editorContentHtmlCopied: '',
            selectedRowId: '',
            image: '',
            lessonCourse: null,
            imagePreview: '',
            lessonRemarks: [],
            allowSubmit: false,
            courseSelectContainer: true,
            passwordModal: false,
            masterPassword: '',
            lessonViewType: true,
            copyStatus: false,
            showEditorCode: false,
            uniqueId: '',
            id: null,
            showEditModal: false,
            notification: false,
            link: {
                linkId: '',
                lessonNo: '',
                title: '',
                description: '',
                videoLink: '',
                selectedOptionId: '',
                views: '',
                dropdownOptions: [],
                editorState: EditorState.createEmpty(),
                editorStateCopied: EditorState.createEmpty(),
                editorContentHtmlCopied: '',
                imagePreview: '',
                image: '',
                remarks: [],
                uniqueId: '',
                editorContentHtml: '',
                video: '',
                itemList: [],
                disabled: false,
            },
            showAllButton: true
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.onEditorStateChange = this.onEditorStateChange.bind(this)
        this.onCourseCardClick = this.onCourseCardClick.bind(this)
        this.handleCreateLessonOpen = this.handleCreateLessonOpen.bind(this)
        this.toggleEditorCode = this.toggleEditorCode.bind(this)
        this.onEditEditorHTML = this.onEditEditorHTML.bind(this)
        this.handleCopy = this.handleCopy.bind(this)
        this.handlePaste = this.handlePaste.bind(this)
        this.columns = props.columns
        this.updateData = this.updateData.bind(this)
        this.handleTablePaste = this.handleTablePaste.bind(this)
    }

    handlePasteImage = (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                let reader = new FileReader()
                reader.onload = () => {
                    let binaryData = reader.result
                    let base64String = btoa(binaryData)
                    this.setState({ image: base64String })
                }
                reader.readAsBinaryString(blob)
                this.setState({ imagePreview: URL.createObjectURL(blob), image: blob });
                // If you want to upload the image, you can use a function like this.uploadImage(blob)
                // this.uploadImage(blob);
            }
        }
    };

    handleCategoryChange = (event) => {
        const category = event.target.value
        this.setState({ selectedCategory: category })
        if (category === '') {
            // if no category is selected, show all data
            this.setState({ filteredData: [] })
        } else {
            const filteredData = this.state.data.filter(
                (item) => item.category === category
            )
            this.setState({ filteredData })
        }
    }

    handleTableRow = (event) => {
        const parsedData = this.parseClip(event.target.value)
        const columnData = parsedData.map(item => ({
            Header: item[0],
            accessor: item[0]
        }))
        this.setState({
            columns: parsedData[0].map(i => ({
                accessor: i
            }))
        })
    }

    updateData(index, key, value) {

        const data = [...this.state.link.itemList]
        data[index]['tableData'][index][key] = value
        this.setState({
            itemList: [...data]
        })
    }

    handleTablePaste(event) {
        event.preventDefault()
        const clipboardData = event.clipboardData || window.clipboardData
        const pastedData = clipboardData.getData('text')
        const parsedData = this.parseExcelData(pastedData)
        if (parsedData) {
            const newData = [...this.state.data]
            parsedData.forEach((row, rowIndex) => {
                if (rowIndex < newData.length) {
                    row.forEach((cellValue, cellIndex) => {
                        const columnId = this.state.columns[cellIndex].accessor
                        newData[rowIndex][columnId] = cellValue
                    })
                }
            })
            this.setState({ data: newData })
        }
    }

    parseExcelData(pastedData) {
        const workbook = XLSX.read(pastedData, { type: 'string' })
        const sheet = workbook.Sheets[workbook.SheetNames[0]]
        const range = XLSX.utils.decode_range(sheet['!ref'])
        if (!range || !range.s || !range.e) {
            return null
        }
        const rows = []
        for (let row = range.s.r; row <= range.e.r; row++) {
            const rowData = []
            for (let col = range.s.c; col <= range.e.c; col++) {
                const address = XLSX.utils.encode_cell({ r: row, c: col })
                if (!sheet[address]) {
                    rowData.push('')
                } else {
                    rowData.push(sheet[address].v)
                }
            }
            rows.push(rowData)
        }
        return rows
    }


    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }
    addItem = async (idx) => {
        const { link } = this.state
        let item = {
            showEditorCode: false,
            editorState: EditorState.createEmpty(),
            editorStateCopied: EditorState.createEmpty(),
            editorContentHtmlCopied: '',
            imagePreview: '',
            editorContentHtml: '',
            image: '',
            description: '',
            video: '',
            thumbnail: '',
            order: '',
            orientation: '',
            imageBlob: '',
            showAllButton: true,
            addButton: true,
            showText: false,
            showImage: false,
            showVideo: false,
            showTable: false,
            showButton: false,
            showSubTitle: false,
            showQuiz: false,
            rawStr: '',
            data: '',
            columns: '',
            tableData: '',
            tableArray: '',
            buttonLink: '',
            buttonName: '',
            subTitle: '',
            paymentCategory: false
        }
        await this.setState({
            showAllButton: true,
            link: {
                ...link,
                itemList: [...link.itemList, item]
            }

        })
        let itemUpdated = [...this.state.link.itemList]
        itemUpdated[idx]['addButton'] = false
        await this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemUpdated]
            }
        }))

    }
    parseClip = (str) => {
        let r,
            rlen,
            rows,
            arr = [],
            a = 0,
            c,
            clen,
            multiline,
            last
        rows = str
            .split('\r\n')
            .reduce((acc, item) => acc.concat(item.split('\n')), [])
            .reduce((acc, item) => acc.concat(item.split('\r')), [])
        if (rows.length > 1 && rows[rows.length - 1] === '') {
            rows.pop()
        }
        for (r = 0, rlen = rows.length; r < rlen; r += 1) {
            rows[r] = rows[r].split('\t')
            for (c = 0, clen = rows[r].length; c < clen; c += 1) {
                if (!arr[a]) {
                    arr[a] = []
                }
                if (multiline && c === 0) {
                    last = arr[a].length - 1
                    arr[a][last] = arr[a][last] + '\n' + rows[r][0]
                    if (multiline && this.countQuotes(rows[r][0]) & 1) {
                        //& 1 is a bitwise way of performing mod 2
                        multiline = false
                        arr[a][last] = arr[a][last]
                            .substring(0, arr[a][last].length - 1)
                            .replace(/""/g, '"')
                    }
                } else {
                    if (
                        c === clen - 1 &&
                        rows[r][c].indexOf('"') === 0 &&
                        this.countQuotes(rows[r][c]) & 1
                    ) {
                        arr[a].push(rows[r][c].substring(1).replace(/""/g, '"'))
                        multiline = true
                    } else {
                        arr[a].push(rows[r][c].replace(/""/g, '"'))
                        multiline = false
                    }
                }
            }
            if (!multiline) {
                a += 1
            }
        }
        return arr
    }

    handleTableChange = (e, idx, rowIndex, colIndex) => {
        const newData = [...this.state.link.itemList]
        newData[idx]['tableArray'][rowIndex][colIndex] = e.target.value
        console.log('asdfasfd', ...newData)
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...newData]
            }
        }))
    }

    tableRow = (data, idx) => (
        <table className={'mt-2 mb-2 table table-bordered table-responsive .w-auto'}>
            {data.map((row, i) => (
                <tr key={i}>
                    {row.map((col, j) => (
                        <td style={{}} key={j}><input
                            style={{ border: 'none', positon: 'relative', width: '100%' }}
                            type='text'
                            value={col ? col : ''}
                            onChange={(e) => this.handleTableChange(e, idx, i, j)}
                        /></td>
                    ))}
                </tr>
            ))}
        </table>
    )

    handleTag = async (event) => {
        await this.setState(prevState => ({
            ...prevState,
            tags: event,
            tag: event.map(obj => obj).join(', ')
        }))
    }
    editItem = (event, idx, type) => {
        let name = event.target.name
        let value = event.target.value
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx][name] = value
        if (type === 'paymentCategory') {
            itemTransaction[idx]['paymentCategory'] = event.target.checked
            itemTransaction[idx]['paymentAmount'] = ''
            this.setState({
                itemList: [...itemTransaction]
            })
        }
        if (type === 'table') {
            const parsedData = this.parseClip(event.target.value)
            itemTransaction[idx]['rawStr'] = event.target.value
            itemTransaction[idx]['data'] = this.parseClip(event.target.value)
            itemTransaction[idx]['tableArray'] = this.parseClip(event.target.value)
            if (parsedData) {
                const rows = parsedData[0]
                const columns = parsedData
                const rowData = rows.map((row, index) => {
                    return {
                        row,
                        column: columns[index]
                    }
                })

                const columnData = columns.map((column, index) => {
                    return {
                        column,
                        row: rows[index]
                    }
                })

                const data = rowData

                // Accessing 0 index column
                const firstColumn = columns[0]

                console.log('asdfasdfasdf', data)
                console.log('firstColum', firstColumn)


                itemTransaction[idx]['columns'] = firstColumn.map(item => ({
                    accessor: item
                }))
                itemTransaction[idx]['tableData'] = columnData.map(im => {
                    return (
                        {
                            row2: im.column[0],
                            row3: im.column[1],
                            row4: im.column[2]
                        }
                    )
                })
            }
            this.setState({
                itemList: [...itemTransaction]
            })
        }
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))
    }
    removeItem = async (idx) => {
        if (idx > 0) {
            let item = [...this.state.link.itemList]
            item.splice(idx, 1)
            await this.setState(prevState => ({
                ...prevState,
                link: {
                    ...prevState.link,
                    itemList: item
                }
            }))
            let itemUpdated = [...this.state.link.itemList]
            itemUpdated[idx - 1]['addButton'] = true
            await this.setState(prevState => ({
                ...prevState,
                link: {
                    ...prevState.link,
                    itemList: [...itemUpdated]
                }
            }))
        } else if (idx === 0 && this.state.link.itemList.length >= 2) {
            let item = [...this.state.link.itemList]
            item.splice(idx, 1)
            await this.setState(prevState => ({
                ...prevState,
                showAllButton: true,
                link: {
                    ...prevState.link,
                    itemList: item
                }
            }))
        } else {
            let item = [...this.state.link.itemList]
            item.splice(idx, 1)
            await this.setState(prevState => ({
                ...prevState,
                showAllButton: true,
                link: {
                    ...prevState.link,
                    itemList: [{
                        image: '',
                        description: '',
                        video: '',
                        order: '',
                        orientation: '',
                        thumbnail: '',
                        showAllButton: true,
                        addButton: true,
                        tableData: '',
                        buttonLink: '',
                        buttonName: '',
                        subTitle: ''
                    }]
                }
            }))
        }
    }

    updateEditState = async (data) => {
        await this.setState((prevState) => ({
            ...prevState,
            lessonCourse: data.course,
            selectedRowId: data.id,
            imagePreview: data.image,
            image: '',
            selectedRow: data,
            title: data.title,
            description: data.description,
            videoLink: data.youtube_link,
            lessonNo: data.order,
            lessonRemarks: data.remarks,
            descriptionDetails: data.descriptionDetails,
            category: data.category,
            linkId: data.id,
            link: {
                ...prevState.link,
                title: data.title,
                linkId: data.id,
                unique_id: data.unique_id,
                link_slug: data.link_slug,
                link_image: data.link_image,
                views: data.views,
                itemList: data.objects.length > 0 ? data.objects.map((item, index) => (console.log('asdfasfd', item.tableArray), {
                    image: item.image || '',
                    video: item.youtube_link || '',
                    thumbnail: item.thumbnail || '',
                    buttonLink: item.button_link || '',
                    buttonName: item.button_name || '',
                    subTitle: item.title || '',
                    tableArray: item.data || '',
                    order: item.order,
                    orientation: item.orientation,
                    editorState: item.text && this.handleInitialEditorText(item.text),
                    editorContentHtml: item.text || '',
                    addButton: index === data.objects.length - 1 ? true : false,
                    showText: item.text && !item.key ? true : false,
                    showImage: item.image && !item.key ? true : false,
                    showVideo: item.youtube_link ? true : false,
                    showTable: item.data && !item.key ? true : false,
                    showButton: item.button_link && !item.key ? true : false,
                    showSubTitle: item.title && !item.key ? true : false,
                    showQuiz: item.amount ? true : false,
                    paymentButtonName: item.button_name,
                    paymentAmount: item.amount,
                    showAllButton: true,
                    paymentCategory: item.payment_category || false

                })) :
                    [{
                        showEditorCode: false,
                        editorState: EditorState.createEmpty(),
                        editorStateCopied: EditorState.createEmpty(),
                        editorContentHtmlCopied: '',
                        imagePreview: '',
                        editorContentHtml: '',
                        image: '',
                        description: '',
                        thumbnail: '',
                        video: '',
                        order: '',
                        orientation: '',
                        buttonLink: '',
                        buttonName: '',
                        subTitle: '',
                        imageBlob: '',
                        showAllButton: true,
                        addButton: true,
                        showText: false,
                        showImage: false,
                        showVideo: false,
                        showTable: false,
                        showSubTitle: false,
                        showQuiz: false,
                        paymentButtonName: '',
                        paymentAmount: '',
                        quizData: '',
                        rawStr: '',
                        data: [],
                        tableArray: '',
                        paymentCategory: false,
                    }]


            }

        }))
    }

    addHtmlToEditor = () => {
        const { editorContentHtml } = this.state
        let editorState
        if (editorContentHtml.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(editorContentHtml))
        } else {
            editorState = EditorState.createEmpty()
        }
        this.setState({ editorState })
    }

    componentDidMount() {
        this.getLessonList()
    }

    handleScroll = () => {
        window.scroll({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth'
        })
    }


    getCourseList() {
        Services.getCourses().then((response) => {
            this.setState({ courseList: response })
            this.populateSelectDropdown()
        })
    }

    populateSelectDropdown() {
        let data = this.state.courseList
        data.forEach((item) => {
            this.state.dropdownOptions.push({ value: item.id, label: item.title })
        })
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOption, selectedOptionId: selectedOption.value, loading: true }
        )
        Services.getLessonList(selectedOption.value).then((response) => {
            this.setState({ data: response, loading: false })
        })
        // this.getLessonList()
    }

    onCourseCardClick = selectedOption => {
        this.setState(
            { selectedOption, selectedOptionId: selectedOption.value, loading: true }
        )
        Services.getLessonList(selectedOption.value).then((response) => {
            this.setState({ data: response, loading: false })
        })
    }

    getLessonList = async () => {
        this.setState({ loading: true })
        await Services.getArticles().then((response) => {
            this.setState({ data: response, loading: false })
        })
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({
            [name]: value
        })
    }

    changeEditorContent(data) {
        this.setState({
            editorState: data
        })
    }


    returnHtmlBlock(text) {
        if (this.state.showEditModal === true) {
            const processedHTML = htmlToDraft(text, customChunkRenderer)
            return ContentState.createFromBlockArray(processedHTML)
        } else {
            const processedHTML = convertFromHTML(text)
            return ContentState.createFromBlockArray(processedHTML)
        }
    }

    handleInitialEditorText(text) {
        let editorState
        if (text.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(text))
        } else {
            editorState = EditorState.createEmpty()
        }
        return editorState
    }

    onEditEditorHTML = (idx, e) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['editorContentHtml'] = e.target.value
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))
        // this.setState({ editorContentHtml: e.target.value })
    }
    toggleEditorCode = async (idx) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['showEditorCode'] = !itemTransaction[idx].showEditorCode
        await this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))
        // this.setState({ showEditorCode: !this.state.showEditorCode })
    }
    returnHtmlBlock = (text) => {
        if (this.state.lessonViewType === true) {
            const processedHTML = htmlToDraft(text, customChunkRenderer)
            return ContentState.createFromBlockArray(processedHTML)
        } else {
            const processedHTML = convertFromHTML(text)
            return ContentState.createFromBlockArray(processedHTML)
        }
    }
    addHtmlToEditor = (idx) => {
        const { editorContentHtml } = this.state.link.itemList[idx]
        let editorState
        if (editorContentHtml.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(editorContentHtml))
        } else {
            editorState = EditorState.createEmpty()
        }
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['editorState'] = editorState
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))
    }

    getUploadParams = ({ meta }) => {
        return { url: 'https://httpbin.org/post' }
    }

    handleChangeStatus(meta, file, idx) {
        // this.setState({image:file})
        let reader = new FileReader()
        reader.onloadend = function () {
            // console.log('RESULT', reader.result)
            // this.setState({ image: reader.result })
            let itemTransaction = [...this.state.link.itemList]
            itemTransaction[idx]['image'] = reader.result
            this.setState(prevState => ({
                ...prevState,
                link: {
                    ...prevState.link,
                    itemList: [...itemTransaction]
                }
            }))
        }.bind(this)
        reader.readAsDataURL(file)
    }


    async updateLesson() {
        let newData = {
            description: this.state.editorContentHtml,
            title: this.state.title,
            is_archived: false
        }

        await Services.updateLink(this.state.id, newData).then((response) => {
            this.getLessonList()
            this.toggleState('editFormView')
            this.props.enqueueSnackbar('Link Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            this.toggleState('lessonEditModal')
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })
    }


    //Image selection and cases

    onEditorStateChange = async (editorState, idx) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['editorContentHtml'] = draftToHtml(rawContentState)
        itemTransaction[idx]['editorState'] = editorState
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))
        console.log('editor test', this.state.link.itemList)

    }

    uploadImageCallBack = (file) => {
        let token = localStorage.getItem('token')
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest()
                const serverUrl = window.config.apiUrl + 'common/image-upload'
                xhr.open('POST', serverUrl)
                xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                const data = new FormData()
                data.append('image', file)
                xhr.send(data)
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText)
                    resolve(response)
                })
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText)
                    reject(error)
                })
            }
        )
    }

    //Image selection and cases

    getExtension = (filename) => {
        var parts = filename.split('.')
        return parts[parts.length - 1]
    }

    isImage = (filename) => {
        var ext = this.getExtension(filename)
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'gif':
            case 'bmp':
            case 'png':
            case 'jpeg':
                //etc
                return true
        }
        return false
    }

    handleImageSelect = async (e, idx) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let itemTransaction = [...this.state.link.itemList]
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        itemTransaction[idx]['image'] = response.data.link
                        itemTransaction[idx]['imagePreview'] = response.data.link
                        this.setState(prevState => ({
                            ...prevState,
                            link: {
                                itemList: [...itemTransaction]
                            }
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }

    handleYoutubeImageSelect = async (e, idx) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let itemTransaction = [...this.state.link.itemList]
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        itemTransaction[idx]['thumbnail'] = response.data.link
                        this.setState(prevState => ({
                            ...prevState,
                            link: {
                                itemList: [...itemTransaction]
                            }
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }


    handleLinkImageSelect = async (e) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        console.log('asdf', response)
                        this.setState(prevState => ({
                            ...prevState,
                            link_image: response.data.link
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }
    handleLinkImageRemove = async (e) => {
        await this.setState(prevState => ({
            ...prevState,
            link_image: ''
        }))
    }

    handleImageRemove = async (e, idx) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['imagePreview'] = ''
        itemTransaction[idx]['image'] = ''
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))
    }
    handleYoutubeImageRemove = async (e, idx) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['thumbnail'] = null
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))
    }


    handleRemarksOptionChange = (optionArray) => {
        this.setState({ lessonRemarks: optionArray })
    }

    handleCopy = (e) => {
        e.preventDefault()
        console.log(this.state.editorContentHtml)
        const { editorContentHtml, editorState } = this.state
        this.setState({ editorStateCopied: editorState, editorContentHtmlCopied: editorContentHtml })
        this.props.enqueueSnackbar('Lesson data copied!', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
            }
        })
        this.setState({ copyStatus: true })
    }

    handleThumbnailImageSelect = (e) => {
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let file = e.target.files[0]
            let reader = new FileReader()
            reader.onload = () => {
                let binaryData = reader.result
                let base64String = btoa(binaryData)
                this.setState({ image: base64String })
            }
            reader.readAsBinaryString(file)
            this.setState({ imagePreview: URL.createObjectURL(e.target.files[0]) })
        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }

    handleThumbnailImageRemove = (e) => {
        this.setState({ imagePreview: '', image: '' })
    }

    handleUpdate() {
        this.setState({ disabled: true })
        const dataArray =
            this.state.link.itemList.map((item, index) => (
                item.editorContentHtml !== '' && item.video === '' || item.video === undefined && item.image === '' || item.image === undefined && item.buttonLink === '' || item.buttonLink === undefined && item.tableArray === '' || item.tableArray === undefined && item.subTitle === '' || item.subTitle === undefined ?
                    {
                        link: '',
                        order: item.order || index + 1,
                        orientation: item.orientation || 'left',
                        text: item.editorContentHtml
                    } : item.video !== '' && item.image === '' || item.image === undefined && item.buttonLink === '' || item.buttonLink === undefined && item.tableArray === '' || item.tableArray === undefined && item.subTitle === '' || item.subTitle === undefined ? {
                        link: '',
                        youtube_link: item.video,
                        order: item.order || index + 1,
                        orientation: item.orientation || 'left',
                        thumbnail: item.thumbnail ? item.thumbnail : null
                    } : item.image !== '' && item.buttonLink === '' || item.buttonLink === undefined && item.tableArray === '' || item.tableArray === undefined && item.subTitle === '' || item.subTitle === undefined ? {
                        link: '',
                        order: item.order || index + 1,
                        orientation: item.orientation || 'left',
                        image: item.image
                    } : item.buttonLink !== '' && item.tableArray === '' || item.tableArray === undefined && item.subTitle === '' || item.subTitle === undefined ? {
                        link: '',
                        order: item.order || index + 1,
                        orientation: item.orientation || 'left',
                        button_link: item.buttonLink,
                        button_name: item.buttonName
                    } : item.tableArray !== '' && item.subTitle === '' || item.subTitle === undefined ? {
                        table: '',
                        order: item.order || index + 1,
                        orientation: item.orientation || 'left',
                        table_array: item.tableArray
                    } : item.subTitle !== '' && item.paymentButtonName === '' || item.paymentButtonName === undefined ? {
                        link: '',
                        order: item.order || index + 1,
                        orientation: item.orientation || 'left',
                        title: item.subTitle
                    } : item.paymentButtonName !== '' && {
                        link: '',
                        order: item.order || index + 1,
                        orientation: item.orientation || 'left',
                        payment_button: item.paymentButtonName,
                        amount: item.paymentAmount || 0,
                        payment_category: item.paymentCategory
                    }
            ))
        let newData = new FormData()
        newData.append('title', this.state.title)
        newData.append('description', this.state.description)
        newData.append('descriptionDetails', this.state.descriptionDetails)
        if (this.state.image !== '') {
            newData.append('image', this.state.image)
        }
        newData.append('objects', JSON.stringify(dataArray))
        newData.append('category', this.state.category)
        newData.append('send_notification', this.state.notification)


        Services.updateArticle(this.state.selectedRowId, newData).then((response) => {
            this.toggleState('editFormView')
            this.props.enqueueSnackbar('Article Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            this.setState({ disabled: false })
            this.getLessonList()
            // this.toggleState('showEditModal')
        })
            .catch((error) => {
                this.setState({ disabled: false })
                this.props.enqueueSnackbar(error.msg, {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })
    }

    addButtonHandler = (state, idx) => {
        let item = [...this.state.link.itemList]
        item[idx][state] = !this.state.link.itemList[idx][state]
        this.setState((prevState) => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...item]
            }
        }))

    }

    handlePaste = async (e) => {
        e.preventDefault()
        console.log('content html hai', this.state.editorContentHtml)
        this.setState({
            editorState: this.state.editorStateCopied,
            editorContentHtml: this.state.editorContentHtmlCopied
        })
        this.setState({ copyStatus: false })
    }

    handleCreateLessonOpen = () => {
        this.toggleState('passwordModal')
        this.toggleState('addFormView')
        this.toggleState('courseSelectContainer')
        this.setState({ masterPassword: '' })

    }

    toggleEditorCode() {
        this.setState({ showEditorCode: !this.state.showEditorCode })
    }


    render() {
        console.log('adfasdf', this.state.link.itemList)
        let handleQuizArray = (idx, quizData) => {
            let itemTransaction = [...this.state.link.itemList]
            itemTransaction[idx]['quizArray'] = quizData
            this.setState(prevState => ({
                ...prevState,
                link: {
                    ...prevState.link,
                    itemList: [...itemTransaction]
                }
            }))
        }
        const ShowEditorCode = () => (
            <div className='rdw-option-wrapper' onClick={this.toggleEditorCode}>
                {this.state.showEditorCode
                    ? 'Hide Code'
                    : 'Show Code'}
            </div>
        )
        // var myTextIcon = React.useRef(null);
        const options = this.state.dropdownOptions
        return (
            <>
                <Header page='lesson' />
                <div className='mt-5 container-fluid'>
                    <Row>
                        <div className=' col'>
                            <Card className=' shadow'>

                                {!(this.state.addFormView) && !this.state.editFormView ?
                                    this.state.loading ?
                                        <CircularProgress style={{
                                            margin: 50,
                                            alignSelf: 'center'
                                        }} />
                                        :
                                        <MaterialTable
                                            style={{ zIndex: '1', position: '' }}
                                            columns={[
                                                {
                                                    title: 'S.N',
                                                    field: 'id',
                                                    editable: 'never',
                                                    render: rowData => <>{rowData.tableData.id + 1}</>
                                                },
                                                {
                                                    title: 'Title',
                                                    field: 'title',
                                                    render: rowData => <b>{rowData.title}</b>
                                                },
                                                {
                                                    title: 'Image',
                                                    field: 'image',
                                                    editable: 'never',
                                                    render: rowData => <a href={rowData.image} target='_blank'>View </a>
                                                },
                                                {
                                                    title: 'Category',
                                                    field: 'category',
                                                    render: rowData => <>{rowData.category || 'N/A'}</>

                                                }
                                                // { title: 'Description', field: 'description' },
                                            ]}
                                            data={this.state.data}
                                            title='Article'
                                            options={{
                                                //   exportButton: true,
                                                // exportAllData: true,
                                                sorting: true,
                                                actionsColumnIndex: -1,
                                                pageSize: 20,
                                                pageSizeOptions: [20, 50, 100],
                                                headerStyle: {
                                                    backgroundColor: '#6772E5',
                                                    color: '#FFF',
                                                    searchFieldAlignment: 'left'
                                                }

                                            }}
                                            detailPanel={rowData => {
                                                return (
                                                    <>
                                                        <p style={{
                                                            padding: 20
                                                        }}>{ReactHtmlParser(rowData.description)}</p>
                                                    </>
                                                )
                                            }}
                                            actions={[
                                                {
                                                    icon: 'delete',
                                                    tooltip: 'Delete Article ',
                                                    onClick: (event, rowData) => {
                                                        // Do save operation
                                                        // Services.deleteArticle(rowData.id).then((response) =>{
                                                        //     this.getCourseList()
                                                        // })
                                                        this.setState({ confirmModal: true, selectedRow: rowData })
                                                    }
                                                },
                                                {
                                                    icon: () => <>
                                                        {/*<Select*/}
                                                        {/*    style={{width:'100%'}}*/}
                                                        {/*    labelId='demo-simple-select-label'*/}
                                                        {/*    id='demo-simple-select'*/}
                                                        {/*    value={this.state.selectedCategory}*/}
                                                        {/*    onChange={this.handleCategoryChange}*/}
                                                        {/*    label={'Select Category'}*/}
                                                        {/*>*/}
                                                        {/*    <MenuItem value={''}>Select Category</MenuItem>*/}
                                                        {/*    <MenuItem value={'Grammar'}>Grammar</MenuItem>*/}
                                                        {/*    <MenuItem value={'Vocabulary'}>Vocabulary</MenuItem>*/}
                                                        {/*    <MenuItem value={'Story'}>Story</MenuItem>*/}
                                                        {/*    <MenuItem value={'Essay'}>Essay</MenuItem>*/}
                                                        {/*    <MenuItem value={'News/Update'}>News/Update</MenuItem>*/}
                                                        {/*    <MenuItem*/}
                                                        {/*        value={'Speaking/Conversation'}>Speaking/Conversation</MenuItem>*/}
                                                        {/*    <MenuItem value={'PracticeMaterials'}>Practice*/}
                                                        {/*        Materials</MenuItem>*/}
                                                        {/*    <MenuItem value={'DailyUpdate'}> Daily Update</MenuItem>*/}
                                                        {/*    <MenuItem value={'Others'}> Others</MenuItem>*/}
                                                        {/*</Select>*/}
                                                        {/*<Input className={'inside-table ml-2'} type={'select'}*/}
                                                        {/*       value={this.state.selectedCategory}*/}
                                                        {/*       onChange={this.handleCategoryChange}>*/}
                                                        {/*    <option value={''}>Select Category</option>*/}
                                                        {/*    <option value={'Grammar'}>Grammar</option>*/}
                                                        {/*    <option value={'Vocabulary'}>Vocabulary</option>*/}
                                                        {/*    <option value={'Story'}>Story</option>*/}
                                                        {/*    <option value={'Essay'}>Essay</option>*/}
                                                        {/*    <option value={'News/Update'}>News/Update</option>*/}
                                                        {/*    <option*/}
                                                        {/*        value={'Speaking/Conversation'}>Speaking/Conversation*/}
                                                        {/*    </option>*/}
                                                        {/*    <option value={'PracticeMaterials'}>Practice*/}
                                                        {/*        Materials*/}
                                                        {/*    </option>*/}
                                                        {/*    <option value={'DailyUpdate'}> Daily Update</option>*/}
                                                        {/*    <option value={'Others'}> Others</option>*/}
                                                        {/*</Input>*/}
                                                    </>,
                                                    // tooltip: 'Select Category',
                                                    isFreeAction: true
                                                },
                                                {
                                                    icon: () => <button className='addNewLessonButton'>Create
                                                        Article</button>,
                                                    tooltip: 'Add Article',
                                                    isFreeAction: true,
                                                    onClick: (event) => {
                                                        this.toggleState('passwordModal')
                                                    }
                                                },
                                                {
                                                    icon: 'info',
                                                    tooltip: 'Details',
                                                    isFreeAction: false,
                                                    onClick: (event, rowData) => {
                                                        this.updateEditState(rowData)
                                                        this.toggleState('editFormView')
                                                    }
                                                }
                                            ]}


                                            editable={{

                                                onRowUpdate: (newData, oldData) =>

                                                    new Promise((resolve, reject) => {
                                                        let newRowUpdate = {
                                                            id: newData.id,
                                                            link_image: newData.link_image,
                                                            objects: newData.objects,
                                                            description: newData.description,
                                                            title: newData.title,
                                                            link_slug: newData.link_slug,
                                                            is_archived: false
                                                        }
                                                        setTimeout(() => {
                                                            {
                                                                Services.updateLink(oldData.id, newRowUpdate).then((response) => {
                                                                    this.getLessonList()
                                                                    this.props.enqueueSnackbar('Free Lesson Updated Successfully', {
                                                                        variant: 'success',
                                                                        anchorOrigin: {
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right'
                                                                        }
                                                                    })
                                                                })
                                                                    .catch((error) => {
                                                                        this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                                            variant: 'warning',
                                                                            anchorOrigin: {
                                                                                vertical: 'bottom',
                                                                                horizontal: 'right'
                                                                            }
                                                                        }
                                                                        )
                                                                    })
                                                            }
                                                            resolve()
                                                        }, 300)
                                                    })
                                            }}
                                            detailPanel={rowData => {
                                                return (
                                                    <>
                                                        <Button onClick={() => {
                                                            this.toggleState('showEditModal')
                                                            this.updateEditState(rowData)
                                                        }} className={'mt-1 float-right btn btn-success'}>Edit</Button>
                                                        {rowData && rowData.objects.map((item, index) => (
                                                            <>
                                                                <div key={index}>
                                                                    {item.text &&
                                                                        <div
                                                                            style={{
                                                                                textAlign: item.orientation,
                                                                                marginTop: '10px',
                                                                                padding: '10px'
                                                                            }}
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: item.text
                                                                            }}>
                                                                        </div>
                                                                    }
                                                                    {item.youtube_link &&
                                                                        <div style={{
                                                                            width: '100%',
                                                                            position: 'relative',
                                                                            marginTop: '10px'
                                                                        }}
                                                                        >
                                                                            <ReactPlayer
                                                                                url={item.youtube_link}
                                                                                className='react-player'
                                                                                // playing={true}
                                                                                controls={true}
                                                                                width='100%'
                                                                                height='620px' />
                                                                        </div>
                                                                    }
                                                                    {item.image &&
                                                                        <div style={{
                                                                            marginTop: '10px',
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            justifyContent: 'center',
                                                                            width: '100%'
                                                                        }}>
                                                                            <img style={{
                                                                                width: '100%',
                                                                                height: '40%',
                                                                                objectFit: 'contain'
                                                                            }} src={item.image}
                                                                                alt={''} />

                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        ))}
                                                    </>
                                                )
                                            }}
                                            components={{
                                                Toolbar: props => (
                                                    <div>
                                                        <MTableToolbar {...props} />
                                                    </div>
                                                )
                                            }}
                                        />
                                    :
                                    <>
                                        {this.state.addFormView && !(this.state.editFormView) ?
                                            <>
                                                <div className='d-flex mt-3'>
                                                    <div className='mr-auto ml-4 mt-2'><h2>Article</h2></div>
                                                    <div className='p-2'>
                                                        <Button className='btn-icon btn-3' color='primary' type='button'
                                                            onClick={() => {
                                                                this.toggleState('addFormView')
                                                                this.toggleState('courseSelectContainer')
                                                            }}>
                                                            <span className='btn-inner--icon'>
                                                                <i className='ni ni-bag-17' />
                                                            </span>
                                                            <span className='btn-inner--text'>Back</span>
                                                        </Button>
                                                    </div>
                                                </div>

                                                <hr className='solid'></hr>
                                                <ArticleNewForm copyStatus={this.state.copyStatus}
                                                    editorState={this.state.editorStateCopied}
                                                    editorContentHtml={this.state.editorContentHtmlCopied}
                                                    onSuccess={(id, selectedOption) => {
                                                        this.setState({
                                                            addFormView: false,
                                                            selectedOption: selectedOption
                                                        })
                                                        this.getLessonList()
                                                        this.toggleState('courseSelectContainer')
                                                    }}></ArticleNewForm>
                                            </> :
                                            <>
                                                <div className='d-flex mt-3'>
                                                    <div className='mr-auto ml-4 mt-2'><h2>Update Article</h2></div>
                                                    <div className='p-2'>
                                                        <Button className='btn-icon btn-3' color='primary' type='button'
                                                            onClick={() => {
                                                                this.toggleState('editFormView')
                                                                this.toggleState('courseSelectContainer')
                                                            }}>
                                                            <span className='btn-inner--icon'>
                                                                <i className='ni ni-bag-17' />
                                                            </span>
                                                            <span className='btn-inner--text'>Back</span>
                                                        </Button>&nbsp;
                                                    </div>
                                                </div>

                                                <hr className='solid'></hr>
                                                <ScrollToTop top={800} smooth color='#1178EF'><i
                                                    className={'fa fa-arrow-up'} /> </ScrollToTop>

                                                <Form role='form' style={{
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    width: '100%'
                                                }}>
                                                    <div className={'d-flex flex-row-reverse'}>
                                                        {this.state.title.length > 0 && <span style={{ fontSize: 18 }}
                                                            className={'font-weight-bold'}>{this.state.title.length}/200</span>}
                                                    </div>
                                                    <FormGroup style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                        <InputGroup className='input-group-alternative mb-3'>
                                                            <InputGroupAddon addonType='prepend'>
                                                                <InputGroupText>
                                                                    <i className='ni ni-hat-3' />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input placeholder='Title' type='text' name='title'
                                                                value={this.state.title}
                                                                onChange={this.handleInputChange} />
                                                        </InputGroup>
                                                        <InputGroup style={{ width: '40%' }}
                                                            className='ml-3 input-group-alternative mb-3'>
                                                            <Input className={'ml-2'} type={'select'} name={'category'}
                                                                value={this.state.category}
                                                                onChange={this.handleInputChange}>
                                                                <option>Select Category</option>
                                                                <option value={'Paid'}>Paid</option>
                                                                <option value={'Unpaid'}>Unpaid</option>
                                                                <option value={'Completed'}>Completed</option>
                                                                <option value={'For_All'}>For All</option>
                                                            </Input>
                                                        </InputGroup>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center'
                                                        }}
                                                            className='custom-control custom-control-alternative custom-checkbox mb-3'>
                                                            <input
                                                                className='custom-control-input'
                                                                id='notification'
                                                                name='notification'
                                                                type='checkbox'
                                                                onChange={this.handleInputChange}
                                                            />
                                                            <label style={{ fontSize: 16 }}
                                                                className='custom-control-label'
                                                                htmlFor='notification'>
                                                                Send Notification
                                                            </label>
                                                        </div>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            <Label>Short Description </Label>
                                                            {this.state.description.length > 0 &&
                                                                <span style={{ fontSize: 18 }}
                                                                    className={'font-weight-bold'}>{this.state.description.length}/500</span>}
                                                        </div>
                                                        <InputGroup className='input-group-alternative mb-3'>
                                                            <Input rows='4' placeholder='Description' type='textarea'
                                                                row={1} name='description'
                                                                value={this.state.description}
                                                                onChange={this.handleInputChange} />
                                                        </InputGroup>
                                                    </FormGroup>
                                                    <br />
                                                    <FormGroup encType='multipart/form-data'>
                                                        <p className={'font-weight-bold'}>{this.state.imagePreview ? 'Change thumbnail' : 'Add thumbnail'}</p>
                                                        <input className='thumbnail_upload_button' accept='png/jpeg'
                                                            type='file'
                                                            onChange={this.handleThumbnailImageSelect} />
                                                        <div

                                                            onPaste={this.handlePasteImage}
                                                            style={{ border: '1px solid #ccc', padding: '10px', height: '80px' }}
                                                        >
                                                        </div>
                                                        <br />
                                                        {this.state.imagePreview &&
                                                            <div className='thumbnailContainer'><i
                                                                onClick={this.handleThumbnailImageRemove}
                                                                className='far fa-times-circle'></i> <img
                                                                    className='lessonFormThumbnailImage' alt='thumbnail'
                                                                    src={this.state.imagePreview} /></div>}
                                                    </FormGroup>
                                                    <br />
                                                    {this.state.link.itemList && this.state.link.itemList.map((item, idx) => (
                                                        <div>
                                                            {this.state.link.itemList[idx].showText &&
                                                                <div className={'mt-3'}>
                                                                    <h4>Text</h4>
                                                                    <Editor
                                                                        key={idx}
                                                                        handlePastedText={() => false}
                                                                        editorStyle={{
                                                                            border: '1px solid black',
                                                                            padding: '5px',
                                                                            borderRadius: '2px',
                                                                            height: 'auto',
                                                                            width: '100%',
                                                                            fontSize: '18px'
                                                                        }}
                                                                        placeholder='Description / Notes  [NEW Layout]'
                                                                        editorState={item.editorState}
                                                                        wrapperClassName='demo-wrapper'
                                                                        editorClassName='demo-editor'
                                                                        onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, idx)}
                                                                        toolbar={{
                                                                            inline: { inDropdown: true },
                                                                            list: { inDropdown: true },
                                                                            textAlign: { inDropdown: true },
                                                                            link: { inDropdown: true },
                                                                            history: { inDropdown: true },
                                                                            image: {
                                                                                uploadCallback: (file) => this.uploadImageCallBack(file),
                                                                                previewImage: true,
                                                                                defaultSize: {
                                                                                    width: '100%',
                                                                                    height: 'auto'
                                                                                }
                                                                            }
                                                                        }}
                                                                    />

                                                                    <FormGroup className={'mt-1'} style={{
                                                                        display: 'flex',
                                                                        width: '50%',
                                                                        justifyContent: 'space-between'
                                                                    }}>
                                                                        <InputGroup
                                                                            className='input-group-alternative mb-3'>
                                                                            <InputGroupAddon addonType='prepend'>
                                                                                <InputGroupText>
                                                                                    <i className='ni ni-hat-3' />
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input placeholder='Order' type='text'
                                                                                name='order'
                                                                                value={item.order}
                                                                                onChange={(event) => this.editItem(event, idx)} />
                                                                        </InputGroup>
                                                                        <Input className={'ml-2'} type={'select'}
                                                                            name={'orientation'}
                                                                            value={item.orientation}
                                                                            onChange={(event) => this.editItem(event, idx)}>
                                                                            <option>Select Orientation</option>
                                                                            <option value={'left'}>Left</option>
                                                                            <option value={'center'}>Center</option>
                                                                            <option value={'right'}>Right</option>
                                                                        </Input>
                                                                    </FormGroup>
                                                                </div>
                                                            }
                                                            {this.state?.link?.itemList[idx]?.showSubTitle &&
                                                                <>
                                                                    <div className={'d-flex justify-content-between'}>
                                                                        <h4>Title</h4>
                                                                        {item.subTitle.length > 0 &&
                                                                            <span style={{ fontSize: 22 }}
                                                                                className={'font-weight-bold'}>{item.subTitle.length}/255</span>}
                                                                    </div>
                                                                    <FormGroup className={'mt-3 mb-0'}
                                                                        style={{
                                                                            display: 'flex',
                                                                            width: '100%',
                                                                            height: 'auto'
                                                                        }}>
                                                                        <InputGroup className='input-group-alternative'>
                                                                            <Input type='textarea' placeholder='Title'
                                                                                rows={'3'}
                                                                                name='subTitle'
                                                                                value={item.subTitle}
                                                                                onChange={(event) => this.editItem(event, idx)} />
                                                                        </InputGroup>
                                                                    </FormGroup>

                                                                    <FormGroup style={{
                                                                        display: 'flex',
                                                                        width: '50%',
                                                                        justifyContent: 'space-between',
                                                                        marginTop: '5px',
                                                                        gap: '10px'
                                                                    }}>
                                                                        <InputGroup
                                                                            className='input-group-alternative mb-3'>
                                                                            <InputGroupAddon addonType='prepend'>
                                                                                <InputGroupText>
                                                                                    <i className='ni ni-hat-3' />
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input placeholder='Order' type='text'
                                                                                name='order' value={item.order}
                                                                                onChange={(event) => this.editItem(event, idx)} />

                                                                        </InputGroup>
                                                                        <Input type={'select'}
                                                                            name={'orientation'}
                                                                            value={item.orientation}
                                                                            onChange={(event) => this.editItem(event, idx)}>
                                                                            <option>Select Orientation</option>
                                                                            <option value={'left'}>Left</option>
                                                                            <option value={'center'}>Center</option>
                                                                            <option value={'right'}>Right</option>
                                                                        </Input>

                                                                    </FormGroup>
                                                                </>
                                                            }
                                                            {this.state?.link?.itemList[idx].showImage &&
                                                                <>
                                                                    <div>
                                                                        <FormGroup className={'mb-0'}>
                                                                            <h4>Upload Image</h4>
                                                                            <input className='thumbnail_upload_button'
                                                                                accept='png/jpeg'
                                                                                type='file'
                                                                                onChange={(e) => this.handleImageSelect(e, idx)} />
                                                                            <br />
                                                                            {item.image &&
                                                                                <div
                                                                                    className='thumbnailContainerAddLesson'>
                                                                                    <i
                                                                                        onClick={(e) => this.handleImageRemove(e, idx)}
                                                                                        class='far fa-times-circle'></i>
                                                                                    <img
                                                                                        className='lessonFormThumbnailImage'
                                                                                        src={item.image} />
                                                                                </div>}

                                                                        </FormGroup>
                                                                        <FormGroup style={{
                                                                            display: 'flex',
                                                                            width: '50%',
                                                                            justifyContent: 'space-between'
                                                                        }}>
                                                                            <InputGroup
                                                                                className='input-group-alternative mb-3'>
                                                                                <InputGroupAddon addonType='prepend'>
                                                                                    <InputGroupText>
                                                                                        <i className='ni ni-hat-3' />
                                                                                    </InputGroupText>
                                                                                </InputGroupAddon>
                                                                                <Input placeholder='Order' type='text'
                                                                                    name='order'
                                                                                    value={item.order}
                                                                                    onChange={(event) => this.editItem(event, idx)} />
                                                                            </InputGroup>
                                                                        </FormGroup>
                                                                    </div>
                                                                </>
                                                            }

                                                            {this.state?.link?.itemList[idx]?.showVideo &&
                                                                <>
                                                                    <h4>Video Link</h4>
                                                                    <FormGroup className={'mt-3 mb-0'}
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between'
                                                                        }}>
                                                                        <InputGroup
                                                                            className='input-group-alternative mb-3'>
                                                                            <InputGroupAddon addonType='prepend'>
                                                                                <InputGroupText>
                                                                                    <i className='ni ni-hat-3' />
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input placeholder='Video Link' type='text'
                                                                                name='video'
                                                                                value={item.video}
                                                                                onChange={(event) => this.editItem(event, idx)} />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <h4>Upload Thumbnail Image</h4>
                                                                        <input className='thumbnail_upload_button'
                                                                            accept='png/jpeg'
                                                                            type='file'
                                                                            onChange={(e) => this.handleYoutubeImageSelect(e, idx)} />
                                                                        <br />
                                                                        {item.thumbnail &&
                                                                            <div
                                                                                className='thumbnailContainerAddLesson'>
                                                                                <i
                                                                                    onClick={(e) => this.handleYoutubeImageRemove(e, idx)}
                                                                                    class='far fa-times-circle'></i>
                                                                                <img
                                                                                    className='lessonFormThumbnailImage'
                                                                                    src={item.thumbnail} />
                                                                            </div>}

                                                                    </FormGroup>
                                                                    <FormGroup style={{
                                                                        display: 'flex',
                                                                        width: '50%',
                                                                        justifyContent: 'space-between',
                                                                        marginTop: '0px'
                                                                    }}>
                                                                        <InputGroup
                                                                            className='input-group-alternative mb-3'>
                                                                            <InputGroupAddon addonType='prepend'>
                                                                                <InputGroupText>
                                                                                    <i className='ni ni-hat-3' />
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input placeholder='Order' type='text'
                                                                                name='order'
                                                                                value={item.order}
                                                                                onChange={(event) => this.editItem(event, idx)} />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </>
                                                            }
                                                            {this.state?.link?.itemList[idx]?.showButton &&
                                                                <>
                                                                    <div className={'d-flex justify-content-between'}>
                                                                        <h4>Button Link </h4>
                                                                        {item.buttonName.length > 0 &&
                                                                            <span style={{ fontSize: 18 }}
                                                                                className={'font-weight-bold'}>{item.buttonName.length}/100</span>}
                                                                    </div>
                                                                    <FormGroup className={'mt-3 mb-0'}
                                                                        style={{
                                                                            width: '100%',
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between',
                                                                            gap: '10px'
                                                                        }}>
                                                                        <div style={{ display: 'flex', width: '50%' }}>
                                                                            <InputGroup
                                                                                className='input-group-alternative mb-3'>
                                                                                <InputGroupAddon addonType='prepend'>
                                                                                    <InputGroupText>
                                                                                        <i className='ni ni-hat-3' />
                                                                                    </InputGroupText>
                                                                                </InputGroupAddon>
                                                                                <Input placeholder='Button Name'
                                                                                    type='text'
                                                                                    name='buttonName'
                                                                                    value={item.buttonName}
                                                                                    onChange={(event) => this.editItem(event, idx)} />
                                                                            </InputGroup>
                                                                        </div>
                                                                        <InputGroup
                                                                            className='input-group-alternative mb-3'>
                                                                            <InputGroupAddon addonType='prepend'>
                                                                                <InputGroupText>
                                                                                    <i className='ni ni-hat-3' />
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input placeholder='Button Link' type='text'
                                                                                name='buttonLink'
                                                                                value={item.buttonLink}
                                                                                onChange={(event) => this.editItem(event, idx)} />
                                                                        </InputGroup>
                                                                    </FormGroup>

                                                                    <FormGroup style={{
                                                                        display: 'flex',
                                                                        width: '50%',
                                                                        justifyContent: 'space-between',
                                                                        marginTop: '0px',
                                                                        gap: '10px'
                                                                    }}>
                                                                        <InputGroup
                                                                            className='input-group-alternative mb-3'>
                                                                            <InputGroupAddon addonType='prepend'>
                                                                                <InputGroupText>
                                                                                    <i className='ni ni-hat-3' />
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input placeholder='Order' type='text'
                                                                                name='order' value={item.order}
                                                                                onChange={(event) => this.editItem(event, idx)} />

                                                                        </InputGroup>
                                                                        <Input type={'select'}
                                                                            name={'orientation'}
                                                                            value={item.orientation}
                                                                            onChange={(event) => this.editItem(event, idx)}>
                                                                            <option>Select Orientation</option>
                                                                            <option value={'left'}>Left</option>
                                                                            <option value={'center'}>Center</option>
                                                                            <option value={'right'}>Right</option>
                                                                        </Input>

                                                                    </FormGroup>
                                                                </>
                                                            }
                                                            {this.state?.link?.itemList[idx]?.showTable &&
                                                                <>
                                                                    <h4>Table Data</h4>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column'
                                                                    }}>
                                                                        <textarea
                                                                            rows='3'
                                                                            placeholder='Paste your excel form data here...'
                                                                            // onPaste={this.handlePaste}
                                                                            onChange={(event) => this.editItem(event, idx, 'table')}
                                                                            value={this.state.link.itemList[idx]?.rawStr}
                                                                        />
                                                                        {this.state.link.itemList[idx]?.tableArray && this.tableRow(this.state.link.itemList[idx]?.tableArray, idx)}
                                                                        {/*{this.state?.link?.itemList[idx]?.columns?.length > 0 && this.state.link.itemList[idx].tableData?.length > 0 &&*/}
                                                                        {/*    <TableRow*/}
                                                                        {/*        columns={this.state?.link?.itemList[idx]?.columns}*/}
                                                                        {/*        data={this.state?.link?.itemList[idx]?.tableData}*/}
                                                                        {/*        handleTablePaste={this.handleTablePaste}*/}
                                                                        {/*        updateData={this.updateData} />*/}
                                                                        {/*}*/}
                                                                    </div>

                                                                    <FormGroup style={{
                                                                        display: 'flex',
                                                                        width: '50%',
                                                                        justifyContent: 'space-between',
                                                                        marginTop: '0px'
                                                                    }}>
                                                                        <InputGroup
                                                                            className='input-group-alternative mb-3'>
                                                                            <InputGroupAddon addonType='prepend'>
                                                                                <InputGroupText>
                                                                                    <i className='ni ni-hat-3' />
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input placeholder='Order' type='text'
                                                                                name='order' value={item.order}
                                                                                onChange={(event) => this.editItem(event, idx)} />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </>
                                                            }
                                                            {this.state?.link?.itemList[idx]?.showQuiz &&
                                                                <>
                                                                    <>
                                                                        <div
                                                                            className={'d-flex justify-content-between'}>
                                                                            <h4>Payment</h4>
                                                                            {item?.paymentButtonName?.length > 0 &&
                                                                                <span style={{ fontSize: 18 }}
                                                                                    className={'font-weight-bold'}>{item?.paymentButtonName?.length}/100</span>}
                                                                        </div>
                                                                        {this.state.category !== 'Paid' && this.state.category !== 'Completed' &&
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'row',
                                                                                    alignItems: 'center'
                                                                                }}>
                                                                                <label className={'ml-2'}>
                                                                                    <span
                                                                                        className={`switch-wrapper`}>
                                                                                        <input name={'paymentCategory'}
                                                                                            type='checkbox'
                                                                                            checked={item.paymentCategory}
                                                                                            onChange={(event) => this.editItem(event, idx, 'paymentCategory')} />
                                                                                        <span
                                                                                            className={`${item.paymentCategory ? 'btn-danger' : 'btn-success'} switch`}>
                                                                                            <span
                                                                                                className='switch-handle' />
                                                                                        </span>
                                                                                    </span>
                                                                                    <span
                                                                                        className='switch-label'>{item.paymentCategory ? 'Unpaid' : 'Normal'}</span>
                                                                                </label>
                                                                            </div>
                                                                        }
                                                                        <FormGroup className={'mt-3 mb-0'}
                                                                            style={{
                                                                                width: '100%',
                                                                                display: 'flex',
                                                                                justifyContent: 'space-between',
                                                                                gap: '10px'
                                                                            }}>
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                width: '100%'
                                                                            }}>
                                                                                <InputGroup
                                                                                    className='input-group-alternative mb-3'>
                                                                                    <InputGroupAddon
                                                                                        addonType='prepend'>
                                                                                        <InputGroupText>
                                                                                            <i className='ni ni-hat-3' />
                                                                                        </InputGroupText>
                                                                                    </InputGroupAddon>
                                                                                    <Input
                                                                                        placeholder='Payment Button Name'
                                                                                        type='text'
                                                                                        name='paymentButtonName'
                                                                                        value={item.paymentButtonName}
                                                                                        onChange={(event) => this.editItem(event, idx)} />
                                                                                </InputGroup>
                                                                            </div>
                                                                            {!item.paymentCategory &&
                                                                                <InputGroup
                                                                                    className='input-group-alternative mb-3'>
                                                                                    <InputGroupAddon
                                                                                        addonType='prepend'>
                                                                                        <InputGroupText>
                                                                                            <i className='ni ni-hat-3' />
                                                                                        </InputGroupText>
                                                                                    </InputGroupAddon>
                                                                                    <Input placeholder='Payment Amount'
                                                                                        type='text'
                                                                                        name='paymentAmount'
                                                                                        value={item.paymentAmount}
                                                                                        onChange={(event) => this.editItem(event, idx)} />
                                                                                </InputGroup>
                                                                            }
                                                                        </FormGroup>

                                                                        <FormGroup style={{
                                                                            display: 'flex',
                                                                            width: '50%',
                                                                            justifyContent: 'space-between',
                                                                            marginTop: '0px',
                                                                            gap: '10px'
                                                                        }}>
                                                                            <InputGroup
                                                                                className='input-group-alternative mb-3'>
                                                                                <InputGroupAddon addonType='prepend'>
                                                                                    <InputGroupText>
                                                                                        <i className='ni ni-hat-3' />
                                                                                    </InputGroupText>
                                                                                </InputGroupAddon>
                                                                                <Input placeholder='Order' type='text'
                                                                                    name='order' value={item.order}
                                                                                    onChange={(event) => this.editItem(event, idx)} />

                                                                            </InputGroup>
                                                                            <Input type={'select'}
                                                                                name={'orientation'}
                                                                                value={item.orientation}
                                                                                onChange={(event) => this.editItem(event, idx)}>
                                                                                <option>Select Orientation</option>
                                                                                <option value={'left'}>Left</option>
                                                                                <option value={'center'}>Center</option>
                                                                                <option value={'right'}>Right</option>
                                                                            </Input>

                                                                        </FormGroup>
                                                                    </>

                                                                </>
                                                            }
                                                            <Row key={idx}>
                                                                {this.state?.link?.itemList[idx]?.showAllButton &&
                                                                    <>
                                                                        <Col xl={12} className={'mb-2'}>

                                                                            {!this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showTable && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo && !this.state.link.itemList[idx].showText &&
                                                                                <Button
                                                                                    onClick={() => this.addButtonHandler('showSubTitle', idx)}
                                                                                    className={'btn btn-primary'}><i
                                                                                        className={'fa fa-plus'} />&nbsp;&nbsp; Add
                                                                                    Title</Button>
                                                                            }
                                                                            {this.state.link.itemList[idx].showSubTitle && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showText && !this.state?.link?.itemList[idx]?.showTable && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'flex-start'
                                                                                }}>
                                                                                    {!this.state.link.itemList.length <= 1 &&
                                                                                        <>
                                                                                            <Button onClick={() => {
                                                                                                this.addButtonHandler('showSubTitle', idx)
                                                                                                this.removeItem(idx)
                                                                                            }}
                                                                                                style={{
                                                                                                    backgroundColor: 'red',
                                                                                                    color: 'white'
                                                                                                }}
                                                                                                className={'btn'}><i
                                                                                                    className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                                                                                        </>
                                                                                    }
                                                                                    {this.state.link.itemList[idx].addButton &&
                                                                                        <Button
                                                                                            onClick={() => this.addItem(idx)}
                                                                                            className={'btn'}><i
                                                                                                className={'fa fa-plus'} />&nbsp;&nbsp;Add
                                                                                        </Button>
                                                                                    }
                                                                                </div>
                                                                            }

                                                                            {!this.state?.link?.itemList[idx]?.showText && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showTable && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo && !this.state.link.itemList[idx].showSubTitle &&
                                                                                <Button
                                                                                    onClick={() => this.addButtonHandler('showText', idx)}
                                                                                    className={'btn btn-primary'}><i
                                                                                        className={'fa fa-plus'} />&nbsp;&nbsp; Add
                                                                                    Text</Button>
                                                                            }
                                                                            {this.state.link.itemList[idx].showText && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showTable && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'flex-start'
                                                                                }}>
                                                                                    {!this.state.link.itemList.length <= 1 &&
                                                                                        <>
                                                                                            <Button onClick={() => {
                                                                                                this.addButtonHandler('showText', idx)
                                                                                                this.removeItem(idx)
                                                                                            }}
                                                                                                style={{
                                                                                                    backgroundColor: 'red',
                                                                                                    color: 'white'
                                                                                                }}
                                                                                                className={'btn'}><i
                                                                                                    className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                                                                                        </>
                                                                                    }
                                                                                    {this.state.link.itemList[idx].addButton &&
                                                                                        <Button
                                                                                            onClick={() => this.addItem(idx)}
                                                                                            className={'btn'}><i
                                                                                                className={'fa fa-plus'} />&nbsp;&nbsp;Add
                                                                                        </Button>
                                                                                    }
                                                                                </div>
                                                                            }

                                                                            {!this.state?.link?.itemList[idx]?.showImage && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showTable && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showVideo &&
                                                                                <Button
                                                                                    onClick={() => this.addButtonHandler('showImage', idx)}
                                                                                    className={'btn btn-primary'}><i
                                                                                        className={'fa fa-plus'} />&nbsp;&nbsp; Add
                                                                                    Image</Button>

                                                                            }
                                                                            {this.state.link.itemList[idx].showImage && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showTable && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showVideo &&
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'flex-start'
                                                                                }}>
                                                                                    <Button onClick={() => {
                                                                                        this.addButtonHandler('showImage', idx)
                                                                                        this.removeItem(idx)
                                                                                    }}
                                                                                        className={'btn'}
                                                                                        style={{
                                                                                            backgroundColor: 'red',
                                                                                            color: 'white'
                                                                                        }}><i
                                                                                            className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                                                                                    {this.state.link.itemList[idx].addButton &&
                                                                                        <Button
                                                                                            onClick={() => this.addItem(idx)}
                                                                                            className={'btn'}><i
                                                                                                className={'fa fa-plus'} />&nbsp;&nbsp;Add
                                                                                        </Button>
                                                                                    }
                                                                                </div>
                                                                            }


                                                                            {!this.state?.link?.itemList[idx]?.showVideo && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showTable && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage &&
                                                                                <Button
                                                                                    onClick={() => this.addButtonHandler('showVideo', idx)}
                                                                                    className={'btn btn-primary'}><i
                                                                                        className={'fa fa-plus'} />&nbsp;&nbsp; Add
                                                                                    Video</Button>
                                                                            }
                                                                            {this.state.link.itemList[idx].showVideo && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showTable && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage &&
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'flex-start'
                                                                                }}>
                                                                                    <Button onClick={() => {
                                                                                        this.addButtonHandler('showVideo', idx)
                                                                                        this.removeItem(idx)
                                                                                    }}
                                                                                        className={'btn'}
                                                                                        style={{
                                                                                            backgroundColor: 'red',
                                                                                            color: 'white'
                                                                                        }}><i
                                                                                            className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                                                                                    {this.state.link.itemList[idx].addButton &&
                                                                                        <Button
                                                                                            onClick={() => this.addItem(idx)}
                                                                                            className={'btn'}><i
                                                                                                className={'fa fa-plus'} />&nbsp;&nbsp;Add
                                                                                        </Button>
                                                                                    }
                                                                                </div>

                                                                            }
                                                                            {!this.state?.link?.itemList[idx]?.showTable && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showVideo && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage &&
                                                                                <Button
                                                                                    onClick={() => this.addButtonHandler('showTable', idx)}
                                                                                    className={'btn btn-primary'}><i
                                                                                        className={'fa fa-plus'} />&nbsp;&nbsp; Add
                                                                                    Table</Button>
                                                                            }
                                                                            {this.state.link.itemList[idx].showTable && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'flex-start'
                                                                                }}>
                                                                                    {!this.state.link.itemList.length <= 1 &&
                                                                                        <>
                                                                                            <Button onClick={() => {
                                                                                                this.addButtonHandler('showTable', idx)
                                                                                                this.removeItem(idx)
                                                                                            }}
                                                                                                style={{
                                                                                                    backgroundColor: 'red',
                                                                                                    color: 'white'
                                                                                                }}
                                                                                                className={'btn'}><i
                                                                                                    className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                                                                                        </>
                                                                                    }
                                                                                    {this.state.link.itemList[idx].addButton &&
                                                                                        <Button
                                                                                            onClick={() => this.addItem(idx)}
                                                                                            className={'btn'}><i
                                                                                                className={'fa fa-plus'} />&nbsp;&nbsp;Add
                                                                                        </Button>
                                                                                    }
                                                                                </div>

                                                                            }

                                                                            {!this.state?.link?.itemList[idx]?.showButton && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state.link.itemList[idx].showVideo && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showTable &&
                                                                                <Button
                                                                                    onClick={() => this.addButtonHandler('showButton', idx)}
                                                                                    className={'btn btn-primary'}><i
                                                                                        className={'fa fa-plus'} />&nbsp;&nbsp; Add
                                                                                    Button</Button>
                                                                            }
                                                                            {this.state.link.itemList[idx].showButton && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'flex-start'
                                                                                }}>
                                                                                    {!this.state.link.itemList.length <= 1 &&
                                                                                        <>
                                                                                            <Button onClick={() => {
                                                                                                this.addButtonHandler('showButton', idx)
                                                                                                this.removeItem(idx)
                                                                                            }}
                                                                                                style={{
                                                                                                    backgroundColor: 'red',
                                                                                                    color: 'white'
                                                                                                }}
                                                                                                className={'btn'}><i
                                                                                                    className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                                                                                        </>
                                                                                    }
                                                                                    {this.state.link.itemList[idx].addButton &&
                                                                                        <Button
                                                                                            onClick={() => this.addItem(idx)}
                                                                                            className={'btn'}><i
                                                                                                className={'fa fa-plus'} />&nbsp;&nbsp;Add
                                                                                        </Button>
                                                                                    }
                                                                                </div>
                                                                            }

                                                                            {!this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showTable && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo && !this.state.link.itemList[idx].showText &&
                                                                                <Button
                                                                                    onClick={() => this.addButtonHandler('showQuiz', idx)}
                                                                                    className={'btn btn-primary'}><i
                                                                                        className={'fa fa-plus'} />&nbsp;&nbsp; Add
                                                                                    Payment</Button>
                                                                            }
                                                                            {this.state.link.itemList[idx].showQuiz && !this.state.link.itemList[idx].showSubTitle && !this.state?.link?.itemList[idx]?.showText && !this.state?.link?.itemList[idx]?.showTable && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'flex-start'
                                                                                }}>
                                                                                    {!this.state.link.itemList.length <= 1 &&
                                                                                        <>
                                                                                            <Button onClick={() => {
                                                                                                this.addButtonHandler('showQuiz', idx)
                                                                                                this.removeItem(idx)
                                                                                            }}
                                                                                                style={{
                                                                                                    backgroundColor: 'red',
                                                                                                    color: 'white'
                                                                                                }}
                                                                                                className={'btn'}><i
                                                                                                    className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                                                                                        </>
                                                                                    }
                                                                                    {this.state.link.itemList[idx].addButton &&
                                                                                        <Button
                                                                                            onClick={() => this.addItem(idx)}
                                                                                            className={'btn'}><i
                                                                                                className={'fa fa-plus'} />&nbsp;&nbsp;Add
                                                                                        </Button>
                                                                                    }
                                                                                </div>
                                                                            }

                                                                        </Col>
                                                                    </>
                                                                }
                                                            </Row>
                                                        </div>
                                                    ))}
                                                    <div className='pb-4'>
                                                        <Button disabled={this.state.disabled} className='mt-4' color='primary' type='button'
                                                            onClick={() => this.handleUpdate()}>
                                                            Update
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </>
                                        }
                                    </>
                                }
                            </Card>
                        </div>
                    </Row>
                </div>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.confirmModal}
                    toggle={() => this.toggleState('confirmModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Are you sure you want to delete?</h4>
                        <Form onSubmit={(e) => {
                            e.preventDefault()
                            Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                Services.deleteArticle(this.state.selectedRow.id).then((response) => {
                                    this.getLessonList()
                                    this.toggleState('confirmModal')
                                    this.props.enqueueSnackbar('Successfully deleted', {
                                        variant: 'success',
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }
                                    }
                                    )
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        masterPassword: ''
                                    }))
                                })
                                    .catch((error) => {
                                        this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                        )
                                        this.setState((prevState) => ({
                                            ...prevState,
                                            masterPassword: ''
                                        }))
                                    })

                            }).catch((error) => {
                                this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                    variant: 'warning',
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }
                                }
                                )
                                this.setState((prevState) => ({
                                    ...prevState,
                                    masterPassword: ''
                                }))
                            })


                        }}>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                            onClick={
                                () => {
                                    Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                        Services.deleteArticle(this.state.selectedRow.id).then((response) => {
                                            this.getLessonList()
                                            this.toggleState('confirmModal')
                                            this.props.enqueueSnackbar('Successfully deleted', {
                                                variant: 'success',
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'right'
                                                }
                                            }
                                            )
                                            this.setState({ masterPassword: '' })
                                        })
                                            .catch((error) => {
                                                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                    variant: 'warning',
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }
                                                }
                                                )
                                                this.setState({ masterPassword: '' })
                                            })

                                    }).catch((error) => {
                                        this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                        )
                                        this.setState({ masterPassword: '' })
                                    })


                                }
                            }>
                            Yes
                        </Button>
                    </div>
                </Modal>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.passwordModal}
                    toggle={() => this.toggleState('passwordModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Enter password to perform this action.</h4>
                        <Form onSubmit={
                            (e) => {
                                e.preventDefault()
                                Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                    this.handleCreateLessonOpen()
                                    this.setState({ masterPassword: '' })
                                }).catch((error) => {
                                    this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                        variant: 'warning',
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }
                                    }
                                    )
                                })
                            }}>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' onClick={
                            () => {
                                Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                    this.handleCreateLessonOpen()
                                    this.setState({ masterPassword: '' })
                                }).catch((error) => {
                                    this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                        variant: 'warning',
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }
                                    }
                                    )
                                    this.setState({ masterPassword: '' })
                                })
                            }
                        }>
                            Enter
                        </Button>
                    </div>
                </Modal>
            </>

        )
    }
}

export default withSnackbar(ArticleNew)
