import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { withSnackbar } from 'notistack'
import { Component } from 'react'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap'
import Services from '../../RemoteServices/RemoteServices'
import MaterialTable from 'material-table'
import { message } from 'antd'

class SetMessage extends Component {
    state = {
        message: '',
        tag: '',
        edit: false,
        id: null
    }

    resetState = () => {
        this.setState({
            tag: '',
            message: '',
            edit: false,
            id: null
        })
    }

    handleDelete = (id) => {
        Services.deleteSaveChat(id).then((response) => {
            this.props.getSaveChat();
            this.props.enqueueSnackbar('Message Deleted Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })
    }

    handleUpdate = () => {
        let newData = {
            tag: this.state.tag,
            message: this.state.message
        }

        Services.updateSaveChat(this.state.id,newData).then((response) => {
            this.props.getSaveChat();
            this.props.enqueueSnackbar('Message Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            this.setState({ tag: '', message: '',id:null,edit:false })
        })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })
    }

    handleSubmit = () => {
        let newData = {
            tag: this.state.tag,
            message: this.state.message
        }

        Services.addSaveChat(newData).then((response) => {
            this.props.getSaveChat();
            this.props.enqueueSnackbar('Message Created Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            this.setState({ tag: '', message: '' })
        })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })
    }


    handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    render() {
        const { open, toggleState, studentData, type } = this.props
        return (
            <Dialog fullScreen
                open={open} onClose={() => toggleState('setMessage')}>
                <div className='modal-header mb-0'>
                    <h3 className='modal-title' id='exampleModalLabel'>
                        Set Messages
                    </h3>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('setMessage')}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body mt-0 pt-0'>
                    <Form>
                        <>
                            <FormGroup>
                                <Label>Tag</Label>
                                <Input
                                    type={'text'} name={'tag'}
                                    value={this.state.tag}
                                    onChange={this.handleInputChange}
                                    placeholder='Enter the title...'>
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>Message</Label>
                                <Input
                                    type={'textarea'} name={'message'}
                                    value={this.state.message}
                                    onChange={this.handleInputChange}
                                    rows={8}
                                    placeholder='Enter the message...'>
                                </Input>
                            </FormGroup>

                        </>
                    </Form>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.setState({edit:false,id:null,tag:'',message:''})}
                        >
                            Reset
                        </Button>
                        <Button color='primary' type='button' onClick={() => this.state.edit ? this.handleUpdate() : this.handleSubmit()}>
                            {this.state.edit ? 'Update' : 'Submit'}
                        </Button>
                    </div>

                    <MaterialTable
                        title='Messages'
                        columns={[
                            {
                                title: 'Tag',
                                field: 'tag',

                            },
                            {
                                title: 'Message',
                                field: 'message',

                            },

                        ]}
                        options={{
                            pageSize: 5,
                            actionsColumnIndex: -1,
                            headerStyle: {
                                backgroundColor: '#6772E5',
                                color: '#FFF'
                            },
                        }}
                        data={this.props.saveChatList}
                        actions={[
                            {
                                icon: 'delete',
                                tooltip: 'Delete Lesson ',
                                onClick: (event, rowData) => {
                                    this.handleDelete(rowData.id)
                                }
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                isFreeAction: false,
                                onClick: (event, rowData) => {
                                    this.setState({
                                        edit: true,
                                        tag: rowData.tag,
                                        id: rowData.id, message: rowData.message
                                    });

                                }
                            }
                        ]}
                    />
                </div>

            </Dialog>
        )
    }
}

export default withSnackbar(SetMessage)
