import React from 'react'

const remote = {
    address: 'https://live.englishhub.app',
    address1:'https://live.englishhub.app/media/'
    // address: 'http://103.90.86.198'
}

export const FormatImage = (link) => {
    return `${remote.address + link}`
}
export const FormatThumbnailImage=(link)=>{
    return `${remote.address1 + link}`
}
