import React, { Component } from 'react'
import { Button, Form, FormGroup, Input, Label, Modal } from 'reactstrap'
import Services from '../../RemoteServices/RemoteServices'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'
import { Editor } from 'react-draft-wysiwyg'
import { css } from 'glamor'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { customChunkRenderer } from 'draft-js-helpers'

class TeacherSupportModal extends Component {
    state = {
        title: '',
        description: '',
        videoLink: '',
        thumbnail: '',
        startMorning: '',
        endMorning: '',
        startEvening: '',
        endEvening: '',
        meetLink: '',
        editorState: EditorState.createEmpty(),
        editorStateCopied: EditorState.createEmpty(),
        editorContentHtml: '',
        id: '',
        visible: false,
        show_refer: false
    }

    async componentDidMount() {
        this.fetchTeacherSupport()
    }

    //convert time to 24 hour format
    convertTo24HourFormat = (time12h) => {
        const [time, period] = time12h.split(' ')
        let [hours, minutes] = time.split(':')

        hours = parseInt(hours, 10)

        if (period === 'PM' && hours < 12) {
            hours += 12
        } else if (period === 'AM' && hours === 12) {
            hours = 0
        }

        hours = hours.toString().padStart(2, '0')
        minutes = minutes.padStart(2, '0')

        return `${hours}:${minutes}`
    }

    //convert to 12 hour
    convertTo12HourFormat = (time24h) => {
        const [hours, minutes] = time24h.split(':')
        let formattedHours = parseInt(hours, 10)
        let period = 'AM'

        if (formattedHours >= 12) {
            period = 'PM'
            formattedHours = (formattedHours % 12) || 12
        }

        formattedHours = formattedHours.toString().padStart(2, '0')

        return `${formattedHours}:${minutes} ${period}`
    }

    updateTeacherSupport = async () => {
        try {
            let reqDto = {
                title: this.state.title,
                description: this.state.editorContentHtml,
                meet_link: this.state.meetLink,
                start_time_morning: this.convertTo12HourFormat(this.state.startMorning),
                start_time_evening: this.convertTo12HourFormat(this.state.startEvening),
                end_time_morning: this.convertTo12HourFormat(this.state.endMorning),
                end_time_evening: this.convertTo12HourFormat(this.state.endEvening),
                thumbnail: this.state.thumbnail,
                videolink: this.state.videoLink,
                visible: this.state.visible,
                show_refer:this.state.show_refer
            }
            if (this.state.id) {
                await Services.updateTeacherSupport(this.state.id, reqDto).then(() => {
                    this.props.toggleState('updateSupport')
                    this.props.enqueueSnackbar('Teacher Support Updated Successfully', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    })
                })
            } else {
                await Services.addTeacherSupport(reqDto).then(() => {
                    this.props.toggleState('updateSupport')
                    this.props.enqueueSnackbar('Teacher Support Added Successfully', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    })
                })
            }

        } catch (e) {
            this.props.toggleState('updateSupport')
            this.props.enqueueSnackbar('Error Occured', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })

        }
    }

    fetchTeacherSupport = async () => {
        try {
            const response = await Services.getTeacherSupport()
            this.setState({
                id: response[0].id,
                title: response[0].title,
                description: '',
                videoLink: response[0].videolink,
                thumbnail: response[0].thumbnail,
                startMorning: this.convertTo24HourFormat(response[0].start_time_morning),
                endMorning: this.convertTo24HourFormat(response[0].end_time_morning),
                startEvening: this.convertTo24HourFormat(response[0].start_time_evening),
                endEvening: this.convertTo24HourFormat(response[0].end_time_evening),
                meetLink: response[0].meet_link,
                editorState: this.handleInitialEditorText(response[0].description),
                editorStateCopied: EditorState.createEmpty(),
                editorContentHtml: response[0].description,
                visible: response[0].visible,
                show_refer:response[0].show_refer
            })
        } catch (e) {
            this.setState({
                title: '',
                description: '',
                videoLink: '',
                thumbnail: '',
                startMorning: '',
                endMorning: '',
                startEvening: '',
                endEvening: '',
                meetLink: '',
                editorState: EditorState.createEmpty(),
                editorStateCopied: EditorState.createEmpty(),
                editorContentHtml: '',
                id: ''
            })
        }
    }


    handleCheckbox = (event) => {
        const target = event.target
        const value = target.checked
        const name = target.name
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        console.log('asdfasdf', name, value)
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    getExtension = (filename) => {
        var parts = filename.split('.')
        return parts[parts.length - 1]
    }

    isImage = (filename) => {
        var ext = this.getExtension(filename)
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
            case 'gif':
            case 'bmp':
            case 'png':
                //etc
                return true
        }
        return false
    }

    handleLinkImageSelect = async (e) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        console.log('asdf', response)
                        this.setState(prevState => ({
                            ...prevState,
                            thumbnail: response.data.link
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }
    handleLinkImageRemove = async (e) => {
        await this.setState(prevState => ({
            ...prevState,
            thumbnail: ''
        }))
    }

    changeEditorContent = (data) => {
        this.setState({
            editorState: data
        })
    }

    onEditorStateChange = (editorState) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        this.setState({
            editorState,
            editorContentHtml: draftToHtml(rawContentState)
        })
    }


    returnHtmlBlock = (text) => {
        if (this.state.lessonViewType === true) {
            const processedHTML = htmlToDraft(text, customChunkRenderer)
            return ContentState.createFromBlockArray(processedHTML)
        } else {
            const processedHTML = convertFromHTML(text)
            return ContentState.createFromBlockArray(processedHTML)
        }
    }

    handleInitialEditorText = (text) => {
        let editorState
        if (text.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(text))
        } else {
            editorState = EditorState.createEmpty()
        }
        return editorState
    }

    onEditEditorHTML = (e) => {
        this.setState({ editorContentHtml: e.target.value })
    }


    addHtmlToEditor = () => {
        const { editorContentHtml } = this.state
        let editorState
        if (editorContentHtml.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(editorContentHtml))
        } else {
            editorState = EditorState.createEmpty()
        }
        this.setState({ editorState })
    }

    render() {
        const { updateSupport, toggleState, studentData, type } = this.props
        const ShowEditorCode = () => (
            <div className='rdw-option-wrapper' onClick={this.toggleEditorCode}>
                {this.state.showEditorCode
                    ? 'Hide Code'
                    : 'Show Code'}
            </div>
        )
        return (
            <Dialog disableBackdropClick={true} fullWidth={true}
                    PaperProps={{ style: { width: '1400px', height: '800px' } }}
                    open={updateSupport} onClose={() => toggleState('updateSupport')}>
                <div className='modal-header mb-0'>
                    <h3 className='modal-title' id='exampleModalLabel'>
                        Teacher Support
                    </h3>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('updateSupport')}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body mt-0 pt-0'>
                    <Form>
                        <>
                            <FormGroup>
                                <Label>Meet Link</Label>
                                <Input
                                    name={'meetLink'}
                                    value={this.state.meetLink}
                                    onChange={this.handleInputChange}
                                    placeholder='Enter Meet Link'>
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>Title</Label>
                                <Input
                                    type={'text'} name={'title'}
                                    value={this.state.title}
                                    onChange={this.handleInputChange}
                                    placeholder='Enter Title'>
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>Description</Label>
                                <div>

                                    <Editor
                                        editorStyle={{
                                            border: '1px solid black',
                                            padding: '5px',
                                            borderRadius: '2px',
                                            minHeight: '300px',
                                            width: '100%',
                                            fontSize: '18px'
                                        }}
                                        toolbarCustomButtons={[<ShowEditorCode />]}
                                        placeholder='Description'
                                        editorState={this.state.editorState}
                                        wrapperClassName='demo-wrapper'
                                        editorClassName='demo-editor'
                                        onEditorStateChange={this.onEditorStateChange}
                                        toolbar={{
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            history: { inDropdown: true },
                                            image: {
                                                uploadCallback: this.uploadImageCallBack,
                                                previewImage: true,
                                                defaultSize: { width: '100%', height: 'auto' }
                                            }
                                        }}
                                    />
                                    {this.state.showEditorCode && (
                                        <div {...css({ width: '100%' })}>
                                                            <textarea
                                                                rows={10}
                                                                {...css({
                                                                    width: '100%',
                                                                    padding: '0'
                                                                })}
                                                                value={this.state.editorContentHtml}
                                                                onChange={this.onEditEditorHTML}
                                                            />
                                            <div>
                                                <Button
                                                    type='button'
                                                    onClick={this.addHtmlToEditor}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Video Link</Label>
                                <Input type={'text'} name={'videoLink'}
                                       value={this.state.videoLink} onChange={this.handleInputChange}
                                       placeholder='Enter Video Link'>
                                    >
                                </Input>
                            </FormGroup>
                            <FormGroup className={'mb-0'}>
                                <Label><span
                                    className={'font-weight-bold'}> Upload Thumbnail Image</span>
                                </Label>
                                <br />
                                <input className='thumbnail_upload_button' accept='png/jpeg'
                                       type='file'
                                       onChange={(e) => this.handleLinkImageSelect(e)} />
                                <br />
                                {this.state.thumbnail &&
                                    <div><i
                                        onClick={(e) => this.handleLinkImageRemove(e)}
                                        class='far fa-times-circle'></i> <img
                                        className='lessonFormThumbnailImage'
                                        src={this.state.thumbnail} />
                                    </div>}
                                <br />
                            </FormGroup>

                            <FormGroup>
                                <div className={'d-flex justify-content-between'}>
                                    <div className={'w-50'}>
                                        <Label>Start Morning Time</Label>
                                        <Input type={'time'} name={'startMorning'}
                                               value={this.state.startMorning} onChange={this.handleInputChange}
                                        >
                                            >
                                        </Input>
                                    </div>
                                    <div className={'w-50'}>
                                        <Label>End Morning Time</Label>
                                        <Input type={'time'} name={'endMorning'}
                                               value={this.state.endMorning} onChange={this.handleInputChange}
                                        >
                                            >
                                        </Input>
                                    </div>

                                </div>
                            </FormGroup>

                            <FormGroup>
                                <div className={'d-flex justify-content-between'}>
                                    <div className={'w-50'}>
                                        <Label>Start Evening Time</Label>
                                        <Input type={'time'} name={'startEvening'}
                                               value={this.state.startEvening} onChange={this.handleInputChange}
                                        >
                                            >
                                        </Input>
                                    </div>
                                    <div className={'w-50'}>
                                        <Label>End Evening Time</Label>
                                        <Input type={'time'} name={'endEvening'}
                                               value={this.state.endEvening} onChange={this.handleInputChange}
                                        >
                                            >
                                        </Input>
                                    </div>

                                </div>
                            </FormGroup>
                            <FormGroup className={'ml-4'}>
                                <Input
                                    type={'checkbox'} name={'visible'}
                                    checked={this.state.visible}
                                    onChange={this.handleCheckbox}>
                                </Input>
                                <Label
                                    className={'font-weight-bold'}>Show/Hide Teacher Support</Label>
                            </FormGroup>

                            <FormGroup className={'ml-4'}>
                                <Input
                                    type={'checkbox'} name={'show_refer'}
                                    checked={this.state.show_refer}
                                    onChange={this.handleCheckbox}>
                                </Input>
                                <Label
                                    className={'font-weight-bold'}>Show/Hide Refer</Label>
                            </FormGroup>
                        </>
                    </Form>
                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('updateSupport')}
                    >
                        Cancel
                    </Button>
                    <Button color='primary' type='button' onClick={() => this.updateTeacherSupport()}>
                        Update
                    </Button>
                </div>
            </Dialog>
        )
    }
}

export default withSnackbar(TeacherSupportModal)
