import React from 'react'
import { withSnackbar } from 'notistack'
import Header from '../../components/Headers/Header'
import { Button, Container, Form, FormGroup, Input, InputGroup, Label } from 'reactstrap'
import Services from '../../RemoteServices/RemoteServices'


class Vacancy extends React.Component {

    state = {}
    sendLeads = () => {
        let reqDto = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        }
        Services.sendLeads(reqDto).then((response) => {
            this.props.enqueueSnackbar('Leads Submitted Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        })
            .catch((error) => {
                this.props.enqueueSnackbar(error || 'Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            })
    }

    handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <>
                <Header page={'lesson'} />
                <Container className={'mt-4'} style={{ minHeight: '480px' }}>
                    <Form role='form' style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        maxWidth: '1000px',
                        width: '100%'
                    }}>

                        <div className='lessonEditTitleDiv'>
                            <h3 style={{ marginBottom: '20px' }} className='modal-title' id='exampleModalLabel'>
                                Vacancy
                            </h3>
                        </div>

                        <FormGroup style={{ display: 'flex', justifyContent: 'flex-start', gap: '20px' }}>
                            <InputGroup style={{ width: '100%', marginRight: '10px' }}
                                        className='input-group-alternative mb-3'>
                                <Input type={'text'} placeholder='Vacancy Title' name='vacancyTitle'
                                       value={this.state.fromDate} onChange={this.handleInputChange} />
                            </InputGroup>
                        </FormGroup>
                        <div className='pb-4'>
                            <Button color='primary' type='button'
                                    onClick={this.sendLeads}>
                                Add Vacancy
                            </Button>
                        </div>
                    </Form>
                </Container>

            </>
        )
    }
}

export default withSnackbar(Vacancy)