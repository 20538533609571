import React, { useState, useEffect } from 'react';
import Avatar from '../../assets/img/theme/avatar.png'
import moment from 'moment'
import './communityStyles.css'
import { Modal, Button } from 'reactstrap'
import CommentReplies from './CommentReplies';
const Services = require('../../RemoteServices/RemoteServices.js');

const CommentComponent = (props) => {
    let [openCommentMenu, setOpenCommentMenu] = useState(false)
    let [deleteModal, setDeleteModal] = useState(false)
    let [showReplies, setShowReplies] = useState(false)
    let [replyCount, setReplyCount] = useState(2)
    let { data } = props
    const formatTime = (time) => {
        return moment(time).fromNow()
    }

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    const deleteComment = async () => {
        await props.deleteComment(data.id)
        await toggleDeleteModal()
    }

    console.log('nnnnn',props.data)



    return (
        <div className="communityPostCommentDiv">
            <div className="communityPostCommentTopDiv">
                <div className="communityPostCommentImageDiv">
                    <img alt="profile avatar" src={data.user.profile_picture ? data.user.profile_picture : Avatar}></img>
                </div>
                <div className="communityPostCommentInfo">
                    <span onClick={() => props.showDetails(data.user)}>{data.user.full_name}</span>
                    <span>{formatTime(data.created_at)} {data.like_count} Likes</span>
                </div>
                <i class="fas fa-ellipsis-h" onClick={() => setOpenCommentMenu(!openCommentMenu)}></i>
                {openCommentMenu &&

                    <div className="commentMenuOptions" onMouseLeave={() => setOpenCommentMenu(!openCommentMenu)}>
                        <ul>
                            <li onClick={toggleDeleteModal}>Delete</li>
                        </ul>
                    </div>}
            </div>
            <div className="communityPostCommentText">
                {data.message} </div>
            {data.reply.length > 0 &&
                <div className="communityPostCommentRepliesDiv">
                    {data.reply.slice(0, replyCount).map((data, id) => {
                        return (<CommentReplies showDetails={props.showDetails} deleteReply={props.deleteReply} data={data} />)
                    })}
                    {data.reply.length > 2 && data.reply.length > replyCount &&
                        <span style={{ cursor: 'pointer', fontSize: '10px' }} onClick={() => setReplyCount(replyCount += 2)}>Load more replies</span>}

                </div>
            }

            <Modal
                className="modal-dialog-centered"
                isOpen={deleteModal}
                toggle={toggleDeleteModal}
            >
                <div className="modal-header">
                    <h3 className="modal-title" id="exampleModalLabel">
                        Confirmation
                    </h3>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleDeleteModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <h4>Are you sure you want to delete this comment?</h4>


                </div>
                <div className="modal-footer">
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleDeleteModal}
                    >
                        Cancel
                    </Button>
                    <Button color="danger" type="button"
                        onClick={() => deleteComment()}>
                        Delete
                    </Button>
                </div>
            </Modal>

        </div>
    )

}

export default CommentComponent