import React from 'react'
import Header from 'components/Headers/Header.js'
import Icon from '@material-ui/core/Icon'

import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Button,
    Modal,
    UncontrolledTooltip
} from 'reactstrap'
import MaterialTable, { MTableToolbar } from 'material-table'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import UsersForm from 'components/Forms/UsersForm.js'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import TextArea from 'antd/es/input/TextArea'

const Services = require('../../RemoteServices/RemoteServices.js')

class Withdraw extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addFormView: false,
            data: [],
            updatePasswordView: false,
            newPassword1: '',
            newPassword2: '',
            selectedRow: null,
            loading: true,
            approveWithdraw: false,
            status: 'Pending',
            reason: 'Your withdrawal request has been denied due to the following reason: '
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.submitChangePassword = this.submitChangePassword.bind(this)
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    async componentDidMount() {
        await this.getUsersList()
    }

    async getUsersList() {
        this.setState({ loading: true })
        await Services.getWithdrawList(this.state.status).then((response) => {
            this.setState({ data: response, loading: false })
        })
    }

    async changeStatus(data) {
        await this.setState({ status: data })
        await this.getUsersList()
    }

    submitChangePassword() {
        let userId = this.state.selectedRow.id
        let data = {
            password1: this.state.newPassword1,
            password2: this.state.newPassword2
        }
        Services.updatePassword(userId, data).then((response) => {
            this.setState({ updatePasswordView: false })
            this.props.enqueueSnackbar('Password Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            })
    }

    async updateStatus() {
        let withrawId = this.state.selectedRow.id
        console.log(withrawId)
        let data = {}
        console.log(this.state.approveWithdraw)
        if (this.state.approveWithdraw === true) {
            data = {
                status: 'Approved'
            }
            console.log(data)
        } else {
            data = {
                status: 'Denied',
                reason:this.state.reason,
            }
        }
        Services.updateWithdrawStatus(withrawId, data).then((response) => {
            console.log(response)
            this.setState({ confirmModal: false })
            this.getUsersList()
            this.props.enqueueSnackbar('Status updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }).catch((error) => {
            this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )
        })


    }

    render() {
        return (
            <>
                <Header onlineCount={this.props.onlineCount} onlineUsers={this.props.onlineUsers} />
                <Container className='mt--7' fluid>
                    <Row>
                        <div className=' col'>
                            <Card className=' shadow'>
                                {!(this.state.addFormView) ?
                                    this.state.loading ?
                                        <CircularProgress style={{
                                            margin: 50,
                                            alignSelf: 'center'
                                        }} />
                                        :
                                        <MaterialTable
                                            columns={[
                                                {
                                                    title: 'S.N',
                                                    field: 'sn',
                                                    render: rowData => <>{rowData.tableData.id + 1} </>,
                                                    editable: false
                                                },
                                                { title: 'Full Name', field: 'user.full_name' },
                                                { title: 'Email', field: 'user.email', editable: false },
                                                { title: 'Phone', field: 'user.phone_number' },
                                                { title: 'Requested Amount', field: 'amount', editable: false }
                                                // { title: 'Referral Code', field: 'referral_code', editable: false  },
                                                // { title: 'Last Login', field: 'last_login', editable: false  }
                                            ]}
                                            data={this.state.data}
                                            title='Withdraw Requests'
                                            actions={[
                                                {
                                                    icon: 'done_all',
                                                    tooltip: 'Approve',
                                                    disabled: this.state.status === 'Pending' ? false : true,
                                                    onClick: (event, rowData) => {
                                                        // Do save operation
                                                        this.setState({
                                                            confirmModal: true,
                                                            selectedRow: rowData,
                                                            approveWithdraw: true
                                                        })
                                                    }
                                                },

                                                {
                                                    icon: 'cancel',
                                                    tooltip: 'Reject',
                                                    disabled: this.state.status === 'Pending' ? false : true,
                                                    onClick: (event, rowData) => {
                                                        this.setState({
                                                            confirmModal: true,
                                                            selectedRow: rowData,
                                                            approveWithdraw: false
                                                        })
                                                    }
                                                }
                                                // {
                                                //   icon: 'update',
                                                //   tooltip: 'Update Password ',
                                                //   onClick: (event, rowData) => {
                                                //       this.setState({updatePasswordView: true, selectedRow: rowData})
                                                //       // Do save operation
                                                //   }
                                                // }
                                            ]}
                                            options={{
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    backgroundColor: '#6772E5',
                                                    color: '#FFF'
                                                }
                                            }}
                                            components={{
                                                Toolbar: props => (
                                                    <div>

                                                        <MTableToolbar {...props} />
                                                        <div style={{
                                                            padding: '10px 10px',
                                                            marginLeft: 10,
                                                            paddingBottom: 20
                                                        }}>Filters:
                                                            <Chip avatar={<Avatar style={{
                                                                backgroundColor: 'blue',
                                                                color: 'white'
                                                            }}>P</Avatar>} label='Pending'
                                                                  style={{ marginRight: 10, marginLeft: 10 }}
                                                                  onClick={() => {
                                                                      this.changeStatus('Pending')
                                                                  }} />
                                                            <Chip avatar={<Avatar style={{
                                                                backgroundColor: 'green',
                                                                color: 'white'
                                                            }}>A</Avatar>} label='Approved'
                                                                  style={{ marginRight: 10, marginLeft: 10 }}
                                                                  onClick={() => {
                                                                      this.changeStatus('Approved')
                                                                  }} />
                                                            <Chip avatar={<Avatar style={{
                                                                backgroundColor: 'red',
                                                                color: 'white'
                                                            }}>D</Avatar>} label='Denied'
                                                                  style={{ marginRight: 10, marginLeft: 10 }}
                                                                  onClick={() => {
                                                                      this.changeStatus('Denied')
                                                                  }} />

                                                        </div>
                                                        {/* <div style={{padding: '1px 10px', marginLeft: 10, paddingBottom: 20}}> */}
                                                        {/* Courses: */}
                                                        {/* { ReactHtmlParser (str.props.children) } */}
                                                        {/* <div dangerouslySetInnerHTML={{ __html: str.props.children }} /> */}
                                                        {/* </div> */}
                                                    </div>
                                                )
                                            }}
                                            // editable={{
                                            //     onRowUpdate: (newData, oldData) =>
                                            //         new Promise((resolve, reject) => {
                                            //             setTimeout(() => {
                                            //                 {
                                            //                     Services.updateStaff(oldData.id, newData).then((response)=>{
                                            //                         this.getUsersList()
                                            //                         this.props.enqueueSnackbar('User Updated Successfully', {
                                            //                             variant: 'success',
                                            //                             anchorOrigin: {
                                            //                               vertical: 'bottom',
                                            //                               horizontal: 'right',
                                            //                           }
                                            //                           })
                                            //                     })
                                            //                     .catch((error)=>{
                                            //                       this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                            //                         variant: 'warning',
                                            //                         anchorOrigin: {
                                            //                           vertical: 'bottom',
                                            //                           horizontal: 'right',
                                            //                         }
                                            //                       }
                                            //                     )
                                            //                     })
                                            //                 }
                                            //                 resolve();
                                            //             }, 300);
                                            //         }),
                                            // }}
                                        />
                                    :
                                    <>
                                        <div class='d-flex mt-3'>
                                            <div class='mr-auto ml-4 mt-2'><h2>Staff List</h2></div>
                                            <div class='p-2'>
                                                <Button className='btn-icon btn-3' color='primary' type='button'
                                                        onClick={() => this.toggleState('addFormView')}>
                                        <span className='btn-inner--icon'>
                                            <i className='ni ni-bag-17' />
                                        </span>
                                                    <span className='btn-inner--text'>Back</span>
                                                </Button>
                                            </div>
                                        </div>

                                        <hr class='solid'></hr>
                                        <UsersForm onSuccess={() => {
                                            this.setState({ addFormView: false })
                                            this.getUsersList()
                                        }}></UsersForm>
                                    </>
                                }
                            </Card>
                        </div>
                    </Row>
                </Container>
                <Modal
                    className='modal-dialog-centered modal-lg'
                    isOpen={this.state.confirmModal}
                    toggle={() => this.toggleState('confirmModal')}
                >
                    <div className='modal-header mt-1 mb-1 pt-3 pb-1'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body pt-0'>
                        <hr className={'mt-1 mb-2'} />
                        <h3 className={'mb-3'}>User Information</h3>
                        <hr className={'mt-2 mb-2'} />
                        <h4>Username: <span style={{fontWeight:'normal'}}>{this.state?.selectedRow?.user?.full_name}</span></h4>
                        <h4>Requested Amount: {this.state?.selectedRow?.amount||0}</h4>
                        <hr className={'mt-4 mb-2'} />
                        <h3 className={'mb-3'}>Bank/Wallet Details</h3>
                        <hr className={'mt-2 mb-2'} />
                        <h4>Account Name: <span style={{fontWeight:'normal'}}>{this.state?.selectedRow?.wallet_details[0]?.full_name}</span></h4>
                        <h4>Bank/Wallet Name: <span style={{fontWeight:'normal'}}>{this.state?.selectedRow?.wallet_details[0]?.bank_name}</span></h4>
                        <h4>Account Number: <span style={{fontWeight:'normal'}}>{this.state?.selectedRow?.wallet_details[0]?.number}</span></h4>
                        <h4>Branch: <span style={{fontWeight:'normal'}}>{this.state?.selectedRow?.wallet_details[0]?.branch ||'N/A'}</span></h4>
                        <h4>Requested Amount: <span style={{fontWeight:'normal'}}>{this.state?.selectedRow?.amount||0}</span></h4>
                        {!this.state.approveWithdraw &&
                            <div>
                                <hr className={'mt-4 mb-2'} />
                                <h3>Reason</h3>
                                <hr className={'mt-2 mb-2'} />
                                <TextArea className={'mt-2'} name={'reason'} value={this.state.reason}
                                          onChange={this.handleInputChange} />
                            </div>
                        }
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            Cancel
                        </Button>
                        <Button onClick={this.updateStatus.bind(this)} color='primary' type='button'>
                            Confirm
                        </Button>
                    </div>
                </Modal>
                <Dialog open={this.state.updatePasswordView} onClose={() => this.toggleState('updatePasswordView')}>
                    <DialogTitle id='form-dialog-title'>Change Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter new password to change.
                        </DialogContentText>
                        <FormGroup>
                            <InputGroup className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <i className='ni ni-hat-3' />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Enter Password' type='text' name='newPassword1'
                                       value={this.state.newPassword1} onChange={this.handleInputChange.bind(this)} />
                            </InputGroup>
                        </FormGroup>

                        <FormGroup>
                            <InputGroup className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <i className='ni ni-hat-3' />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Enter Password Again' type='text' name='newPassword2'
                                       value={this.state.newPassword2} onChange={this.handleInputChange.bind(this)} />
                            </InputGroup>
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.toggleState('updatePasswordView')} color='primary'>
                            Cancel
                        </Button>
                        <Button onClick={this.submitChangePassword.bind(this)} color='primary'>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export default withSnackbar(Withdraw)