import React from 'react'
import Header from 'components/Headers/Header.js'
import { Button, Card, Container, Input, Label, Modal, Row } from 'reactstrap'
import MaterialTable, { MTableToolbar } from 'material-table'
import CircularProgress from '@material-ui/core/CircularProgress'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import { withSnackbar } from 'notistack'
import TablePagination from '@material-ui/core/TablePagination'
import LeadsToSales from 'components/Forms/LeadsToSales.js'
import moment from 'moment'

const Services = require('../../../RemoteServices/RemoteServices.js');

class main extends React.Component {
  state = {
    addFormView: false,
    data: [],
    confirmModal: false,
    selectedRow: {},
    loading: false,
    count: null,
    next: '',
    previous: '',
    pageNumber: 0,
    searchText: '',
    created_at_before: new Date(),
    created_at_after: new Date(),
  }

  toggleState = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  componentDidMount() {
    this.getCourseList()
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    })

  }

  getCourseList() {
    this.setState({ loading: true })
    Services.getLogData(
      moment(this.state.created_at_before).format('YYYY-MM-DD'),
      moment(this.state.created_at_after).format('YYYY-MM-DD'),
    )
      .then((response) => {
        console.log(response)
        this.setState({ data: response.results, loading: false, count: response.count, next: response.next, previous: response.previous })
      })
  }

  getOnlyEmail() {
    this.setState({ loading: true })
    Services.getOnlyEmail().then((response) => {
      this.setState({ data: response.results, loading: false, count: response.count, next: response.next, previous: response.previous })
    })
  }

  getOnlySms() {
    this.setState({ loading: true })
    Services.getOnlySms().then((response) => {
      this.setState({ data: response.results, loading: false, count: response.count, next: response.next, previous: response.previous })
    })
  }

  getOnlyNotifications() {
    this.setState({ loading: true })
    Services.getOnlyNotifications().then((response) => {
      this.setState({ data: response.results, loading: false, count: response.count, next: response.next, previous: response.previous })
    })
  }

  getOnlySuccess() {
    this.setState({ loading: true })
    Services.getOnlySuccess().then((response) => {
      this.setState({ data: response.results, loading: false, count: response.count, next: response.next, previous: response.previous })
    })
  }

  getOnlyFailed() {
    this.setState({ loading: true })
    Services.getOnlyFailed().then((response) => {
      this.setState({ data: response.results, loading: false, count: response.count, next: response.next, previous: response.previous })
    })
  }

  PatchedPagination = function (props) {
    const {
      ActionsComponent,
      onChangePage,
      onChangeRowsPerPage,
      count,
      page,
      rowsPerPage,
      ...tablePaginationProps
    } = props;

    return (
      <TablePagination
        {...tablePaginationProps}
        // @ts-expect-error onChangePage was renamed to onPageChange
        onPageChange={(e, page) => {
          if (page > this.state.pageNumber) {
            this.setState({ pageNumber: this.state.pageNumber + 1, loading: false })
            Services.getCustom(this.state.next).then((response) => {
              this.setState({ data: response.results, loading: false, count: response.count, next: response.next, previous: response.previous })
            })
          }
          else {
            this.setState({ pageNumber: this.state.pageNumber - 1, loading: false })
            Services.getCustom(this.state.previous).then((response) => {
              this.setState({ data: response.results, loading: false, count: response.count, next: response.next, previous: response.previous })
            })
          }
        }}
        onRowsPerPageChange={onChangeRowsPerPage}
        rowsPerPage={10}
        count={this.state.count}
        page={this.state.pageNumber}
        ActionsComponent={(subprops) => {
          const { onPageChange, ...actionsComponentProps } = subprops;
          return (
            // @ts-expect-error ActionsComponent is provided by material-table
            <ActionsComponent
              {...actionsComponentProps}
              onChangePage={onPageChange}
            />
          );
        }}
      />
    );
  }

  render() {
    return (
      <>
        <Header onlineCount={this.props.onlineCount} onlineUsers={this.props.onlineUsers} />
        <Container className="mt--7" fluid>
          <Row>
            <div className=" col">
              <Card className=" shadow" style={{ padding: 20 }}>
                {!(this.state.addFormView) ?
                  this.state.loading ?
                    <CircularProgress style={{
                      margin: 50,
                      alignSelf: 'center'
                    }} />
                    :
                    <MaterialTable
                      columns={[
                        { title: 'S.N', field: 'id', editable: 'never', render: rowData => <>{rowData.tableData.id + 1}</> },
                        // { title: 'Type', field: 'type', render: rowData => <b>{rowData.type}</b> },
                        { title: 'Status', field: 'status' },
                        { title: 'Message', field: 'title' },
                        { title: 'Name', field: 'name' },
                        { title: 'Phone', field: 'phone_number' },
                        { title: 'Date', field: 'created_at', render: rowData => <>{moment(rowData.created_at).format('DD-MM-YYYY')}&nbsp;{moment(rowData.created_at).fromNow()}</> },
                      ]}
                      data={this.state.data}
                      title="SMS / Email / Notifications Logs"
                      actions={[
                        // {
                        // icon: 'delete',
                        // tooltip: 'Delete Lesson ',
                        // onClick: (event, rowData) => {
                        //     // Do save operation
                        //     // Services.deleteCourse(rowData.id).then((response) =>{
                        //     //     this.getCourseList()
                        //     // })
                        //     this.setState({confirmModal: true, selectedRow: rowData})
                        // }
                        // },
                        {
                          icon: 'add',
                          tooltip: 'Add Lesson',
                          isFreeAction: true,
                          onClick: (event) => this.toggleState('addFormView')
                        }
                      ]}
                      options={{
                        pageSize: 50,
                        search: false,
                        actionsColumnIndex: -1,
                        headerStyle: {
                          backgroundColor: '#6772E5',
                          color: '#FFF'
                        }
                      }}
                      components={{
                        Pagination: props => (
                          <>
                            <TablePagination
                              {...props}
                              rowsPerPageOptions={[]}
                              rowsPerPage={50}
                              count={this.state.count}
                              page={this.state.pageNumber}
                              onChangePage={(e, page) => {
                                if (page > this.state.pageNumber) {
                                  this.setState({ pageNumber: this.state.pageNumber + 1, loading: true })
                                  Services.getCustom(this.state.next).then((response) => {
                                    this.setState({ data: response.results, loading: false, count: response.count, next: response.next, previous: response.previous })
                                  })
                                }
                                else {
                                  this.setState({ pageNumber: this.state.pageNumber - 1, loading: true })
                                  Services.getCustom(this.state.previous).then((response) => {
                                    this.setState({ data: response.results, loading: false, count: response.count, next: response.next, previous: response.previous })
                                  })
                                }
                              }}

                            >
                            </TablePagination>
                          </>
                        ),
                        Toolbar: props => (
                          <div>

                            <MTableToolbar {...props} />
                            <div className={'ml-4 mb-2'} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '10px' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                <Label>From:</Label>
                                <Input type='date' name='created_at_before'
                                  value={this.state.created_at_before}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                <Label>To:</Label>
                                <Input type='date' name='created_at_after'
                                  value={this.state.created_at_after}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', width: '15%' }}>

                                <Button className='btn btn-outline' style={{ height: '46px', }} onClick={() => this.getCourseList()}>Search</Button>
                              </div>
                            </div>
                            <div style={{ padding: '10px 10px', marginLeft: 10, paddingBottom: 20 }}>Filter By:
                              <Chip avatar={<Avatar style={{ backgroundColor: 'green', color: 'white' }}>A</Avatar>} label="All" style={{ marginRight: 10, marginLeft: 10 }} onClick={this.getCourseList.bind(this)} />
                              <Chip avatar={<Avatar style={{ backgroundColor: 'yellow', color: 'black' }}>OS</Avatar>} label="Only SMS" style={{ marginRight: 10, marginLeft: 10 }} onClick={this.getOnlySms.bind(this)} />
                              <Chip avatar={<Avatar style={{ backgroundColor: 'blue', color: 'white' }}>OE</Avatar>} label="Only E-Mail" style={{ marginRight: 10 }} onClick={this.getOnlyEmail.bind(this)} />
                              <Chip avatar={<Avatar style={{ backgroundColor: 'yellow', color: 'black' }}>ON</Avatar>} label="Only Notifications" style={{ marginRight: 10 }} onClick={this.getOnlyNotifications.bind(this)} />
                              <Chip avatar={<Avatar style={{ backgroundColor: 'green', color: 'white' }}>OS</Avatar>} label="Only Success" style={{ marginRight: 10 }} onClick={this.getOnlySuccess.bind(this)} />
                              <Chip avatar={<Avatar style={{ backgroundColor: 'red', color: 'white' }}>OF</Avatar>} label="Only Failed" style={{ marginRight: 10 }} onClick={this.getOnlyFailed.bind(this)} />
                            </div>
                          </div>
                        ),
                      }}
                    // editable={{
                    //     onRowUpdate: (newData, oldData) =>
                    //         new Promise((resolve, reject) => {
                    //             setTimeout(() => {
                    //                 {
                    //                     Services.updateCourse(oldData.id,newData).then((response)=>{
                    //                         this.getCourseList()
                    //                         this.props.enqueueSnackbar('Course Updated Successfully', { 
                    //                             variant: 'success',
                    //                             anchorOrigin: {
                    //                               vertical: 'bottom',
                    //                               horizontal: 'right',
                    //                           }
                    //                           })
                    //                     })
                    //                     .catch((error)=>{
                    //                       this.props.enqueueSnackbar('Error! Please try again later or contact administrator', { 
                    //                         variant: 'warning',
                    //                         anchorOrigin: {
                    //                           vertical: 'bottom',
                    //                           horizontal: 'right',
                    //                         }
                    //                       }
                    //                     )
                    //                     })
                    //                 }
                    //                 resolve();
                    //             }, 300);
                    //         }),
                    // }}
                    />
                  :
                  <>
                    <div class="d-flex mt-3">
                      <div class="mr-auto ml-4 mt-2"><h2>SMS / Email / Notifications Logs</h2></div>
                      <div class="p-2">
                        <Button className="btn-icon btn-3" color="primary" type="button" onClick={() => this.toggleState("addFormView")}>
                          <span className="btn-inner--icon">
                            <i className="ni ni-bag-17" />
                          </span>
                          <span className="btn-inner--text">Back</span>
                        </Button>
                      </div>
                    </div>

                    <hr class="solid"></hr>
                    <LeadsToSales onSuccess={() => {
                      this.setState({ addFormView: false })
                      this.getCourseList()
                    }}></LeadsToSales>
                  </>
                }
              </Card>
            </div>
          </Row>
        </Container>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.confirmModal}
          toggle={() => this.toggleState("confirmModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Confirmation
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleState("confirmModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <h4>Are you sure you want to delete?</h4>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleState("confirmModal")}
            >
              Cancel
            </Button>
            <Button color="primary" type="button"
              onClick={
                () => {
                  let selectedRowId = this.state.selectedRow.id
                  Services.deleteCourse(selectedRowId).then((response) => {
                    this.getCourseList()
                    this.toggleState("confirmModal")
                    this.props.enqueueSnackbar('Successfully deleted', {
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      }
                    }
                    )
                  })
                    .catch((error) => {
                      this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        }
                      }
                      )
                    })

                }
              }>
              Yes
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default withSnackbar(main)