import React from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col
} from 'reactstrap'
import { withSnackbar } from 'notistack'


const Services = require('../../RemoteServices/RemoteServices.js')


class UsersForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fullName: '',
            email: '',
            phone: '',
            password1: '',
            password2: '',
            userType: '',
            selectedRow: {},
            formModal: false
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit() {
        let data = {
            full_name: this.state.fullName,
            phone_number: this.state.phone,
            is_staff: true,
            email: this.state.email,
            password1: this.state.password1,
            password2: this.state.password2,
            role_type: this.state.userType
        }
        Services.createUser(data).then((response) => {
            this.props.onSuccess()
            this.props.enqueueSnackbar('Staff Created Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }).catch((error) => {
            this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )
        })
    }


    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <>
                <Form role='form' style={{
                    paddingLeft: 20,
                    paddingRight: 20
                }}>
                    <FormGroup>
                        <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Full name' type='text' name='fullName' value={this.state.fullName}
                                   onChange={this.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Email' type='email' name='email' value={this.state.email}
                                   onChange={this.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Phone' type='text' name='phone' value={this.state.phone}
                                   onChange={this.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input className={'ml-2'} type={'select'} name={'userType'}
                                   value={this.state.userType} onChange={this.handleInputChange}>
                                <option>Select Role Type</option>
                                <option value={'Superuser'}>Super Admin</option>
                                <option value={'Admin'}>Admin</option>
                                <option value={'Basic'}>Basic (SML)</option>
                                <option value={'Medium'}>Medium(SMLAC)</option>
                                <option value={'Pro Ext'}>Pro Ext (SMLAC.Ext)</option>
                                <option value={'Pro Int'}>Pro Int (SMLAC.Int)</option>
                            </Input>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Password' type='Password' name='password1' value={this.state.password1}
                                   onChange={this.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Password Again' type='Password' name='password2'
                                   value={this.state.password2} onChange={this.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                    {/* <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                        id="exampleFormControlTextarea1"
                        placeholder="Description / Notes"
                        rows="3"
                        type="textarea"
                        name="description"
                        value={this.state.description} 
                        onChange={this.handleInputChange}
                    />
                  </InputGroup>
                </FormGroup> */}
                    <div className='pb-4'>
                        <Button className='mt-4' color='primary' type='button' onClick={this.handleSubmit}>
                            Create
                        </Button>
                    </div>
                </Form>

            </>
        )
    }
}

export default withSnackbar(UsersForm)