import React, { useState, useEffect } from 'react'
import Header from 'components/Headers/Header.js'
import { withRouter } from 'react-router-dom'
import {
    Container,
    Button
} from 'reactstrap'
import Select from 'react-select'
import { withSnackbar } from 'notistack'
import './Quizzes.css'
import LessonQuizTabs from 'components/Forms/LessonQuiz/LessonQuizTab'
import LessonQuizView from 'components/Forms/LessonQuiz/LessonQuizView'

const Services = require('../../RemoteServices/RemoteServices.js')

const Quizzes = (props) => {

    let [lessonQuiz, setLessonQuiz] = useState([])
    let [lessonFreeQuiz, setLessonFreeQuiz] = useState([])
    let [courseOptions, setCourseOptions] = useState([])
    let [lessonOptions, setLessonOptions] = useState([])
    let [freeLessonOptions, setFreeLessonOptions] = useState([])
    let [selectedCourseData, setSelectedCourseData] = useState('')
    let [viewQuizData, setViewQuizData] = useState([])
    let [viewFreeQuizData, setViewFreeQuizData] = useState([])

    let [selectedLessonData, setSelectedLessonData] = useState('')
    let [selectedFreeLessonData, setSelectedFreeLessonData] = useState('')
    let [createQuiz, setCreateQuiz] = useState(false)
    let [editQuiz, setEditQuiz] = useState(false)
    let [viewQuiz, setViewQuiz] = useState(false)
    let [createFreeQuiz, setCreateFreeQuiz] = useState(false)
    let [editFreeQuiz, setEditFreeQuiz] = useState(false)
    let [viewFreeQuiz, setViewFreeQuiz] = useState(false)
    const [checkBox, setCheckBox] = useState()

    let displayStyle = checkBox ? 'btn-warning' : 'btn-success'
    const handleCheckBox = (event) => {
        setCheckBox(event.target.checked)
    }


    const getCourseList = () => {
        Services.getCourses().then((response) => {
            populateCourseDropdown(response)
        })
    }

    const populateCourseDropdown = (data) => {
        data.forEach((item) => {
            courseOptions.push({ value: item.id, label: item.title })
        })
    }
    const getFreeLessonList = () => {
        Services.getFreeLessons().then((response) => {
            populateFreeLessonDropdown(response)
        })
    }
    const populateFreeLessonDropdown = (data) => {
        data.forEach((item) => {
            freeLessonOptions.push({ value: item.id, label: item.order + `. ${item.title}` })

        })
    }

    useEffect(() => {
        getCourseList()
        getFreeLessonList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const populateLessonsDropdown = async (data) => {
        console.log('Lesson data here', data)
        let newData = []
        setLessonOptions([])
        data.forEach((item) => {
            newData.push({ value: item.id, label: item.order + `. ${item.title}` })
        })
        setLessonOptions(newData)
    }

    const handleCourseSelect = async selectedCourse => {
        setSelectedCourseData(selectedCourse)
        Services.getCourseLessons(selectedCourse.value).then((response) => {
            populateLessonsDropdown(response)
        })

    }

    const handleLessonSelect = async (selectedLesson) => {
        setSelectedLessonData(selectedLesson)
        // await Services.filterByCourseLesson(this.state.selectedCourseId, selectedLesson.value).then((response)=> {
        //   this.setState({data: response.results, loading: false, count: response.count, next: response.next, previous: response.previous})
        // })

    }
    const handleFreeLessonSelect = async (selectedLesson) => {
        setSelectedFreeLessonData(selectedLesson)
        // await Services.filterByCourseLesson(this.state.selectedCourseId, selectedLesson.value).then((response)=> {
        //   this.setState({data: response.results, loading: false, count: response.count, next: response.next, previous: response.previous})
        // })

    }

    let handleQuizArray = (quizData) => {
        setLessonQuiz(quizData)
    }

    let handleFreeQuizArray = (quizData) => {
        setLessonFreeQuiz(quizData)
    }


    const handleUpdateQuiz = () => {
        let quizPostData = {
            course: selectedCourseData.value,
            lesson: selectedLessonData.value,
            quizArray: lessonQuiz
        }

        Services.updateLessonQuiz(quizPostData).then((response) => {

            if (response.status === 200) {
                // eslint-disable-next-line no-restricted-globals
                props.enqueueSnackbar('Quiz updated successfully!', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
                setViewQuizData([])
                handleAllClose()
                props.history.push('/admin/Quizzes')

            } else {
                props.enqueueSnackbar('Something went wrong', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })

            }

        }).catch((error) => {
            props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )
        })
    }

    const handleQuizPost = () => {
        let quizPostData = {
            course: selectedCourseData.value,
            lesson: selectedLessonData.value,
            quizArray: lessonQuiz
        }
        Services.addLessonQuiz(quizPostData).then((response) => {
            if (response.status === 200) {
                // eslint-disable-next-line no-restricted-globals
                props.enqueueSnackbar(response.msg, {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
                setLessonQuiz([])
                handleAllClose()
                props.history.push('/admin/Quizzes')

            } else {
                props.enqueueSnackbar(response.msg, {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })

            }

        }).catch((error) => {
            props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )
        })
    }

    const handleUpdateFreeQuiz = () => {
        let quizPostData = {
            lesson: selectedFreeLessonData.value,
            quizArray: lessonFreeQuiz
        }

        Services.updateLessonFreeQuiz(quizPostData).then((response) => {

            if (response.status === 200) {
                // eslint-disable-next-line no-restricted-globals
                props.enqueueSnackbar('Quiz updated successfully!', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
                setViewFreeQuiz([])
                handleFreeAllClose()
                props.history.push('/admin/Quizzes')

            } else {
                props.enqueueSnackbar('Something went wrong', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })

            }

        }).catch((error) => {
            props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )
        })
    }

    const handleQuizFreePost = () => {
        let quizPostData = {
            lesson: selectedFreeLessonData.value,
            quizArray: lessonFreeQuiz
        }
        Services.addLessonFreeQuiz(quizPostData).then((response) => {
            if (response.status === 200) {
                // eslint-disable-next-line no-restricted-globals
                props.enqueueSnackbar(response.msg, {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
                setLessonFreeQuiz([])
                handleAllClose()
                props.history.push('/admin/Quizzes')

            } else {
                props.enqueueSnackbar(response.msg, {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })

            }

        }).catch((error) => {
            props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )
        })
    }

    const initiateQuizSearch = () => {
        let data = {
            lesson: selectedLessonData.value,
            course: selectedCourseData.value
        }
        Services.getLessonQuiz(data.lesson, data.course).then((response) => {
            setViewQuizData(response)
            if (response.length === 0) {
                props.enqueueSnackbar('No Quiz found for this lesson', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })

            }
            props.enqueueSnackbar('Quiz Searched Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }).catch(() => {
            props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )

        })
    }

    const initiateFreeQuizSearch = () => {
        let data = {
            lesson: selectedFreeLessonData.value,
        }
        Services.getFreeLessonQuiz(data.lesson).then((response) => {
            setViewFreeQuizData(response)
            setViewFreeQuiz(true)
            if (response.length === 0) {
                props.enqueueSnackbar('No Quiz found for this lesson', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })

            }
            props.enqueueSnackbar('Quiz Searched Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }).catch(() => {
            props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )

        })
    }

    const handleAddQuiz = () => {
        setCreateQuiz(true)
        setEditQuiz(false)
        setViewQuiz(false)

    }

    const handleEditQuiz = () => {
        setLessonQuiz([])
        setCreateQuiz(false)
        setEditQuiz(true)
        setViewQuiz(false)

    }

    const handleViewQuiz = () => {
        setCreateQuiz(false)
        setEditQuiz(false)
        setViewQuiz(true)
    }

    const handleAllClose = () => {
        setCreateQuiz(false)
        setEditQuiz(false)
        setViewQuiz(false)
    }

    const handleFreeAddQuiz = () => {
        setCreateFreeQuiz(true)
        setEditFreeQuiz(false)
        setViewFreeQuiz(false)

    }

    const handleFreeEditQuiz = () => {
        setLessonFreeQuiz([])
        setCreateFreeQuiz(false)
        setEditFreeQuiz(true)
        setViewFreeQuiz(false)

    }

    const handleFreeViewQuiz = () => {
        setCreateFreeQuiz(false)
        setEditFreeQuiz(false)
        setViewFreeQuiz(true)
    }

    const handleFreeAllClose = () => {
        setCreateFreeQuiz(false)
        setEditFreeQuiz(false)
        setViewFreeQuiz(false)
    }


    return (
        <>
            <Header page='lesson' />
            <Container className='mt--7'>

                {!checkBox ?
                    <>

                        <div className='quiz_page_title'
                             style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <h2>Quiz Page</h2>
                            <label className={'ml-2'}>
                        <span className={`default switch-wrapper`}>
                        <input type='checkbox' onChange={(e) => handleCheckBox(e)} />
                        <span className={`${displayStyle} switch`}>
                             <span className='switch-handle' />
                        </span>
                        </span>
                                <span className='switch-label'>{checkBox ? 'FreeLesson' : 'Paid Lessons'}</span>
                            </label>
                        </div>
                        <div style={{
                            padding: '10px 10px',
                            zIndex: '100',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}>
                            <div style={{ width: '40%' }}>
                                <Select options={courseOptions} placeholder='Select course'
                                        value={selectedCourseData}
                                        onChange={handleCourseSelect} />
                            </div>
                            <div style={{ width: '40%' }}><Select options={lessonOptions} placeholder='Select lesson'
                                                                  value={selectedLessonData}
                                                                  onChange={handleLessonSelect} />
                            </div>
                            {(viewQuiz || editQuiz) &&
                                <Button type='button' color='primary' onClick={() => initiateQuizSearch()}> Search
                                    Quiz</Button>}

                        </div>
                        <div className='viewQuizOption_button_container'>
                            <Button type='button' color='primary' onClick={() => {
                                handleAddQuiz()
                            }}>Add Quizzes</Button>
                            <Button type='button' color='primary' onClick={() => {
                                handleEditQuiz()
                            }}>Edit Quizzes</Button>
                            <Button type='button' color='primary' onClick={() => handleViewQuiz()}>View Quizzes</Button>
                        </div>
                        <div className='quizPageContentsContainer'>
                            {createQuiz && <>
                                <div className='lessonQuizTabContainer'>
                                    <h2>Add Quizes for lesson {selectedLessonData.label}</h2>
                                    <LessonQuizTabs handleQuizArray={handleQuizArray} />
                                </div>
                                <Button type='button' color='warning' onClick={() => handleQuizPost()}>Upload
                                    Quiz</Button> </>}

                            {viewQuiz &&
                                <div className='viewQuizContainer'><h2>View {selectedLessonData.label} Lesson
                                    Quizzes</h2>
                                    {viewQuizData && <div>
                                        {viewQuizData.map((data, index) => {
                                            return (
                                                <div>
                                                    <h3>{data.title}</h3>
                                                    <LessonQuizView questions={data.quizData} />
                                                </div>
                                            )
                                        })}
                                    </div>}

                                </div>}

                            {editQuiz &&
                                <div className='viewQuizContainer'><h2>Edit {selectedLessonData.label} Lesson
                                    Quizzes</h2>
                                    {viewQuizData.length > 0 && <div>
                                        <LessonQuizTabs lessonQuiz={viewQuizData} handleQuizArray={handleQuizArray} />
                                        <Button style={{ marginTop: '15px' }} type='button' color='warning'
                                                onClick={() => handleUpdateQuiz()}>Update Quiz</Button>
                                    </div>
                                    }

                                </div>}

                        </div>
                    </>
                    :
                    <>
                        <div className='quiz_page_title'
                             style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <h2>Quiz Page</h2>
                            <label className={'ml-2'}>
                        <span className={`default switch-wrapper`}>
                        <input type='checkbox' onChange={(e) => handleCheckBox(e)} />
                        <span className={`${displayStyle} switch`}>
                             <span className='switch-handle' />
                        </span>
                        </span>
                                <span className='switch-label'>{checkBox ? 'FreeLesson' : 'Paid Lessons'}</span>
                            </label>
                        </div>
                        <div style={{
                            padding: '10px 10px',
                            zIndex: '100',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}>
                            <div style={{ width: '40%' }}>
                                <Select options={freeLessonOptions} placeholder='Select Free lesson'
                                                                  value={selectedFreeLessonData}
                                                                  onChange={handleFreeLessonSelect} />
                            </div>
                            {(viewFreeQuiz || editFreeQuiz) &&
                                <Button type='button' color='primary' onClick={() => initiateFreeQuizSearch()}> Search
                                    Quiz</Button>}

                        </div>
                        <div className='viewQuizOption_button_container'>
                            <Button type='button' color='primary' onClick={() => {
                                handleFreeAddQuiz()
                            }}>Add Quizzes</Button>
                            <Button type='button' color='primary' onClick={() => {
                                handleFreeEditQuiz()
                            }}>Edit Quizzes</Button>
                            <Button type='button' color='primary' onClick={() => handleFreeViewQuiz()}>View Quizzes</Button>
                        </div>
                        <div className='quizPageContentsContainer'>
                            {createFreeQuiz && <>
                                <div className='lessonQuizTabContainer'>
                                    <h2>Add Quizes for lesson {selectedFreeLessonData.label}</h2>
                                    <LessonQuizTabs handleQuizArray={handleFreeQuizArray} />
                                </div>
                                <Button type='button' color='warning' onClick={() => handleQuizFreePost()}>Upload
                                    Quiz</Button> </>}

                            {viewFreeQuiz &&
                                <div className='viewQuizContainer'><h2>View {selectedFreeLessonData.label} Lesson
                                    Quizzes</h2>
                                    {viewFreeQuizData && <div>
                                        {viewFreeQuizData&&viewFreeQuizData?.map((data, index) => {
                                            return (
                                                <div>
                                                    <h3>{data.title}</h3>
                                                    <LessonQuizView questions={data?.quizData} />
                                                </div>
                                            )
                                        })}
                                    </div>}

                                </div>}

                            {editFreeQuiz &&
                                <div className='viewQuizContainer'><h2>Edit {selectedFreeLessonData.label} Lesson
                                    Quizzes</h2>
                                    {viewFreeQuizData.length > 0 && <div>
                                        <LessonQuizTabs lessonQuiz={viewFreeQuizData} handleQuizArray={handleFreeQuizArray} />
                                        <Button style={{ marginTop: '15px' }} type='button' color='warning'
                                                onClick={() => handleUpdateFreeQuiz()}>Update Quiz</Button>
                                    </div>
                                    }

                                </div>}

                        </div>
                    </>
                }

            </Container>


        </>
    )
}

export default withRouter(withSnackbar(Quizzes))