import React from 'react'
import { withSnackbar } from 'notistack'
import Header from '../../components/Headers/Header'
import { Button, Container, Form, FormGroup, Input, InputGroup, Label } from 'reactstrap'
import Services from '../../RemoteServices/RemoteServices'


class AmountSetup extends React.Component {

    state = {
        amount: '7910',
        downline1: '10',
        downline2: '10',
        downline3: '10',
        id:''
    }

    updateAmount = () => {
        let reqDto = {
            total_amount: this.state.amount,
            d1_percent: Number(this.state.downline1),
            d2_percent: Number(this.state.downline2),
            d3_percent: Number(this.state.downline3)
        }
        Services.updateTotalAndPercentage(this.state.id,reqDto).then((response) => {
            this.props.enqueueSnackbar('Amount Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
           this.getAmount();
        }).catch((error) => {
                this.props.enqueueSnackbar(error.message, {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            })
    }
    getAmount = () => {
        Services.getTotalAndPercentage().then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                id:response[0].id,
                amount: response[0].total_amount,
                downline1: response[0].d1_percent,
                downline2: response[0].d2_percent,
                downline3: response[0].d3_percent
            }))

        })
    }

    componentDidMount() {
        this.getAmount();
    }


    handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <>
                <Header page={'lesson'} />
                <Container className={'mt-4'} style={{ minHeight: '480px' }}>
                    <Form role='form' style={{
                        maxWidth: '1000px',
                        width: '100%'
                    }}>
                        <FormGroup style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                            <Label style={{ fontWeight: 'bold' }}>Total Amount</Label>
                            <InputGroup style={{ width: '40%', marginRight: '10px' }}
                                        className='input-group-alternative mb-3'>
                                <Input type={'number'} placeholder='Total Amount' name='amount'
                                       value={this.state.amount} onChange={this.handleInputChange} />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                            <Label style={{ fontWeight: 'bold' }}>Downline 1 Percentage</Label>
                            <InputGroup style={{ width: '40%', marginRight: '10px' }}
                                        className='input-group-alternative mb-3'>
                                <Input type={'number'} placeholder='Downline 1 Percentage' name='downline1'
                                       value={this.state.downline1} onChange={this.handleInputChange} />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                            <Label style={{ fontWeight: 'bold' }}>Downline 2 Percentage</Label>
                            <InputGroup style={{ width: '40%', marginRight: '10px' }}
                                        className='input-group-alternative mb-3'>
                                <Input type={'number'} placeholder='Downline 2 Percentage' name='downline2'
                                       value={this.state.downline2} onChange={this.handleInputChange} />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
                            <Label style={{ fontWeight: 'bold' }}>Downline 3 Percentage</Label>
                            <InputGroup style={{ width: '40%', marginRight: '10px' }}
                                        className='input-group-alternative mb-3'>
                                <Input type={'number'} placeholder='Downline 3 Percentage' name='downline3'
                                       value={this.state.downline3} onChange={this.handleInputChange} />
                            </InputGroup>
                        </FormGroup>
                        <div className='pb-4'>
                            <Button style={{ width: '40%' }} color='primary' type='button'
                                    onClick={()=>this.updateAmount()}>
                                Update
                            </Button>
                        </div>
                    </Form>
                </Container>

            </>
        )
    }
}

export default withSnackbar(AmountSetup)