import React, { useState, useEffect } from 'react'
import Header from 'components/Headers/Header.js'
import { Container, Modal, Button, Spinner } from 'reactstrap'
import './ReviewComments.css'
import CommentCard from '../../components/Cards/CommentCard'
import { withSnackbar } from 'notistack'
import './Quizzes.css'

const Services = require('../../RemoteServices/RemoteServices.js')

const ReviewComments = (props) => {

    let [showComments, setShowComments] = useState('review')
    let [commentsList, setCommentsList] = useState([])
    let [commentsListLoading, setCommentsListLoading] = useState(true)
    let [showFreeComments, setShowFreeComments] = useState('review')
    let [freeCommentsList, setFreeCommentsList] = useState([])
    let [freeCommentsListLoading, setFreeCommentsListLoading] = useState(true)
    let [remarksModal, setRemarksModal] = useState(false)
    let [freeRemarksModal, setFreeRemarksModal] = useState(false)
    let [commentsData, setCommentsData] = useState({})
    let [freeCommentsData, setFreeCommentsData] = useState({})
    let [currentCommentInfo, setCurrentCommentInfo] = useState({
        reviewType: '',
        commentText: '',
        commentId: '',
        userId: '',
        lessonId: ''
    })
    let [freeCurrentCommentInfo, setFreeCurrentCommentInfo] = useState({
        reviewType: '',
        commentText: '',
        commentId: '',
        userId: '',
        lessonId: ''
    })
    const [checkBox, setCheckBox] = useState()

    let displayStyle = checkBox ? 'btn-warning' : 'btn-success'
    const handleCheckBox = (event) => {
        setCheckBox(event.target.checked)
        if(event.target.checked) {
            fetchFreeComments(false)
            setShowFreeComments('review')
        }else{
            fetchComments(false)
            setShowComments('review')
        }
    }

    const handleCommentsToFetchChange = (e) => {
        const { name } = e.target
        // eslint-disable-next-line no-lone-blocks
        {
            name === 'published' ? showPublished('published', true) : showPublished('review', false)
        }
    }
    const handleFreeCommentsToFetchChange = (e) => {
        const { name } = e.target
        // eslint-disable-next-line no-lone-blocks
        {
            name === 'published' ? showFreePublished('published', true) : showFreePublished('review', false)
        }
    }

    const showPublished = (statusWord, publishStatus) => {
        setCommentsListLoading(true)
        setShowComments(statusWord)
        fetchComments(publishStatus)
    }
    const showFreePublished = (statusWord, publishStatus) => {
        setFreeCommentsListLoading(true)
        setShowFreeComments(statusWord)
        fetchFreeComments(publishStatus)
    }

    useEffect(() => {
        fetchComments(false);
    }, [])

    const toggleRemarksModal = () => {
        setRemarksModal(!remarksModal)
    }
    const toggleFreeRemarksModal = () => {
        setFreeRemarksModal(!freeRemarksModal)
    }

    const fetchComments = (publishStat) => {
        Services.getLessonComments(publishStat).then((response) => {
            setCommentsList(response.results)
            setCommentsData(response)
            setCommentsListLoading(false)
        }).catch((error) => {
            console.log(error)
        })
    }

    const fetchFreeComments = (publishStat) => {
        Services.getFreeLessonComments(publishStat).then((response) => {
            setFreeCommentsList(response.results)
            setFreeCommentsData(response)
            setFreeCommentsListLoading(false)
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleCommentTextChange = (e) => {
        setCurrentCommentInfo({ ...currentCommentInfo, commentText: e.target.value })
    }

    const handleFreeCommentTextChange = (e) => {
        setFreeCurrentCommentInfo({ ...freeCurrentCommentInfo, commentText: e.target.value })
    }

    const setCurrentCommentData = (commentId, commentMessage, reviewType, userId, LessonId) => {
        setCurrentCommentInfo({
            commentId,
            commentText: commentMessage,
            reviewType,
            userId,
            lessonId: LessonId
        })
        toggleRemarksModal()
    }
    const setFreeCurrentCommentData = (commentId, commentMessage, reviewType, userId, LessonId) => {
        setFreeCurrentCommentInfo({
            commentId,
            commentText: commentMessage,
            reviewType,
            userId,
            lessonId: LessonId
        })
        toggleFreeRemarksModal()
    }


    const handlePublishDelete = () => {
        {
            currentCommentInfo.reviewType === 'Publish' ? handlePublishComment() : handleDeleteComment()
        }
    }

    const handleFreePublishDelete = () => {
        {
            freeCurrentCommentInfo.reviewType === 'Publish' ? handleFreePublishComment() : handleFreeDeleteComment()
        }
    }

    const handlePublishComment = () => {

        let { commentId, commentText, userId, lessonId, LessonId } = currentCommentInfo
        let data = {
            commentId: commentId,
            message: commentText,
            publish: currentCommentInfo.reviewType === 'Publish' ? true : false,
            user: userId,
            lesson: lessonId
        }
        Services.publishComment(data).then((response) => {
            props.enqueueSnackbar('Comment Published.', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )
            setCommentsListLoading(true)
            toggleRemarksModal()
            setShowComments(showComments)
            fetchComments(showComments === 'review' ? false : true)
        }).catch((error) => {
            props.enqueueSnackbar('Error deleting comment.', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )
        })
    }

    const handleDeleteComment = () => {
        let { commentId } = currentCommentInfo
        Services.deleteComment(commentId).then((response) => {
            props.enqueueSnackbar('Successfully deleted', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )
            setCommentsListLoading(true)
            toggleRemarksModal()
            setShowComments(showComments)
            fetchComments(showComments === 'review' ? false : true)
        }).catch((error) => {
            props.enqueueSnackbar('Something went wrong. Please try again!', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )
        })

    }

    const handleFreePublishComment = () => {

        let { commentId, commentText, userId, lessonId, LessonId } = freeCurrentCommentInfo
        let data = {
            commentId: commentId,
            message: commentText,
            publish: freeCurrentCommentInfo.reviewType === 'Publish' ? true : false,
            user: userId,
            lesson: lessonId
        }
        Services.publishFreeComment(data).then((response) => {
            props.enqueueSnackbar('Comment Published.', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )
            setFreeCommentsListLoading(true)
            toggleFreeRemarksModal()
            setShowFreeComments(showComments)
            fetchFreeComments(showFreeComments === 'review' ? false : true)
        }).catch((error) => {
            props.enqueueSnackbar('Error deleting comment.', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )
        })
    }

    const handleFreeDeleteComment = () => {
        let { commentId } = freeCurrentCommentInfo
        Services.deleteFreeComment(commentId).then((response) => {
            props.enqueueSnackbar('Successfully deleted', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )
            setFreeCommentsListLoading(true)
            toggleFreeRemarksModal()
            setShowFreeComments(showComments)
            fetchFreeComments(showComments === 'review' ? false : true)
        }).catch((error) => {
            props.enqueueSnackbar('Something went wrong. Please try again!', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
            )
        })

    }

    const handleShowMore = (action) => {
        action === 'next' ?
            Services.getCustom(commentsData.next).then((response) => {
                setCommentsList(response.results)
                setCommentsData(response)
            }) : Services.getCustom(commentsData.previous).then((response) => {
                setCommentsList(response.results)
                setCommentsData(response)
            })
    }

    const handleFreeShowMore = (action) => {
        action === 'next' ?
            Services.getFreeCustom(freeCommentsData.next).then((response) => {
                setFreeCommentsList(response.results)
                setFreeCommentsData(response)
            }) : Services.getCustom(freeCommentsData.previous).then((response) => {
                setFreeCommentsList(response.results)
                setFreeCommentsData(response)
            })
    }

    return (
        <>
            <Header page='lesson' />
            <Container className='mt--7'>
                {!checkBox ?
                    <>
                        <div className='quiz_page_title'
                             style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <label className={'ml-2'}>
                        <span className={`default switch-wrapper`}>
                        <input type='checkbox' onChange={(e) => handleCheckBox(e)} />
                        <span className={`${displayStyle} switch`}>
                             <span className='switch-handle' />
                        </span>
                        </span>
                                <span
                                    className='switch-label'>{!checkBox ? 'Lesson Review' : 'Free Lesson Preview'}</span>
                            </label>
                        </div>
                        <div className='reviewCommentContainer'>
                            <div className='reviewRatingHeadTextContainer'>
                                <h2>Review Lesson Comments</h2>
                                <div><span
                                    className='ratingCountNumber'>Total Comments: {commentsData && commentsData.count}</span>
                                </div>
                            </div>
                            <div className='reviewCommentButtonDiv'>
                                <button onClick={(e) => handleCommentsToFetchChange(e)} name='published'
                                        className={`reviewCommentButton_published ${showComments === 'published' ? 'active' : ''} `}>Published
                                </button>
                                <button onClick={(e) => handleCommentsToFetchChange(e)} name='review'
                                        className={`reviewCommentButton_forReview ${showComments === 'review' ? 'active' : ''}`}>Held
                                    for review
                                </button>
                            </div>

                            {commentsListLoading ?
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                                    <Spinner /></div> :

                                <div className='reviewComment_commentsContainer'>
                                    {commentsList.length > 0 ? commentsList.map((item, index) => {
                                        return (
                                            <CommentCard showComments={showComments} fetchComments={fetchComments}
                                                         commentData={item}
                                                         key={index} setCurrentCommentData={setCurrentCommentData} />
                                        )
                                    }) : <span className='noCommentsSpan'>No comments to display!</span>}
                                    {commentsData.count > 10 &&
                                        <div className='nextPreviousDiv'><Button type='button' color='primary'
                                                                                 disabled={commentsData.previous === null}
                                                                                 onClick={() => handleShowMore('prev')}> {'< Previous'}</Button><Button
                                            type='button' color='primary' disabled={commentsData.next === null}
                                            onClick={() => handleShowMore('next')}> {'Next >'}</Button></div>}

                                    <CommentCard />

                                </div>}
                        </div>
                    </>
                    :
                    <>
                        <div className='quiz_page_title'
                             style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <label className={'ml-2'}>
          <span className={`default switch-wrapper`}>
          <input type='checkbox' onChange={(e) => handleCheckBox(e)} />
          <span className={`${displayStyle} switch`}>
          <span className='switch-handle' />
          </span>
          </span>
                                <span
                                    className='switch-label'>{!checkBox ? 'Lesson Review' : 'Free Lesson Preview'}</span>
                            </label>
                        </div>
                        <div className='reviewCommentContainer'>
                            <div className='reviewRatingHeadTextContainer'>
                                <h2>Review Free Lesson Comments</h2>
                                <div><span
                                    className='ratingCountNumber'>Total Comments: {freeCommentsData && freeCommentsData.count}</span>
                                </div>
                            </div>
                            <div className='reviewCommentButtonDiv'>
                                <button onClick={(e) => handleFreeCommentsToFetchChange(e)} name='published'
                                        className={`reviewCommentButton_published ${showFreeComments === 'published' ? 'active' : ''} `}>Published
                                </button>
                                <button onClick={(e) => handleFreeCommentsToFetchChange(e)} name='review'
                                        className={`reviewCommentButton_forReview ${showFreeComments === 'review' ? 'active' : ''}`}>Held
                                    for review
                                </button>
                            </div>

                            {freeCommentsListLoading ?
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                                    <Spinner /></div> :

                                <div className='reviewComment_commentsContainer'>
                                    {freeCommentsList.length > 0 ? freeCommentsList.map((item, index) => {
                                        return (
                                            <CommentCard showComments={showFreeComments} fetchComments={fetchFreeComments}
                                                         commentData={item} key={index}
                                                         setCurrentCommentData={setFreeCurrentCommentData} />
                                        )
                                    }) : <span className='noCommentsSpan'>No comments to display!</span>}
                                    {freeCommentsData.count > 10 &&
                                        <div className='nextPreviousDiv'><Button type='button' color='primary'
                                                                                 disabled={freeCommentsData.previous === null}
                                                                                 onClick={() => handleFreeShowMore('prev')}> {'< Previous'}</Button><Button
                                            type='button' color='primary' disabled={freeCommentsData.next === null}
                                            onClick={() => handleFreeShowMore('next')}> {'Next >'}</Button></div>}

                                    <CommentCard />

                                </div>}
                        </div>
                    </>
                }
            </Container>
            <Modal
                className='modal-dialog-centered'
                isOpen={remarksModal}
                toggle={toggleRemarksModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Confirmation
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={toggleRemarksModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body'>
                    <h4>Are you sure you want to {currentCommentInfo.reviewType} this comment?</h4>
                    {currentCommentInfo.reviewType === 'Publish' &&
                        <input className='editCommentInput' type='text' onChange={(e) => handleCommentTextChange(e)}
                               value={currentCommentInfo.commentText}></input>}

                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={toggleRemarksModal}
                    >
                        Cancel
                    </Button>
                    <Button color='primary' type='button'
                            onClick={() => handlePublishDelete()}>
                        {currentCommentInfo.reviewType}
                    </Button>
                </div>
            </Modal>

            <Modal
                className='modal-dialog-centered'
                isOpen={freeRemarksModal}
                toggle={toggleFreeRemarksModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Confirmation
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={toggleFreeRemarksModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body'>
                    <h4>Are you sure you want to {freeCurrentCommentInfo.reviewType} this comment?</h4>
                    {freeCurrentCommentInfo.reviewType === 'Publish' &&
                        <input className='editCommentInput' type='text' onChange={(e) => handleFreeCommentTextChange(e)}
                               value={freeCurrentCommentInfo.commentText}></input>}

                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={toggleFreeRemarksModal}
                    >
                        Cancel
                    </Button>
                    <Button color='primary' type='button'
                            onClick={() => handleFreePublishDelete()}>
                        {freeCurrentCommentInfo.reviewType}
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default withSnackbar(ReviewComments)
