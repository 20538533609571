
import React, {useState,useEffect} from 'react'
import {InputGroup,Input,Button,Modal} from 'reactstrap'
import './LessonQuizForm.css'
import Tooltip from '@material-ui/core/Tooltip';


// let questions = [
//     {   
//         questionText: '',
//         answerOptions: [
//           { answerText: '', isCorrect: false },
//           { answerText: '', isCorrect: true }
//         ],
//       },

// ]






const LessonQuizView = (props) => {

  const {questions} = props
    console.log('asdf',questions)

    let [quizQuestions,setQuizQuestions] = useState(questions)
    let [questionAlert, setQuestionAlert] = useState(false)
    let [currentQuestionId, setCurrentQuestionId] = useState(null)
  
  
  
  
  //   const handleQuizDone = () => {
  //   handleQuizSetChange(number,quizQuestions)

  // }


    


    const updateQuestion = (updateValue, updatedQuestionIndex) => {
       let updatedQuizQuestions = quizQuestions.map((q, index) => {
          if (index === updatedQuestionIndex) {
            return {...q, questionText: updateValue}
          }
      
          return q
        })
      
        setQuizQuestions(updatedQuizQuestions)
      }

      const updateAnswerOption = (event, updateQuestionIndex, updateAnswerOptionIndex) => {
        const { value } = event.target
      
        let optionValue = value;
      
        const updatedQuizQuestions = quizQuestions.map((q, questionIndex) => {
          if (questionIndex === updateQuestionIndex) {
            const updatedAnswerOptions = q.answerOptions.map((a, answerIndex) => {
              if (answerIndex === updateAnswerOptionIndex) {
                return {...a, answerText: optionValue}
              }
              return a
            })
            return {...q, answerOptions: updatedAnswerOptions}
          }
      
          return q
        })
      
        setQuizQuestions(updatedQuizQuestions)
      }

      const updateAnswerChecked = (event, updateQuestionIndex, updateAnswerOptionIndex) => {
        const { checked} = event.target
      
        let optionValue = checked;
      
        const updatedQuizQuestions = quizQuestions.map((q, questionIndex) => {
          if (questionIndex === updateQuestionIndex) {
            const updatedAnswerOptions = q.answerOptions.map((a, answerIndex) => {
              if (answerIndex === updateAnswerOptionIndex) {
                return {...a, isCorrect: optionValue}
              }
              return {...a, isCorrect:false}
            })
            return {...q, answerOptions: updatedAnswerOptions}
          }
      
          return q
        })
      
        setQuizQuestions(updatedQuizQuestions)
      }

      let optionsFormat = {
          answerText:'',
          isCorrect:false
      }

      const addOption = (qnsIndex) => {
          const updatedQuizQuestions = quizQuestions.map((q,index) => {
            if(index === qnsIndex){
                return {...q, answerOptions: [...q.answerOptions, optionsFormat]  }
            } else return q
          })

          setQuizQuestions(updatedQuizQuestions)

      }

      const removeAnswer = (questionIndex, answerIndex) => {
        const updatedQuizQuestions = quizQuestions.map((q, qIndex) => {
          if (qIndex === questionIndex) {
            const updatedAnswerOption = q.answerOptions.filter((_, aIndex) => aIndex !== answerIndex)
    
            return {...q, answerOptions: updatedAnswerOption}
          } else {
            return q
          }
        })
    
        setQuizQuestions(updatedQuizQuestions)
      }

      const removeQuestion = (questionIndex) => {
          let updatedQuestions = quizQuestions.filter((_,qns) => qns !== questionIndex )
          setQuizQuestions(updatedQuestions)
      }


      const addQuestion = () => {
          let updatedQuizQuestions = quizQuestions.concat(questions)
          setQuizQuestions(updatedQuizQuestions)
      }

      const conformQuestionDelete = (qnsId) => {
        setQuestionAlert(true)
        setCurrentQuestionId(qnsId)

      }

     




    return (
        <div>

            {questions.map((ques,qnsIndex) => {
                return (
                    <div className="quizQuestionDiv" key={qnsIndex}>
                        <div className="quizQuestionTitleContainer">
                        <p>Question number <strong>{qnsIndex + 1}</strong></p> </div>
                        
                        <Input placeholder="Enter your question" value={ques.questionText}></Input>
                        <h4 style={{marginTop:'15px'}}>Answers</h4>
                        {ques.answerOptions.length &&
                            <div className="quizOptionsDiv">
                                {ques.answerOptions.map((ans, index) => {
                                    return (
                                        <div key={index} className="quizAnswerContainer">
                                            <Input name="answerOption" placeholder="Option" key={index}
                                                   value={ans.answerText}>
                                            </Input>
                                            <Tooltip title="Correct option">
                                                <input
                                                    index={index}
                                                    type="checkbox"
                                                    style={{ marginRight: '15px' }}
                                                    checked={ans.isCorrect}
                                                ></input>
                                            </Tooltip>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        
                    </div>
                )
            })}

        </div>
    )
}

export default LessonQuizView


