import React from 'react'
import Header from 'components/Headers/Header.js'
import Icon from '@material-ui/core/Icon'

import {
    Form,
    Card,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Container,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Button,
    Modal,
    Dropdown,
    Label
} from 'reactstrap'
import Select from 'react-select'
import MaterialTable, { MTableToolbar } from 'material-table'
import CircularProgress from '@material-ui/core/CircularProgress'
import StudentForm from 'components/Forms/StudentForm.js'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withSnackbar } from 'notistack'
import { TablePagination, TablePaginationProps } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import StudentDetails from 'components/Student/StudentDetails'
import avatar from '../../assets/img/dummyUser.png'
import PaymentStatus from '../../components/Modal/PaymentStatus'
import LeadsStudentDetails from 'components/LeadsStudent/LeadsStudentDetails'

const Services = require('../../RemoteServices/RemoteServices.js')


class LeadsFollowup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addFormView: false,
            passwordModal: false,
            data: [],
            coursesLookup: {},
            updatePasswordView: false,
            newPassword1: '',
            newPassword2: '',
            referralListShow: false,
            selectedRow: null,
            count: null,
            next: '',
            previous: '',
            pageNumber: 0,
            searchText: '',
            detailsPanel: false,
            searchType: '',
            searchTypeText: '',
            normalDropDownOpen: false,
            dateDropDown: false,
            dateText: '',
            dropdownOptions: [],
            selectedCourse: '',
            selectedCourseId: '',
            courseLessonList: [],
            courseLessonOptions: [],
            selectedLesson: '',
            selectedLessionId: '',
            rowsPerPage: 20,
            rowsPerpage2: 20,
            masterPassword: '',
            confirmModal: false,
            paymentHistory: false,
            studentData: '',
            newTableData: '',
            oldTableData: '',
            discountModal: false,
            totalAmount: '',
            discountAmount: '',
            amount: '',
            stage: 0,
            searchTextValue: '',
            isNew: 'new'
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.submitChangePassword = this.submitChangePassword.bind(this)
        this.onSearchChange = this.onSearchChange.bind(this)
        this.changeSearchType = this.changeSearchType.bind(this)
        this.renderCoursesFilters = this.renderCoursesFilters.bind(this)
        this.onSearchWithTypes = this.onSearchWithTypes.bind(this)
        this.PatchedPagination = this.PatchedPagination.bind(this)
    }


    getAmount = () => {
        Services.getTotalAndPercentage().then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                amount: response[0].total_amount,
            }))

        })
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name
        if (name === 'totalAmount') {
            this.setState({
                totalAmount: Number(value),
                discountAmount: Number(this.state.amount) - Number(value)
            })
        } else if (name === 'discountAmount') {
            this.setState({
                totalAmount: Number(this.state.amount) - Number(value),
                discountAmount: Number(value)
            })
        } else {
            this.setState({
                [name]: value
            })
        }
    }

    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    toggleBasicDropdown = () => {
        this.setState({
            normalDropDownOpen: !this.state.normalDropDownOpen
        })
    }

    async componentDidMount() {
        // this.getCourseList()
        // this.getUsersList(20)
        this.getLeadList(this.state.rowsPerPage, this.state.stage, this.state.searchTextValue, this.state.isNew);
        // await this.getCourses()
        // this.getAmount();
    }

    getUsersList(limit) {
        this.setState({ loading: true })
        Services.getStudents(limit).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous,
                verifiedEmail: response.total_verified_emails
            })
        })
    }

    getRequestedUsers() {
        this.setState({ loading: true })
        Services.getRequestedStudents().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getPaidUsers() {
        this.setState({ loading: true })
        Services.getPaidStudents().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getHasReferralsUsers() {
        this.setState({ loading: true })
        Services.getHasReferralsStudents().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getYoutubeUsers() {
        this.setState({ loading: true })
        Services.getYoutubeUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getNonYoutubeUsers() {
        this.setState({ loading: true })
        Services.getNonYoutubeUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getTestUsers() {
        this.setState({ loading: true })
        Services.getTestUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    setStage = (stage) => {
        this.setState({
            stage: stage
        })
    }

    getLeadList = (limit, stage, search, isNew) => {
        try {

            this.setState({ loading: true, stage: stage, search: search, isNew: isNew })
            Services.getLeadList(limit, stage, search, isNew).then((response) => {
                this.setState({
                    data: response.results,
                    loading: false,
                    count: response.count,
                    next: response.next,
                    previous: response.previous
                })
            })

        } catch (e) {

        }
    }

    getSearchLeadList = (limit, stage, search, isNew) => {
        try {

            this.setState({ stage: stage, search: search, isNew: isNew })
            Services.getLeadList(limit, stage, search, isNew).then((response) => {
                this.setState({
                    data: response.results,
                    loading: false,
                    count: response.count,
                    next: response.next,
                    previous: response.previous
                })
            })

        } catch (e) {

        }
    }

    getDueUsers(limit) {
        this.setState({ loading: true })
        Services.getDueUsers(limit).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    async getCourses() {
        let coursesLookup = {}
        await Services.getCourses().then((response) => {
            (response).forEach((item) => {
                // console.log(item)
                let itemId = item.id
                coursesLookup[itemId] = item.title
            })
        })
        // console.log(coursesLookup)
        await this.setState({ coursesLookup: coursesLookup })
    }

    submitChangePassword() {
        let userId = this.state.selectedRow.user.id
        let data = {
            password1: this.state.newPassword1,
            password2: this.state.newPassword2
        }
        Services.updatePassword(userId, data).then((response) => {
            this.setState({ updatePasswordView: false })
            this.props.enqueueSnackbar('Password Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })
    }

    async onSearchChange(search) {
        await this.setState({ searchTextValue: search.target.value })
        this.getSearchLeadList(this.state.rowsPerPage, this.state.stage, this.state.searchTextValue, this.state.isNew)

    }

    changeSearchType = async (type, typeText) => {
        this.setState({ searchType: type, searchTypeText: typeText, searchText: '' })
        if (type === '') {
            Services.getStudents().then((response) => {
                this.setState({
                    data: response.results,
                    loading: false,
                    count: response.count,
                    next: response.next,
                    previous: response.previous
                })
            })

        }
    }

    AddOrSubractDays = (startingDate, number) => {
        return new Date(new Date().setDate(startingDate.getDate() - number)).toLocaleDateString()
    }

    formatDate = (date) => {
        const dateParts = date.split('/')
        const outputDate = dateParts[2] + '-' + ('0' + dateParts[0]).slice(-2) + '-' + ('0' + dateParts[1]).slice(-2)
        return outputDate
    }

    changeDateType = async (event) => {
        this.setState({ dateType: event.target.value })
        await Services.getStudentsWithDate(
            event.target.value === 'All' ? '' : this.formatDate(new Date().toLocaleDateString()),
            event.target.value === 'today' ? this.formatDate(new Date().toLocaleDateString()) : event.target.value === '1' ? this.formatDate(this.AddOrSubractDays(new Date(), 1)) : event.target.value === '2' ? this.formatDate(this.AddOrSubractDays(new Date(), 2)) : event.target.value === '3' ? this.formatDate(this.AddOrSubractDays(new Date(), 3)) : event.target.value === '7' ? this.formatDate(this.AddOrSubractDays(new Date(), 7)) : event.target.value === '30' ? this.formatDate(this.AddOrSubractDays(new Date(), 30)) : ''
        ).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    async onSearchWithTypes(search) {
        await this.setState({ searchTextValue: search.target.value })
        await Services.getStudentsWithSearchType(this.state.searchType, this.state.searchText).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getCourseList() {
        Services.getCourses().then((response) => {
            this.setState({ courseList: response })
            this.populateSelectDropdown()
        })
    }

    populateSelectDropdown() {
        let data = this.state.courseList
        data.forEach((item) => {
            this.state.dropdownOptions.push({ value: item.id, label: item.title })
        })
    }

    populateLessonsDropdown() {
        this.setState({ courseLessonOptions: [] })
        let data = this.state.courseLessonList
        data.forEach((item) => {
            this.state.courseLessonOptions.push({ value: item.id, label: item.order + `. ${item.title}` })
        })


    }

    handleCourseSelect = async selectedCourse => {
        this.setState(
            { selectedCourse, selectedCourseId: selectedCourse.value }
        )
        await Services.filterByCourseLesson(selectedCourse.value, this.state.selectedLessionId).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
        await Services.getCourseLessons(selectedCourse.value).then((response) => {
            this.setState({
                courseLessonList: response
            })
        })
        this.populateLessonsDropdown()
    }

    handleLessonSelect = async (selectedLesson) => {
        this.setState({
            selectedLesson: selectedLesson.label, selectedLessonId: selectedLesson.value
        })
        await Services.filterByCourseLesson(this.state.selectedCourseId, selectedLesson.value).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })

    }

    renderCoursesFilters() {
        let coursesLookup = this.state.coursesLookup
        let str = ''
        // console.log(coursesLookup)
        for (let courseId in coursesLookup) {
            // console.log(courseId)
            // str = str + "<b>test</b>"
            str = str + '<Chip avatar={<Avatar style={{backgroundColor: \'blue\', color: \'white\'}}>OS</Avatar>} label=\'Only Test\' style={{marginRight: 10}} onClick={this.getTestUsers.bind(this)}  />'
        }
        return (
            <>
                {str}
            </>
        )
    }


    PatchedPagination = function (props) {
        const {
            ActionsComponent,
            onChangePage,
            onChangeRowsPerPage,
            count,
            page,
            rowsPerPage,
            ...tablePaginationProps
        } = props

        return (
            <TablePagination
                {...tablePaginationProps}
                // @ts-expect-error onChangePage was renamed to onPageChange
                onPageChange={(e, page) => {
                    if (page > this.state.pageNumber) {
                        this.setState({ pageNumber: this.state.pageNumber + 1, loading: false })
                        Services.getCustom(this.state.next).then((response) => {
                            this.setState({
                                data: response.results,
                                loading: false,
                                count: response.count,
                                next: response.next,
                                previous: response.previous
                            })
                        })
                    } else {
                        this.setState({ pageNumber: this.state.pageNumber - 1, loading: false })
                        Services.getCustom(this.state.previous).then((response) => {
                            this.setState({
                                data: response.results,
                                loading: false,
                                count: response.count,
                                next: response.next,
                                previous: response.previous
                            })
                        })
                    }
                }}
                onRowsPerPageChange={(e, page) => {
                    this.onRowsPerPageChange()
                    this.setState({ rowsPerPage: page.props.value })
                }}
                rowsPerPage={this.state.rowsPerPage}
                count={this.state.count}
                page={this.state.pageNumber}
                ActionsComponent={(subprops) => {
                    const { onPageChange, onRowsPerPageChange, ...actionsComponentProps } = subprops
                    return (
                        // @ts-expect-error ActionsComponent is provided by material-table
                        <ActionsComponent
                            {...actionsComponentProps}
                            onChangePage={onPageChange}
                            onChangeRowsPerPage={onRowsPerPageChange}
                        />
                    )
                }}
            />
        )
    }


    handleAmountUpdate = () => {
        new Promise((resolve, reject) => {
            setTimeout(() => {
                if (JSON.parse(localStorage.getItem('user')).role_type !== 'StudentEditor') {
                    if (this.state.newTableData.course !== null && this.state.newTableData.payment_status !== 'Unpaid') {
                        let reqDTO = {
                            full_name: this.state.newTableData.full_name,
                            email: this.state.newTableData.email,
                            discountAmount: Number(this.state.discountAmount),
                            course: this.state.newTableData.course,
                            payment_status: this.state.newTableData.payment_status,
                            phone_number: this.state.newTableData.phone_number
                        }

                        // Allow update only for specificRoleType
                        Services.updateStudent(this.state.oldTableData.id, reqDTO).then((response) => {
                            this.getUsersList(20);
                            this.setState({ studentData: response, discountModal: false, totalAmount: 0, discountAmount: 0 })

                            if (this.state.oldTableData.payment_status === 'Unpaid' && this.state.oldTableData.course === null) {
                                this.toggleState('paymentHistory')
                            }
                            this.props.enqueueSnackbar('User Updated Successfully', {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }
                            })
                        })
                            .catch((error) => {
                                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                    variant: 'warning',
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }
                                })
                            })
                    } else {
                        this.props.enqueueSnackbar('You Are not allowed to perform this action', {
                            variant: 'success',
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right'
                            }
                        })
                    }
                } else {
                    this.props.enqueueSnackbar('Please Contact Adminstrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    })
                }
                resolve()
            }, 300)
        })
    }

    handleCreateStudentOpen() {
        this.toggleState('passwordModal')
        this.toggleState('addFormView')
    }

    handleUserUpdate(oldData, newData) {

    }

    render() {
        const coursesLookup = this.state.coursesLookup
        const referralsList = this.state.selectedRow
        const courseOptions = this.state.dropdownOptions
        const lessonOptions = this.state.courseLessonOptions
        let str = this.renderCoursesFilters()


        console.log('Selected rows', this.state.rowsPerPage)
        // str = str.props.children
        // console.log(str.props.children)
        return (
            <>
                <Header onlineCount={this.props.onlineCount} onlineUsers={this.props.onlineUsers} />
                <Container className='mt--7' fluid>

                    <Row>
                        <div className=' col'>
                            <Card className=' shadow'>
                                {!(this.state.addFormView) ?
                                    this.state.loading ?
                                        <CircularProgress style={{
                                            margin: 50,
                                            alignSelf: 'center'
                                        }} />
                                        :
                                        <>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>


                                                <FormGroup
                                                    style={{ margin: 0, padding: 15, display: 'flex', width: '100%' }}>
                                                    <div style={{ display: 'flex', width: '100%', gap: '10px', alignItems: 'center' }}>
                                                        <div style={{ width: '60%' }}>

                                                            <Input
                                                                placeholder={this.state.searchTypeText ? this.state.searchTypeText : 'Search..'}
                                                                type='text' name='searchTextValue'
                                                                value={this.state.searchTextValue}
                                                                onChange={
                                                                    this.onSearchChange.bind(this)
                                                                } />

                                                        </div>
                                                        <div style={{ width: '15%' }}>
                                                            <Input className={'ml-2'} type={'select'}
                                                                name={'isNew'}
                                                                value={this.state.isNew}
                                                                onChange={(e) => {
                                                                    this.handleInputChange.bind(this)
                                                                    this.getLeadList(this.state.rowsPerPage, this.state.stage, this.state.searchTextValue, e.target.value);

                                                                }
                                                                }>
                                                                <option value={'new'}>Newest</option>
                                                                <option value={''}>Oldest</option>
                                                            </Input>
                                                        </div>
                                                    </div>
                                                </FormGroup>

                                            </div>
                                            <MaterialTable
                                                columns={[
                                                    {
                                                        title: 'Avatar',
                                                        field: 'imageUrl',
                                                        render: rowData => (
                                                            <div style={{ position: 'relative', width: 40, height: 40 }}>
                                                                <img
                                                                    src={rowData?.profile_picture || avatar}
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        objectFit: 'fill',
                                                                        borderRadius: '50%'
                                                                    }}
                                                                />
                                                                <span
                                                                    style={{
                                                                        position: 'absolute',
                                                                        bottom: 0,
                                                                        right: 0,
                                                                        width: 12,
                                                                        height: 12,
                                                                        borderRadius: '50%',
                                                                        backgroundColor: rowData?.is_online ? 'green' : 'lightgrey',
                                                                        border: '2px solid white'
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    },
                                                    {
                                                        title: 'Full Name',
                                                        field: 'user.full_name',
                                                        render: rowData => <span style={{ cursor: 'pointer' }}
                                                            onClick={async () => {
                                                                await this.setState({
                                                                    detailsPanel: true,
                                                                    selectedRow: rowData
                                                                })
                                                            }
                                                            }>{rowData.full_name}
                                                            &nbsp;
                                                            {rowData?.email_verified && <i style={{ color: 'blue', fontSize: 18 }} className={' fa fa-check-circle'}></i>}

                                                        </span>
                                                    },
                                                    {
                                                        title: 'Email', field: 'user.email', render: rowData =>
                                                            <>
                                                                <span>
                                                                    {rowData.email}</span>

                                                            </>
                                                    },
                                                    { title: 'Phone', field: 'phone_number' },
                                                    // {
                                                    //     title: 'Course',
                                                    //     field: 'course',
                                                    //     render: rowData => <> {rowData.course ? coursesLookup[rowData.course] : 'NA'}</>,
                                                    //     lookup: coursesLookup
                                                    // },
                                                    // {
                                                    //     title: 'Payment Status',
                                                    //     field: 'payment_status',
                                                    //     render: rowData =>
                                                    //         <>
                                                    //             {rowData.payment_status === 'Paid' &&
                                                    //                 <Icon style={{ color: 'green' }}>check</Icon>
                                                    //             }
                                                    //             {
                                                    //                 rowData.payment_status === 'Unpaid' &&
                                                    //                 <Icon style={{ color: 'red' }}>cancel</Icon>
                                                    //             }
                                                    //             {
                                                    //                 rowData.payment_status === 'Due' &&
                                                    //                 <h3>Due</h3>
                                                    //             }
                                                    //         </>,
                                                    //     lookup: { 'Paid': 'Paid', 'Unpaid': 'Not Paid', 'Due': 'Due' }
                                                    // },
                                                    // {
                                                    //     title: 'Group',
                                                    //     lookup: {
                                                    //         'youtuber': 'Youtuber',
                                                    //         'student': 'Student',
                                                    //         'test': 'Test'
                                                    //     },
                                                    //     hidden: true
                                                    // },
                                                    // {
                                                    //     title: 'Referral Code',
                                                    //     hidden: true,
                                                    //     render: rowData => <b>{rowData.user.referral_code}</b>
                                                    // },
                                                    // {
                                                    //     title: 'Referred Count',
                                                    //     hidden: true,
                                                    //     render: rowData => <b>
                                                    //         {rowData.user.referred_count}
                                                    //         {rowData.user.referred_count > 0 &&
                                                    //             <Button className='view-icon btn-2' color='secondary'
                                                    //                 outline type='button' onClick={() => {
                                                    //                     this.setState({
                                                    //                         referralListShow: true,
                                                    //                         selectedRow: rowData
                                                    //                     })
                                                    //                 }}>
                                                    //                 <span className='btn-inner--icon'>
                                                    //                     <i className='ni ni-bullet-list-67' />
                                                    //                 </span>
                                                    //             </Button>
                                                    //         }

                                                    //     </b>
                                                    // }
                                                ]}
                                                data={this.state.data}
                                                title={<div style={{ display: 'flex', width: '500px', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <h2>User List</h2>
                                                    {/* <span>Verified Email: {this.state.verifiedEmail || 0}</span> */}
                                                </div>}
                                                actions={[
                                                    // {
                                                    //     icon: 'add',
                                                    //     tooltip: 'Add Student',
                                                    //     isFreeAction: true,
                                                    //     onClick: (event) => this.toggleState('passwordModal')
                                                    // },
                                                    // {
                                                    //     icon: 'update',
                                                    //     tooltip: 'Update Password ',
                                                    //     onClick: (event, rowData) => {
                                                    //         this.setState({
                                                    //             updatePasswordView: true,
                                                    //             selectedRow: rowData
                                                    //         })
                                                    //    
                                                    //     }
                                                    // },
                                                    {
                                                        icon: 'visibility',
                                                        tooltip: 'View ',
                                                        onClick: async (event, rowData) => {
                                                            await this.setState({
                                                                detailsPanel: true,
                                                                selectedRow: rowData
                                                            })
                                                        }
                                                    },
                                                    // JSON.parse(localStorage.getItem('user')).role_type !== 'StudentEditor' && {
                                                    //     icon: 'delete',
                                                    //     tooltip: 'Delete User ',
                                                    //     onClick: (event, rowData) => {
                                                    //         this.setState({
                                                    //             confirmModal: true,
                                                    //             selectedRow: rowData
                                                    //         })
                                                    //     }
                                                    // }
                                                ]}


                                                components={{
                                                    Pagination: props => (
                                                        <>
                                                            <TablePagination
                                                                {...props}
                                                                rowsPerPageOptions={[20, 100, 500]}
                                                                rowsPerPage={this.state.rowsPerPage}
                                                                count={this.state.count}
                                                                page={this.state.pageNumber}
                                                                onChangeRowsPerPage={(e, page) => {
                                                                    this.setState({ rowsPerPage: page.props.value })
                                                                    this.getLeadList(page.props.value, this.state.stage, this.state.searchTextValue, this.state.isNew);

                                                                }}
                                                                onChangePage={(e, page) => {
                                                                    if (page > this.state.pageNumber) {
                                                                        this.setState({
                                                                            pageNumber: this.state.pageNumber + 1,
                                                                            loading: true
                                                                        })
                                                                        Services.getCustom(this.state.next).then((response) => {
                                                                            this.setState({
                                                                                data: response.results,
                                                                                loading: false,
                                                                                count: response.count,
                                                                                next: response.next,
                                                                                previous: response.previous
                                                                            })
                                                                        })
                                                                    } else {
                                                                        this.setState({
                                                                            pageNumber: this.state.pageNumber - 1,
                                                                            loading: true
                                                                        })
                                                                        Services.getCustom(this.state.previous).then((response) => {
                                                                            this.setState({
                                                                                data: response.results,
                                                                                loading: false,
                                                                                count: response.count,
                                                                                next: response.next,
                                                                                previous: response.previous
                                                                            })
                                                                        })
                                                                    }
                                                                }
                                                                }

                                                            >
                                                            </TablePagination>
                                                        </>
                                                    ),
                                                    Toolbar: props => (
                                                        <div>
                                                            <MTableToolbar {...props} />
                                                            <div style={{
                                                                padding: '10px 10px',
                                                                marginLeft: 10,
                                                                paddingBottom: 20
                                                            }}>
                                                                <Chip avatar={<Avatar style={{
                                                                    backgroundColor: this.state.stage === 0 ? 'blue' : 'green',
                                                                    color: 'white'
                                                                }}>0 </Avatar>} label='Stage'
                                                                    style={{ marginRight: 10, marginLeft: 10 }}
                                                                    onClick={() => {
                                                                        this.getLeadList(this.state.rowsPerPage, 0, this.state.searchTextValue, this.state.isNew);
                                                                        this.setState({ stage: 0 })
                                                                    }} />
                                                                <Chip avatar={<Avatar style={{
                                                                    backgroundColor: this.state.stage === 1 ? 'blue' : 'green',
                                                                    color: 'white'
                                                                }}>1 </Avatar>} label='Stage'
                                                                    style={{ marginRight: 10, marginLeft: 10 }}
                                                                    onClick={() => {
                                                                        this.getLeadList(this.state.rowsPerPage, 1, this.state.searchTextValue, this.state.isNew);
                                                                        this.setState({ stage: 1 })
                                                                    }} />
                                                                <Chip avatar={<Avatar style={{
                                                                    backgroundColor: this.state.stage === 2 ? 'blue' : 'green',
                                                                    color: 'white'
                                                                }}>2</Avatar>} label='Stage'
                                                                    style={{ marginRight: 10, marginLeft: 10 }}
                                                                    onClick={() => {
                                                                        this.getLeadList(this.state.rowsPerPage, 2, this.state.searchTextValue, this.state.isNew);
                                                                        this.setState({ stage: 2 })
                                                                    }} />
                                                                <Chip avatar={<Avatar style={{
                                                                    backgroundColor: this.state.stage === 3 ? 'blue' : 'green',
                                                                    color: 'white'
                                                                }}>3</Avatar>} label='Stage'
                                                                    style={{ marginRight: 10, marginLeft: 10 }}
                                                                    onClick={() => {
                                                                        this.getLeadList(this.state.rowsPerPage, 3, this.state.searchTextValue, this.state.isNew);
                                                                        this.setState({ stage: 3 })
                                                                    }} />
                                                                <Chip avatar={<Avatar style={{
                                                                    backgroundColor: this.state.stage === 4 ? 'blue' : 'green',
                                                                    color: 'white'
                                                                }}>4</Avatar>} label='Stage'
                                                                    style={{ marginRight: 10, marginLeft: 10 }}
                                                                    onClick={() => {
                                                                        this.getLeadList(this.state.rowsPerPage, 4, this.state.searchTextValue, this.state.isNew);
                                                                        this.setState({ stage: 4 })
                                                                    }} />
                                                                <Chip avatar={<Avatar style={{
                                                                    backgroundColor: this.state.stage === 5 ? 'blue' : 'green',
                                                                    color: 'white'
                                                                }}>5</Avatar>} label='Stage'
                                                                    style={{ marginRight: 10, marginLeft: 10 }}
                                                                    onClick={() => {
                                                                        this.getLeadList(this.state.rowsPerPage, 5, this.state.searchTextValue, this.state.isNew);
                                                                        this.setState({ stage: 5 })
                                                                    }} />
                                                                <Chip avatar={<Avatar style={{
                                                                    backgroundColor: this.state.stage === 6 ? 'blue' : 'green',
                                                                    color: 'white'
                                                                }}>6</Avatar>} label='Stage'
                                                                    style={{ marginRight: 10, marginLeft: 10 }}
                                                                    onClick={() => {
                                                                        this.getLeadList(this.state.rowsPerPage, 6, this.state.searchTextValue, this.state.isNew);
                                                                        this.setState({ stage: 6 })
                                                                    }} />
                                                                <Chip avatar={<Avatar style={{
                                                                    backgroundColor: this.state.stage === 7 ? 'blue' : 'green',
                                                                    color: 'white'
                                                                }}>C</Avatar>} label='Completed'
                                                                    style={{ marginRight: 10, marginLeft: 10 }}
                                                                    onClick={() => {
                                                                        this.getLeadList(this.state.rowsPerPage, 7, this.state.searchTextValue, this.state.isNew);
                                                                        this.setState({ stage: 7 })
                                                                    }} />



                                                            </div>
                                                            {/* <div style={{padding: '1px 10px', marginLeft: 10, paddingBottom: 20}}> */}
                                                            {/* Courses: */}
                                                            {/* { ReactHtmlParser (str.props.children) } */}
                                                            {/* <div dangerouslySetInnerHTML={{ __html: str.props.children }} /> */}
                                                            {/* </div> */}
                                                        </div>
                                                    )
                                                }}
                                                options={{
                                                    exportButton: false,
                                                    exportAllData: false,
                                                    // exportCsv: (columns, data) => {
                                                    //   alert('You should develop a code to export ' + data.length + ' rows');
                                                    // },
                                                    search: false,
                                                    pageSize: this.state.rowsPerPage,
                                                    rowsPerPage: this.state.rowsPerPage,
                                                    pageSizeOptions: [20, 100, 500],
                                                    columnsButton: false,
                                                    actionsColumnIndex: -1,
                                                    headerStyle: {
                                                        backgroundColor: '#6772E5',
                                                        color: '#FFF'
                                                    }
                                                }}
                                            />
                                        </>
                                    :
                                    <>
                                        <div class='d-flex mt-3'>
                                            <div class='mr-auto ml-4 mt-2'><h2>Students List</h2></div>
                                            <div class='p-2'>
                                                <Button className='btn-icon btn-3' color='primary' type='button'
                                                    onClick={() => this.toggleState('addFormView')}>
                                                    <span className='btn-inner--icon'>
                                                        <i className='ni ni-bag-17' />
                                                    </span>
                                                    <span className='btn-inner--text'>Back</span>
                                                </Button>
                                            </div>
                                        </div>

                                        <hr class='solid'></hr>
                                        <StudentForm onSuccess={() => {
                                            this.setState({ addFormView: false })
                                            this.getUsersList()
                                        }}></StudentForm>
                                    </>
                                }
                            </Card>
                        </div>
                    </Row>
                </Container>
                <Dialog open={this.state.updatePasswordView} onClose={() => this.toggleState('updatePasswordView')}>
                    <DialogTitle id='form-dialog-title'>Change Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter new password to change.
                        </DialogContentText>
                        <FormGroup>
                            <InputGroup className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <i className='ni ni-hat-3' />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Enter Password' type='text' name='newPassword1'
                                    value={this.state.newPassword1} onChange={this.handleInputChange.bind(this)} />
                            </InputGroup>
                        </FormGroup>

                        <FormGroup>
                            <InputGroup className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <i className='ni ni-hat-3' />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Enter Password Again' type='text' name='newPassword2'
                                    value={this.state.newPassword2} onChange={this.handleInputChange.bind(this)} />
                            </InputGroup>
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.toggleState('updatePasswordView')} color='primary'>
                            Cancel
                        </Button>
                        <Button onClick={this.submitChangePassword.bind(this)} color='primary'>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog disableBackdropClick fullWidth
                    maxWidth="sm" open={this.state.discountModal} onClose={() => this.toggleState('discountModal')}>
                    {/* <DialogTitle id='form-dialog-title'>Add Amount</DialogTitle> */}
                    <h2 className='ml-3 mt-1'>Total Amount: {this.state.amount}</h2>
                    <DialogContent>
                        <FormGroup>
                            <Label>User Paid Amount</Label>
                            <InputGroup className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <i className='ni ni-hat-3' />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Enter Total Amount' type='text' name='totalAmount'
                                    value={this.state.totalAmount} onChange={this.handleInputChange.bind(this)} />
                            </InputGroup>
                        </FormGroup>

                        <FormGroup>
                            <Label>Discount From Total Amount</Label>
                            <InputGroup className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <i className='ni ni-hat-3' />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Enter Discount Amount' type='text' name='discountAmount'
                                    value={this.state.discountAmount} onChange={this.handleInputChange.bind(this)} />
                            </InputGroup>
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleAmountUpdate()} color='primary'>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                {this.state.referralListShow &&
                    <Modal
                        className='modal-dialog-centered'
                        isOpen={this.state.referralListShow}
                        toggle={() => this.toggleState('referralListShow')}
                    >
                        <div className='modal-header'>
                            {/* <h4 className="modal-title" id="modal-title-default">
                  Referrals List
                </h4> */}
                            <Button
                                size='sm'
                                className='ml-auto'
                                color='primary'
                                data-dismiss='modal'
                                type='button'
                                onClick={() => this.toggleState('referralListShow')}
                            >
                                Close
                            </Button>
                        </div>
                        {/* <div className="modal-body"> */}
                        <MaterialTable
                            columns={[
                                { title: 'Full name', field: 'full_name' },
                                { title: 'Email', field: 'email' }
                            ]}
                            data={referralsList.user.referrals}
                            title='Referrals List'
                            options={{
                                actionsColumnIndex: -1,
                                headerStyle: {
                                    backgroundColor: '#6772E5',
                                    color: '#FFF'
                                }
                            }}
                        />
                        {/* </div> */}

                    </Modal>

                }
                {
                    this.state.detailsPanel &&
                    <LeadsStudentDetails stage={this.state.stage}
                        studentData={this.state.selectedRow}
                        getLeadList={this.getLeadList}
                        setStage={this.setStage}
                        closePanel={() => {
                            this.setState({ detailsPanel: false })

                        }}></LeadsStudentDetails>
                }
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.passwordModal}
                    toggle={() => this.toggleState('passwordModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Enter password to perform this action.</h4>
                        <Form onSubmit={
                            (e) => {
                                e.preventDefault()
                                Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                    this.handleCreateStudentOpen()
                                }).catch((error) => {
                                    this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                        variant: 'warning',
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }
                                    }
                                    )
                                })
                            }}>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                            onClick={
                                () => {
                                    Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                        this.handleCreateStudentOpen()
                                    }).catch((error) => {
                                        this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                        )
                                    })
                                }
                            }>
                            Enter
                        </Button>
                    </div>
                </Modal>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.confirmModal}
                    toggle={() => this.toggleState('confirmModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Are you sure you want to delete this user?</h4>
                        <Form>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                            onClick={
                                () => {
                                    let selectedRowId = this.state.selectedRow.id
                                    Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                        Services.deleteStudent(selectedRowId).then((response) => {
                                            this.toggleState('confirmModal')
                                            this.getUsersList()
                                            this.props.enqueueSnackbar('Successfully deleted', {
                                                variant: 'success',
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'right'
                                                }
                                            }
                                            )
                                        })
                                            .catch((error) => {
                                                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                    variant: 'warning',
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }
                                                }
                                                )
                                            })

                                    }).catch((error) => {
                                        this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                        )
                                    })
                                }
                            }>
                            Yes
                        </Button>
                    </div>
                </Modal>
                {this.state.paymentHistory &&
                    <PaymentStatus
                        paymentStatus={this.state.paymentHistory}
                        toggleState={this.toggleState}
                        studentData={this.state.studentData}
                        type={'table'}
                    />

                }
            </>
        )
    }
}

export default withSnackbar(LeadsFollowup)


// props => (
//   <>
//       <TablePagination
//           {...props}
//           rowsPerPageOptions={[]}
//           rowsPerPage={10}
//           count={this.state.count}
//           page={this.state.pageNumber}
//           onPageChange={(e, page) =>{
//             if(page > this.state.pageNumber) {
//               this.setState({pageNumber: this.state.pageNumber+1, loading: true})
//               Services.getCustom(this.state.next).then((response)=>{
//                 this.setState({data: response.results, loading: false, count: response.count, next: response.next, previous: response.previous})
//              })
//             }
//             else  {
//               this.setState({pageNumber: this.state.pageNumber-1, loading: true})
//               Services.getCustom(this.state.previous).then((response)=>{
//                 this.setState({data: response.results, loading: false, count: response.count, next: response.next, previous: response.previous})
//              })
//             }
//           }
//           }

//       >
//       </TablePagination>
//   </>
// )
