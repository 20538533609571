import React from 'react'
import { withSnackbar } from 'notistack'
import Select from 'react-select'
import { Row, Col, Alert, Modal, Label, Card } from 'reactstrap'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import DeleteIcon from '@material-ui/icons/Delete'
import AlarmIcon from '@material-ui/icons/Create'
import MaterialTable, { MTableToolbar } from 'material-table'
import UpdateAmount from 'components/Student/components/UpdateAmount'
import UpdateCurrentLesson from 'components/Student/components/UpdateCurrentLesson'
import SendNotifications from 'components/Student/components/SendNotifications'
import UpdateAssignmentVisibility from 'components/Student/components/UpdateAssignmentVisibility'
import Viewer from 'react-viewer'
import ReactHtmlParser from 'react-html-parser'
import Chip from '@material-ui/core/Chip'
import UserProfilePage from './UserProfilePage'
import { AddAlarmTwoTone, AddCircle, LooksTwoOutlined } from '@material-ui/icons'
import PaymentStatus from '../Modal/PaymentStatus'
import moment from 'moment'
import StudentHistoryDetail from '../Modal/StudentHistoryDetail'
import PromoCode from '../Modal/PromoCode'
import Icon from '@material-ui/core/Icon'
import AssignmentDescription from './AssignmnetDescription'
import LeadsStudentDetails from 'components/LeadsStudent/LeadsStudentDetails'
import LeadsDetail from './components/LeadsDetail'
import ChatStudentProfilePage from './ChatStudentProfilePage'

const Services = require('../../RemoteServices/RemoteServices.js')


class ChatStudent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: true,
            showDelieveredAmount: false,
            showCurrentLessonUpdate: false,
            showAssignmentVisibilityUpdate: false,
            showCreateNotifications: false,
            historyData: [],
            studentDetails: {
                current_lesson: {
                    title: ''
                }
            },
            studentDetailsNew: {},
            studentId: this.props?.studentData,
            assignmentImages: [],
            imagesModal: false,
            referralListModal: false,
            followListModal: false,
            followList: [],
            followListNext: '',
            followListPrevious: '',
            followTableTitle: '',
            pageLoading: true,
            noteDetail: [],
            paymentHistory: false,
            historyDetail: false,
            studentHistoryData: '',
            promoCode: '',
            promoCodeDetails: [],
            showRemarks: false,
            assignmentRemarks: ''
        }
        this.openImagesInNewTab = this.openImagesInNewTab.bind(this)
    }


    async componentDidMount() {
        // await this.getAssignmentHistory()
        await this.getDetails()
        // await this.getStudentNotesDetail()
        // await this.getPromoCodeDetail()
    }




    async getAssignmentHistory() {
        const userId = this.props.studentData
        console.log(userId)
        Services.getAssignmentListHistory(userId).then((response) => {
            this.setState({ historyData: response.results.reverse() })
        })
        await this.getDetails()
    }

    async getDetails() {
        const userId = this.props.studentData
        console.log(this.props.studentData)
        await Services.getStudentsDetails(userId).then((response) => {
            this.setState({ studentDetails: response, pageLoading: false })
        }).catch((error)=>{
            this.setState({pageLoading:false})
        })
    }

    getStudentNotesDetail = async (id) => {
        const userId = id ? id : this.props.studentData
        await Services.getStudentNotes(userId).then((response) => {
            this.setState({ noteDetail: response.results })
        })
    }

    getPromoCodeDetail = async (id) => {
        const userId = id ? id : this.props.studentData
        await Services.getPromoCode(userId).then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                promoCodeDetails: response
            }))
        }).catch((e) => {
            console.log('asdf', e)
        })
    }

    async openImagesInNewTab() {
        console.log('reached here', this.state.selectedRow)
        const images = []
        if (this.state.selectedRow) {
            let userAssignmentImages = this.state.selectedRow.images
            userAssignmentImages.forEach((image) => {
                images.push({ src: image.image, alt: '' })
                // window.open(image.image, "_blank")
            })
            this.setState({ assignmentImages: images })
            this.setState({ imagesModal: true })
            console.log(this.state.assignmentImages)
        }
    }

    async setRowData(rowData) {
        await this.setState({ selectedRow: rowData })
        await this.openImagesInNewTab()
    }

    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    render() {
        const data = this.state.studentDetails
        const user = data.user
        const course = data.current_course ? data.current_course : 'NA'
        let currentLesson = data.current_lesson !== null ? data.current_lesson.title : 'NA'
        let currentLessonNumber = data.current_lesson !== null ? data.current_lesson.order : 'NA'
        return (this.state.pageLoading ?
            <> Loading </> :
            <>
                <div>

                    <Dialog fullWidth
                        maxWidth="lg" open={this.state.open} onClose={() => {
                            this.props.closePanel()
                        }} TransitionComponent={this.Transition}>

                        <AppBar color='info' style={{ position: 'relative' }}>
                            <Toolbar>
                               
                                <Typography variant='h6' style={{
                                    marginLeft: 20,
                                    flex: 1
                                }}>
                                    <h2>User Details</h2>
                                </Typography>
                                <IconButton edge='start' color='inherit' onClick={() => {
                                    this.props.closePanel()
                                }} aria-label='close'>
                                    <CloseIcon />
                                </IconButton>
                                {/* <Button color='primary' type='button' onClick={() => {
                                    // this.props.closePanel()
                                    this.setState({ showCreateNotifications: true })
                                }}>
                                    SEND SMS / NOTIFICATION / E-MAIL
                                </Button> */}
                            </Toolbar>
                        </AppBar>


                        <ChatStudentProfilePage userId={data.new_id} />
                    </Dialog>
                </div>

            </>
        )
    }
}

export default withSnackbar(ChatStudent)