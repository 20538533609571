import React from 'react'
import Header from 'components/Headers/Header.js'
import { Editor } from 'react-draft-wysiwyg'
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor'
import './Lessons.css'
import {
    Badge,
    Card,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Label,
    Col,
    Button,
    Modal,
    CardTitle
} from 'reactstrap'
import MaterialTable, { MTableToolbar } from 'material-table'
import LessonsForm from 'components/Forms/LessonsForm.js'
import { withSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import { stateToHTML } from 'draft-js-export-html'
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import LessonRemarksTabs from 'components/Forms/LessonRemarksTabs'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import ReactHtmlParser from 'react-html-parser'
import { css } from 'glamor'
import {
    customChunkRenderer
} from 'draft-js-helpers'

const Services = require('../../RemoteServices/RemoteServices.js')


class Lessons extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addFormView: false,
            data: [],
            courseList: [],
            dropdownOptions: [],
            selectedOption: null,
            selectedOptionId: null,
            selectedRow: {},
            formModal: false,
            loading: true,
            lessonEditModal: false,
            lessonNo: '',
            title: '',
            description: '',
            videoLink: '',
            editorState: EditorState.createEmpty(),
            editorStateCopied: EditorState.createEmpty(),
            editorContentHtml: '',
            editorContentHtmlCopied: '',
            selectedRowId: '',
            image: '',
            lessonCourse: null,
            imagePreview: '',
            lessonRemarks: [],
            allowSubmit: false,
            courseSelectContainer: true,
            passwordModal: false,
            masterPassword: '',
            lessonViewType: true,
            copyStatus: false,
            showEditorCode: false
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.onEditorStateChange = this.onEditorStateChange.bind(this)
        this.onCourseCardClick = this.onCourseCardClick.bind(this)
        this.handleCreateLessonOpen = this.handleCreateLessonOpen.bind(this)
        this.toggleEditorCode = this.toggleEditorCode.bind(this)
        this.onEditEditorHTML = this.onEditEditorHTML.bind(this)
        this.handleCopy = this.handleCopy.bind(this)
        this.handlePaste = this.handlePaste.bind(this)
    }


    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    componentDidMount() {
        this.getCourseList()
        this.getLessonList()
    }

    getCourseList() {
        Services.getCourses().then((response) => {
            this.setState({ courseList: response })
            this.populateSelectDropdown()
        })
    }

    populateSelectDropdown() {
        let data = this.state.courseList
        data.forEach((item) => {
            this.state.dropdownOptions.push({ value: item.id, label: item.title })
        })
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOption, selectedOptionId: selectedOption.value, loading: true }
        )
        Services.getLessonList(selectedOption.value).then((response) => {
            this.setState({ data: response, loading: false })
        })
        // this.getLessonList()
    }

    onCourseCardClick = selectedOption => {
        this.setState(
            { selectedOption, selectedOptionId: selectedOption.value, loading: true }
        )
        Services.getLessonList(selectedOption.value).then((response) => {
            this.setState({ data: response, loading: false })
        })
    }

    getLessonList() {
        this.setState({ loading: true })
        let courseId = this.state.selectedOptionId ? this.state.selectedOptionId : null
        Services.getLessonList(this.state.selectedOptionId).then((response) => {
            this.setState({ data: response, loading: false })
        })
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    changeEditorContent(data) {
        this.setState({
            editorState: data
        })
    }

    onEditorStateChange(editorState) {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        this.setState({
            editorState,
            editorContentHtml: draftToHtml(rawContentState)
        })
    };


    returnHtmlBlock(text) {
        if (this.state.lessonViewType === true) {
            const processedHTML = htmlToDraft(text, customChunkRenderer)
            return ContentState.createFromBlockArray(processedHTML)
        } else {
            const processedHTML = convertFromHTML(text)
            return ContentState.createFromBlockArray(processedHTML)
        }
    }

    handleInitialEditorText(text) {
        let editorState
        if (text.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(text))
        } else {
            editorState = EditorState.createEmpty()
        }
        return editorState
    }

    onEditEditorHTML(e) {
        this.setState({ editorContentHtml: e.target.value })
    }


    addHtmlToEditor = () => {
        const { editorContentHtml } = this.state
        let editorState
        if (editorContentHtml.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(editorContentHtml))
        } else {
            editorState = EditorState.createEmpty()
        }
        this.setState({ editorState })
    }


    async updateLesson() {
        let newData = new FormData()
        newData.append('course', this.state.lessonCourse)
        newData.append('title', this.state.title)
        newData.append('description', this.state.description)
        if (this.state.image !== '') {
            newData.append('thumbnail', this.state.image, this.state.image.name)
        } else {
            newData.append('thumbnail', '')
        }
        newData.append('remarks', JSON.stringify(this.state.lessonRemarks))

        newData.append('youtube_link', this.state.videoLink)
        newData.append('details', this.state.editorContentHtml)
        newData.append('order', this.state.lessonNo)
        await Services.updateLesson(this.state.selectedRowId, newData).then((response) => {
            this.getLessonList()
            this.props.enqueueSnackbar('Lesson Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            this.toggleState('lessonEditModal')
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            })
    }

    uploadImageCallBack(file) {
        let token = localStorage.getItem('token')
        console.log(file)
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest()
                const serverUrl = window.config.apiUrl + 'common/image-upload'
                xhr.open('POST', serverUrl)
                xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                const data = new FormData()
                data.append('image', file)
                xhr.send(data)
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText)
                    resolve(response)
                })
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText)
                    reject(error)
                })
            }
        )
    }

    //Image selection and cases

    getExtension = (filename) => {
        var parts = filename.split('.')
        return parts[parts.length - 1]
    }

    isImage = (filename) => {
        var ext = this.getExtension(filename)
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
            case 'gif':
            case 'bmp':
            case 'png':
                //etc
                return true
        }
        return false
    }


    handleImageSelect = async (e) => {
        console.log(e.target.files[0].type)
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            this.setState({ imagePreview: URL.createObjectURL(e.target.files[0]), image: e.target.files[0] })
        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })

        }
    }

    handleImageRemove = (e) => {
        this.setState({ imagePreview: '', image: '' })
    }


    handleRemarksOptionChange = (optionArray) => {
        this.setState({ lessonRemarks: optionArray })
    }

    handleCopy = (e) => {
        e.preventDefault()
        console.log(this.state.editorContentHtml)
        const { editorContentHtml, editorState } = this.state
        this.setState({ editorStateCopied: editorState, editorContentHtmlCopied: editorContentHtml })
        this.props.enqueueSnackbar('Lesson data copied!', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
            }
        })
        this.setState({ copyStatus: true })
    }

    handlePaste = async (e) => {
        e.preventDefault()
        console.log('content html hai', this.state.editorContentHtml)
        this.setState({
            editorState: this.state.editorStateCopied,
            editorContentHtml: this.state.editorContentHtmlCopied
        })
        this.setState({ copyStatus: false })
    }

    handleCreateLessonOpen = () => {
        this.toggleState('passwordModal')
        this.toggleState('addFormView')
        this.toggleState('courseSelectContainer')
        this.setState({ masterPassword: '' })

    }

    toggleEditorCode() {
        this.setState({ showEditorCode: !this.state.showEditorCode })
    }


    render() {

        const ShowEditorCode = () => (
            <div className='rdw-option-wrapper' onClick={this.toggleEditorCode}>
                {this.state.showEditorCode
                    ? 'Hide Code'
                    : 'Show Code'}
            </div>
        )
        // var myTextIcon = React.useRef(null);
        const options = this.state.dropdownOptions
        return (
            <>
                <Header page='lesson' />
                <Container className='mt--7'>
                        <div className='courseSelectContainer'>
                            {this.state.courseSelectContainer && !this.state.lessonEditModal &&
                                <div>
                                    <div style={{
                                        padding: '10px 10px',
                                        zIndex: '100',
                                        width: '35%',
                                        marginTop: '130px'
                                    }}>
                                        {/* <Select options={options} placeholder="Select Course" value={this.state.selectedOption} onChange={this.handleChange.bind(this)} /> */}
                                        <h3>Select Course</h3>
                                    </div>
                                    <div style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        marginBottom: '20px',
                                        justifyContent: 'space-around',
                                        flexWrap: 'nowrap'
                                    }} className='coursesCardSlider'>

                                        {options.map((data) => {
                                            return (

                                                <Card style={{
                                                    marginRight: '10px',
                                                    width: '120px',
                                                    height: '60px',
                                                    padding: '10px',
                                                    border: '1px solid #ced6e0'
                                                }} className='coursesSelectCard' fixed
                                                      onClick={() => this.onCourseCardClick(data)} body
                                                      color={data.value === this.state.selectedOptionId ? 'success' : ''}>
                                                    <Row>
                                                        <div className='col'>

                                                            <CardTitle
                                                                tag='h5'
                                                            >
                                                                {data.label}
                                                            </CardTitle>

                                                        </div>
                                                    </Row>

                                                </Card>

                                            )
                                        })}

                                    </div>
                                </div>}
                        </div>


                    <Row>
                        <div className=' col'>
                            <Card className=' shadow'>

                                {!(this.state.addFormView) && !this.state.lessonEditModal ?
                                    this.state.loading ?
                                        <CircularProgress style={{
                                            margin: 50,
                                            alignSelf: 'center'
                                        }} />
                                        :

                                        <MaterialTable
                                            style={{ zIndex: '1', position: '' }}
                                            columns={[
                                                { title: 'Lesson Number', field: 'order' },
                                                { title: 'Title', field: 'title' },
                                                // { title: 'Description / Notes', field: 'description' },
                                                {
                                                    title: 'video',
                                                    field: 'youtube_link',
                                                    render: rowData => <a href={rowData.youtube_link}
                                                                          target='_blank'>{rowData.youtube_link}</a>
                                                }
                                            ]}
                                            data={this.state.data}
                                            title='Lessons'
                                            detailPanel={rowData => {
                                                return (
                                                    <>
                                                        <p style={{
                                                            padding: 20
                                                        }}>{ReactHtmlParser(rowData.details)}</p>
                                                    </>
                                                )
                                            }}
                                            actions={[
                                                {
                                                    icon: 'delete',
                                                    tooltip: 'Delete Lesson ',
                                                    onClick: (event, rowData) => {
                                                        // Do save operation
                                                        // Services.deleteCourse(rowData.id).then((response) =>{
                                                        //     this.getCourseList()
                                                        // })
                                                        this.setState({ confirmModal: true, selectedRow: rowData })
                                                    }
                                                },
                                                {
                                                    icon: () => <button className='addNewLessonButton'>Create a new
                                                        lesson</button>,
                                                    tooltip: 'Add Lesson',
                                                    isFreeAction: true,
                                                    onClick: (event) => {
                                                        this.toggleState('passwordModal')
                                                    }
                                                },
                                                {
                                                    icon: () => <button
                                                        className='toggleViewButton'>{this.state.lessonViewType ? 'New' : 'Old'}</button>,
                                                    tooltip: 'For Viewing Lesson Details',
                                                    isFreeAction: true,
                                                    onClick: (event) => {
                                                        this.setState({ lessonViewType: !this.state.lessonViewType })
                                                    }
                                                },
                                                {
                                                    icon: 'info',
                                                    tooltip: 'Details',
                                                    isFreeAction: false,
                                                    onClick: (event, rowData) => {
                                                        this.setState({
                                                            lessonNo: rowData.order,
                                                            description: rowData.description,
                                                            videoLink: rowData.youtube_link,
                                                            title: rowData.title,
                                                            editorState: this.handleInitialEditorText(rowData.details),
                                                            editorContentHtml: rowData.details,
                                                            selectedRowId: rowData.id,
                                                            imagePreview: rowData.thumbnail,
                                                            selectedRow: rowData,
                                                            lessonRemarks: rowData.remarks,
                                                            lessonCourse: rowData.course
                                                        })
                                                        this.toggleState('lessonEditModal');
                                                        this.toggleState('courseSelectContainer')
                                                    }
                                                }
                                            ]}


                                            editable={{

                                                onRowUpdate: (newData, oldData) =>

                                                    new Promise((resolve, reject) => {
                                                        let newRowUpdate = new FormData()
                                                        newRowUpdate.append('course', oldData.course)
                                                        newRowUpdate.append('order', newData.order)
                                                        newRowUpdate.append('title', newData.title)
                                                        newRowUpdate.append('youtube_link', newData.youtube_link)
                                                        setTimeout(() => {
                                                            {
                                                                Services.updateLesson(oldData.id, newRowUpdate).then((response) => {
                                                                    this.getLessonList()
                                                                    this.props.enqueueSnackbar('Lesson Updated Successfully', {
                                                                        variant: 'success',
                                                                        anchorOrigin: {
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right'
                                                                        }
                                                                    })
                                                                })
                                                                    .catch((error) => {
                                                                        this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                                                variant: 'warning',
                                                                                anchorOrigin: {
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'right'
                                                                                }
                                                                            }
                                                                        )
                                                                    })
                                                            }
                                                            resolve()
                                                        }, 300)
                                                    })
                                            }}
                                            // detailPanel={rowData => {
                                            //     return (
                                            //         <>
                                            //         <p style={{
                                            //         paddingLeft: 10
                                            //     }}><b>{rowData.title}</b></p>
                                            //     <p style={{
                                            //         paddingLeft: 10
                                            //     }}>{rowData.description}</p>
                                            //     </>
                                            //     )
                                            // }}
                                            options={{
                                                //   exportButton: true,
                                                // exportAllData: true,
                                                actionsColumnIndex: -1,
                                                pageSize: 20,
                                                pageSizeOptions: [20, 50, 100],
                                                headerStyle: {
                                                    backgroundColor: '#6772E5',
                                                    color: '#FFF',
                                                    searchFieldAlignment: 'left'
                                                }

                                            }}
                                            components={{
                                                Toolbar: props => (
                                                    <div>
                                                        <MTableToolbar {...props} />

                                                    </div>
                                                )
                                            }}
                                        />
                                    :
                                    <>
                                        {this.state.addFormView ?
                                            <>
                                                <div className='d-flex mt-3'>
                                                    <div className='mr-auto ml-4 mt-2'><h2>Lessons</h2></div>
                                                    <div className='p-2'>
                                                        <Button className='btn-icon btn-3' color='primary' type='button'
                                                                onClick={() => {
                                                                    this.toggleState('addFormView')
                                                                    //this.setState({courseSelectContainer:true})
                                                                     this.toggleState('courseSelectContainer')
                                                                }}>
                                        <span className='btn-inner--icon'>
                                            <i className='ni ni-bag-17' />
                                        </span>
                                                            <span className='btn-inner--text'>Back</span>
                                                        </Button>
                                                    </div>
                                                </div>

                                                <hr className='solid'></hr>
                                                <LessonsForm copyStatus={this.state.copyStatus}
                                                             editorState={this.state.editorStateCopied}
                                                             editorContentHtml={this.state.editorContentHtmlCopied}
                                                             onSuccess={(id, selectedOption) => {
                                                                 this.setState({
                                                                     addFormView: false,
                                                                     selectedOptionId: id,
                                                                     selectedOption: selectedOption
                                                                 })
                                                                 this.getLessonList()
                                                                 this.toggleState('courseSelectContainer')
                                                             }}></LessonsForm>
                                            </>
                                            :
                                            <Form role='form' style={{
                                                width: '100%',
                                                padding:'10px'
                                            }}>

                                                <div className='lessonEditTitleDiv' style={{ padding: '20px' }}>
                                                    <h3 style={{ marginBottom: '20px' }} className='modal-title'
                                                        id='exampleModalLabel'>
                                                        Edit Lesson
                                                    </h3>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '30%',
                                                        justifyContent: 'flex-end'
                                                    }}>
                                                        <Button onClick={(e) => this.handleCopy(e)}><i
                                                            className='ni ni-bag-17' /> Copy
                                                        </Button>
                                                        {this.state.copyStatus && <Button className='pasteButton'
                                                                                          onClick={(e) => this.handlePaste(e)}>Paste </Button>}&nbsp;
                                                        <Button className='btn-icon btn-3' color='primary' type='button'
                                                                onClick={() => {
                                                                    this.toggleState('lessonEditModal')
                                                                    this.toggleState('courseSelectContainer')
                                                                }}>
                                                                <span className='btn-inner--icon'>
                                                                    <i className='ni ni-bag-17' />
                                                                </span>
                                                            <span className='btn-inner--text'>Back</span>
                                                        </Button>
                                                    </div>
                                                </div>

                                                <FormGroup style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <InputGroup style={{ width: '40%', marginRight: '10px' }}
                                                                className='input-group-alternative mb-3'>
                                                        <InputGroupAddon addonType='prepend'>
                                                            <InputGroupText>
                                                                <i className='ni ni-hat-3' />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder='Lesson Number' type='text' name='lessonNo'
                                                               value={this.state.lessonNo}
                                                               onChange={this.handleInputChange} />
                                                    </InputGroup>
                                                    <InputGroup style={{ width: '40%', marginRight: '10px' }}
                                                                className='input-group-alternative mb-3'>
                                                        <InputGroupAddon addonType='prepend'>
                                                            <InputGroupText>
                                                                <i className='ni ni-hat-3' />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder='Lesson Title' type='text' name='title'
                                                               value={this.state.title}
                                                               onChange={this.handleInputChange} />
                                                    </InputGroup>
                                                    <InputGroup style={{ width: '40%' }}
                                                                className='input-group-alternative mb-3'>
                                                        <InputGroupAddon addonType='prepend'>
                                                            <InputGroupText>
                                                                <i className='ni ni-hat-3' />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder='Video Link' type='text' name='videoLink'
                                                               value={this.state.videoLink}
                                                               onChange={this.handleInputChange} />
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup encType='multipart/form-data'>
                                                    <p>{this.state.imagePreview ? 'Change thumbnail' : 'Add thumbnail'}</p>
                                                    <input className='thumbnail_upload_button' accept='png/jpeg'
                                                           type='file'
                                                           onChange={this.handleImageSelect} />
                                                    <br />
                                                    {this.state.imagePreview &&
                                                        <div className='thumbnailContainer'><i
                                                            onClick={this.handleImageRemove.bind(this)}
                                                            className='far fa-times-circle'></i> <img
                                                            className='lessonFormThumbnailImage' alt='thumbnail'
                                                            src={this.state.imagePreview} /></div>}


                                                </FormGroup>
                                                <div>

                                                    <Editor
                                                        editorStyle={{
                                                            border: '1px solid black',
                                                            padding: '5px',
                                                            borderRadius: '2px',
                                                            minHeight: '400px',
                                                            width: '100%'
                                                        }}
                                                        toolbarCustomButtons={[<ShowEditorCode />]}
                                                        placeholder='Details / Notes  [NEW Layout]'
                                                        editorState={this.state.editorState}
                                                        wrapperClassName='demo-wrapper'
                                                        editorClassName='demo-editor'
                                                        onEditorStateChange={this.onEditorStateChange.bind(this)}
                                                        toolbar={{
                                                            inline: { inDropdown: true },
                                                            list: { inDropdown: true },
                                                            textAlign: { inDropdown: true },
                                                            link: { inDropdown: true },
                                                            history: { inDropdown: true },
                                                            image: {
                                                                uploadCallback: this.uploadImageCallBack,
                                                                previewImage: true,
                                                                defaultSize: { width: '100%', height: 'auto' }
                                                            }
                                                        }}
                                                    />
                                                    {this.state.showEditorCode && (
                                                        <div {...css({ width: '100%' })}>
                                                            <textarea
                                                                rows={10}
                                                                {...css({
                                                                    width: '100%',
                                                                    padding: '0'
                                                                })}
                                                                value={this.state.editorContentHtml}
                                                                onChange={this.onEditEditorHTML}
                                                            />
                                                            <div>
                                                                <Button
                                                                    type='button'
                                                                    onClick={this.addHtmlToEditor}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>


                                                <div style={{ marginTop: '10pxddd' }}>
                                                    <h3>Add Remarks</h3>

                                                    <LessonRemarksTabs lessonRemarks={this.state.lessonRemarks}
                                                                       handleRemarksOptionChange={this.handleRemarksOptionChange.bind(this)} />

                                                </div>


                                                <div className='pb-4'>
                                                    <Button className='mt-4' color='primary' type='button'
                                                            onClick={this.updateLesson.bind(this)}>
                                                        Save
                                                    </Button>
                                                    <Button className='mt-4' color='danger' type='button'
                                                            onClick={() => this.toggleState('lessonEditModal')}>
                                                        Cancel
                                                    </Button>
                                                    <Button className={'mt-4'} onClick={(e) => this.handleCopy(e)}> <i
                                                        className='ni ni-bag-17' /> Copy </Button>
                                                    {this.state.copyStatus &&
                                                        <Button className={'mt-4'} className='pasteButton'
                                                                onClick={(e) => this.handlePaste(e)}>Paste </Button>}
                                                </div>
                                            </Form>
                                        }
                                    </>
                                }

                            </Card>
                        </div>
                    </Row>
                </Container>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.confirmModal}
                    toggle={() => this.toggleState('confirmModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Are you sure you want to delete?</h4>
                        <Form>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                                onClick={
                                    () => {
                                        Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                            Services.deleteLesson(this.state.selectedRow.id).then((response) => {
                                                this.getLessonList()
                                                this.toggleState('confirmModal')
                                                this.props.enqueueSnackbar('Successfully deleted', {
                                                        variant: 'success',
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'right'
                                                        }
                                                    }
                                                )
                                            })
                                                .catch((error) => {
                                                    this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                            variant: 'warning',
                                                            anchorOrigin: {
                                                                vertical: 'bottom',
                                                                horizontal: 'right'
                                                            }
                                                        }
                                                    )
                                                })

                                        }).catch((error) => {
                                            this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                                    variant: 'warning',
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }
                                                }
                                            )
                                        })


                                    }
                                }>
                            Yes
                        </Button>
                    </div>
                </Modal>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.passwordModal}
                    toggle={() => this.toggleState('passwordModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Enter password to perform this action.</h4>
                        <Form onSubmit={
                            (e) => {
                                e.preventDefault()
                                Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                    this.handleCreateLessonOpen()
                                }).catch((error) => {
                                    this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                    )
                                })


                            }
                        }>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                                onClick={
                                    () => {
                                        Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                            this.handleCreateLessonOpen()
                                        }).catch((error) => {
                                            this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                                    variant: 'warning',
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }
                                                }
                                            )
                                        })


                                    }
                                }>
                            Enter
                        </Button>
                    </div>
                </Modal>
                <Modal
                    size='lg'
                    className='modal-dialog-centered lessonUpdate'
                    //isOpen={this.state.lessonEditModal}
                    // toggle={() => this.toggleState("lessonEditModal")}
                    style={{ maxWidth: '1000px', width: '100%' }}>
                    <div className='modal-header'>
                        <Form role='form' style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            maxWidth: '1000px',
                            width: '100%'
                        }}>

                            <div className='lessonEditTitleDiv'>
                                <h3 style={{ marginBottom: '20px' }} className='modal-title' id='exampleModalLabel'>
                                    Edit Lesson
                                </h3>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '30%',
                                    justifyContent: 'flex-end'
                                }}>
                                    <Button onClick={(e) => this.handleCopy(e)}><i className='ni ni-bag-17' /> Copy
                                    </Button>
                                    {this.state.copyStatus && <Button className='pasteButton'
                                                                      onClick={(e) => this.handlePaste(e)}>Paste </Button>}&nbsp;
                                    <Button style={{ justifyContent: 'center', alignItems: 'center' }} color='danger'
                                            type='button'
                                            onClick={() => this.toggleState('lessonEditModal')}>
                                        Cancel
                                    </Button>
                                </div>
                            </div>

                            <FormGroup style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <InputGroup style={{ width: '40%', marginRight: '10px' }}
                                            className='input-group-alternative mb-3'>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <i className='ni ni-hat-3' />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder='Lesson Number' type='text' name='lessonNo'
                                           value={this.state.lessonNo} onChange={this.handleInputChange} />
                                </InputGroup>
                                <InputGroup style={{ width: '40%', marginRight: '10px' }}
                                            className='input-group-alternative mb-3'>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <i className='ni ni-hat-3' />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder='Lesson Title' type='text' name='title' value={this.state.title}
                                           onChange={this.handleInputChange} />
                                </InputGroup>
                                <InputGroup style={{ width: '40%' }} className='input-group-alternative mb-3'>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <i className='ni ni-hat-3' />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder='Video Link' type='text' name='videoLink'
                                           value={this.state.videoLink} onChange={this.handleInputChange} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup encType='multipart/form-data'>
                                <p>{this.state.imagePreview ? 'Change thumbnail' : 'Add thumbnail'}</p>
                                <input className='thumbnail_upload_button' accept='png/jpeg' type='file'
                                       onChange={this.handleImageSelect} />
                                <br />
                                {this.state.imagePreview &&
                                    <div className='thumbnailContainer'><i onClick={this.handleImageRemove.bind(this)}
                                                                           className='far fa-times-circle'></i> <img
                                        className='lessonFormThumbnailImage' alt='thumbnail'
                                        src={this.state.imagePreview} /></div>}


                            </FormGroup>
                            <div>

                                <Editor
                                    editorStyle={{
                                        border: '1px solid black',
                                        padding: '5px',
                                        borderRadius: '2px',
                                        height: '850px',
                                        width: '100%'
                                    }}
                                    toolbarCustomButtons={[<ShowEditorCode />]}
                                    placeholder='Details / Notes  [NEW Layout]'
                                    editorState={this.state.editorState}
                                    wrapperClassName='demo-wrapper'
                                    editorClassName='demo-editor'
                                    onEditorStateChange={this.onEditorStateChange.bind(this)}
                                    toolbar={{
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                        image: {
                                            uploadCallback: this.uploadImageCallBack,
                                            previewImage: true,
                                            defaultSize: { width: '100%', height: 'auto' }
                                        }
                                    }}
                                />
                                {this.state.showEditorCode && (
                                    <div {...css({ width: '100%' })}>
            <textarea
                rows={10}
                {...css({
                    width: '100%',
                    padding: '0'
                })}
                value={this.state.editorContentHtml}
                onChange={this.onEditEditorHTML}
            />
                                        <div>
                                            <Button
                                                type='button'
                                                onClick={this.addHtmlToEditor}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>


                            <div className='addRemarksContainer'>
                                <h3>Add Remarks</h3>

                                <LessonRemarksTabs lessonRemarks={this.state.lessonRemarks}
                                                   handleRemarksOptionChange={this.handleRemarksOptionChange.bind(this)} />

                            </div>


                            <div className='pb-4'>
                                <Button className='mt-4' color='primary' type='button'
                                        onClick={this.updateLesson.bind(this)}>
                                    Save
                                </Button>
                                <Button className='mt-4' color='danger' type='button'
                                        onClick={() => this.toggleState('lessonEditModal')}>
                                    Cancel
                                </Button>
                                <Button className={'mt-4'} onClick={(e) => this.handleCopy(e)}> <i
                                    className='ni ni-bag-17' /> Copy </Button>
                                {this.state.copyStatus && <Button className={'mt-4'} className='pasteButton'
                                                                  onClick={(e) => this.handlePaste(e)}>Paste </Button>}
                            </div>
                        </Form>
                    </div>

                </Modal>
            </>

        )
    }
}

export default withSnackbar(Lessons)