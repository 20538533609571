import React, { useState } from 'react'

const DeactiveUserToggle = ({ toggleCheckState,showDeactiveUser }) => {

    const [checkBox, setCheckBox] = useState(showDeactiveUser)

    let displayStyle = checkBox ? 'btn-warning' : 'btn-success'
    const handleCheckBox = (event) => {
        setCheckBox(event.target.checked)
        if (event.target.checked) {
            toggleCheckState(true)
        } else {
            toggleCheckState(false)
        }
        // if(event.target.checked) {
        //     fetchFreeComments(false)
        //     setShowFreeComments('review')
        // }else{
        //     fetchComments(false)
        //     setShowComments('review')
        // }
    }


    return (
        <div className='quiz_page_title'
             style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <label className={'ml-2'}>
                        <span className={`default switch-wrapper`}>
                        <input type='checkbox' onChange={(e) => handleCheckBox(e)} />
                        <span className={`${displayStyle} switch`}>
                             <span className='switch-handle' />
                        </span>
                        </span>
                <span
                    className='switch-label'>{!checkBox ? 'Requested User' : 'Deactivated User'}</span>
            </label>
        </div>
    )
}
export default DeactiveUserToggle