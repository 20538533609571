import React, { useCallback } from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col, Label
} from 'reactstrap'
import { withSnackbar } from 'notistack'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { stateToHTML } from 'draft-js-export-html'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Select from 'react-select'

const Services = require('../../RemoteServices/RemoteServices.js')


class CoursesForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            description: '',
            image: null,
            editorState: EditorState.createEmpty(),
            selectedOption: [],
            videoLink: '',
            images: [],
            selectedOptionId: '',
            category: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangeStatus = this.handleChangeStatus.bind(this)
        this.handleChangeStatusImages = this.handleChangeStatusImages.bind(this)
    }

    async handleChange(selectedOption) {
        console.log('asdfasf', selectedOption)
        await this.setState(
            { selectedOptionId: selectedOption.value, selectedOption }
        )
        console.log(this.state.selectedOptionId)
    };

    handleSubmit() {
        let courseData = {
            title: this.state.title,
            image: this.state.image,
            description: this.state.description,
            descriptionDetails: this.state.editorContentHtml,
            category: this.state.category
        }
        Services.createArticle(courseData).then((response) => {
            this.props.enqueueSnackbar('Article Created Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            this.props.onSuccess()
        })
            .catch((error) => {
                if(error.title && error.description && error.image){
                    this.props.enqueueSnackbar("Title, Description And Image Must Not Be Blank", {
                            variant: 'warning',
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right'
                            }
                        }
                    )
                }else if(error.title){
                    this.props.enqueueSnackbar("Title Must Not Be Blank", {
                            variant: 'warning',
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right'
                            }
                        }
                    )
                }else if(error.description){
                    this.props.enqueueSnackbar("Description Must Not Be Blank", {
                            variant: 'warning',
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right'
                            }
                        }
                    )
                }else if(error.image){
                    this.props.enqueueSnackbar("Image Must Not Be Blank", {
                            variant: 'warning',
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right'
                            }
                        }
                    )
                }else{
                    this.props.enqueueSnackbar("Please Contact Administrator", {
                            variant: 'warning',
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right'
                            }
                        }
                    )
                }


            })
    }


    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    getUploadParams = ({ meta }) => {
        return { url: 'https://httpbin.org/post' }
    }

    handleChangeStatusImages({ meta, file }, status) {
        let productImages = this.state.images
        let reader = new FileReader()
        reader.onloadend = function() {
            let result = reader
            if (!(productImages.includes(reader.result))) productImages.push(reader.result)
            this.setState({ images: productImages })
        }.bind(this)
        reader.readAsDataURL(file)
    }

    handleChangeStatus({ meta, file }, status) {
        // this.setState({image:file})
        let reader = new FileReader()
        reader.onloadend = function() {
            // console.log('RESULT', reader.result)
            this.setState({ image: reader.result })
        }.bind(this)
        reader.readAsDataURL(file)
        //  console.log(this.state)

    }

    // receives array of files that are done uploading when submit button is clicked
    //  handleSubmit = (files, allFiles) => {
    //   console.log(files.map(f => f.meta))
    //   allFiles.forEach(f => f.remove())
    // }

    onEditorStateChange(editorState) {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        this.setState({
            editorState,
            editorContentHtml: draftToHtml(rawContentState)
        })
    };

    uploadImageCallBack(file) {
        let token = localStorage.getItem('token')
        console.log(file)
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest()
                const serverUrl = window.config.apiUrl + 'common/image-upload'
                xhr.open('POST', serverUrl)
                xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                const data = new FormData()
                data.append('image', file)
                xhr.send(data)
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText)
                    resolve(response)
                })
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText)
                    reject(error)
                })
            }
        )
    }

    render() {
        const options = [
            { value: 'video', label: 'Video' },
            { value: 'images', label: 'Images' }
            // { value: 'vanilla', label: 'Vanilla' }
        ]
        return (
            <>
                <Form role='form' style={{
                    paddingLeft: 20,
                    paddingRight: 20
                }}>
                    <FormGroup style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Title' type='text' name='title' value={this.state.title}
                                   onChange={this.handleInputChange} />
                        </InputGroup>
                        <InputGroup style={{ width: '40%' }} className='ml-3 input-group-alternative mb-3'>
                            <Input className={'ml-2'} type={'select'} name={'category'}
                                   value={this.state.category} onChange={this.handleInputChange}>
                                <option>Select Category</option>
                                <option value={'Paid'}>Paid</option>
                                <option value={'Unpaid'}>Unpaid</option>
                                <option value={'Completed'}>Completed</option>
                                <option value={'For_All'}>For All</option>
                            </Input>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <Label>Short Description (old)</Label>
                        {this.state.description.length>0&& <span style={{fontSize:22}} className={'font-weight-bold'}>{this.state.description.length}</span>}
                        </div>
                        <InputGroup className='input-group-alternative mb-3'>
                            <Input rows="4" placeholder='Description' type='textarea' row={1} name='description'
                                   value={this.state.description} onChange={this.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                    {/* <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                        id="exampleFormControlTextarea1"
                        placeholder="Description / Notes [Old Layout]"
                        rows="3"
                        type="textarea"
                        name="description"
                        value={this.state.description}
                        onChange={this.handleInputChange}
                    />
                  </InputGroup>
                </FormGroup> */}
                    <b>Default Image:</b>
                    <Dropzone
                        getUploadParams={this.getUploadParams}
                        onChangeStatus={this.handleChangeStatus}
                        multiple={false}
                        maxFiles={1}
                        // onSubmit={this.handleSubmit}
                        accept='image/*'
                    />
                    <br />
                    {/*<Select name={'selectedOptionId'} onChange={(e)=>this.handleChange(e)} options={options}/>*/}
                    {
                        this.state.selectedOptionId === 'video' &&
                        <>
                            <FormGroup>
                                <InputGroup className='input-group-alternative mb-3'>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <i className='ni ni-hat-3' />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder='Video Link' type='text' name='videoLink'
                                           value={this.state.videoLink} onChange={this.handleInputChange} />
                                </InputGroup>
                            </FormGroup>
                        </>
                    }
                    {
                        this.state.selectedOptionId == 'images' &&
                        <>
                            <b>Article Description Details Images:</b>
                            <Dropzone
                                getUploadParams={this.getUploadParams}
                                onChangeStatus={this.handleChangeStatusImages}
                                multiple={true}
                                maxFiles={5}
                                // onSubmit={this.handleSubmit}
                                accept='image/*'
                            />
                            <br />
                        </>
                    }

                    <FormGroup>
                        <Editor
                            editorStyle={{
                                border: '1px solid black',
                                padding: '5px',
                                borderRadius: '2px',
                                height: 'auto',
                                minHeight:'200px',
                                width: '100%'
                            }}
                            placeholder='Description / Notes  [NEW Layout]'
                            editorState={this.state.editorState}
                            wrapperClassName='demo-wrapper'
                            editorClassName='demo-editor'
                            onEditorStateChange={this.onEditorStateChange.bind(this)}
                            toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                image: {
                                    uploadCallback: this.uploadImageCallBack,
                                    previewImage: true,
                                    defaultSize: { width: '100%', height: '100%' }
                                }
                            }}
                        />
                    </FormGroup>
                    <div className='pb-4'>
                        <Button className='mt-4' color='primary' type='button' onClick={this.handleSubmit}>
                            Create
                        </Button>
                    </div>
                </Form>
            </>
        )
    }
}

export default withSnackbar(CoursesForm)
