import React, { useState, useEffect } from 'react';
import Avatar from '../../assets/img/theme/avatar.png'
import moment from 'moment'
import './communityStyles.css'

const LikeComponent = (props) => {
    let [openCommentMenu, setOpenCommentMenu] = useState(false)
    let { data } = props
    const formatTime = (time) => {
        return moment(time).fromNow()
    }

    return (
        <div className="communityPostCommentDiv">
            <div className="communityPostCommentTopDiv">
                <div className="communityPostCommentImageDiv">
                    <img alt="profile avatar" src={data.user_profile_picture ? data.user_profile_picture : Avatar}></img>
                </div>
                <div className="communityPostCommentInfo">
                    <span onClick={() => props.showDetails(data.user)}>{data.user_full_name}</span>
                    <span>{formatTime(data.created_at)}</span>
                </div>
                {/* <i class="fas fa-ellipsis-h" onClick={() => setOpenCommentMenu(!openCommentMenu)}></i> */}
                {openCommentMenu &&

                    <div className="commentMenuOptions" onMouseLeave={() => setOpenCommentMenu(!openCommentMenu)}>
                        <ul>
                            <li>Delete</li>
                        </ul>
                    </div>}
            </div>
        </div>
    )

}

export default LikeComponent