import React, { Component } from 'react'
import { Button, Form, FormGroup, Input, Label, Modal } from 'reactstrap'
import Services from '../../RemoteServices/RemoteServices'
import { EditorState } from 'draft-js'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'

class PromoCode extends Component {
    state = {
        promoCode: '',
        status: 'Active',
        discount:''
    }

    handleSubmit = () => {
        let newData = {
            status: this.state.status==='Active'?true:false,
            code: this.state.promoCode.toUpperCase(),
            discount:Number(this.state.discount),
            user: this.props.studentData.user.id
        }

        Services.createPromoCode(newData).then((response) => {
            this.props.enqueueSnackbar('Promo Code Created Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            this.props.toggleState('promoCode')
            this.props.getPromoCodeDetail(this.props.studentData.user.id);
        })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            })
    }


    handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    render() {
        const { promoCode, toggleState, studentData, type } = this.props
        return (
            <Dialog PaperProps={{ style: { width: '800px', minHeight: '400px', height: 'auto' } }}
                    open={promoCode} onClose={() => toggleState('promoCode')}>
                <div className='modal-header mb-0'>
                    <h3 className='modal-title' id='exampleModalLabel'>
                        {type === 'table' ? 'Promo Code' : 'Add Promo Code'}
                    </h3>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('promoCode')}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body mt-0 pt-0'>
                    <Form>
                        <>
                            <FormGroup>
                                <Label>Promo Code</Label>
                                <Input
                                maxLength={20}
                                    type={'text'} name={'promoCode'}
                                       value={this.state.promoCode.toUpperCase()}
                                       onChange={this.handleInputChange}
                                       placeholder='Enter Promo Code'>
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>Discount</Label>
                                <Input  type={'number'} name={'discount'}
                                       value={this.state.discount}
                                       onChange={this.handleInputChange}
                                       placeholder='Enter Discount Percent'></Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>Status</Label>
                                <Input type={'select'} name={'status'}
                                       value={this.state.status} onChange={this.handleInputChange}>
                                    <option value={'Active'}>Active</option>
                                    <option value={'Inactive'}>InActive</option>
                                </Input>
                            </FormGroup>
                        </>
                    </Form>
                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('promoCode')}
                    >
                        Cancel
                    </Button>
                    <Button color='primary' type='button' onClick={() => this.handleSubmit()}>
                        Submit
                    </Button>
                </div>
            </Dialog>
        )
    }
}

export default withSnackbar(PromoCode)
