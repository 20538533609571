import React, { Component } from 'react'
import { Button, Form, FormGroup, Input, Label, Modal } from 'reactstrap'
import Services from '../../RemoteServices/RemoteServices'
import { EditorState } from 'draft-js'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'
import TextArea from 'antd/es/input/TextArea'

class StudentHistoryDetail extends Component {
    state = {
        // paymentStatus: this.props.studentData.payment_status,
        // paymentAmount: this.props.studentData.payment_status === 'UnPaid' ? '7910' : '0',
        // paymentDate: moment(new Date()).format('YYYY-MM-DD'),
        // comment: '',
        // paymentMethod: '',
        // image: '',
        // courseId: this.props.studentData.course,
        // courseList: []
    }

    handleSubmit = () => {
        let newData = {
            payment_status: this.state.paymentStatus,
            paid_amount: this.state.paymentAmount,
            paid_date: this.state.paymentDate,
            comment: this.state.comment,
            payment_method: this.state.paymentMethod,
            payment_screenshot: this.state.image,
            course_assign: this.state.courseId,
            student: this.props.studentData.studentId
        }

        Services.createStudentNotes(newData).then((response) => {
            this.props.enqueueSnackbar('Student Notes Created Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            this.props.getNoteDetail(this.props.studentData.studentId)
            this.props.toggleState('paymentHistory')
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            })
    }


    handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    getExtension = (filename) => {
        var parts = filename.split('.')
        return parts[parts.length - 1]
    }
    isImage = (filename) => {
        var ext = this.getExtension(filename)
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'gif':
            case 'bmp':
            case 'png':
            case 'jpeg':
                //etc
                return true
        }
        return false
    }

    handleLinkImageSelect = async (e) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        this.setState(prevState => ({
                            ...prevState,
                            image: response.data.link
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }
    handleLinkImageRemove = async (e) => {
        await this.setState(prevState => ({
            ...prevState,
            image: ''
        }))
    }

    getCourseList() {
        Services.getCourses().then((response) => {
            console.log('asdfasdf', response.data)
            this.setState({ courseList: response })
        })
    }

    componentDidMount() {
        // this.getCourseList()
    }

    render() {
        const { historyDetail, toggleState, studentData } = this.props
        console.log('asdfasdf', studentData)
        return (
            <Dialog PaperProps={{ style: { width: '800px', minHeight: '400px', height: 'auto' } }}
                    open={historyDetail} onClose={() => toggleState('historyDetail')}>
                <div className='modal-header mb-0'>
                    <h3 className='modal-title' id='exampleModalLabel'>
                        Student History Detail
                    </h3>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('historyDetail')}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body mt-0 pt-0'>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                            <h4>Course Assign : {studentData.course_assign}</h4>
                        </div>
                        <br/>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                            <h4>Comments</h4>
                        </div>
                        <TextArea disabled={true} value={studentData.comment}></TextArea>
                        <br/>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                            <h4>Screenshot</h4>
                        </div>

                        <img src={studentData.payment_screenshot} alt={'payment-screenshot'}   style={{ width: '100%', height: '100%' }}/>
                    </div>
                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('historyDetail')}
                    >
                        Cancel
                    </Button>
                </div>
            </Dialog>
        )
    }
}

export default withSnackbar(StudentHistoryDetail)
