import React from 'react'
import Header from 'components/Headers/Header.js'
import Icon from '@material-ui/core/Icon'

import { Button, Input } from 'reactstrap'
import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack'
import { TablePagination } from '@material-ui/core'
import avatar from '../../assets/img/dummyUser.png'
import ReferralDetails from '../../components/Referral/ReferralDetails'
import moment from 'moment';


const Services = require('../../RemoteServices/RemoteServices.js')


class ReferralList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addFormView: false,
            passwordModal: false,
            data: [],
            coursesLookup: {},
            updatePasswordView: false,
            newPassword1: '',
            newPassword2: '',
            referralListShow: false,
            selectedRow: null,
            count: null,
            next: '',
            previous: '',
            pageNumber: 0,
            searchText: '',
            detailsPanel: false,
            searchType: '',
            searchTypeText: '',
            normalDropDownOpen: false,
            dateDropDown: false,
            dateText: '',
            dropdownOptions: [],
            selectedCourse: '',
            selectedCourseId: '',
            courseLessonList: [],
            courseLessonOptions: [],
            selectedLesson: '',
            selectedLessionId: '',
            rowsPerPage: 50,
            rowsPerpage2: 20,
            masterPassword: '',
            confirmModal: false,
            statsData: '',
            verifiedEmail: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.submitChangePassword = this.submitChangePassword.bind(this)
        this.onSearchChange = this.onSearchChange.bind(this)
        this.changeSearchType = this.changeSearchType.bind(this)
        this.renderCoursesFilters = this.renderCoursesFilters.bind(this)
        this.onSearchWithTypes = this.onSearchWithTypes.bind(this)
        this.PatchedPagination = this.PatchedPagination.bind(this)
    }


    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    toggleBasicDropdown = () => {
        this.setState({
            normalDropDownOpen: !this.state.normalDropDownOpen
        })
    }

    async componentDidMount() {
        const params = new URLSearchParams(this.props.location.search)
        if (params.get('id')) {
            const searchType = params.get('id')
            this.setState({
                detailsPanel: true,
                studentData: {
                    id: searchType
                }
            })
        }


        this.getPaidUsers()
        this.getReferralStat()
    }

    getUsersList() {
        this.setState({ loading: true })
        Services.getStudents().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getRequestedUsers() {
        this.setState({ loading: true })
        Services.getRequestedStudents().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getPaidUsers() {
        this.setState({ loading: true })
        Services.getActiveReferral().then((response) => {
            this.setState({
                data: response.result,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous,
                verifiedEmail: response.total_verified_emails
            })
        })
    }

    getReferralStat() {
        Services.getReferralStats().then((response) => {
            this.setState({
                statsData: response
            })
        }).catch((error) => {
            console.log('error', error)

            //     this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
            //             variant: 'warning',
            //             anchorOrigin: {
            //                 vertical: 'bottom',
            //                 horizontal: 'right'
            //             }
            //         }
            //     )
            // })
        })
    }

    getVerifiedEmail = () => {
        Services.getVerifiedEmail().then((response) => {
            this.setState({
                verifiedEmail: response
            })
        }).catch((error) => {
            console.log('error', error)
        })
    }

    getHasReferralsUsers() {
        this.setState({ loading: true })
        Services.getPaidStudents().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getYoutubeUsers() {
        this.setState({ loading: true })
        Services.getYoutubeUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getNonYoutubeUsers() {
        this.setState({ loading: true })
        Services.getNonYoutubeUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getTestUsers() {
        this.setState({ loading: true })
        Services.getTestUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getDueUsers() {
        this.setState({ loading: true })
        Services.getDueUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    async getCourses() {
        let coursesLookup = {}
        await Services.getCourses().then((response) => {
            (response).forEach((item) => {
                // console.log(item)
                let itemId = item.id
                coursesLookup[itemId] = item.title
            })
        })
        // console.log(coursesLookup)
        await this.setState({ coursesLookup: coursesLookup })
    }

    submitChangePassword() {
        let userId = this.state.selectedRow.user.id
        let data = {
            password1: this.state.newPassword1,
            password2: this.state.newPassword2
        }
        Services.updatePassword(userId, data).then((response) => {
            this.setState({ updatePasswordView: false })
            this.props.enqueueSnackbar('Password Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })
    }

    async onSearchChange(search) {
        await this.setState({ searchTextValue: search.target.value })
        this.state.searchType !== '' ?
            await Services.getStudentsWithSearchType(this.state.searchType, this.state.searchTextValue).then((response) => {
                this.setState({
                    data: response.results,
                    loading: false,
                    count: response.count,
                    next: response.next,
                    previous: response.previous
                })
            }) :
            await Services.getStudentsSearch(this.state.searchTextValue).then((response) => {
                this.setState({
                    data: response.results,
                    loading: false,
                    count: response.count,
                    next: response.next,
                    previous: response.previous
                })
            })
    }

    changeSearchType = async (type, typeText) => {
        this.setState({ searchType: type, searchTypeText: typeText, searchText: '' })
        if (type === '') {
            Services.getStudents().then((response) => {
                this.setState({
                    data: response.results,
                    loading: false,
                    count: response.count,
                    next: response.next,
                    previous: response.previous
                })
            })

        }
    }

    AddOrSubractDays = (startingDate, number) => {
        return new Date(new Date().setDate(startingDate.getDate() - number)).toLocaleDateString()
    }

    formatDate = (date) => {
        const dateParts = date.split('/')
        const outputDate = dateParts[2] + '-' + ('0' + dateParts[0]).slice(-2) + '-' + ('0' + dateParts[1]).slice(-2)
        return outputDate
    }

    changeDateType = async (event) => {
        this.setState({ dateType: event.target.value })
        await Services.getStudentsWithDate(
            event.target.value === 'All' ? '' : this.formatDate(new Date().toLocaleDateString()),
            event.target.value === 'today' ? this.formatDate(new Date().toLocaleDateString()) : event.target.value === '1' ? this.formatDate(this.AddOrSubractDays(new Date(), 1)) : event.target.value === '2' ? this.formatDate(this.AddOrSubractDays(new Date(), 2)) : event.target.value === '3' ? this.formatDate(this.AddOrSubractDays(new Date(), 3)) : event.target.value === '7' ? this.formatDate(this.AddOrSubractDays(new Date(), 7)) : event.target.value === '30' ? this.formatDate(this.AddOrSubractDays(new Date(), 30)) : ''
        ).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    async onSearchWithTypes(search) {
        await this.setState({ searchTextValue: search.target.value })
        await Services.getStudentsWithSearchType(this.state.searchType, this.state.searchText).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getCourseList() {
        Services.getCourses().then((response) => {
            this.setState({ courseList: response })
            this.populateSelectDropdown()
        })
    }

    populateSelectDropdown() {
        let data = this.state.courseList
        data.forEach((item) => {
            this.state.dropdownOptions.push({ value: item.id, label: item.title })
        })
    }

    populateLessonsDropdown() {
        this.setState({ courseLessonOptions: [] })
        let data = this.state.courseLessonList
        data.forEach((item) => {
            this.state.courseLessonOptions.push({ value: item.id, label: item.order + `. ${item.title}` })
        })


    }

    handleCourseSelect = async selectedCourse => {
        this.setState(
            { selectedCourse, selectedCourseId: selectedCourse.value }
        )
        await Services.filterByCourseLesson(selectedCourse.value, this.state.selectedLessionId).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
        await Services.getCourseLessons(selectedCourse.value).then((response) => {
            this.setState({
                courseLessonList: response
            })
        })
        this.populateLessonsDropdown()
    }

    handleLessonSelect = async (selectedLesson) => {
        this.setState({
            selectedLesson: selectedLesson.label, selectedLessonId: selectedLesson.value
        })
        await Services.filterByCourseLesson(this.state.selectedCourseId, selectedLesson.value).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })

    }

    renderCoursesFilters() {
        let coursesLookup = this.state.coursesLookup
        let str = ''
        // console.log(coursesLookup)
        for (let courseId in coursesLookup) {
            // console.log(courseId)
            // str = str + "<b>test</b>"
            str = str + '<Chip avatar={<Avatar style={{backgroundColor: \'blue\', color: \'white\'}}>OS</Avatar>} label=\'Only Test\' style={{marginRight: 10}} onClick={this.getTestUsers.bind(this)}  />'
        }
        return (
            <>
                {str}
            </>
        )
    }


    PatchedPagination = function (props) {
        const {
            ActionsComponent,
            onChangePage,
            onChangeRowsPerPage,
            count,
            page,
            rowsPerPage,
            ...tablePaginationProps
        } = props

        return (
            <TablePagination
                {...tablePaginationProps}
                // @ts-expect-error onChangePage was renamed to onPageChange
                onPageChange={(e, page) => {
                    if (page > this.state.pageNumber) {
                        this.setState({ pageNumber: this.state.pageNumber + 1, loading: false })
                        Services.getCustom(this.state.next).then((response) => {
                            this.setState({
                                data: response.results,
                                loading: false,
                                count: response.count,
                                next: response.next,
                                previous: response.previous
                            })
                        })
                    } else {
                        this.setState({ pageNumber: this.state.pageNumber - 1, loading: false })
                        Services.getCustom(this.state.previous).then((response) => {
                            this.setState({
                                data: response.results,
                                loading: false,
                                count: response.count,
                                next: response.next,
                                previous: response.previous
                            })
                        })
                    }
                }}
                onRowsPerPageChange={(e, page) => {
                    this.onRowsPerPageChange()
                    this.setState({ rowsPerPage: page.props.value })
                }}
                rowsPerPage={this.state.rowsPerPage}
                count={this.state.count}
                page={this.state.pageNumber}
                ActionsComponent={(subprops) => {
                    const { onPageChange, onRowsPerPageChange, ...actionsComponentProps } = subprops
                    return (
                        // @ts-expect-error ActionsComponent is provided by material-table
                        <ActionsComponent
                            {...actionsComponentProps}
                            onChangePage={onPageChange}
                            onChangeRowsPerPage={onRowsPerPageChange}
                        />
                    )
                }}
            />
        )
    }

    handleCreateStudentOpen() {
        this.toggleState('passwordModal')
        this.toggleState('addFormView')
    }

    handleUserUpdate(oldData, newData) {

    }

    render() {
        return (
            <>
                <Header page='lesson' />
                <div className='container-fluid'>
                    <div className={'statistics-section'}>
                        <div className={'box-card'}>
                            <strong className={'heading'}>Total Referred User</strong>
                            <strong className={'content'}>{this.state?.statsData?.total_referred_users || ''}</strong>
                        </div>
                        <div className={'box-card'}>
                            <strong className={'heading'}>Total Paid User</strong>
                            <strong className={'content'}>{this.state?.statsData?.maximum_referred_count || ''}</strong>
                        </div>
                        <div className={'box-card'}>
                            <strong className={'heading'}>Total Max Downline</strong>
                            <strong
                                className={'content'}>{this.state?.statsData?.max_downline_amoung_all_users || ''}</strong>
                        </div>
                        <div className={'box-card'}>
                            <strong className={'heading'}>Max Referred</strong>
                            <strong className={'content'}>{this.state?.statsData?.maximum_referred_count || ''}</strong>
                        </div>
                        <div className={'box-card'}>
                            <strong className={'heading'}>Verified Email</strong>
                            <strong className={'content'}>{this.state?.verifiedEmail || 0}</strong>
                        </div>
                    </div>
                    <div className={'top-user-heading'}>
                        <h1>Top 10 Users</h1>
                    </div>
                    <div className={'top-user'}>
                        {this.state.statsData.top_users && this.state.statsData.top_users.map((item, index) => (
                            <div className={'users'} key={index}>
                                <div className={'badge'}>
                                    <img src={require('../../assets/img/badge.png')} height={50} width={50}
                                        style={{ objectFit: 'contain' }} />
                                </div>
                                <div className={'user-detail'}>
                                    <div><span> ({index + 1})</span>
                                        &nbsp;
                                        <a style={{
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            color: 'black'
                                        }}
                                            href={`${window.location.origin}/mlm/referralIds?id=${item.id}`}
                                            target={'_blank'}
                                        >{item.full_name}</a></div>
                                    <h4>Total: {item.referred_users_count}</h4>
                                </div>
                            </div>
                        ))}


                    </div>
                    <div className='paymentTableContainer'>
                        <MaterialTable
                            title='Referral IDS'
                            columns={[
                                {
                                    title: 'Avatar',
                                    field: 'imageUrl',
                                    render: rowData =>
                                        <div style={{ position: 'relative', width: 40, height: 40 }}>
                                            <img
                                                src={rowData.profile_picture?window.config.base.concat(rowData.profile_picture) :avatar}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'fill',
                                                    borderRadius: '50%'
                                                }}
                                            />
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    right: 0,
                                                    width: 12,
                                                    height: 12,
                                                    borderRadius: '50%',
                                                    backgroundColor: rowData?.is_online ? 'green' : 'lightgrey',
                                                    border: '2px solid white'
                                                }}
                                            />
                                        </div>
                                },
                                {
                                    title: 'Full Name',
                                    field: 'user.full_name',
                                    render: rowData => <span style={{ cursor: 'pointer' }}
                                        onClick={async () => {
                                            await this.setState({
                                                detailsPanel: true,
                                                selectedRow: rowData
                                            })
                                        }
                                        }>{rowData.full_name}
                                        &nbsp;
                                        {rowData?.email_verified && <i style={{ color: 'blue', fontSize: 18 }} className={' fa fa-check-circle'}></i>}
                                    </span>

                                },
                                {
                                    title: 'Email', field: 'email', render: rowData =>
                                        <>
                                            <span>
                                                {rowData.email}
                                            </span>

                                        </>
                                },

                                { title: 'Phone', field: 'phone_number' },
                                {
                                    title: 'Referral Code',
                                    field: 'referral_code',
                                    editable: false,
                                    hidden: true,
                                    render: rowData => <b>{rowData.user.referral_code}</b>
                                }
                            ]}
                            data={this.state.data}
                            // components={{
                            //     Pagination: props => (
                            //         <>
                            //             <TablePagination
                            //                 {...props}
                            //                 rowsPerPageOptions={[20]}
                            //                 rowsPerPage={20}
                            //                 count={JSON.parse(localStorage.getItem('user')).role_type === 'superuser' ? this.state.count : 'n/a'}
                            //                 page={this.state.pageNumber}
                            //                 onChangeRowsPerPage={(e, page) => {
                            //                     this.setState({ rowsPerPage: page.props.value })
                            //                 }}
                            //                 onChangePage={(e, page) => {
                            //                     if (page > this.state.pageNumber) {
                            //                         this.setState({
                            //                             pageNumber: this.state.pageNumber + 1,
                            //                             loading: true
                            //                         })
                            //                         Services.getCustom(this.state.next).then((response) => {
                            //                             this.setState({
                            //                                 data: response.results,
                            //                                 loading: false,
                            //                                 count: response.count,
                            //                                 next: response.next,
                            //                                 previous: response.previous
                            //                             })
                            //                         })
                            //                     } else {
                            //                         this.setState({
                            //                             pageNumber: this.state.pageNumber - 1,
                            //                             loading: true
                            //                         })
                            //                         Services.getCustom(this.state.previous).then((response) => {
                            //                             this.setState({
                            //                                 data: response.results,
                            //                                 loading: false,
                            //                                 count: response.count,
                            //                                 next: response.next,
                            //                                 previous: response.previous
                            //                             })
                            //                         })
                            //                     }
                            //                 }
                            //                 }
                            //
                            //             >
                            //             </TablePagination>
                            //         </>
                            //     ),
                            // }}
                            actions={[
                                {
                                    icon: 'visibility',
                                    tooltip: 'View',
                                    onClick: async (event, rowData) => {
                                        await this.setState({
                                            detailsPanel: true,
                                            selectedRow: rowData
                                        })

                                    }
                                }
                            ]}
                            options={{
                                //   exportButton: true,
                                // exportAllData: true,
                                sorting: true,
                                actionsColumnIndex: -1,
                                pageSize: 20,
                                pageSizeOptions: [20, 50, 100],
                                headerStyle: {
                                    backgroundColor: '#6772E5',
                                    color: '#FFF',
                                    searchFieldAlignment: 'left'
                                }

                            }}
                        />
                    </div>

                </div>
                {
                    this.state.detailsPanel &&
                    <ReferralDetails
                        studentData={this.state.selectedRow ? this.state.selectedRow : this.state.studentData}
                        closePanel={() => {
                            this.setState({ detailsPanel: false })
                        }}>
                    </ReferralDetails>
                }
            </>
        )
    }
}

export default withSnackbar(ReferralList)
