import React, { useState, useEffect } from 'react'
import { Input, Button, Modal } from 'reactstrap'
import './LinkQuizForm.css'
import Tooltip from '@material-ui/core/Tooltip'


let questions = [
    {
        questionText: '',
        link_answerOptions: [
            { answerText: '', isCorrect: false },
            { answerText: '', isCorrect: false }
        ]
    }

]


const LinkQuizForm = (props) => {

    let { questionsData } = props
    const { number, handleQuizSetChange } = props

    let [quizQuestions, setQuizQuestions] = useState(questionsData && questionsData?.length > 0 ? questionsData : questions)
    let [questionAlert, setQuestionAlert] = useState(false)
    let [currentQuestionId, setCurrentQuestionId] = useState(null)


    //   const handleQuizDone = () => {
    //   handleQuizSetChange(number,quizQuestions)

    // }

    useEffect(() => {
        handleQuizSetChange(number, quizQuestions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quizQuestions])


    const updateQuestion = (updateValue, updatedQuestionIndex) => {
        console.log('updateQuestion',updateValue)
        let updatedQuizQuestions = quizQuestions.map((q, index) => {
            if (index === updatedQuestionIndex) {
                return { ...q, questionText: updateValue }
            }

            return q
        })

        setQuizQuestions(updatedQuizQuestions)
    }

    const updateAnswerOption = (event, updateQuestionIndex, updateAnswerOptionIndex) => {
        const { value } = event.target

        let optionValue = value

        const updatedQuizQuestions = quizQuestions.map((q, questionIndex) => {
            if (questionIndex === updateQuestionIndex) {
                const updatedAnswerOptions = q.link_answerOptions.map((a, answerIndex) => {
                    if (answerIndex === updateAnswerOptionIndex) {
                        return { ...a, answerText: optionValue }
                    }
                    return a
                })
                return { ...q, link_answerOptions: updatedAnswerOptions }
            }

            return q
        })

        setQuizQuestions(updatedQuizQuestions)
    }

    const updateAnswerChecked = async (event, updateQuestionIndex, updateAnswerOptionIndex) => {
        const { checked } = event.target

        let optionValue = checked

        const updatedQuizQuestions = quizQuestions.map((q, questionIndex) => {
            if (questionIndex === updateQuestionIndex) {
                const updatedAnswerOptions = q.link_answerOptions.map((a, answerIndex) => {
                    if (answerIndex === updateAnswerOptionIndex) {
                        return { ...a, isCorrect: optionValue }
                    }
                    return { ...a, isCorrect: false }
                })
                return { ...q, link_answerOptions: updatedAnswerOptions }
            }

            return q
        })

        await setQuizQuestions(updatedQuizQuestions)
    }

    const updateFreeAnswerChecked = async (event, updateQuestionIndex, updateAnswerOptionIndex) => {
        const { checked } = event.target

        let optionValue = checked

        const updatedQuizQuestions = quizQuestions.map((q, questionIndex) => {
            if (questionIndex === updateQuestionIndex) {
                const updatedAnswerOptions = q.freeanswerOptions.map((a, answerIndex) => {
                    if (answerIndex === updateAnswerOptionIndex) {
                        return { ...a, isCorrect: optionValue }
                    }
                    return { ...a, isCorrect: false }
                })
                return { ...q, link_answerOptions: updatedAnswerOptions }
            }

            return q
        })

        await setQuizQuestions(updatedQuizQuestions)
    }

    let optionsFormat = {
        answerText: '',
        isCorrect: false
    }

    const addOption = (qnsIndex) => {
        const updatedQuizQuestions = quizQuestions.map((q, index) => {
            if (index === qnsIndex) {
                if (q.link_answerOptions.length < 4) { // Check if the current number of answer options is less than 4
                    return { ...q, link_answerOptions: [...q.link_answerOptions, optionsFormat] }
                }
            }
            return q
        })

        setQuizQuestions(updatedQuizQuestions)
    }

    const removeAnswer = (questionIndex, answerIndex) => {
        const updatedQuizQuestions = quizQuestions.map((q, qIndex) => {
            if (qIndex === questionIndex) {
                const updatedAnswerOption = q.link_answerOptions.filter((_, aIndex) => aIndex !== answerIndex)

                return { ...q, link_answerOptions: updatedAnswerOption }
            } else {
                return q
            }
        })

        setQuizQuestions(updatedQuizQuestions)
    }

    const removeQuestion = (questionIndex) => {
        let updatedQuestions = quizQuestions.filter((_, qns) => qns !== questionIndex)
        setQuizQuestions(updatedQuestions)
    }


    const addQuestion = () => {
        let updatedQuizQuestions = quizQuestions.concat(questions)
        setQuizQuestions(updatedQuizQuestions)
    }

    const conformQuestionDelete = (qnsId) => {
        setQuestionAlert(true)
        setCurrentQuestionId(qnsId)

    }

    return (
        <div>

            {quizQuestions && quizQuestions.map((ques, qnsIndex) => {
                return (
                    <div className='quizQuestionDiv' key={qnsIndex}>
                        <div className='quizQuestionTitleContainer'>
                            <p>Question number <strong>{qnsIndex + 1}</strong></p> <i
                            onClick={() => conformQuestionDelete(qnsIndex)} class='fas fa-times'></i></div>

                        <Input onChange={(e) => updateQuestion(e.target.value, qnsIndex)}
                               placeholder='Enter your question' value={ques.questionText}></Input>
                        <h4 style={{ marginTop: '15px' }}>Answers</h4>
                        {ques.link_answerOptions &&
                            <div className='quizOptionsDiv'>
                                {ques.link_answerOptions && ques.link_answerOptions.map((ans, index) => {
                                    return (
                                        <div key={index} className='quizAnswerContainer'>
                                            <Input name='answerOption' placeholder='Option' key={index}
                                                   value={ans.answerText}
                                                   onChange={(event) => updateAnswerOption(event, qnsIndex, index)}>
                                            </Input>
                                            <Tooltip title='Correct option'>
                                                <input
                                                    index={index}
                                                    type='checkbox'
                                                    checked={ans.isCorrect}
                                                    onChange={(event) => updateAnswerChecked(event, qnsIndex, index)}
                                                ></input>
                                            </Tooltip>

                                            <i onClick={(e) => removeAnswer(qnsIndex, index)}
                                               class='far fa-trash-alt'></i>

                                        </div>
                                    )
                                })}
                            </div>
                        }

                        {ques.freeanswerOptions &&
                            <div className='quizOptionsDiv'>
                                {ques.freeanswerOptions.map((ans, index) => {
                                    return (
                                        <div key={index} className='quizAnswerContainer'>
                                            <Input name='answerOption' placeholder='Option' key={index}
                                                   value={ans.answerText}
                                                   onChange={(event) => updateFreeAnswerChecked(event, qnsIndex, index)}>
                                            </Input>
                                            <Tooltip title='Correct option'>
                                                <input
                                                    index={index}
                                                    type='checkbox'
                                                    checked={ans.isCorrect}
                                                    onChange={(event) => updateFreeAnswerChecked(event, qnsIndex, index)}
                                                ></input>
                                            </Tooltip>

                                            <i onClick={(e) => removeAnswer(qnsIndex, index)}
                                               class='far fa-trash-alt'></i>

                                        </div>
                                    )
                                })}
                            </div>
                        }

                        <Button type='button' color='primary' onClick={() => addOption(qnsIndex)}>Add Option</Button>
                    </div>
                )
            })}

            <Button className={'mb-2'} type='button' color='primary' onClick={() => addQuestion()}>Add Question</Button>
            {/* <Button type="button" color="primary" onClick={()=> handleQuizDone()}>Done</Button> */}
            <Modal className='modal-dialog-centered' isOpen={questionAlert}
                   toggle={() => setQuestionAlert(!questionAlert)}>
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Confirmation
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => setQuestionAlert(!questionAlert)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body'>
                    <h4>Are you sure you want to delete this question?</h4>
                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => setQuestionAlert(!questionAlert)}
                    >
                        Cancel
                    </Button>
                    <Button color='primary' type='button'
                            onClick={() => {
                                setQuestionAlert(!questionAlert)
                                removeQuestion(currentQuestionId)
                            }}>
                        Yes
                    </Button>
                </div>
            </Modal>

        </div>
    )
}

export default LinkQuizForm


