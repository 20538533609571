import React from "react";
import { withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete';
import AlarmIcon from '@material-ui/icons/Create';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col
  } from "reactstrap";
import MaterialTable , { MTableToolbar } from 'material-table'
const Services = require('../../../RemoteServices/RemoteServices.js');


class UpdateAssignmentVisibility extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
     open:true,
     amount: '',
     hide:false,
    };
  }

  handleInputChange (){

  }

 
   render(){
    let studentId = this.props.studentData.id
    console.log(this.state.hide)
       return (
           <> 
            <div>
               
                    <div className="custom-control custom-radio mb-3">
                      <input
                        className="custom-control-input"
                        id="customRadio5"
                        name="custom-radio-2"
                        type="radio"
                        onClick={()=>{
                          this.setState({hide:false})
                        }}
                      />
                      <label className="custom-control-label" htmlFor="customRadio5">
                        Show
                      </label>
                    </div>
                    <div className="custom-control custom-radio mb-3">
                      <input
                        className="custom-control-input"
                        id="customRadio6"
                        name="custom-radio-2"
                        type="radio"
                        onClick={()=>{
                          this.setState({hide:true})
                        }}
                      />
                      <label className="custom-control-label" htmlFor="customRadio6">
                        Hide
                      </label>
                    </div>
                <Button size="sm"  color="primary" type="button" onClick={()=>{
                  let data ={
                    hide_assignment: this.state.hide
                  }
                  Services.updateStudent(studentId, data).then((response)=>{
                    this.props.onclose()
                    this.props.enqueueSnackbar('Successfully Updated', { 
                        variant: 'success',
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        }
                      }
                    )
                  })
                }}>
                    UPDATE
                  </Button>
                  <Button size="sm"  color="secondary" type="button" onClick={()=> {
                    console.log(this.state.hide)
                      this.props.onclose()
                  }}>
                    CANCEL
                  </Button>
             </div>
           </>
       )
   }
}

export default withSnackbar(UpdateAssignmentVisibility)