/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react'
import {
    Form,
    FormGroup,
    Input,
    Button,
    Card
} from 'reactstrap'
import './communityStyles.css'
import { withSnackbar } from 'notistack';
const Services = require('../../RemoteServices/RemoteServices.js');

const AnnouncementField = (props) => {
    let clickHandler = null
    let [announcementData, setAnnouncementData] = useState({
        message: '',
        image: ""
    })
    let [ImagePreview, setImagePreview] = useState('')

    const setImageInPost = (event) => {
        const { files } = event.target
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        setImagePreview(window.URL.createObjectURL(files[0]))
        reader.onload = function () {
            console.log(reader.result)
            setAnnouncementData({ ...announcementData, image: reader.result })
        }


    }

    const removeImage = () => {
        setAnnouncementData({ ...announcementData, image: "" })
    }

    const uploadCommunityPost = () => {
        {
            announcementData.message !== '' ?

                Services.addCommunityPost(announcementData).then((response) => {
                    props.enqueueSnackbar('Post Added', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        }
                    }
                    )
                    props.getCommunityPosts()
                    setAnnouncementData({ image: "", message: "" })

                }) :
                props.enqueueSnackbar('Announcement text cannot be empty!', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
                )

        }

    }


    return (
        <div className="announcementFieldContainer">
            <Form>
                <FormGroup>
                    <Input type="textarea" value={announcementData.message} onChange={(e) => { setAnnouncementData({ ...announcementData, message: e.target.value }) }} placeholder="Add an announcement."></Input>
                </FormGroup>
            </Form>
            {announcementData.image !== "" &&
                <div className="announcementImageContainer">

                    <img alt="preview" src={ImagePreview}></img>
                    <i class="fas fa-times-circle" onClick={() => removeImage()}></i>

                </div>}


            <input
                type='file'
                name='logo'
                hidden={true}
                onChange={setImageInPost}
                ref={(input) => {
                    clickHandler = input
                }}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <i class="fas fa-image" onClick={() => clickHandler.click()} ></i> <Button type="button" color="primary" onClick={() => uploadCommunityPost()}>Post</Button>
            </div>

        </div >
    )
}

export default withSnackbar(AnnouncementField)