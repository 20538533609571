import React from 'react'
import Header from 'components/Headers/Header.js'
import {
    Card,
    Container,
    Row,
    Button,
    Modal,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    InputGroupAddon, Label
} from 'reactstrap'
import Select from 'react-select'
import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import ArticlesForm from 'components/Forms/ArticlesForm.js'
import ReactHtmlParser from 'react-html-parser'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import Dropzone from 'react-dropzone-uploader'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor'
import { css } from 'glamor'
import './Articles.css'
import {
    customChunkRenderer
} from 'draft-js-helpers'

const Services = require('../../RemoteServices/RemoteServices.js')

class Courses extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filteredData: [],
            selectedCategory: '',
            addFormView: false,
            data: [],
            confirmModal: false,
            selectedRow: {},
            loading: true,
            masterPassword: '',
            articleEditOpen: false,
            title: '',
            description: '',
            image: null,
            editorState: EditorState.createEmpty(),
            selectedOption: [],
            selectedOptionId: '',
            articleViewType: true,
            articleId: '',
            imageAdded: false
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleUpdateArticle = this.handleUpdateArticle.bind(this)
        this.toggleEditorCode = this.toggleEditorCode.bind(this)
        this.onEditEditorHTML = this.onEditEditorHTML.bind(this)
        this.handleChangeStatus = this.handleChangeStatus.bind(this)
        this.handleChangeStatusImages = this.handleChangeStatusImages.bind(this)
    }


    async handleChange(selectedOption) {
        await this.setState(
            { selectedOptionId: selectedOption.value, selectedOption }
        )
    };

    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    async componentDidMount() {
        await this.getArticles()
    }

    async getArticles() {
        this.setState({ loading: true })
        await Services.getArticles().then((response) => {
            console.log(response)
            this.setState({ data: response, loading: false })
        })
    }

    handleCreateArtcleOpen = () => {
        this.toggleState('addFormView')
        this.toggleState('passwordModal')
    }

    onEditorStateChange(editorState) {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        this.setState({
            editorState,
            editorContentHtml: draftToHtml(rawContentState)
        })
    };

    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    returnHtmlBlock(text) {
        if (this.state.articleViewType === true) {
            const processedHTML = htmlToDraft(text)
            return ContentState.createFromBlockArray(processedHTML)
        } else {
            const processedHTML = DraftPasteProcessor.processHTML(text)
            return ContentState.createFromBlockArray(processedHTML)
        }
    }

    handleInitialEditorText(text) {
        let editorState
        if (text.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(text))
            editorState = EditorState.moveFocusToEnd(editorState)
        } else {
            editorState = EditorState.createEmpty()
        }
        // this.setState({editorContentHtml:stateToHTML(editorState.getCurrentContent())})
        return editorState
    }

    toggleEditorCode() {
        this.setState({ showEditorCode: !this.state.showEditorCode })
    }

    getUploadParams = ({ meta }) => {
        return { url: 'https://httpbin.org/post' }
    }

    handleChangeStatusImages({ meta, file }, status) {
        let productImages = this.state.images
        let reader = new FileReader()
        reader.onloadend = function() {
            if (!(productImages.includes(reader.result))) productImages.push(reader.result)
            this.setState({ images: productImages })
        }.bind(this)
        reader.readAsDataURL(file)

    }

    onEditEditorHTML(e) {
        this.setState({ editorContentHtml: e.target.value })
    }


    addHtmlToEditor = () => {
        const { editorContentHtml } = this.state
        let editorState
        if (editorContentHtml.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(editorContentHtml))
        } else {
            editorState = EditorState.createEmpty()
        }
        this.setState({ editorState })
    }

    uploadImageCallBack(file) {
        let token = localStorage.getItem('token')
        console.log(file)
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest()
                const serverUrl = window.config.apiUrl + 'common/image-upload'
                xhr.open('POST', serverUrl)
                xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                const data = new FormData()
                data.append('image', file)
                xhr.send(data)
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText)
                    resolve(response)
                })
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText)
                    reject(error)
                })
            }
        )
    }

    getUploadParams = ({ meta }) => {
        return { url: 'https://httpbin.org/post' }
    }

    // handleChangeStatusImages({ meta, file }, status)  {
    //   let productImages = this.state.images
    //   let reader = new FileReader();
    //         reader.onloadend = function() {
    //           let result = reader
    //           if(!(productImages.includes(reader.result))) productImages.push(reader.result)
    //             this.setState({images: productImages})
    //           }.bind(this)
    //   reader.readAsDataURL(file);
    //  console.log(this.state.images)

    // }

    handleChangeStatus({ meta, file }, status) {
        // this.setState({image:file})
        let reader = new FileReader()
        reader.onloadend = function() {
            // console.log('RESULT', reader.result)
            this.setState({ image: reader.result, imageAdded: true })
        }.bind(this)
        reader.readAsDataURL(file)
        //  console.log(this.state)

    }

    handleUpdateArticle() {
        let articleData
        this.state.imageAdded ?
            articleData = {
                title: this.state.title,
                descriptionDetails: this.state.editorContentHtml,
                image: this.state.image,
                description: this.state.description,
                category: this.state.category
            } : articleData = {
                title: this.state.title,
                descriptionDetails: this.state.editorContentHtml,
                description: this.state.description,
                category: this.state.category

            }
        Services.updateArticle(this.state.articleId, articleData).then((response) => {
            this.setState({ imageAdded: false })
            this.props.enqueueSnackbar('Article Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            this.toggleState('articleEditOpen')
            this.getArticles()
        }).catch((error) => {
            if(error.title && error.description && error.image){
                this.props.enqueueSnackbar("Title, Description And Image Must Not Be Blank", {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            }else if(error.title){
                this.props.enqueueSnackbar("Title Must Not Be Blank", {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            }else if(error.description){
                this.props.enqueueSnackbar("Description Must Not Be Blank", {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            }else if(error.image){
                this.props.enqueueSnackbar("Image Must Not Be Blank", {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            }else{
                this.props.enqueueSnackbar("Please Contact Administrator", {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            }


        })
    }

    handleCategoryChange = (event) => {
        const category = event.target.value;
        this.setState({ selectedCategory: category });
        if (category === '') {
            // if no category is selected, show all data
            this.setState({ filteredData: [] });
        } else {
            const filteredData = this.state.data.filter(
                (item) => item.category === category
            );
            this.setState({ filteredData });
        }
    };

    render() {

        const ShowEditorCode = () => (
            <div className='rdw-option-wrapper' onClick={this.toggleEditorCode}>
                {this.state.showEditorCode
                    ? 'Hide Code'
                    : 'Show Code'}
            </div>
        )
        return (
            <>
                <Header />
                <Container className='mt--7' fluid>
                    <Row>
                        <div className=' col'>
                            <Card className=' shadow'>
                                {!(this.state.addFormView) ?
                                    this.state.loading ?
                                        <CircularProgress style={{
                                            margin: 50,
                                            alignSelf: 'center'
                                        }} />
                                        :
                                        <MaterialTable
                                            columns={[
                                                {
                                                    title: 'S.N',
                                                    field: 'id',
                                                    editable: 'never',
                                                    render: rowData => <>{rowData.tableData.id + 1}</>
                                                },
                                                {
                                                    title: 'Title',
                                                    field: 'title',
                                                    render: rowData => <b>{rowData.title}</b>
                                                },
                                                {
                                                    title: 'Image',
                                                    field: 'image',
                                                    editable: 'never',
                                                    render: rowData => <a href={rowData.image} target='_blank'>View </a>
                                                },
                                                {
                                                    title:'Category',
                                                    field:'category',
                                                    render:rowData => <>{rowData.category|| 'N/A'}</>

                                                }
                                                // { title: 'Description', field: 'description' },
                                            ]}
                                            data={this.state.filteredData.length > 0 ? this.state.filteredData : this.state.data}
                                            title='Articles'
                                            actions={[
                                                {
                                                    icon: () => <>
                                                        <Input className={'inside-table ml-2'} type={'select'}
                                                               value={this.state.selectedCategory}
                                                               onChange={this.handleCategoryChange}>
                                                            <option>Select Category</option>
                                                            <option value={'Paid'}>Paid</option>
                                                            <option value={'Unpaid'}>Unpaid</option>
                                                            <option value={'Completed'}>Completed</option>
                                                            <option value={'For_All'}>For All</option>
                                                        </Input>
                                                    </>,
                                                    // tooltip: 'Select Category',
                                                    isFreeAction: true,
                                                },
                                                {
                                                    icon: 'delete',
                                                    tooltip: 'Delete Lesson ',
                                                    onClick: (event, rowData) => {
                                                        // Do save operation
                                                        // Services.deleteCourse(rowData.id).then((response) =>{
                                                        //     this.getCourseList()
                                                        // })
                                                        this.setState({ confirmModal: true, selectedRow: rowData })
                                                    }
                                                },
                                                {
                                                    icon: 'add',
                                                    tooltip: 'Add article',
                                                    isFreeAction: true,
                                                    onClick: (event) => this.toggleState('passwordModal')
                                                },
                                                {
                                                    icon: () => <button
                                                        className='toggleViewButton'>{this.state.articleViewType ? 'New' : 'Old'}</button>,
                                                    tooltip: 'For Viewing Article Details',
                                                    isFreeAction: true,
                                                    onClick: (event) => {
                                                        this.setState({ articleViewType: !this.state.articleViewType })
                                                    }
                                                },
                                                {
                                                    icon: 'info',
                                                    tooltip: 'Details',
                                                    isFreeAction: false,
                                                    onClick: (event, rowData) => {
                                                        this.setState({
                                                            editorState: this.handleInitialEditorText(rowData.descriptionDetails),
                                                            image: rowData.image,
                                                            title: rowData.title,
                                                            articleId: rowData.id,
                                                            description: rowData.description,
                                                            category:rowData.category,
                                                        })
                                                        this.toggleState('articleEditOpen')
                                                    }
                                                }
                                            ]}
                                            options={{
                                                //   exportButton: true,
                                                // exportAllData: true,
                                                actionsColumnIndex: -1,
                                                pageSize: 10,
                                                pageSizeOptions: [10, 20, 50],
                                                headerStyle: {
                                                    backgroundColor: '#6772E5',
                                                    color: '#FFF',
                                                    searchFieldAlignment: 'left'
                                                }

                                            }}
                                            detailPanel={rowData => {
                                                return (
                                                    <p style={{
                                                        marginTop: 10,
                                                        marginLeft: 10
                                                    }}>
                                                        {ReactHtmlParser(rowData.descriptionDetails)}
                                                    </p>
                                                )
                                            }}
                                            editable={{
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise((resolve, reject) => {
                                                        setTimeout(() => {
                                                            {
                                                                Services.updateArticle(oldData.id, { title: newData.title }).then((response) => {
                                                                    this.getArticles()
                                                                    this.props.enqueueSnackbar('Article Updated Successfully', {
                                                                        variant: 'success',
                                                                        anchorOrigin: {
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right'
                                                                        }
                                                                    })
                                                                })
                                                            }
                                                            resolve()
                                                        }, 300)
                                                    })
                                            }}
                                        />
                                    :
                                    <>
                                        <div class='d-flex mt-3'>
                                            <div class='mr-auto ml-4 mt-2'><h2>Articles</h2></div>
                                            <div class='p-2'>
                                                <Button className='btn-icon btn-3' color='primary' type='button'
                                                        onClick={() => this.toggleState('addFormView')}>
                                        <span className='btn-inner--icon'>
                                            <i className='ni ni-bag-17' />
                                        </span>
                                                    <span className='btn-inner--text'>Back</span>
                                                </Button>
                                            </div>
                                        </div>

                                        <hr class='solid'></hr>
                                        <ArticlesForm onSuccess={(async) => {
                                            this.setState({ addFormView: false })
                                            this.getArticles()
                                        }}></ArticlesForm>
                                    </>
                                }
                            </Card>
                        </div>
                    </Row>
                </Container>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.confirmModal}
                    toggle={() => this.toggleState('confirmModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Are you sure you want to delete?</h4>
                        <Form>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>

                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                                onClick={
                                    () => {
                                        Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                            let selectedRowId = this.state.selectedRow.id
                                            Services.deleteArticle(selectedRowId).then((response) => {
                                                this.getArticles()
                                                this.toggleState('confirmModal')
                                                this.props.enqueueSnackbar('Successfully deleted', {
                                                        variant: 'success',
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'right'
                                                        }
                                                    }
                                                )
                                            })
                                                .catch((error) => {
                                                    this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                            variant: 'warning',
                                                            anchorOrigin: {
                                                                vertical: 'bottom',
                                                                horizontal: 'right'
                                                            }
                                                        }
                                                    )
                                                })


                                        }).catch((error) => {
                                            this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                                    variant: 'warning',
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }
                                                }
                                            )
                                        })


                                    }
                                }>
                            Yes
                        </Button>
                    </div>
                </Modal>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.passwordModal}
                    toggle={() => this.toggleState('passwordModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Enter password to perform this action.</h4>
                        <Form onSubmit={
                            (e) => {
                                e.preventDefault()
                                Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                    this.handleCreateArtcleOpen()
                                }).catch((error) => {
                                    this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                    )
                                })


                            }
                        }>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                                onClick={
                                    () => {
                                        Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                            this.handleCreateArtcleOpen()
                                        }).catch((error) => {
                                            this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                                    variant: 'warning',
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }
                                                }
                                            )
                                        })


                                    }
                                }>
                            Enter
                        </Button>
                    </div>
                </Modal>
                <Modal
                    size='lg'
                    className='modal-dialog-centered lessonUpdate'
                    isOpen={this.state.articleEditOpen}
                    toggle={() => this.toggleState('articleEditOpen')}
                    style={{ maxWidth: '1000px', width: '100%' }}>

                    <div className='modal-header mb-3'>
                        <h3 className='modal-title' id='exampleModalLabel'>
                            Update Article
                        </h3>
                        <Button color='danger' type='button'
                            onClick={() => this.toggleState('articleEditOpen')}
                        >
                            Close
                        </Button>
                    </div>



                    <Form role='form' style={{
                        paddingLeft: 20,
                        paddingRight: 20
                    }}>
                        <FormGroup style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <InputGroup className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <i className='ni ni-hat-3' />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Title' type='text' name='title' value={this.state.title}
                                       onChange={this.handleInputChange} />
                            </InputGroup>
                            <InputGroup style={{ width: '40%' }} className='ml-3 input-group-alternative mb-3'>
                                <Input className={'ml-2'} type={'select'} name={'category'}
                                       value={this.state.category} onChange={this.handleInputChange}>
                                    <option>Select Category</option>
                                    <option value={'Paid'}>Paid</option>
                                    <option value={'Unpaid'}>Unpaid</option>
                                    <option value={'Completed'}>Completed</option>
                                    <option value={'For_All'}>For All</option>
                                </Input>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                <Label>Short Description (old)</Label>
                                {this.state.description.length>0&& <span style={{fontSize:22}} className={'font-weight-bold'}>{this.state.description.length}</span>}
                            </div>
                            <InputGroup className='input-group-alternative mb-3'>
                                <Input rows='4' placeholder='Description' type='textarea' row={1} name='description'
                                       value={this.state.description} onChange={this.handleInputChange} />
                            </InputGroup>
                        </FormGroup>
                        <b>Default Image:</b>
                        <div className='articleCurrentImageContainer'>
                            <img style={{ width: '400px', height: '200px' }} src={this.state.image}
                                 alt='Article Main Pic' />
                        </div>
                        <Dropzone
                            getUploadParams={this.getUploadParams}
                            onChangeStatus={this.handleChangeStatus}
                            multiple={false}
                            maxFiles={1}
                            // onSubmit={this.handleSubmit}
                            accept='image/*'
                        />
                        <br />
                        <FormGroup>
                            <Editor
                                toolbarCustomButtons={[<ShowEditorCode />]}
                                editorStyle={{
                                    border: '1px solid black',
                                    padding: '5px',
                                    borderRadius: '2px',
                                    height: 'auto',
                                    minHeight:'200px',
                                    width: '100%',
                                    fontSize: '18px',
                                }}
                                placeholder='Description / Notes  [NEW Layout]'
                                editorState={this.state.editorState}
                                wrapperClassName='demo-wrapper'
                                editorClassName='demo-editor'
                                onEditorStateChange={this.onEditorStateChange.bind(this)}
                                toolbar={{
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                    link: { inDropdown: true },
                                    history: { inDropdown: true },
                                    image: {
                                        uploadCallback: this.uploadImageCallBack,
                                        previewImage: true,
                                        defaultSize: { width: '100%', height: 'auto' }
                                    }
                                }}
                            />
                            {this.state.showEditorCode && (
                                <div {...css({ width: '100%' })}>
            <textarea
                rows={10}
                {...css({
                    width: '100%',
                    padding: '0'
                })}
                value={this.state.editorContentHtml}
                onChange={this.onEditEditorHTML}
            />
                                    <div>
                                        <Button
                                            type='button'
                                            onClick={this.addHtmlToEditor}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </FormGroup>
                        <div className='pb-4'>
                            <Button className='mt-4' color='danger' type='button'
                                    onClick={() => this.toggleState('articleEditOpen')}>
                                Cancel
                            </Button>
                            <Button className='mt-4' color='primary' type='button' onClick={this.handleUpdateArticle}>
                                Update
                            </Button>
                        </div>
                    </Form>

                </Modal>
            </>
        )
    }
}

export default withSnackbar(Courses)
