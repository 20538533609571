import React from 'react'
import { withSnackbar } from 'notistack'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import DeleteIcon from '@material-ui/icons/Delete'
import AlarmIcon from '@material-ui/icons/Create'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Modal, Label
} from 'reactstrap'
import MaterialTable, { MTableToolbar } from 'material-table'
import { convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Editor } from 'react-draft-wysiwyg'

const Services = require('../../../RemoteServices/RemoteServices.js')


class SendNotifications extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: true,
            amount: '',
            exampleModal: true,
            title: '',
            description: '',
            shortDescription: '',
            smsMarketing: false,
            emailMarketing: false,
            notificationMarketing: true,
            editorState: EditorState.createEmpty(),
            editorContentHtml: ''

        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.chooseFilters = this.chooseFilters.bind(this)
    }

    onEditorStateChange = (editorState) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        this.setState({
            editorState,
            editorContentHtml: draftToHtml(rawContentState)
        })
    }


    uploadImageCallBack = (file) => {
        let token = localStorage.getItem('token')

        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest()
                const serverUrl = window.config.apiUrl + 'common/image-upload'
                xhr.open('POST', serverUrl)
                xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                const data = new FormData()
                data.append('image', file)
                xhr.send(data)
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText)
                    resolve(response)
                })
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText)
                    reject(error)
                })
            }
        )
    }


    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    handleSubmit() {
        const {
            isPaid,
            hasRequested,
            isYoutuber,
            isReferred,
            onlyStudents,
            smsMarketing,
            emailMarketing,
            notificationMarketing
        } = this.state
        let leadsData = {
            title: this.state.title,
            message: this.state.notificationMarketing ? this.state.editorContentHtml : this.state.description,
            short_description: this.state.shortDescription,
            delete_after: null
        }
        let str = '?ids=' + this.props.studentData.id

        if (this.state.smsMarketing) {
            Services.createSmsCampaign(leadsData, str).then((response) => {
                this.props.enqueueSnackbar('Sms Marketing kept in queue', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })

                this.props.onSuccess()
            })
                .catch((error) => {
                    this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                    )
                })
        }

        if (this.state.emailMarketing) {
            Services.createEmailCampaign(leadsData, str).then((response) => {
                this.props.enqueueSnackbar('Email Marketing kept in queue', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
                this.props.onSuccess()
            })
                .catch((error) => {
                    this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                    )
                })
        }

        if (this.state.notificationMarketing) {
            Services.createNotificationCampaign(leadsData, str).then((response) => {
                this.props.enqueueSnackbar('Notifications Marketing kept in queue', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
                this.props.onSuccess()
            })
                .catch((error) => {
                    this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                    )
                })
        }

    }

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    chooseFilters = async (event) => {
        if (event === 'smsMarketing') {
            this.setState({
                notificationMarketing: false,
                emailMarketing: false,
                smsMarketing: true
            })
        } else if (event === 'notificationMarketing') {
            this.setState({
                smsMarketing: false,
                emailMarketing: false,
                notificationMarketing: true
            })
        } else {
            this.setState({
                emailMarketing: true,
                notificationMarketing: false,
                smsMarketing: false
            })
        }
    }


    render() {
        let student = this.props.studentData
        return (
            <>
                <div>
                    <Dialog PaperProps={{ style: { width: '800px', minHeight: '600px', height: 'auto' } }}
                        open={this.state.exampleModal} onClose={() => this.toggleModal('updatePasswordView')}>
                        <DialogTitle id='form-dialog-title'>Send SMS / Notification / E-Mail</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please enter all the details to send.
                            </DialogContentText>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '20px',
                                    height: '80px',
                                    marginBottom: '10px'
                                }}>
                                <div style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '33.33%',
                                    height: '100%',
                                    borderRadius: 5,
                                    backgroundColor: 'white',
                                    boxShadow: '2px 2px 2px 0px lightgrey',
                                    display: 'flex',
                                    border: this.state.notificationMarketing ? '1px solid blue' : '1px solid lightgrey',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => this.chooseFilters('notificationMarketing')}
                                >
                                    <span style={{ fontSize: 14, color: 'grey' }}
                                        className={'font-weight-bold'}>Notification</span>
                                </div>
                                <div style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '33.33%',
                                    height: '100%',
                                    borderRadius: 5,
                                    backgroundColor: 'white',
                                    boxShadow: '2px 2px 2px 0px lightgrey',
                                    display: 'flex',
                                    border: this.state.emailMarketing ? '1px solid blue' : '1px solid lightgrey',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => this.chooseFilters('emailMarketing')}
                                >
                                    <span style={{ fontSize: 18, color: 'grey' }}
                                        className={'font-weight-bold'}>Email</span>
                                </div>
                                <div style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '33.33%',
                                    height: '100%',
                                    borderRadius: 5,
                                    backgroundColor: 'white',
                                    boxShadow: '2px 2px 2px 0px lightgrey',
                                    display: 'flex',
                                    border: this.state.smsMarketing ? '1px solid blue' : '1px solid lightgrey',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => this.chooseFilters('smsMarketing')}
                                >
                                    <span style={{ fontSize: 18, color: 'grey' }}
                                        className={'font-weight-bold'}>SMS</span>
                                </div>
                            </div>

                            {!this.state.smsMarketing ?
                                <FormGroup>
                                    <InputGroup className='input-group-alternative mb-3'>
                                        <InputGroupAddon addonType='prepend'>
                                            <InputGroupText>
                                                <i className='ni ni-hat-3' />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input placeholder='Title' type='text' name='title' value={this.state.title}
                                            onChange={this.handleInputChange} />
                                    </InputGroup>
                                </FormGroup>
                                :
                                ''
                            }
                            {this.state.notificationMarketing ?
                                <FormGroup>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Label>Short Description</Label>
                                        {this.state.shortDescription.length > 0 && <span style={{ fontSize: 22 }} className={'font-weight-bold'}>{this.state.shortDescription.length}</span>}
                                    </div>
                                    <InputGroup className='input-group-alternative mb-3'>
                                        <Input rows={4} placeholder='Short Description' type='textarea' name='shortDescription' value={this.state.shortDescription}
                                            onChange={this.handleInputChange} />
                                    </InputGroup>
                                </FormGroup>
                                :
                                ''
                            }

                            <FormGroup>
                                {!this.state.notificationMarketing ?
                                    <InputGroup className='input-group-alternative'>
                                        <Input
                                            id='exampleFormControlTextarea1'
                                            placeholder='Description / Message'
                                            rows='3'
                                            type='textarea'
                                            name='description'
                                            value={this.state.description}
                                            onChange={this.handleInputChange}
                                        />
                                    </InputGroup>
                                    :
                                    <Editor
                                        editorStyle={{
                                            border: '1px solid black',
                                            padding: '5px',
                                            borderRadius: '2px',
                                            height: '300px',
                                            width: '100%'
                                        }}
                                        placeholder='Description'
                                        editorState={this.state.editorState}
                                        wrapperClassName='demo-wrapper'
                                        editorClassName='demo-editor'
                                        onEditorStateChange={this.onEditorStateChange.bind(this)}
                                        toolbar={{
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            history: { inDropdown: true },
                                            image: {
                                                uploadCallback: this.uploadImageCallBack,
                                                previewImage: true,
                                                defaultSize: { width: '100%', height: 'auto' }
                                            }
                                        }}
                                    />
                                }
                            </FormGroup>
                            <br />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.props.closeNotificationModal()} color='primary'>
                                Cancel
                            </Button>
                            <Button color='primary' onClick={this.handleSubmit}>
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </>
        )
    }
}

export default withSnackbar(SendNotifications)