import { AppBar, Dialog, IconButton, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { Button, Modal } from 'reactstrap';
import CloseIcon from '@material-ui/icons/Close'


const AssignmentDescription = ({ showRemarks, toggleState, arrayObject }) => {
    console.log('asdfasdf', arrayObject)
    return (
        <>
            <Dialog maxWidth='lg' PaperProps={{ style: { width: '900px', minHeight: '400px', height: 'auto' } }}
                open={showRemarks} onClose={() => toggleState('showRemarks')}>
                <AppBar color='info' style={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography variant='h6' style={{
                            marginLeft: 20,
                            flex: 1
                        }}>
                            <h2>Remarks</h2>
                        </Typography>
                        <IconButton edge='start' color='inherit' onClick={() => {
                            toggleState('showRemarks')
                        }} aria-label='close'>
                            <CloseIcon />
                        </IconButton>

                    </Toolbar>
                </AppBar>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '10px' }}>
                    <div className={'mb-3'} style={{
                        textAlign: arrayObject.remarks,
                    }}
                        dangerouslySetInnerHTML={{
                            __html: arrayObject.remarks,
                        }}
                    >
                    </div>
                    {arrayObject.assignment_remarks && arrayObject.assignment_remarks.sort((a, b) => a.order - b.order).map((item, index) => {
                        return (
                            <div key={index}>
                                {item.remark_text && (
                                    <div className={'mb-3'} style={{
                                        textAlign: item.orientation,
                                    }}
                                        dangerouslySetInnerHTML={{
                                            __html: item.remark_text,
                                        }}
                                    >
                                    </div>
                                )}

                                {item.button_link &&
                                    <>
                                        <div className={'button-link mb-3 mt-3'} style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: item.orientation,
                                            width: '100%'
                                        }}>
                                            <Button color={'primary'} href={item.button_link}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                {item.button_name}
                                            </Button>
                                        </div>
                                    </>
                                }

                                {item.video_link &&
                                    <>
                                        <div className={'button-link mb-3 mt-3'} style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: item.orientation,
                                            width: '100%'
                                        }}>

                                            <Button color={'primary'} href={item.video_link}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                Video Link
                                            </Button>
                                        </div>
                                    </>
                                }

                                {/* {item.video_link &&
                                    <>
                                        {item.thumbnail_image !== null || item ?
                                            <>
                                                <div className="mt-3 mb-3">

                                                    <ReactPlayer
                                                        url={item.video_link}
                                                        playIcon={<div></div>}
                                                        light={
                                                            <div style={{
                                                                position: 'relative',
                                                                width: '100%',
                                                                height: '100%'
                                                            }}>
                                                                <img
                                                                    alt={'thumbnail'}
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        objectFit: 'stretch'
                                                                    }}
                                                                    src={item.thumbnail_image}
                                                                />
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)'
                                                                }}>
                                                                    <div style={{
                                                                        border: '4px solid purple',
                                                                        borderRadius: '50%',
                                                                        width: '60px',
                                                                        height: '60px',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        color: 'purple'
                                                                    }}>
                                                                        <i style={{ fontSize: 20 }}
                                                                            className="fa fa-play"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        className="react-player"
                                                        width={'100%'}
                                                        height={'498px'}
                                                        playing={true}
                                                        controls={true}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="mt-3 mb-3">
                                                    <ReactPlayer
                                                        url={item.video_link}
                                                        className="react-player"
                                                        height='478px'
                                                        width='100%'
                                                        // playing={!shouldPause}
                                                        controls={true}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </>
                                } */}
                                {item.table_data && (
                                    <>
                                        <div className={'mt-2 mb-2'}
                                            style={{ width: '100%', overflowX: "auto" }}>
                                            <table style={{ width: '100%' }}>
                                                {item.table_data.map((row, i) => (
                                                    <React.Fragment key={i}>
                                                        {i === 0 ? (
                                                            <thead>
                                                                <tr>
                                                                    {row.map((col, j) => (
                                                                        <th style={{
                                                                            border: '2px solid #4633af',
                                                                            borderRadius: '10px'
                                                                        }} key={j}>
                                                                            {col ? col : ''}
                                                                        </th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                        ) : (
                                                            <tbody>
                                                                <tr>
                                                                    {row.map((col, j) => (
                                                                        <td style={{
                                                                            border: '2px solid #4633af',
                                                                            borderRadius: '10px',
                                                                            textAlign: 'left', // Align content to the left

                                                                        }} key={j}>{col ? col : ''}</td>
                                                                    ))}
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </table>
                                        </div>
                                    </>
                                )}
                                {item.image &&
                                    <>
                                        <div className={'mt-3 mb-3'}>
                                            <img className={'m-0 p-0'}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain'
                                                }}
                                                src={item.image}
                                                alt={''} />
                                        </div>
                                    </>
                                }
                            </div>
                        )
                    })}
                </div>
            </Dialog>

        </>

    )
}
export default AssignmentDescription;