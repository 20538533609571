import React from 'react'
import Header from 'components/Headers/Header.js'
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Button,
    Modal,
    UncontrolledTooltip, Label
} from 'reactstrap'
import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import ArticlesForm from 'components/Forms/ArticlesForm.js'
import ScrollToTop from 'react-scroll-to-top'
import { TagsInput } from 'react-tag-input-component'
import arrowDown from '../../assets/img/arrowdown.png'
import { Editor } from 'react-draft-wysiwyg'
import LinkQuizTabs from '../../components/Forms/LinkQuiz/LinkQuizTabs'
import { css } from 'glamor'
import LessonRemarksTabs from '../../components/Forms/LessonRemarksTabs'

const Services = require('../../RemoteServices/RemoteServices.js')

class Settings extends React.Component {
    state = {
        addFormView: false,
        data: [],
        confirmModal: false,
        selectedRow: {},
        loading: true,
        editFormView: false,
        title: '',
        lesson_type: '',
        youtube_link: '',
        location: '',
        tag: '',
        thumbnail: '',
        imagePreview: '',
        id: '',
        category:''

    }

    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    updateEditState = async (data) => {
        await this.setState((prevState) => ({
            ...prevState,
            id: data.id,
            title: data.title,
            youtube_link: data.youtube_link,
            tag: data.tag,
            location: data.location,
            lesson_type: data.lesson_type,
            thumbnail: data.thumbnail
        }))
    }

    handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }


    updateConfig = async () => {
        let newData = {
            lesson_type: this.state.lesson_type,
            title: this.state.title,
            location: this.state.location,
            tag: this.state.tag,
            youtube_link: this.state.youtube_link,
            thumbnail: this.state.thumbnail
        }
        await Services.updateDefaultLink(this.state.id, newData).then((response) => {
            this.toggleState('editFormView')
            this.props.enqueueSnackbar('Config Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            })
    }

    getExtension = (filename) => {
        var parts = filename.split('.')
        return parts[parts.length - 1]
    }

    isImage = (filename) => {
        var ext = this.getExtension(filename)
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'gif':
            case 'bmp':
            case 'png':
            case 'jpeg':
                //etc
                return true
        }
        return false
    }


    handleImageSelect = (e) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        console.log('asdf', response)
                        this.setState(prevState => ({
                            ...prevState,
                            thumbnail: response.data.link
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }
    handleImageRemove = async (e) => {
        await this.setState(prevState => ({
            ...prevState,
            thumbnail: ''
        }))
    }


    async componentDidMount() {
        await this.getDefaultLinks()
    }

    async getDefaultLinks() {
        this.setState({ loading: true })
        await Services.getDefaultLinks().then((response) => {
            console.log(response)
            this.setState({ data: response, loading: false })
        })
    }

    render() {
        return (
            <>
                <Header onlineCount={this.props.onlineCount} onlineUsers={this.props.onlineUsers} />
                <Container className='mt--7' fluid>
                    <Row>
                        <div className=' col'>
                            <Card className=' shadow'>
                                {!(this.state.addFormView) ?
                                    this.state.loading ?
                                        <CircularProgress style={{
                                            margin: 50,
                                            alignSelf: 'center'
                                        }} />
                                        :
                                        <MaterialTable
                                            columns={[
                                                {
                                                    title: 'S.N',
                                                    field: 'id',
                                                    render: rowData => <>{rowData.tableData.id + 1}</>,
                                                    editable: false
                                                },
                                                { title: 'Title', field: 'title' },
                                                // { title: 'Type', field: 'lesson_type', editable: false },
                                                { title: 'Video', field: 'youtube_link' },
                                                { title: 'Location', field: 'location', editable: false },
                                                { title: 'Tag', field: 'tag', editable: false }
                                                // { title: 'Description', field: 'description' },
                                            ]}
                                            data={this.state.data}
                                            title='Configuration'
                                            actions={[
                                                {
                                                    icon: 'delete',
                                                    tooltip: 'Delete Lesson ',
                                                    onClick: (event, rowData) => {
                                                        // Do save operation
                                                        // Services.deleteCourse(rowData.id).then((response) =>{
                                                        //     this.getCourseList()
                                                        // })
                                                        this.setState({ confirmModal: true, selectedRow: rowData })
                                                    }
                                                },
                                                {
                                                    icon: 'info',
                                                    tooltip: 'Details',
                                                    isFreeAction: false,
                                                    onClick: (event, rowData) => {
                                                        this.updateEditState(rowData)
                                                        this.toggleState('editFormView')
                                                    }
                                                }
                                                // {
                                                //     icon: 'add',
                                                //     tooltip: 'Add Lesson',
                                                //     isFreeAction: true,
                                                //     onClick: (event) => this.toggleState('addFormView')
                                                // }
                                            ]}
                                            options={{
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    backgroundColor: '#6772E5',
                                                    color: '#FFF'
                                                }
                                            }}
                                            editable={{
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise((resolve, reject) => {
                                                        setTimeout(() => {
                                                            {
                                                                Services.updateDefaultLink(oldData.id, newData).then((response) => {
                                                                    this.getDefaultLinks()
                                                                    this.props.enqueueSnackbar('User Updated Successfully', {
                                                                        variant: 'success',
                                                                        anchorOrigin: {
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right'
                                                                        }
                                                                    })
                                                                })
                                                                    .catch((error) => {
                                                                        this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                                                variant: 'warning',
                                                                                anchorOrigin: {
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'right'
                                                                                }
                                                                            }
                                                                        )
                                                                    })
                                                            }
                                                            resolve()
                                                        }, 300)
                                                    })
                                            }}
                                        />
                                    :
                                    <>
                                    </>
                                }

                            </Card>
                        </div>
                    </Row>
                </Container>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.confirmModal}
                    toggle={() => this.toggleState('confirmModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Are you sure you want to delete?</h4>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                                onClick={
                                    () => {
                                        let selectedRowId = this.state.selectedRow.id
                                        Services.deleteDefaultLink(selectedRowId).then((response) => {
                                            this.getDefaultLinks()
                                            this.toggleState('confirmModal')
                                            this.props.enqueueSnackbar('Successfully deleted', {
                                                    variant: 'success',
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }
                                                }
                                            )
                                        })
                                            .catch((error) => {
                                                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                        variant: 'warning',
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'right'
                                                        }
                                                    }
                                                )
                                            })

                                    }
                                }>
                            Yes
                        </Button>
                    </div>
                </Modal>

                <Modal
                    size='lg'
                    className='modal-dialog-centered'
                    isOpen={this.state.editFormView}
                    toggle={() => this.toggleState('editFormView')}
                    style={{ maxWidth: '1000px', width: '100%' }}>
                    <div className='modal-header' style={{borderBottom:'1px solid lightgrey'}}>
                        <h4 className='modal-title ml-4' id='exampleModalLabel'>
                            Config
                        </h4>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('editFormView')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body' style={{borderBottom:'1px solid lightgrey'}}>
                        <Form role='form' style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            maxWidth: '1000px',
                            width: '100%'
                        }}>

                            <FormGroup>
                                <Label className={'font-weight-bold'}>Title</Label>
                                <InputGroup style={{ width: '60%', marginRight: '10px' }}
                                            className='input-group-alternative mb-3'>
                                    <Input placeholder='Title' type='text' name='title' value={this.state.title}
                                           onChange={this.handleInputChange} />
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <Label className={'font-weight-bold'}>Youtube Link</Label>
                                <InputGroup style={{ width: '60%', marginRight: '10px' }}
                                            className='input-group-alternative mb-3'>
                                    <Input disabled placeholder='Video Link' type='text' name='youtube_link'
                                           value={this.state.youtube_link}
                                           onChange={this.handleInputChange} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label className={'font-weight-bold'}>Location</Label>
                                <InputGroup style={{ width: '60%' }} className='input-group-alternative mb-3'>
                                    <Input disabled placeholder='Location' type='text' name='location'
                                           value={this.state.location} onChange={this.handleInputChange} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className={'m-0 p-0'}>
                                <Label className={'font-weight-bold'}>Tag</Label>
                                <InputGroup style={{ width: '60%' }} className='input-group-alternative mb-3'>
                                    <Input disabled placeholder='Tag' type='text' name='tag' value={this.state.tag}
                                           onChange={this.handleInputChange} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className={'mb-0'}>
                                <Label><span
                                    className={'font-weight-bold'}> Upload Thumbnail Image</span>
                                </Label>
                                <br />
                                <input className='thumbnail_upload_button' accept='png/jpeg'
                                       type='file'
                                       onChange={(e) => this.handleImageSelect(e)} />
                                <br />
                                {this.state.thumbnail &&
                                    <div className='thumbnailContainerAddLesson'><i
                                        onClick={(e) => this.handleImageRemove(e)}
                                        class='far fa-times-circle'></i> <img
                                        className='lessonFormThumbnailImage'
                                        src={this.state.thumbnail} />
                                    </div>}
                                <br />
                            </FormGroup>

                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('editFormView')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button' onClick={()=>this.updateConfig()}>
                            Update
                        </Button>
                    </div>

                </Modal>
            </>
        )
    }
}

export default withSnackbar(Settings)
