import React, { useState, useEffect } from 'react'
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { stateToHTML } from 'draft-js-export-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './LessonRemarks.css'
import '../../assets/css/ckeditorStyles.css'
import htmlToDraft from 'html-to-draftjs'
import { Tabs } from 'antd'
import draftToHtml from 'draftjs-to-html'

const LessonRemarksForm = (props) => {


    const returnHtmlBlock = (text) => {
        const processedHTML = htmlToDraft(text)
        return ContentState.createFromBlockArray(processedHTML)

    }

    const handleInitialEditorText = (text) => {
        let editorState
        if (text.trim() !== '') {
            editorState = EditorState.createWithContent(returnHtmlBlock(text))
            editorState = EditorState.moveFocusToEnd(editorState)
        } else {
            editorState = EditorState.createEmpty()
        }
        // this.setState({editorContentHtml:stateToHTML(editorState.getCurrentContent())})
        return editorState


    }

    let { changeOptionText, text, number } = props

    let [editorState, setEditorState] = useState(handleInitialEditorText(text))
    let [editorContentHtml, setEditorContentHtml] = useState('')


    const onEditorStateChange = (editorState) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        setEditorState(editorState)
        setEditorContentHtml(draftToHtml(rawContentState))
    }

    useEffect(() => {
        changeOptionText(number, editorContentHtml)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorContentHtml])

    const uploadImageCallBack = (file) => {
        let token = localStorage.getItem('token')

        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest()
                const serverUrl = window.config.apiUrl + 'common/image-upload'
                xhr.open('POST', serverUrl)
                xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                const data = new FormData()
                data.append('image', file)
                xhr.send(data)
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText)
                    resolve(response)
                })
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText)
                    reject(error)
                })
            }
        )
    }


    return (
        <div className='ck-content'>
            <Editor
                editorStyle={{
                    border: '1px solid black',
                    padding: '5px',
                    borderRadius: '2px',
                    height: '400px',
                    width: '100%',
                    fontSize: '18px'
                }}
                placeholder='Write lesson remarks..'
                editorState={editorState}
                wrapperClassName='demo-wrapper'
                editorClassName='demo-editor'
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    image: {
                        uploadCallback: uploadImageCallBack,
                        previewImage: true,
                        defaultSize: { width: '100%', height: 'auto' }
                    }
                }}
            />

        </div>

    )
}

export default LessonRemarksForm