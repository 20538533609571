import React, { useState, useEffect } from 'react'
import Header from 'components/Headers/Header.js'
import { Container, Button, Spinner, Modal } from 'reactstrap'
import AnnouncementField from 'components/CommunityFeed/AnnouncementField'
import CommunityPost from 'components/CommunityFeed/CommunityPost'
import './CommunityFeed.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import UserProfilePage from 'components/Student/UserProfilePage'

const Services = require('../../RemoteServices/RemoteServices.js')


const CommunityFeed = (props) => {

    let [communityPosts, setCommunityPosts] = useState([])
    let [postsLoading, setPostsLoading] = useState(true)
    let [postsLimit, setPostsLimit] = useState(10)
    let [communityPostsData, setCommunityPostsData] = useState({})
    let [selectedUserId, setSelectedUserId] = useState('')
    let [showUserDetails, setShowUserDetails] = useState(false)
    let [userType, setUserType] = useState('all')
    const increasePostsLimit = () => {
        setPostsLimit(postsLimit += 10)
    }

    let user = {
        id: selectedUserId
    }

    const getCommunityPosts = () => {
        Services.getCommunityPost().then((response) => {
            setCommunityPosts(response.results)
            setPostsLoading(false)
        })
    }

    const topFunction = () => {
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    }

    useEffect(() => {
        Services.getCommunityPost(postsLimit).then((response) => {
            setCommunityPosts(response.results)
            setCommunityPostsData(response)
            setPostsLoading(false)
        })
    }, [postsLimit])


    const handleDisplayDetails = async (userId) => {
        await setSelectedUserId(userId)
        setShowUserDetails(!showUserDetails)

    }

    const toggleUserModal = () => {
        setShowUserDetails(!showUserDetails)
    }

    const handlePostsToFetchChange = (e) => {
        let { name } = e.target
        setUserType(name)

    }

    const fetchPosts = () => {
        Services.getCommunityPost(postsLimit).then((response) => {
            setCommunityPosts(response.results)
            setCommunityPostsData(response)
            setPostsLoading(false)
        })

    }


    return (
        <>
            <Header page='lesson' />
            <Container className='mt--7'>
                <div className='communityFeedContainer'>
                    <div className='feedFilterContainer'>
                        <div className='filterButtonsDiv'>
                            <button className={`feedFilterButton ${userType === 'all' ? 'active' : ''}`}
                                    onClick={(e) => handlePostsToFetchChange(e)} name='all'>All
                            </button>
                            <button className={`feedFilterButton ${userType === 'paid' ? 'active' : ''}`}
                                    onClick={(e) => handlePostsToFetchChange(e)} name='paid'>Paid
                            </button>
                            <button className={`feedFilterButton ${userType === 'unpaid' ? 'active' : ''}`}
                                    onClick={(e) => handlePostsToFetchChange(e)} name='unpaid'>Unpaid
                            </button>
                        </div>
                        <div className='filterSearchDiv'>
                            <input type='text' placeholder='Search by name'></input>
                        </div>

                    </div>
                    <AnnouncementField getCommunityPosts={getCommunityPosts} />
                    <h3>Feeds ({communityPostsData.count||communityPosts.length})</h3>
                    {postsLoading ?
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}><Spinner />
                        </div> :
                        <div className='communityPostsContainerMain'>
                            <InfiniteScroll
                                style={{ overflow: 'none' }}
                                dataLength={communityPosts.length}
                                hasMore={communityPostsData.count > communityPosts.length}
                                next={increasePostsLimit}
                                loader={<div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                                    <Spinner /></div>}
                                endMessage={<p style={{ textAlign: 'center' }}>
                                    <b>Yay! You have seen it all</b>
                                </p>}
                            >

                                {communityPosts.map((item, index) => {
                                    return (
                                        <CommunityPost showDetails={handleDisplayDetails}
                                                       getCommunityPosts={getCommunityPosts} data={item} key={index} />
                                    )
                                })}
                            </InfiniteScroll>
                        </div>
                    }
                </div>
                <button className='scrollButton' title='Go to top' onClick={topFunction}><i class='fas fa-arrow-up'></i>
                </button>
                {/* <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}> {communityPosts.length !== communityPostsData.count ? <Button type="button" color="primary" onClick={increasePostsLimit}>Load More</Button> : <Button type="button" color="primary" onClick={topFunction}>Scroll To Top</Button>}  </div> */}
            </Container>
            <Modal
                className='modal-dialog-centered'
                isOpen={showUserDetails}
                toggle={toggleUserModal}
                size='lg'
                style={{ minWidth: '1200px' }}
            >
                <div className='modal-header'>


                    <Button
                        size='sm'
                        className='ml-auto'
                        color='primary'
                        data-dismiss='modal'
                        type='button'
                        onClick={toggleUserModal}
                    >
                        Close
                    </Button>
                </div>
                <UserProfilePage userId={selectedUserId} />
            </Modal>
        </>
    )
}


export default CommunityFeed