import React , {useState, useEffect} from 'react'
import { Tabs } from 'antd';

import LessonRemarksForm from './LessonRemarksForm';
const { TabPane } = Tabs;

const LessonRemarksTabs = (props) => {

    const {lessonRemarks} = props


    const initialPanes = [
        {
          title: 'Option 1',
          key: 1,
          number:1,
          text:'',
        }  
      ];

      const [states, setStates] = useState({
        activeKey:lessonRemarks && lessonRemarks.length > 0 ? lessonRemarks[0].key : initialPanes[0].key,
        panes: lessonRemarks && lessonRemarks.length > 0 ? lessonRemarks: initialPanes,
      });

      const changeOptionText =  (number,text) => {
        // let index = states.panes.findIndex(i => i.number === number);
        setStates(prevState => ({...prevState,panes:prevState.panes.map((val) => {
          if(val.number === number)
          {return {...val,text} }
        else return val
        }
          )}))
          
  
      }

      useEffect(() => {
        props.handleRemarksOptionChange(states.panes)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [states])

      // Math.floor(Math.random() * 10).toString();

      const add = () => {
        const { panes } = states;
        const {number} = panes[states.panes.length -1]
        const activePane = number + 1
        const newPanes = [...panes];
        newPanes.push({
          title: `Option ${activePane}`,
          key: activePane,
          number: activePane,
          text:''
        });
        props.handleRemarksOptionChange(newPanes)
        setStates({
          panes: newPanes,
          activeKey: activePane,
        });
       
      };

      const onChange = (key) => {
        setStates({ ...states, activeKey: key });
      };

      const remove = (targetKey) => {
        if(states.panes.length === 1) return;
        const { panes, activeKey } = states;
        let newActiveKey = activeKey;
        let lastIndex;
        panes.forEach((pane, i) => {
          if (pane.key === Number(targetKey)) {
            lastIndex = i - 1;
          }
        });
        const newPanes = panes.filter(
          (pane) => pane.key !== Number(targetKey),
        ).map(pane =>{ if(pane.key > targetKey){ 
          return {...pane, title: `Option ${pane.key-1}`, key: pane.key-1,number:pane.number-1}} 
        else return pane})
        
        if (newPanes.length && newActiveKey === targetKey) {
          if (lastIndex >= 0) {
            newActiveKey = newPanes[lastIndex].key;
          } else {
            newActiveKey = newPanes[0].key;
          }
        } else {
          newActiveKey = newActiveKey - 1
        }
        console.log(newActiveKey)
        props.handleRemarksOptionChange(newPanes)
        setStates({
          panes: newPanes,
          activeKey: newActiveKey,
        });
      };
    
      const onEdit = (targetKey, action) => {
        action === 'add' ? add() : remove(targetKey);
      };

      const { panes, activeKey } = states;

    return (
        <div>
            <h2>Options</h2>
            <Tabs
        type="editable-card"
        className="tabsStyl"
        onChange={onChange}
        activeKey={String(activeKey)}
        onEdit={onEdit}
      >
        {panes.map((pane) => (
          <TabPane
            tab={pane.title}
            key={pane.key}
            closable={pane.closable}
          >
             <LessonRemarksForm changeOptionText={changeOptionText} number={pane.number} text={pane.text} />
          </TabPane>
        ))}
      </Tabs>
        </div>
    )
}

export default LessonRemarksTabs