import React, { Component } from 'react'
import { Button, Form, FormGroup, Input, Label, Modal } from 'reactstrap'
import Services from '../../RemoteServices/RemoteServices'
import { EditorState } from 'draft-js'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'

class PaymentStatus extends Component {
    state = {
        paymentStatus: this.props.studentData.payment_status,
        paymentAmount: this.props.studentData.payment_status === 'UnPaid' ? '7910' : '0',
        paymentDate: moment(new Date()).format('YYYY-MM-DD'),
        comment: '',
        paymentMethod: '',
        image: '',
        courseId: this.props.studentData.student_course || this.props.studentData.course,
        courseList: []
    }

    handlePasteImage = (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                let token = localStorage.getItem('token')
                return new Promise(
                    (resolve, reject) => {
                        const xhr = new XMLHttpRequest()
                        const serverUrl = window.config.apiUrl + 'common/image-upload'
                        xhr.open('POST', serverUrl)
                        xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                        const data = new FormData()
                        data.append('image', blob)
                        xhr.send(data)
                        xhr.addEventListener('load', () => {
                            const response = JSON.parse(xhr.responseText)
                            resolve(response)
                            this.setState(prevState => ({
                                ...prevState,
                                image: response.data.link
                            }))
                        })
                        xhr.addEventListener('error', () => {
                            const error = JSON.parse(xhr.responseText)
                            reject(error)
                        })
                    }
                )
                // If you want to upload the image, you can use a function like this.uploadImage(blob)
                // this.uploadImage(blob);
            } 
            // else {
            //     this.props.enqueueSnackbar('Sorry Error Occured', {
            //         variant: 'warning',
            //         anchorOrigin: {
            //             vertical: 'bottom',
            //             horizontal: 'right'
            //         }
            //     })
            // }
        }
    };

    handleSubmit = () => {
        let newData = {
            payment_status: this.state.paymentStatus,
            paid_amount: this.state.paymentAmount,
            paid_date: this.state.paymentDate,
            comment: this.state.comment,
            payment_method: this.state.paymentMethod,
            payment_screenshot: this.state.image,
            course_assign: this.state.courseId,
            student: this.props.studentData.id
        }
        Services.createStudentNotes(newData).then((response) => {
            this.props.enqueueSnackbar('Student Notes Created Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            if (this.props.type !== 'table') {
                this.props.getNoteDetail(this.props.studentData.id)
            }
            this.props.toggleState('paymentHistory')
        })
            .catch((error) => {
                console.log('asdfasdfasdfasdf', error)
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })
    }


    handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    getExtension = (filename) => {
        var parts = filename.split('.')
        return parts[parts.length - 1]
    }
    isImage = (filename) => {
        var ext = this.getExtension(filename)
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'gif':
            case 'bmp':
            case 'png':
            case 'jpeg':
                //etc
                return true
        }
        return false
    }

    handleLinkImageSelect = async (e) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        this.setState(prevState => ({
                            ...prevState,
                            image: response.data.link
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }
    handleLinkImageRemove = async (e) => {
        await this.setState(prevState => ({
            ...prevState,
            image: ''
        }))
    }

    getCourseList() {
        Services.getCourses().then((response) => {
            console.log('asdfasdf', response.data)
            this.setState({ courseList: response })
        })
    }

    componentDidMount() {
        this.getCourseList()
    }

    render() {
        const { paymentStatus, toggleState, studentData, type } = this.props
        return (
            <Dialog hideBackdrop={false} 
            disableBackdropClick={true}
            PaperProps={{ style: { width: '800px', minHeight: '400px', height: 'auto' } }}
                open={paymentStatus} onClose={() => toggleState('paymentHistory')}>
                <div className='modal-header mb-0'>
                    <h3 className='modal-title' id='exampleModalLabel'>
                        {type === 'table' ? 'Payment History' : 'Add Comments'}
                    </h3>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('paymentHistory')}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body mt-0 pt-0'>
                    <Form>
                        {studentData.student_course === 3 && studentData.payment_status === "Paid" && type === 'table' &&
                            <>
                                <FormGroup>
                                    <Label>Select Course</Label>
                                    <Input disabled={true} type={'select'} name={'courseId'}
                                        value={this.state.courseId} onChange={this.handleInputChange}>
                                        <option>Select Course</option>
                                        {this.state.courseList.map((item) => {
                                            return <option value={item.id}>{item.title}</option>
                                        })}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Payment Status</Label>
                                    <Input disabled={true} type={'select'} name={'paymentStatus'}
                                        value={this.state.paymentStatus} onChange={this.handleInputChange}>
                                        <option>Select Payment Status</option>
                                        <option value={'Paid'}>Paid</option>
                                        <option value={'UnPaid'}>Not Paid</option>
                                        <option value={'Due'}>Due</option>
                                    </Input>
                                </FormGroup>
                                {studentData.payment_status === 'Unpaid' &&
                                    <FormGroup>
                                        <Label>Payment Amount</Label>
                                        <Input disabled={true} type={'text'} name={'paymentAmount'}
                                            value={this.state.paymentAmount}
                                            onChange={this.handleInputChange}
                                            placeholder='Enter Payment Amount'></Input>
                                    </FormGroup>
                                }
                                <FormGroup>
                                    <Label>Payment Method</Label>
                                    <Input type={'select'} name={'paymentMethod'}
                                        value={this.state.paymentMethod} onChange={this.handleInputChange}>
                                        <option>Select Payment Status</option>
                                        <option value={'Khalti'}>Khalti</option>
                                        <option value={'Esewa'}>Esewa</option>
                                        <option value={'Bank'}>Bank</option>
                                    </Input>
                                </FormGroup>
                            </>
                        }

                        <FormGroup className={'mb-0'}>
                            <Label>Upload Screenshot</Label>
                            <br />
                            <input className='thumbnail_upload_button' accept='png/jpeg' type='file'
                                onChange={(e) => this.handleLinkImageSelect(e)} />
                            <br />
                            <div

                                onPaste={this.handlePasteImage}
                                style={{ border: '1px solid #ccc', padding: '10px', height: '60px' }}
                            >
                            </div>
                            <br />
                            {this.state.image && <div style={{ justifyContent: 'space-between' }}><i
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    color: 'red'
                                }}
                                onClick={(e) => this.handleLinkImageRemove(e)}
                                className='far fa-times-circle'></i>
                                <img alt={'screenshot-image'}
                                    style={{ width: '100%', height: 'auto' }}
                                    src={this.state.image} />
                            </div>}
                            <br />

                        </FormGroup>

                        <FormGroup>
                            <Label>Comment</Label>
                            <Input type={'textarea'} name={'comment'} value={this.state.comment}
                                onChange={this.handleInputChange}
                                placeholder='Enter Comment'></Input>
                        </FormGroup>
                    </Form>
                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('paymentHistory')}
                    >
                        Cancel
                    </Button>
                    <Button color='primary' type='button' onClick={() => this.handleSubmit()}>
                        Update
                    </Button>
                </div>
            </Dialog>
        )
    }
}

export default withSnackbar(PaymentStatus)
