import React from "react";
import { withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete';
import AlarmIcon from '@material-ui/icons/Create';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col
  } from "reactstrap";
import MaterialTable , { MTableToolbar } from 'material-table'
const Services = require('../../../RemoteServices/RemoteServices.js');


class UpdateAmount extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
     open:true,
     amount: null
    };
    this.handleInputChange = this.handleInputChange.bind(this);

  }

 
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

 
   render(){
    let userId = this.props.studentData.user.id
    console.log(userId)
       return (
           <> 
            <div>
            <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Amount" type="text" name="amount" value={this.state.amount} onChange={this.handleInputChange} />
                  </InputGroup>
                </FormGroup>
                <Button size="sm"  color="primary" type="button" onClick={()=>{
                    let data = {
                        amount: parseInt(this.state.amount)
                    }
                    Services.updateUserAmount(userId, data).then((response)=>{
                        this.props.onclose()
                        this.props.enqueueSnackbar('Successfully Updated', { 
                        variant: 'success',
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        }
                      }
                    )
                    })
                    .catch((error)=>{
                        this.props.enqueueSnackbar('Error! Please try again later or contact administrator', { 
                          variant: 'warning',
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                          }
                        }
                      )
                      })
                    
                }}>
                    UPDATE
                  </Button>
                  <Button size="sm"  color="secondary" type="button" onClick={()=> {
                      this.props.onclose()
                  }}>
                    CANCEL
                  </Button>
              </Form>
             </div>
           </>
       )
   }
}

export default withSnackbar(UpdateAmount)