/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import {ErrorBoundary} from 'react-error-boundary'



import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import 'antd/dist/antd.css';
import 'assets/css/ckeditorStyles.css'
import './index.css'

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import MlmLayout from "layouts/Mlm"

function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <div className='errorContainer' role="alert">
      <p className="errorTitle">Oops! Something went wrong!</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Reload</button>
    </div>
  )
}

ReactDOM.render(
  <ErrorBoundary
  FallbackComponent={ErrorFallback}
  onReset={() => {
    // reset the state of your app so the error doesn't happen again
  }}
>
  <BrowserRouter>
  <SnackbarProvider maxSnack={3}>
    <Switch>
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/mlm" render={props => <MlmLayout {...props} />} />

      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      <Redirect from="/" to="/auth" />
    </Switch>
    </SnackbarProvider>
  </BrowserRouter></ErrorBoundary>,  document.getElementById("root")
);

