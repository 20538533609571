import React, { useState, useEffect } from 'react'
import Header from "components/Headers/Header.js";
import { Container, Modal, Button, Spinner } from 'reactstrap'
import './ReviewRatings.css'
import CommentCard from '../../components/Cards/CommentCard'
import RatingCard from '../../components/Cards/RatingCard'
import { withSnackbar } from 'notistack';
import Rating from '@material-ui/lab/Rating';
const Services = require('../../RemoteServices/RemoteServices.js');


const ReviewRatings = (props) => {

  let [showComments, setShowComments] = useState('review')
  let [commentsList, setCommentsList] = useState([])
  let [commentsListLoading, setCommentsListLoading] = useState(true)
  let [remarksModal, setRemarksModal] = useState(false)
  let [ratingData, setRatingData] = useState({})

  let [currentCommentInfo, setCurrentCommentInfo] = useState({
    reviewType: '',
    commentText: '',
    ratingId: '',
    userId: '',
    lessonId: '',
    rating: null,
  })

  const handleCommentsToFetchChange = (e) => {
    const { name } = e.target
    // eslint-disable-next-line no-lone-blocks
    { name === 'published' ? showPublished('published', true) : showPublished('review', false) }
  }

  const showPublished = (statusWord, publishStatus) => {
    setCommentsListLoading(true)
    setShowComments(statusWord);
    fetchRatings(publishStatus)
  }

  useEffect(() => {
    fetchRatings(false)
  }, [])

  const toggleRemarksModal = () => {
    setRemarksModal(!remarksModal)
  }

  const fetchRatings = (publishStat) => {
    Services.getAppRatings(publishStat).then((response) => {
      setCommentsList(response.results)
      setRatingData(response)
      setCommentsListLoading(false)
    }).catch((error) => {
      console.log(error)
    })
  }

  const handleCommentTextChange = (e) => {
    setCurrentCommentInfo({ ...currentCommentInfo, commentText: e.target.value })
  }

  const setCurrentCommentData = (ratingId, commentMessage, reviewType, userId, LessonId, rating) => {
    setCurrentCommentInfo({
      ratingId,
      commentText: commentMessage,
      reviewType,
      userId,
      lessonId: LessonId,
      rating
    })
    toggleRemarksModal()
  }
  const handlePublishDelete = () => {
    { currentCommentInfo.reviewType === 'Publish' ? handlePublishRating() : handleDeleteRating() }
  }

  const handlePublishRating = () => {

    let { ratingId, commentText, rating } = currentCommentInfo
    let data = {
      ratingId: ratingId,
      message: commentText,
      publish: currentCommentInfo.reviewType === 'Publish' ? true : false,
      rating: currentCommentInfo.rating

    }
    Services.publishAppRating(data).then((response) => {
      props.enqueueSnackbar('App rating published.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }
      }
      )
      setCommentsListLoading(true)
      toggleRemarksModal()
      setShowComments(showComments)
      fetchRatings(showComments === 'review' ? false : true)
    }).catch((error) => {
      props.enqueueSnackbar('Error deleting comment.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }
      }
      )
    })
  }

  const handleDeleteRating = () => {
    let { ratingId } = currentCommentInfo
    Services.deleteAppRating(ratingId).then((response) => {
      props.enqueueSnackbar('Successfully deleted', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }
      }
      )
      setCommentsListLoading(true)
      toggleRemarksModal()
      setShowComments(showComments)
      fetchRatings(showComments === 'review' ? false : true)
    }).catch((error) => {
      props.enqueueSnackbar('Something went wrong. Please try again!', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }
      }
      )
    })

  }

  const handleShowMore = (action) => {
    action === 'next' ?
      Services.getCustom(ratingData.next).then((response) => {
        setCommentsList(response.results)
        setRatingData(response)
      }) : Services.getCustom(ratingData.previous).then((response) => {
        setCommentsList(response.results)
        setRatingData(response)
      })
  }

  return (
    <>
      <Header page='lesson' />
      <Container className="mt--7" >
        <div className="reviewCommentContainer">
          <div className="reviewRatingHeadTextContainer" style={{marginTop:'90px'}}>
            <h2>Review App Ratings</h2>  <div><span className="ratingCountNumber">Total Ratings: {ratingData && ratingData.count} Average Rating: {ratingData && ratingData.avg_rating}</span></div></div>
          <div className="reviewCommentButtonDiv">
            <button onClick={(e) => handleCommentsToFetchChange(e)} name="published" className={`reviewCommentButton_published ${showComments === 'published' ? 'active' : ''} `}>Published</button>
            <button onClick={(e) => handleCommentsToFetchChange(e)} name="review" className={`reviewCommentButton_forReview ${showComments === 'review' ? 'active' : ''}`}>Held for review</button>

          </div>

          {commentsListLoading ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}> <Spinner /> </div> :

            <div className="reviewComment_commentsContainer">
              {commentsList.length > 0 ? commentsList.map((item, index) => {
                return (
                  <RatingCard showComments={showComments} fetchRatings={fetchRatings} ratingData={item} key={index} setCurrentRatingData={setCurrentCommentData} />
                )
              }) : <span className="noCommentsSpan">No app ratings to display!</span>}
              {ratingData.count > 10 &&
                <div className="nextPreviousDiv"><Button type="button" color="primary" disabled={ratingData.previous === null} onClick={() => handleShowMore('prev')}> {'< Previous'}</Button><Button type="button" color="primary" disabled={ratingData.next === null} onClick={() => handleShowMore('next')}> {'Next >'}</Button></div>}


              <CommentCard />

            </div>}



        </div>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={remarksModal}
        toggle={toggleRemarksModal}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Confirmation
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleRemarksModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <h4>Are you sure you want to {currentCommentInfo.reviewType} this comment?</h4>

          {currentCommentInfo.reviewType === 'Publish' && <>
            <Rating
              name="simple-controlled"
              value={currentCommentInfo.rating}
              onChange={(event, newValue) => {
                setCurrentCommentInfo({ ...currentCommentInfo, rating: newValue })
              }}
            /> <input className="editCommentInput" type="text" onChange={(e) => handleCommentTextChange(e)} value={currentCommentInfo.commentText}></input> </>}

        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={toggleRemarksModal}
          >
            Cancel
          </Button>
          <Button color="primary" type="button"
            onClick={() => handlePublishDelete()}>
            {currentCommentInfo.reviewType}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default withSnackbar(ReviewRatings)