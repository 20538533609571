/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// node.js library that concatenates classes (strings)
import classnames from 'classnames'
// javascipt plugin for creating charts
import Chart from 'chart.js'
// react plugin used to create charts
import { Line, Bar } from 'react-chartjs-2'
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from 'reactstrap'

// core components
import {
    chartOptions,
    parseOptions,
    chartExample1,
    chartExample2
} from 'variables/charts.js'

import Header from 'components/Headers/Header.js'
import addNotification from 'react-push-notification'

const Services = require('../RemoteServices/RemoteServices.js')

class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            totalUsers: 0,
            approvedUsers: 0,
            pendingAssignments: 0,
            totalAssignments: 0,
            activeNav: 1,
            chartExample1Data: 'data1',
            dataMonthlyLabels: [],
            dataMonthlyValues: [],
            dataWeeklyLabels: [],
            dataWeeklyValues: [],
            assignmentLabels: [],
            assignmentValues: [],
            selectedMonthly: true
        }
        if (window.Chart) {
            parseOptions(Chart, chartOptions())
        }
    }

    toggleNavs = (e, index) => {
        e.preventDefault()
        this.setState({
            selectedMonthly: !(this.state.selectedMonthly)
            // activeNav: index,
            // chartExample1Data:
            //   this.state.chartExample1Data === "data1" ? "data2" : "data1"
        })
    }


    async componentDidMount() {
      addNotification({
        title: 'Warning',
        subtitle: 'This is a subtitle',
        message: 'This is a very long message',
        theme: 'darkblue',
        native: true // when using native, your OS will handle theming.
      });
        await Services.getMonthlyDashboardData().then((response) => {
            this.setState({ dataMonthlyLabels: response.names, dataMonthlyValues: response.values })
        })
            .catch((error) => {

            })
        await Services.getWeeklyDashboardData().then((response) => {
            this.setState({ dataWeeklyLabels: response.names, dataWeeklyValues: response.values })
        })
            .catch((error) => {

            })
        await Services.getAssignmentsDashboardData().then((response) => {
            this.setState({ assignmentLabels: response.names, assignmentValues: response.values })
        })
            .catch((error) => {

            })
    }

    render() {
        let data = {}
        let assignmentData = {
            labels: (this.state.assignmentLabels).reverse(),
            datasets: [
                {
                    label: 'Sales',
                    data: (this.state.assignmentValues).reverse(),
                    maxBarThickness: 10
                }
            ]
        }
        if (this.state.selectedMonthly) {
            data = {
                labels: this.state.dataMonthlyLabels,
                datasets: [
                    {
                        label: 'Performance',
                        data: this.state.dataMonthlyValues
                    }
                ]
            }
        } else {
            data = {
                labels: this.state.dataWeeklyLabels,
                datasets: [
                    {
                        label: 'Performance',
                        data: this.state.dataWeeklyValues
                    }
                ]
            }
        }
        let userType = JSON.parse(localStorage.getItem('user')).user_type

        return (
            <>
                <Header onlineCount={this.props.onlineCount} onlineUsers={this.props.onlineUsers} />
                {/* Page content */}

                {userType !== 'Superuser' ?
                    <div style={{ padding:'20px',width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <h2 style={{marginTop:'10px', color:'#6772E5',textAlign: 'justify', }}>You do not have full access to the admin panel because
                            you have been assigned a specific task. You can access limited data within the admin panel,
                            and if you require more information, you can contact the superuser at <strong>9851153918</strong> for
                            assistance.</h2>
                    </div>
                    :

                    <Container className='mt--7' fluid>
                        {userType === 'Superuser' &&
                            <Row>
                                <Col className='mb-5 mb-xl-0' xl='8'>
                                    <Card className='bg-gradient-default shadow'>
                                        <CardHeader className='bg-transparent'>
                                            <Row className='align-items-center'>
                                                <div className='col'>
                                                    <h6 className='text-uppercase text-light ls-1 mb-1'>
                                                        Overview
                                                    </h6>
                                                    <h2 className='text-white mb-0'>Users</h2>
                                                </div>
                                                <div className='col'>
                                                    <Nav className='justify-content-end' pills>
                                                        <NavItem>
                                                            <NavLink
                                                                className={classnames('py-2 px-3', {
                                                                    active: this.state.activeNav === 1
                                                                })}
                                                                href='#pablo'
                                                                onClick={e => this.toggleNavs(e, 1)}
                                                            >
                                                                <span className='d-none d-md-block'>Month</span>
                                                                <span className='d-md-none'>M</span>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                className={classnames('py-2 px-3', {
                                                                    active: this.state.activeNav === 2
                                                                })}
                                                                data-toggle='tab'
                                                                href='#pablo'
                                                                onClick={e => this.toggleNavs(e, 2)}
                                                            >
                                                                <span className='d-none d-md-block'>Week</span>
                                                                <span className='d-md-none'>W</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                </div>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            {/* Chart */}
                                            <div className='chart'>

                                                <Line
                                                    data={data}
                                                    options={chartExample1.options}
                                                    getDatasetAtEvent={e => console.log(e)}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl='4'>
                                    <Card className='shadow'>
                                        <CardHeader className='bg-transparent'>
                                            <Row className='align-items-center'>
                                                <div className='col'>
                                                    <h6 className='text-uppercase text-muted ls-1 mb-1'>
                                                        Performance
                                                    </h6>
                                                    <h2 className='mb-0'>Assignments</h2>
                                                </div>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            {/* Chart */}
                                            <div className='chart'>
                                                <Bar
                                                    data={assignmentData}
                                                    options={chartExample2.options}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        {/* <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Page visits</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Page name</th>
                      <th scope="col">Visitors</th>
                      <th scope="col">Unique users</th>
                      <th scope="col">Bounce rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">/argon/</th>
                      <td>4,569</td>
                      <td>340</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/index.html</th>
                      <td>3,985</td>
                      <td>319</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/charts.html</th>
                      <td>3,513</td>
                      <td>294</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                        36,49%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/tables.html</th>
                      <td>2,050</td>
                      <td>147</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />{" "}
                        50,87%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/profile.html</th>
                      <td>1,795</td>
                      <td>190</td>
                      <td>
                        <i className="fas fa-arrow-down text-danger mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Social traffic</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Referral</th>
                      <th scope="col">Visitors</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Facebook</th>
                      <td>1,480</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">60%</span>
                          <div>
                            <Progress
                              max="100"
                              value="60"
                              barClassName="bg-gradient-danger"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Facebook</th>
                      <td>5,480</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">70%</span>
                          <div>
                            <Progress
                              max="100"
                              value="70"
                              barClassName="bg-gradient-success"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Google</th>
                      <td>4,807</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">80%</span>
                          <div>
                            <Progress max="100" value="80" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Instagram</th>
                      <td>3,678</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">75%</span>
                          <div>
                            <Progress
                              max="100"
                              value="75"
                              barClassName="bg-gradient-info"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">twitter</th>
                      <td>2,645</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">30%</span>
                          <div>
                            <Progress
                              max="100"
                              value="30"
                              barClassName="bg-gradient-warning"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row> */}
                    </Container>
                }
            </>
        )
    }
}

export default Index
