import React from "react";
import ImageUpload from 'image-upload-react'
import 'image-upload-react/dist/index.css'
import '../../views/examples/Lessons.css'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col
  } from "reactstrap";
import { withSnackbar } from 'notistack';
import Select from 'react-select'
import { EditorState, convertToRaw } from 'draft-js';
import {Editor} from "react-draft-wysiwyg"
import { stateToHTML } from "draft-js-export-html";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import LessonRemarksTabs from "./LessonRemarksTabs";
import draftToHtml from 'draftjs-to-html';


const Services = require('../../RemoteServices/RemoteServices.js');


  
class LessonsForm extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      lessonNo: '',
      title:'',
      description: '',
      videoLink: '',
      selectedOptionId: '',
      dropdownOptions: [],
      editorState: EditorState.createEmpty(),
      imagePreview:'',
      image:'',
      remarks:[]
    };
  this.handleInputChange = this.handleInputChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handlePaste = this.handlePaste.bind(this)
  }



  handleSubmit(){

    let newData = new FormData();
    newData.append('title',this.state.title )
    newData.append('description',this.state.description )
    newData.append('course',this.state.selectedOptionId )
    if (this.state.image !== ''  ){
      newData.append('thumbnail',this.state.image,this.state.image.name )
    }
    
    newData.append('youtube_link',this.state.videoLink)
    newData.append('details',this.state.editorContentHtml)
    newData.append('order',this.state.lessonNo)
    newData.append('remarks',JSON.stringify(this.state.remarks))

    let courseId = this.state.selectedOptionId
    Services.createLesson(courseId,newData).then((response) => {
      this.props.enqueueSnackbar('Lesson Created Successfully', { 
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
      }
      })
     
      this.props.onSuccess(courseId, this.state.selectedOption)
     })
     .catch((error)=>{
      this.props.enqueueSnackbar('Error! Please try again later or contact administrator', { 
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }
      }
    )
    })
     }
  
  componentDidMount(){
      this.getCourseList()
  }

  getCourseList() {
      Services.getCourses().then((response) => {
          this.setState({courseList: response})
          this.populateSelectDropdown()
      })
  }

  populateSelectDropdown(){
      let data = this.state.courseList
      data.forEach((item)=>{
          this.state.dropdownOptions.push({value: item.id, label: item.title})
      })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleChange = selectedOption => {
    this.setState(
      {  selectedOptionId: selectedOption.value, selectedOption }
    );
  };

  onEditorStateChange (editorState) {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    this.setState({
      editorState,
      editorContentHtml: draftToHtml(rawContentState)
    });
  };


  uploadImageCallBack(file) {
    let token = localStorage.getItem('token')
    
    return new Promise(
      (resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const serverUrl = window.config.apiUrl + 'common/image-upload'
        xhr.open('POST', serverUrl);
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        const data = new FormData();
        data.append('image', file);
        xhr.send(data);
        xhr.addEventListener('load', () => {
          const response = JSON.parse(xhr.responseText);
          resolve(response);
        });
        xhr.addEventListener('error', () => {
          const error = JSON.parse(xhr.responseText);
          reject(error);
        });
      }
    );
  }

      //Image selection and cases

      getExtension = (filename) => {
        var parts = filename.split('.');
        return parts[parts.length - 1];
      }
  
       isImage = (filename) =>  {
        var ext = this.getExtension(filename);
        switch (ext.toLowerCase()) {
          case 'jpg':
          case 'gif':
          case 'bmp':
          case 'png':
          case 'jpeg':
            //etc
            return true;
        }
        return false;
      }

   handleImageSelect = (e) => {
    let filename = e.target.files[0].name
    if( this.isImage(filename) ){
      this.setState({imagePreview: URL.createObjectURL(e.target.files[0]), image:e.target.files[0]})
    

    }
    else {
      this.props.enqueueSnackbar('Please choose a correct image format', { 
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
      }
      })     
    }
  
  }

  handleImageRemove = (e) => {
    this.setState({imagePreview:'',image:''})
  }

  handleRemarksOptionChange = (optionArray) => {
    this.setState({remarks: optionArray})
  }


  handlePaste = () => {
    this.setState({
      editorState:this.props.editorState,
      editorContentHtml: this.props.editorContentHtml
    })
  }

   render(){
    const options = this.state.dropdownOptions
       return (
           <> 
            <Form encType="multipart/form-data" role="form" style={{
                paddingLeft: 20,
                paddingRight: 20 
            }}>
              <div style={{marginBottom:'20px', display:'flex', justifyContent:'flex-end'}}>
              {this.props.copyStatus &&  <Button  type="button" color="secondary" onClick={()=> this.handlePaste()}>Paste Data</Button>}
              </div>
                 <FormGroup>
                    <Select options={options} placeholder="Select Course" value={this.state.selectedOption} onChange={this.handleChange.bind(this)} />
                </FormGroup>
               <FormGroup style={{display:'flex', justifyContent:'space-between'}}>
                  <InputGroup style={{width:'40%',marginRight:'10px'}} className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Lesson Number" type="text" name="lessonNo" value={this.state.lessonNo} onChange={this.handleInputChange} />
                  </InputGroup>
                  <InputGroup style={{width:'40%',marginRight:'10px'}} className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Lesson Title" type="text" name="title" value={this.state.title} onChange={this.handleInputChange} />
                  </InputGroup>
                  <InputGroup style={{width:'40%'}} className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Video Link" type="text" name="videoLink" value={this.state.videoLink} onChange={this.handleInputChange} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                    <p>Upload image for thumbnail</p>
                    <input className='thumbnail_upload_button' accept='png/jpeg' type='file'
                           onChange={this.handleImageSelect} />
                    <br />
                    {this.state.imagePreview &&
                        <div className='thumbnailContainerAddLesson'><i onClick={this.handleImageRemove.bind(this)}
                                                                        class='far fa-times-circle'></i> <img
                            className='lessonFormThumbnailImage' src={this.state.imagePreview} /></div>}

                </FormGroup>
                {/* <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                        id="exampleFormControlTextarea1"
                        placeholder="Description / Notes  [Old Layout]"
                        rows="3"
                        type="textarea"
                        name="description"
                        value={this.state.description} 
                        onChange={this.handleInputChange}
                    />
                  </InputGroup>
                </FormGroup> */}
                    <Editor
                    editorStyle={{
                      border: '1px solid black',
                      padding: '5px',
                      borderRadius: '2px',
                      height: '400px',
                      width: '100%',
                    }}
                    placeholder="Description / Notes  [NEW Layout]"
                      editorState={this.state.editorState}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={this.onEditorStateChange.bind(this)}
                      toolbar={{
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      image: {
                        uploadCallback: this.uploadImageCallBack,
                        previewImage: true,
                        defaultSize: {width: '100%', height: 'auto'}
                      },
                    }}
                    />

                    <div className="addRemarksContainerNew">
                      <h3>Add Remarks</h3>

                     <LessonRemarksTabs  courseId={this.state.selectedOptionId} handleRemarksOptionChange={this.handleRemarksOptionChange.bind(this)} />

                    </div>

                    {/* <div className="addRemarksContainerNew">
                      <h3>Add Lesson Quizes</h3>

                     <LessonQuizTabs  />

                    </div> */}
                    
                <div className="pb-4">
                  <Button  className="mt-4" color="primary" type="button" onClick={this.handleSubmit}>
                    Create
                  </Button>
                </div>
              </Form>
           </>
       )
   }
}





export default withSnackbar(LessonsForm)