import React, { useState } from 'react'
import Header from 'components/Headers/Header.js'
import {
    Card,
    Container,
    Row,
    Button,
    Modal,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    InputGroupAddon, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import Select from 'react-select'
import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import ArticlesForm from 'components/Forms/ArticlesForm.js'
import ReactHtmlParser from 'react-html-parser'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import Dropzone from 'react-dropzone-uploader'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor'
import { css } from 'glamor'
import './Articles.css'
import {
    customChunkRenderer
} from 'draft-js-helpers'
import avatar from '../../assets/img/dummyUser.png'
import moment from 'moment'
import DateFormatter from '../../utils/DateFormatter'
import DeactiveUserToggle from '../../components/Toggle/DeactiveUser'

const Services = require('../../RemoteServices/RemoteServices.js')

class DeactivatedUsers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filteredData: [],
            selectedCategory: '',
            addFormView: false,
            data: [],
            confirmModal: false,
            selectedRow: {},
            loading: true,
            masterPassword: '',
            articleEditOpen: false,
            title: '',
            description: '',
            image: null,
            editorState: EditorState.createEmpty(),
            selectedOption: [],
            selectedOptionId: '',
            articleViewType: true,
            articleId: '',
            imageAdded: false,
            searchType: '',
            searchTypeText: '',
            normalDropDownOpen: false,
            reactivateFormView: false,
            userId: '',
            deactiveReason: '',
            showDeactiveUser: false,
            deactivatedData: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleUpdateArticle = this.handleUpdateArticle.bind(this)
        this.toggleEditorCode = this.toggleEditorCode.bind(this)
        this.onEditEditorHTML = this.onEditEditorHTML.bind(this)
        this.handleChangeStatus = this.handleChangeStatus.bind(this)
        this.handleChangeStatusImages = this.handleChangeStatusImages.bind(this)
    }

    onSearchChange = async (search) => {
        await this.setState({ searchTextValue: search.target.value })
        this.state.searchType !== '' ?
            await Services.getDeactivatedUsersWithSearchType(this.state.searchType, this.state.searchTextValue).then((response) => {
                this.setState({
                    data: response.results,
                    loading: false,
                    count: response.count,
                    next: response.next,
                    previous: response.previous
                })
            }) :
            await Services.getDeactiveRequestUser(this.state.searchTextValue).then((response) => {
                this.setState({
                    data: response.results,
                    loading: false,
                    count: response.count,
                    next: response.next,
                    previous: response.previous
                })
            })
    }


    changeSearchType = async (type, typeText) => {
        this.setState({ searchType: type, searchTypeText: typeText, searchText: '' })
        if (type === '') {
            Services.getDeactivatedUsers().then((response) => {
                this.setState({
                    data: response.results,
                    loading: false,
                    count: response.count,
                    next: response.next,
                    previous: response.previous
                })
            })

        }
    }

    async handleChange(selectedOption) {
        await this.setState(
            { selectedOptionId: selectedOption.value, selectedOption }
        )
    };

    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    toggleCheckState = async (flag) => {
        if (flag) {
            this.setState({
                showDeactiveUser: flag
            })
            await this.getDeactivatedUser()
        } else {
            this.setState({
                showDeactiveUser: flag
            })
            this.getArticles()
        }
    }

    async componentDidMount() {
        await this.getArticles()
    }

    async getDeactivatedUser() {
        this.setState({ loading: true })
        await Services.getDeactivatedUser().then((response) => {
            console.log(response)
            this.setState({ deactivatedData: response, loading: false })
        }).catch((e) => {
            this.setState({ loading: false })
        })
    }
    async getArticles() {
        this.setState({ loading: true })
        await Services.getDeactiveRequestUser().then((response) => {
            console.log(response)
            this.setState({ data: response, loading: false })
        }).catch((e) => {
            this.setState({ loading: false })
        })
    }

    handleCreateArtcleOpen = () => {
        this.toggleState('addFormView')
        this.toggleState('passwordModal')
    }

    onEditorStateChange(editorState) {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        this.setState({
            editorState,
            editorContentHtml: draftToHtml(rawContentState)
        })
    };

    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    returnHtmlBlock(text) {
        if (this.state.articleViewType === true) {
            const processedHTML = htmlToDraft(text)
            return ContentState.createFromBlockArray(processedHTML)
        } else {
            const processedHTML = DraftPasteProcessor.processHTML(text)
            return ContentState.createFromBlockArray(processedHTML)
        }
    }

    handleInitialEditorText(text) {
        let editorState
        if (text.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(text))
            editorState = EditorState.moveFocusToEnd(editorState)
        } else {
            editorState = EditorState.createEmpty()
        }
        // this.setState({editorContentHtml:stateToHTML(editorState.getCurrentContent())})
        return editorState
    }

    toggleEditorCode() {
        this.setState({ showEditorCode: !this.state.showEditorCode })
    }

    getUploadParams = ({ meta }) => {
        return { url: 'https://httpbin.org/post' }
    }

    handleChangeStatusImages({ meta, file }, status) {
        let productImages = this.state.images
        let reader = new FileReader()
        reader.onloadend = function () {
            if (!(productImages.includes(reader.result))) productImages.push(reader.result)
            this.setState({ images: productImages })
        }.bind(this)
        reader.readAsDataURL(file)

    }

    onEditEditorHTML(e) {
        this.setState({ editorContentHtml: e.target.value })
    }


    addHtmlToEditor = () => {
        const { editorContentHtml } = this.state
        let editorState
        if (editorContentHtml.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(editorContentHtml))
        } else {
            editorState = EditorState.createEmpty()
        }
        this.setState({ editorState })
    }

    uploadImageCallBack(file) {
        let token = localStorage.getItem('token')
        console.log(file)
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest()
                const serverUrl = window.config.apiUrl + 'common/image-upload'
                xhr.open('POST', serverUrl)
                xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                const data = new FormData()
                data.append('image', file)
                xhr.send(data)
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText)
                    resolve(response)
                })
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText)
                    reject(error)
                })
            }
        )
    }

    getUploadParams = ({ meta }) => {
        return { url: 'https://httpbin.org/post' }
    }

    // handleChangeStatusImages({ meta, file }, status)  {
    //   let productImages = this.state.images
    //   let reader = new FileReader();
    //         reader.onloadend = function() {
    //           let result = reader
    //           if(!(productImages.includes(reader.result))) productImages.push(reader.result)
    //             this.setState({images: productImages})
    //           }.bind(this)
    //   reader.readAsDataURL(file);
    //  console.log(this.state.images)

    // }

    handleReactivateUser = () => {
        let reqDto = {
            id: this.state.userId
        }
        Services.reactivateUser(reqDto).then((response) => {
            this.props.enqueueSnackbar('User Reactivated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })
    }

    handleChangeStatus({ meta, file }, status) {
        // this.setState({image:file})
        let reader = new FileReader()
        reader.onloadend = function () {
            // console.log('RESULT', reader.result)
            this.setState({ image: reader.result, imageAdded: true })
        }.bind(this)
        reader.readAsDataURL(file)
        //  console.log(this.state)

    }

    handleUpdateArticle() {
        let articleData
        this.state.imageAdded ?
            articleData = {
                title: this.state.title,
                descriptionDetails: this.state.editorContentHtml,
                image: this.state.image,
                description: this.state.description,
                category: this.state.category
            } : articleData = {
                title: this.state.title,
                descriptionDetails: this.state.editorContentHtml,
                description: this.state.description,
                category: this.state.category

            }
        Services.updateArticle(this.state.articleId, articleData).then((response) => {
            this.setState({ imageAdded: false })
            this.props.enqueueSnackbar('Article Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            this.toggleState('articleEditOpen')
            this.getArticles()
        }).catch((error) => {
            if (error.title && error.description && error.image) {
                this.props.enqueueSnackbar('Title, Description And Image Must Not Be Blank', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            } else if (error.title) {
                this.props.enqueueSnackbar('Title Must Not Be Blank', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            } else if (error.description) {
                this.props.enqueueSnackbar('Description Must Not Be Blank', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            } else if (error.image) {
                this.props.enqueueSnackbar('Image Must Not Be Blank', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            } else {
                this.props.enqueueSnackbar('Please Contact Administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            }


        })
    }

    handleCategoryChange = (event) => {
        const category = event.target.value
        this.setState({ selectedCategory: category })
        if (category === '') {
            // if no category is selected, show all data
            this.setState({ filteredData: [] })
        } else {
            const filteredData = this.state.data.filter(
                (item) => item.category === category
            )
            this.setState({ filteredData })
        }
    }

    updateEditState = async (data) => {
        await this.setState((prevState) => ({
            ...prevState,
            userId: data.id,
            deactiveReason: data.reason_for_delete
        }))
    }

    render() {
        const ShowEditorCode = () => (
            <div className='rdw-option-wrapper' onClick={this.toggleEditorCode}>
                {this.state.showEditorCode
                    ? 'Hide Code'
                    : 'Show Code'}
            </div>
        )
        return (
            <>
                <Header onlineCount={this.props.onlineCount} onlineUsers={this.props.onlineUsers} />
                <Container className='mt--7' fluid>
                    <DeactiveUserToggle showDeactiveUser={this.state.showDeactiveUser}
                        toggleCheckState={this.toggleCheckState} />
                    {this.state.showDeactiveUser ?
                        <Row>
                            <div className=' col'>
                                <Card className=' shadow'>
                                    {!(this.state.addFormView) ?
                                        this.state.loading ?
                                            <CircularProgress style={{
                                                margin: 50,
                                                alignSelf: 'center'
                                            }} />
                                            :
                                            <>
                                                {/*<div style={{ display: 'flex', flexDirection: 'column' }}>*/}
                                                {/*    <FormGroup*/}
                                                {/*        style={{*/}
                                                {/*            margin: 0,*/}
                                                {/*            padding: 15,*/}
                                                {/*            display: 'flex',*/}
                                                {/*            width: '100%'*/}
                                                {/*        }}>*/}
                                                {/*        <Dropdown isOpen={this.state.normalDropDownOpen} toggle={() => {*/}
                                                {/*            this.toggleState('normalDropDownOpen')*/}
                                                {/*        }}>*/}
                                                {/*            <DropdownToggle caret>*/}
                                                {/*                {this.state.searchTypeText ? this.state.searchTypeText : 'All'}*/}
                                                {/*            </DropdownToggle>*/}
                                                {/*            <DropdownMenu container='body'>*/}
                                                {/*                <DropdownItem*/}
                                                {/*                    onClick={() => this.changeSearchType('', 'All')}>All</DropdownItem>*/}
                                                {/*                <DropdownItem*/}
                                                {/*                    onClick={() => this.changeSearchType('user_full_name', 'Name')}>Name</DropdownItem>*/}
                                                {/*                <DropdownItem*/}
                                                {/*                    onClick={() => this.changeSearchType('user_id', 'User Id')}>User*/}
                                                {/*                    Id</DropdownItem>*/}
                                                {/*                <DropdownItem*/}
                                                {/*                    onClick={() => this.changeSearchType('user_phone_number', 'Phone No')}>Phone*/}
                                                {/*                    No.</DropdownItem>*/}
                                                {/*                <DropdownItem*/}
                                                {/*                    onClick={() => this.changeSearchType('user_referral_code', 'Referal Code')}>Referal*/}
                                                {/*                    Code</DropdownItem>*/}
                                                {/*                <DropdownItem*/}
                                                {/*                    onClick={() => this.changeSearchType('user_email', 'Email Address')}>Email*/}
                                                {/*                    Address</DropdownItem>*/}

                                                {/*            </DropdownMenu>*/}
                                                {/*        </Dropdown>*/}
                                                {/*        <InputGroup*/}
                                                {/*            className='input-group-alternative mb-3'>*/}
                                                {/*            <InputGroupAddon addonType='prepend'>*/}
                                                {/*                <InputGroupText>*/}
                                                {/*                    <i className='ni ni-hat-3' />*/}
                                                {/*                </InputGroupText>*/}
                                                {/*            </InputGroupAddon>*/}
                                                {/*            <Input*/}
                                                {/*                placeholder={this.state.searchTypeText ? this.state.searchTypeText : 'Search..'}*/}
                                                {/*                type='text' name='searchTextValue'*/}
                                                {/*                value={this.state.searchTextValue}*/}
                                                {/*                onChange={this.onSearchChange} />*/}
                                                {/*        </InputGroup>*/}
                                                {/*    </FormGroup>*/}
                                                {/*</div>*/}
                                                <MaterialTable
                                                    columns={[
                                                        {
                                                            title: 'Avatar',
                                                            field: 'imageUrl',
                                                            render: rowData => <img src={avatar}
                                                                style={{
                                                                    width: 40,
                                                                    height: 40,
                                                                    objectFit: 'fill',
                                                                    borderRadius: '50%'
                                                                }} />
                                                        },
                                                        {
                                                            title: 'Full Name',
                                                            field: 'full_name',
                                                            render: rowData =>
                                                                <b>{rowData.full_name || 'Test Name'}</b>
                                                        },
                                                        {
                                                            title: 'Email',
                                                            field: 'email',
                                                            editable: 'never'
                                                        },
                                                        {
                                                            title: 'Status', field: 'status',
                                                            lookup: {
                                                                'active': 'Active',
                                                                'deactive': 'Deactive'
                                                            },
                                                            render: rowData => <>{'Deactivated'}</>
                                                        }
                                                    ]}
                                                    data={this.state.deactivatedData}
                                                    title='Deactivated Users'
                                                    actions={[
                                                        {
                                                            icon: 'edit',
                                                            tooltip: 'Edit',
                                                            isFreeAction: false,
                                                            onClick: (event, rowData) => {
                                                                this.updateEditState(rowData)
                                                                this.toggleState('reactivateFormView')
                                                            }
                                                        }
                                                    ]}
                                                    options={{
                                                        actionsColumnIndex: -1,
                                                        pageSize: 10,
                                                        pageSizeOptions: [10, 20, 50],
                                                        headerStyle: {
                                                            backgroundColor: '#6772E5',
                                                            color: '#FFF',
                                                            searchFieldAlignment: 'left'
                                                        }

                                                    }}

                                                />
                                            </>
                                        :
                                        <>
                                            <div class='d-flex mt-3'>
                                                <div class='mr-auto ml-4 mt-2'><h2>Articles</h2></div>
                                                <div class='p-2'>
                                                    <Button className='btn-icon btn-3' color='primary' type='button'
                                                        onClick={() => this.toggleState('addFormView')}>
                                                        <span className='btn-inner--icon'>
                                                            <i className='ni ni-bag-17' />
                                                        </span>
                                                        <span className='btn-inner--text'>Back</span>
                                                    </Button>
                                                </div>
                                            </div>

                                            <hr class='solid'></hr>
                                        </>
                                    }
                                </Card>
                            </div>
                        </Row>
                        :
                        <Row>
                            <div className=' col'>
                                <Card className=' shadow'>
                                    {!(this.state.addFormView) ?
                                        this.state.loading ?
                                            <CircularProgress style={{
                                                margin: 50,
                                                alignSelf: 'center'
                                            }} />
                                            :
                                            <>
                                                {/*<div style={{ display: 'flex', flexDirection: 'column' }}>*/}
                                                {/*    <FormGroup*/}
                                                {/*        style={{*/}
                                                {/*            margin: 0,*/}
                                                {/*            padding: 15,*/}
                                                {/*            display: 'flex',*/}
                                                {/*            width: '100%'*/}
                                                {/*        }}>*/}
                                                {/*        <Dropdown isOpen={this.state.normalDropDownOpen} toggle={() => {*/}
                                                {/*            this.toggleState('normalDropDownOpen')*/}
                                                {/*        }}>*/}
                                                {/*            <DropdownToggle caret>*/}
                                                {/*                {this.state.searchTypeText ? this.state.searchTypeText : 'All'}*/}
                                                {/*            </DropdownToggle>*/}
                                                {/*            <DropdownMenu container='body'>*/}
                                                {/*                <DropdownItem*/}
                                                {/*                    onClick={() => this.changeSearchType('', 'All')}>All</DropdownItem>*/}
                                                {/*                <DropdownItem*/}
                                                {/*                    onClick={() => this.changeSearchType('user_full_name', 'Name')}>Name</DropdownItem>*/}
                                                {/*                <DropdownItem*/}
                                                {/*                    onClick={() => this.changeSearchType('user_id', 'User Id')}>User*/}
                                                {/*                    Id</DropdownItem>*/}
                                                {/*                <DropdownItem*/}
                                                {/*                    onClick={() => this.changeSearchType('user_phone_number', 'Phone No')}>Phone*/}
                                                {/*                    No.</DropdownItem>*/}
                                                {/*                <DropdownItem*/}
                                                {/*                    onClick={() => this.changeSearchType('user_referral_code', 'Referal Code')}>Referal*/}
                                                {/*                    Code</DropdownItem>*/}
                                                {/*                <DropdownItem*/}
                                                {/*                    onClick={() => this.changeSearchType('user_email', 'Email Address')}>Email*/}
                                                {/*                    Address</DropdownItem>*/}

                                                {/*            </DropdownMenu>*/}
                                                {/*        </Dropdown>*/}
                                                {/*        <InputGroup*/}
                                                {/*            className='input-group-alternative mb-3'>*/}
                                                {/*            <InputGroupAddon addonType='prepend'>*/}
                                                {/*                <InputGroupText>*/}
                                                {/*                    <i className='ni ni-hat-3' />*/}
                                                {/*                </InputGroupText>*/}
                                                {/*            </InputGroupAddon>*/}
                                                {/*            <Input*/}
                                                {/*                placeholder={this.state.searchTypeText ? this.state.searchTypeText : 'Search..'}*/}
                                                {/*                type='text' name='searchTextValue'*/}
                                                {/*                value={this.state.searchTextValue}*/}
                                                {/*                onChange={this.onSearchChange} />*/}
                                                {/*        </InputGroup>*/}
                                                {/*    </FormGroup>*/}
                                                {/*</div>*/}
                                                <MaterialTable
                                                    columns={[
                                                        {
                                                            title: 'Avatar',
                                                            field: 'imageUrl',
                                                            render: rowData => <img src={avatar}
                                                                style={{
                                                                    width: 40,
                                                                    height: 40,
                                                                    objectFit: 'fill',
                                                                    borderRadius: '50%'
                                                                }} />
                                                        },
                                                        {
                                                            title: 'Full Name',
                                                            field: 'student_name',
                                                            render: rowData =>
                                                                <b>{rowData.student_name || 'Test Name'}</b>
                                                        },
                                                        {
                                                            title: 'Email',
                                                            field: 'student_email',
                                                            editable: 'never'
                                                        },
                                                        {
                                                            title: 'Reason',
                                                            field: 'reason',
                                                            render: rowData => <>{rowData.reason_for_delete || 'N/A'}</>

                                                        },
                                                        {
                                                            title: 'Time Left',
                                                            field: 'time_left',
                                                            render: rowData => <>{DateFormatter(rowData.request_time) || 'N/A'}</>

                                                        },
                                                        {
                                                            title: 'Status', field: 'status',
                                                            lookup: {
                                                                'active': 'Active',
                                                                'deactive': 'Deactive'
                                                            },
                                                            render: rowData => <>{'Processing'}</>
                                                        }
                                                    ]}
                                                    data={this.state.filteredData.length > 0 ? this.state.filteredData : this.state.data}
                                                    title='Requested Users'
                                                    actions={[
                                                    ]}
                                                    options={{
                                                        actionsColumnIndex: -1,
                                                        pageSize: 10,
                                                        pageSizeOptions: [10, 20, 50],
                                                        headerStyle: {
                                                            backgroundColor: '#6772E5',
                                                            color: '#FFF',
                                                            searchFieldAlignment: 'left'
                                                        }

                                                    }}

                                                />
                                            </>
                                        :
                                        <>
                                            <div class='d-flex mt-3'>
                                                <div class='mr-auto ml-4 mt-2'><h2>Articles</h2></div>
                                                <div class='p-2'>
                                                    <Button className='btn-icon btn-3' color='primary' type='button'
                                                        onClick={() => this.toggleState('addFormView')}>
                                                        <span className='btn-inner--icon'>
                                                            <i className='ni ni-bag-17' />
                                                        </span>
                                                        <span className='btn-inner--text'>Back</span>
                                                    </Button>
                                                </div>
                                            </div>

                                            <hr class='solid'></hr>
                                        </>
                                    }
                                </Card>
                            </div>
                        </Row>
                    }
                </Container>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.confirmModal}
                    toggle={() => this.toggleState('confirmModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Are you sure you want to delete?</h4>
                        <Form>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>

                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                            onClick={
                                () => {
                                    Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                        let selectedRowId = this.state.selectedRow.id
                                        Services.deleteArticle(selectedRowId).then((response) => {
                                            this.getArticles()
                                            this.toggleState('confirmModal')
                                            this.props.enqueueSnackbar('Successfully deleted', {
                                                variant: 'success',
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'right'
                                                }
                                            }
                                            )
                                        })
                                            .catch((error) => {
                                                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                    variant: 'warning',
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }
                                                }
                                                )
                                            })


                                    }).catch((error) => {
                                        this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                        )
                                    })


                                }
                            }>
                            Yes
                        </Button>
                    </div>
                </Modal>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.passwordModal}
                    toggle={() => this.toggleState('passwordModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Enter password to perform this action.</h4>
                        <Form onSubmit={
                            (e) => {
                                e.preventDefault()
                                Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                    this.handleCreateArtcleOpen()
                                }).catch((error) => {
                                    this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                        variant: 'warning',
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }
                                    }
                                    )
                                })


                            }
                        }>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                            onClick={
                                () => {
                                    Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                        this.handleCreateArtcleOpen()
                                    }).catch((error) => {
                                        this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                        )
                                    })


                                }
                            }>
                            Enter
                        </Button>
                    </div>
                </Modal>
                <Modal
                    size='lg'
                    className='modal-dialog-centered lessonUpdate'
                    isOpen={this.state.reactivateFormView}
                    toggle={() => this.toggleState('reactivateFormView')}
                    style={{ maxWidth: '1000px', width: '100%' }}>

                    <div className='modal-header'>
                        <h3 className='modal-title' id='exampleModalLabel'>
                            Reactivate User
                        </h3>
                        <Button color='danger' type='button'
                            onClick={() => this.toggleState('reactivateFormView')}
                        >
                            Close
                        </Button>
                    </div>
                    <div className={'pl-2 modal-body m-0 p-0'}>
                        <span>
                            {this.state.deactiveReason || ''}
                        </span>
                    </div>
                    <div className='pb-4 pl-2'>
                        <Button className='mt-4' color='danger' type='button'
                            onClick={() => this.toggleState('reactivateFormView')}>
                            Cancel
                        </Button>
                        <Button className='mt-4' color='primary' type='button' onClick={this.handleReactivateUser}>
                            Reactivate
                        </Button>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withSnackbar(DeactivatedUsers)
