import React from 'react'
import Header from 'components/Headers/Header.js'
import { Editor } from 'react-draft-wysiwyg'
import './Lessons.css'
import {
    Button,
    Card,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Label,
    Modal,
    Row
} from 'reactstrap'
import MaterialTable, { MTableToolbar } from 'material-table'
import { withSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import ReactHtmlParser from 'react-html-parser'
import { customChunkRenderer } from 'draft-js-helpers'
import DictionaryForm from '../../components/Forms/DictionaryForm'
import ReactPlayer from 'react-player/youtube'
import { TagsInput } from 'react-tag-input-component'

const Services = require('../../RemoteServices/RemoteServices.js')


class Dictionary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            link_image: '',
            link_slug: '',
            addFormView: false,
            data: [],
            courseList: [],
            dropdownOptions: [],
            selectedOption: null,
            selectedOptionId: null,
            selectedRow: {},
            formModal: false,
            loading: true,
            lessonEditModal: false,
            lessonNo: '',
            title: '',
            linkId: '',
            description: '',
            videoLink: '',
            editorState: EditorState.createEmpty(),
            editorStateCopied: EditorState.createEmpty(),
            editorContentHtml: '',
            editorContentHtmlCopied: '',
            selectedRowId: '',
            image: '',
            lessonCourse: null,
            imagePreview: '',
            lessonRemarks: [],
            allowSubmit: false,
            courseSelectContainer: true,
            passwordModal: false,
            masterPassword: '',
            lessonViewType: true,
            copyStatus: false,
            showEditorCode: false,
            uniqueId: '',
            id: null,
            showEditModal: false,
            link: {
                linkId: '',
                lessonNo: '',
                title: '',
                description: '',
                videoLink: '',
                selectedOptionId: '',
                dropdownOptions: [],
                editorState: EditorState.createEmpty(),
                editorStateCopied: EditorState.createEmpty(),
                editorContentHtmlCopied: '',
                imagePreview: '',
                image: '',
                remarks: [],
                uniqueId: '',
                editorContentHtml: '',
                video: '',
                itemList: []
            },
            showAllButton: true,
            editFormView: false
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.onEditorStateChange = this.onEditorStateChange.bind(this)
        this.onCourseCardClick = this.onCourseCardClick.bind(this)
        this.handleCreateLessonOpen = this.handleCreateLessonOpen.bind(this)
        this.toggleEditorCode = this.toggleEditorCode.bind(this)
        this.onEditEditorHTML = this.onEditEditorHTML.bind(this)
        this.handleCopy = this.handleCopy.bind(this)
        this.handlePaste = this.handlePaste.bind(this)
    }

    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }
    addItem = async (idx) => {
        const { link } = this.state
        let item = {
            showEditorCode: false,
            editorState: EditorState.createEmpty(),
            editorStateCopied: EditorState.createEmpty(),
            editorContentHtmlCopied: '',
            synonyms: [],
            antonyms: [],
            meaning: '',
            partOfSpeech: '',
            brePronounciation: '',
            amePronounciation: '',
            meaningEnglish: '',
            meaningNepali: '',
            detailMeaning: '',
            exampleMeaningEnglishEditorState: EditorState.createEmpty(),
            exampleMeaningEnglishStateCopied: EditorState.createEmpty(),
            exampleMeaningEnglishEditorContentHtmlCopied: '',
            exampleMeaningEnglishEditorContentHtml: '',
            exampleMeaningNepaliEditorState: EditorState.createEmpty(),
            exampleMeaningNepaliStateCopied: EditorState.createEmpty(),
            exampleMeaningNepaliEditorContentHtmlCopied: '',
            exampleMeaningNepaliEditorContentHtml: '',
            showAllButton: true,
            addButton: true
        }
        await this.setState({
            showAllButton: true,
            link: {
                ...link,
                itemList: [...link.itemList, item]
            }

        })
        // let itemUpdated = [...this.state.link.itemList]
        // itemUpdated[idx]['addButton'] = false
        // await this.setState(prevState => ({
        //     ...prevState,
        //     link: {
        //         ...prevState.link,
        //         itemList: [...itemUpdated]
        //     }
        // }))

    }
    editItem = (event, idx, type) => {
        let name = event.target.name
        let value = event.target.value
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx][name] = value
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))
    }
    removeItem = async (idx) => {
        let item = [...this.state.link.itemList]
        item.splice(idx, 1)
        await this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: item
            }
        }))

    }

    updateEditState = async (data) => {
        await this.setState((prevState) => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: data?.word?.map((item, index) => ({
                    synonyms: item.meaningsynonyms.map((item=>item.synonyms)),
                    antonyms: item.meaningantonyms.map((item=>item.antonyms)),
                    meaning: item.meaning,
                    partOfSpeech: item.part_of_speech,
                    brePronounciation: item.brepronounce,
                    amePronounciation: item.amepronounce,
                    meaningEnglish: item.meaning_english,
                    meaningNepali: item.meaning_nepali,
                    editorState: item.detailed_meaning && this.handleInitialEditorText(item.detailed_meaning),
                    editorContentHtml: item.detailed_meaning || '',
                    exampleMeaningNepaliEditorState: item.nepali_example && this.handleInitialEditorText(item.nepali_example),
                    exampleMeaningNepaliEditorContentHtml: item.nepali_example || '',
                    exampleMeaningEnglishEditorState: item.english_example && this.handleInitialEditorText(item.english_example),
                    exampleMeaningEnglishEditorContentHtml: item.english_example || '',
                    addButton: index === data?.word?.length - 1 ? true : false
                }))

            },
            linkId: data.id,
            title: data.words
        }))
        console.log('asdfasdf',this.state.link.itemList)
    }

    addHtmlToEditor = () => {
        const { editorContentHtml } = this.state
        let editorState
        if (editorContentHtml.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(editorContentHtml))
        } else {
            editorState = EditorState.createEmpty()
        }
        this.setState({ editorState })
    }

    componentDidMount() {
        this.getLessonList()
    }

    getCourseList() {
        Services.getCourses().then((response) => {
            this.setState({ courseList: response })
            this.populateSelectDropdown()
        })
    }

    populateSelectDropdown() {
        let data = this.state.courseList
        data.forEach((item) => {
            this.state.dropdownOptions.push({ value: item.id, label: item.title })
        })
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOption, selectedOptionId: selectedOption.value, loading: true }
        )
        Services.getLessonList(selectedOption.value).then((response) => {
            this.setState({ data: response, loading: false })
        })
        // this.getLessonList()
    }

    onCourseCardClick = selectedOption => {
        this.setState(
            { selectedOption, selectedOptionId: selectedOption.value, loading: true }
        )
        Services.getLessonList(selectedOption.value).then((response) => {
            this.setState({ data: response, loading: false })
        })
    }

    getLessonList() {
        this.setState({ loading: true })
        Services.getDictionary().then((response) => {
            this.setState({ data: response, loading: false })
        })
    }

    handleInputChange(event) {
        event.preventDefault()
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    changeEditorContent(data) {
        this.setState({
            editorState: data
        })
    }


    returnHtmlBlock(text) {
        if (this.state.showEditModal === true) {
            const processedHTML = htmlToDraft(text, customChunkRenderer)
            return ContentState.createFromBlockArray(processedHTML)
        } else {
            const processedHTML = convertFromHTML(text)
            return ContentState.createFromBlockArray(processedHTML)
        }
    }

    handleInitialEditorText(text) {
        let editorState
        if (text.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(text))
        } else {
            editorState = EditorState.createEmpty()
        }
        return editorState
    }

    onEditEditorHTML = (idx, e) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['editorContentHtml'] = e.target.value
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))
        // this.setState({ editorContentHtml: e.target.value })
    }
    toggleEditorCode = async (idx) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['showEditorCode'] = !itemTransaction[idx].showEditorCode
        await this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))
        // this.setState({ showEditorCode: !this.state.showEditorCode })
    }
    returnHtmlBlock = (text) => {
        if (this.state.lessonViewType === true) {
            const processedHTML = htmlToDraft(text, customChunkRenderer)
            return ContentState.createFromBlockArray(processedHTML)
        } else {
            const processedHTML = convertFromHTML(text)
            return ContentState.createFromBlockArray(processedHTML)
        }
    }
    addHtmlToEditor = (idx) => {
        const { editorContentHtml } = this.state.link.itemList[idx]
        let editorState
        if (editorContentHtml.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(editorContentHtml))
        } else {
            editorState = EditorState.createEmpty()
        }
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['editorState'] = editorState
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))
    }

    getUploadParams = ({ meta }) => {
        return { url: 'https://httpbin.org/post' }
    }

    handleChangeStatus(meta, file, idx) {
        // this.setState({image:file})
        let reader = new FileReader()
        reader.onloadend = function() {
            // console.log('RESULT', reader.result)
            // this.setState({ image: reader.result })
            let itemTransaction = [...this.state.link.itemList]
            itemTransaction[idx]['image'] = reader.result
            this.setState(prevState => ({
                ...prevState,
                link: {
                    ...prevState.link,
                    itemList: [...itemTransaction]
                }
            }))
        }.bind(this)
        reader.readAsDataURL(file)
    }


    async updateLesson() {
        let newData = {
            description: this.state.editorContentHtml,
            title: this.state.title,
            is_archived: false
        }

        await Services.updateLink(this.state.id, newData).then((response) => {
            this.getLessonList()
            this.props.enqueueSnackbar('Link Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            this.toggleState('lessonEditModal')
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            })
    }


    //Image selection and cases

    onEditorStateChange = async (editorState, idx, state) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        let itemTransaction = [...this.state.link.itemList]
        if (state === 'detail') {
            itemTransaction[idx]['editorContentHtml'] = draftToHtml(rawContentState)
            itemTransaction[idx]['editorState'] = editorState
        } else if (state === 'nepali') {
            itemTransaction[idx]['exampleMeaningNepaliEditorContentHtml'] = draftToHtml(rawContentState)
            itemTransaction[idx]['exampleMeaningNepaliEditorState'] = editorState
        } else if (state === 'english') {
            itemTransaction[idx]['exampleMeaningEnglishEditorContentHtml'] = draftToHtml(rawContentState)
            itemTransaction[idx]['exampleMeaningEnglishEditorState'] = editorState
        }
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))
        console.log('editor test', this.state.link.itemList)

    }

    uploadImageCallBack = (file) => {
        let token = localStorage.getItem('token')
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest()
                const serverUrl = window.config.apiUrl + 'common/image-upload'
                xhr.open('POST', serverUrl)
                xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                const data = new FormData()
                data.append('image', file)
                xhr.send(data)
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText)
                    resolve(response)
                })
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText)
                    reject(error)
                })
            }
        )
    }

    //Image selection and cases

    getExtension = (filename) => {
        var parts = filename.split('.')
        return parts[parts.length - 1]
    }

    isImage = (filename) => {
        var ext = this.getExtension(filename)
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'gif':
            case 'bmp':
            case 'png':
            case 'jpeg':
                //etc
                return true
        }
        return false
    }

    handleImageSelect = async (e, idx) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let itemTransaction = [...this.state.link.itemList]
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        itemTransaction[idx]['image'] = response.data.link
                        itemTransaction[idx]['imagePreview'] = response.data.link
                        this.setState(prevState => ({
                            ...prevState,
                            link: {
                                itemList: [...itemTransaction]
                            }
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }

    handleYoutubeImageSelect = async (e, idx) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let itemTransaction = [...this.state.link.itemList]
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        itemTransaction[idx]['thumbnail'] = response.data.link
                        this.setState(prevState => ({
                            ...prevState,
                            link: {
                                itemList: [...itemTransaction]
                            }
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }


    handleLinkImageSelect = async (e) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        console.log('asdf', response)
                        this.setState(prevState => ({
                            ...prevState,
                            link_image: response.data.link
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }
    handleLinkImageRemove = async (e) => {
        await this.setState(prevState => ({
            ...prevState,
            link_image: ''
        }))
    }

    handleImageRemove = async (e, idx) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['imagePreview'] = ''
        itemTransaction[idx]['image'] = ''
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))
    }
    handleYoutubeImageRemove = async (e, idx) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['thumbnail'] = null
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))
    }


    handleRemarksOptionChange = (optionArray) => {
        this.setState({ lessonRemarks: optionArray })
    }

    handleCopy = (e) => {
        e.preventDefault()
        console.log(this.state.editorContentHtml)
        const { editorContentHtml, editorState } = this.state
        this.setState({ editorStateCopied: editorState, editorContentHtmlCopied: editorContentHtml })
        this.props.enqueueSnackbar('Lesson data copied!', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
            }
        })
        this.setState({ copyStatus: true })
    }

    handleSynonyms = async (event, idx) => {
        console.log('asdfasdf', event)
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['synonyms'] = event
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))
    }
    handleAntonyms = async (event, idx) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['antonyms'] = event
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))
        console.log('asdf', this.state.link.itemList)
    }


    handleUpdate = () => {
        let newData = {
            id: this.state.linkId,
            words: this.state.title,
            is_archived: false,
            word:
                this.state.link.itemList.map((item, index) => (
                    {
                        meaningantonyms: item.antonyms.map((items) => ({
                            antonyms: items,
                            meaning: 'meaning'

                        })),
                        meaningsynonyms: item.synonyms.map((items) => ({
                            synonyms: items,
                            meaning: 'meaning'
                        })),
                        meaning: item.meaning,
                        part_of_speech: item.partOfSpeech,
                        brepronounce: item.brePronounciation,
                        amepronounce: item.amePronounciation,
                        meaning_english: item.meaningEnglish,
                        meaning_nepali: item.meaningNepali,
                        detailed_meaning: item.editorContentHtml,
                        english_example: item.exampleMeaningEnglishEditorContentHtml,
                        nepali_example: item.exampleMeaningNepaliEditorContentHtml,
                        word: 'hello'
                    }
                ))
        }

        Services.updateDictionary(this.state.link.linkId, newData).then((response) => {
            if (response.status === 200) {
                this.props.enqueueSnackbar('Link Updated Successfully', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
                this.getLessonList()
                this.toggleState('editFormView')

            } else {
                this.props.enqueueSnackbar(response.msg, {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
            }
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            })
    }

    addButtonHandler = (state, idx) => {
        let item = [...this.state.link.itemList]
        item[idx][state] = !this.state.link.itemList[idx][state]
        this.setState((prevState) => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...item]
            }
        }))

    }

    handlePaste = async (e) => {
        e.preventDefault()
        console.log('content html hai', this.state.editorContentHtml)
        this.setState({
            editorState: this.state.editorStateCopied,
            editorContentHtml: this.state.editorContentHtmlCopied
        })
        this.setState({ copyStatus: false })
    }

    handleCreateLessonOpen = () => {
        this.toggleState('passwordModal')
        this.toggleState('addFormView')
        this.toggleState('courseSelectContainer')
        this.setState({ masterPassword: '' })

    }

    toggleEditorCode() {
        this.setState({ showEditorCode: !this.state.showEditorCode })
    }


    render() {
        const ShowEditorCode = () => (
            <div className='rdw-option-wrapper' onClick={this.toggleEditorCode}>
                {this.state.showEditorCode
                    ? 'Hide Code'
                    : 'Show Code'}
            </div>
        )
        // var myTextIcon = React.useRef(null);
        const options = this.state.dropdownOptions
        return (
            <>
                <Header page='lesson' />
                <div className='mt-5 container-fluid'>
                    <Row>
                        <div className=' col'>
                            <Card className=' shadow'>

                                {!(this.state.addFormView) && !this.state.editFormView ?
                                    this.state.loading ?
                                        <CircularProgress style={{
                                            margin: 50,
                                            alignSelf: 'center'
                                        }} />
                                        :

                                        <MaterialTable
                                            style={{ zIndex: '1', position: '' }}
                                            columns={[
                                                { title: 'Dictionary Word', field: 'words' },
                                                { title: 'Words', field: 'words' }
                                            ]}
                                            data={this.state.data}
                                            title='Dictionary'
                                            detailPanel={rowData => {
                                                return (
                                                    <>
                                                        <p style={{
                                                            padding: 20
                                                        }}>{ReactHtmlParser(rowData.description)}</p>
                                                    </>
                                                )
                                            }}
                                            actions={[
                                                {
                                                    icon: 'delete',
                                                    tooltip: 'Delete Dictionary Data ',
                                                    onClick: (event, rowData) => {
                                                        // Do save operation
                                                        // Services.deleteCourse(rowData.id).then((response) =>{
                                                        //     this.getCourseList()
                                                        // })
                                                        this.setState({ confirmModal: true, selectedRow: rowData })
                                                    }
                                                },
                                                {
                                                    icon: () => <button className='addNewLessonButton'>Create
                                                        New Words</button>,
                                                    tooltip: 'Add Lesson',
                                                    isFreeAction: true,
                                                    onClick: (event) => {
                                                        this.toggleState('passwordModal')
                                                    }
                                                },
                                                {
                                                    icon: 'info',
                                                    tooltip: 'Details',
                                                    isFreeAction: false,
                                                    onClick: (event, rowData) => {
                                                        this.updateEditState(rowData)
                                                        this.toggleState('editFormView')
                                                    }
                                                }
                                            ]}


                                            editable={{

                                                onRowUpdate: (newData, oldData) =>

                                                    new Promise((resolve, reject) => {
                                                        let newRowUpdate = {
                                                            word: newData.words,
                                                            is_archived: false
                                                        }
                                                        setTimeout(() => {
                                                            {
                                                                Services.updateLink(oldData.id, newRowUpdate).then((response) => {
                                                                    this.getLessonList()
                                                                    this.props.enqueueSnackbar('Free Lesson Updated Successfully', {
                                                                        variant: 'success',
                                                                        anchorOrigin: {
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right'
                                                                        }
                                                                    })
                                                                })
                                                                    .catch((error) => {
                                                                        this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                                                variant: 'warning',
                                                                                anchorOrigin: {
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'right'
                                                                                }
                                                                            }
                                                                        )
                                                                    })
                                                            }
                                                            resolve()
                                                        }, 300)
                                                    })
                                            }}
                                            detailPanel={rowData => {
                                                return (
                                                    <>
                                                        <span>{rowData.words}</span>
                                                        {rowData && rowData.word.map((item, idx) => (
                                                            <span></span>
                                                            // <>
                                                            //     <div style={{
                                                            //         border: '2px solid lightgrey',
                                                            //         marginTop: '10px',
                                                            //         padding: '10px'
                                                            //     }} key={idx}>
                                                            //
                                                            //         <Row>
                                                            //             <Col xl={3}>
                                                            //                 <FormGroup style={{
                                                            //                     marginBottom: '0px',
                                                            //                     display: 'flex',
                                                            //                     flexDirection: 'column',
                                                            //                     justifyContent: 'center'
                                                            //                 }}>
                                                            //                     <InputGroup
                                                            //                         className='input-group-alternative mb-3'>
                                                            //                         <InputGroupAddon addonType='prepend'>
                                                            //                             <InputGroupText>
                                                            //                                 <i className='ni ni-hat-3' />
                                                            //                             </InputGroupText>
                                                            //                         </InputGroupAddon>
                                                            //                         <Input placeholder='Meaning' type='text'
                                                            //                                name='meaning'
                                                            //                                value={item.meaning}
                                                            //                                onChange={(event) => this.editItem(event, idx)} />
                                                            //                     </InputGroup>
                                                            //                 </FormGroup>
                                                            //             </Col>
                                                            //             <Col xl={3}>
                                                            //                 <FormGroup style={{
                                                            //                     marginBottom: '0px',
                                                            //                     display: 'flex',
                                                            //                     flexDirection: 'column',
                                                            //                     justifyContent: 'space-between'
                                                            //                 }}>
                                                            //                     <Input placeHolder={'Select Part Of Speech'}
                                                            //                            type={'select'}
                                                            //                            name={'partOfSpeech'}
                                                            //                            value={item.part_of_speech}
                                                            //                            onChange={(event) => this.editItem(event, idx)}>
                                                            //                         <option>Select Parts Of Speech</option>
                                                            //                         <option value={'noun'}>Noun</option>
                                                            //                         <option value={'Pronoun'}>Pronoun</option>
                                                            //                         <option value={'verb'}>Verb</option>
                                                            //                         <option value={'article'}>Article</option>
                                                            //                         <option value={'adjective'}>Adjective
                                                            //                         </option>
                                                            //                         <option value={'preposition'}>Preposition
                                                            //                         </option>
                                                            //                         <option value={'conjunction'}>Conjunction
                                                            //                         </option>
                                                            //                         <option value={'adverb'}>Adverb</option>
                                                            //                     </Input>
                                                            //                 </FormGroup>
                                                            //             </Col>
                                                            //             <Col xl={3}>
                                                            //                 <FormGroup style={{
                                                            //                     marginBottom: '0px',
                                                            //                     display: 'flex',
                                                            //                     flexDirection: 'column',
                                                            //                     justifyContent: 'center'
                                                            //                 }}>
                                                            //                     <InputGroup
                                                            //                         className='input-group-alternative mb-3'>
                                                            //                         <InputGroupAddon addonType='prepend'>
                                                            //                             <InputGroupText>
                                                            //                                 <i className='ni ni-hat-3' />
                                                            //                             </InputGroupText>
                                                            //                         </InputGroupAddon>
                                                            //                         <Input placeholder='BrE Pronunciation'
                                                            //                                type='text' name='brePronounciation'
                                                            //                                value={item.breprounce}
                                                            //                                onChange={(event) => this.editItem(event, idx)} />
                                                            //                     </InputGroup>
                                                            //                 </FormGroup>
                                                            //             </Col>
                                                            //             <Col xl={3}>
                                                            //                 <FormGroup style={{
                                                            //                     marginBottom: '0px',
                                                            //                     display: 'flex',
                                                            //                     flexDirection: 'column',
                                                            //                     justifyContent: 'center'
                                                            //                 }}>
                                                            //                     <InputGroup
                                                            //                         className='input-group-alternative mb-3'>
                                                            //                         <InputGroupAddon addonType='prepend'>
                                                            //                             <InputGroupText>
                                                            //                                 <i className='ni ni-hat-3' />
                                                            //                             </InputGroupText>
                                                            //                         </InputGroupAddon>
                                                            //                         <Input placeholder='AmE Pronunciation'
                                                            //                                type='text' name='amePronounciation'
                                                            //                                value={item.amepronunce}
                                                            //                                onChange={(event) => this.editItem(event, idx)} />
                                                            //                     </InputGroup>
                                                            //                 </FormGroup>
                                                            //             </Col>
                                                            //         </Row>
                                                            //         <Row>
                                                            //             <Col xl={6}>
                                                            //                 <FormGroup style={{
                                                            //                     marginBottom: '0px',
                                                            //                     display: 'flex',
                                                            //                     flexDirection: 'column',
                                                            //                     justifyContent: 'center'
                                                            //                 }}>
                                                            //                     <Input rows='3' placeholder='Meaning In English'
                                                            //                            type='textarea'
                                                            //                            name='meaningEnglish'
                                                            //                            value={item.meaning_english}
                                                            //                            onChange={(event) => this.editItem(event, idx)} />
                                                            //                 </FormGroup>
                                                            //             </Col>
                                                            //             <Col xl={6}>
                                                            //                 <FormGroup style={{
                                                            //                     marginBottom: '0px',
                                                            //                     display: 'flex',
                                                            //                     flexDirection: 'column',
                                                            //                     justifyContent: 'center'
                                                            //                 }}>
                                                            //                     <Input rows='3' placeholder='Meaning In Nepali'
                                                            //                            type='textarea'
                                                            //                            name='meaningNepali'
                                                            //                            value={item.meaning_nepali}
                                                            //                            onChange={(event) => this.editItem(event, idx)} />
                                                            //                 </FormGroup>
                                                            //             </Col>
                                                            //         </Row>
                                                            //         <Row>
                                                            //             <Col xl={12}>
                                                            //                 <FormGroup style={{
                                                            //                     marginTop: '12px',
                                                            //                     marginBottom: '12px',
                                                            //                     display: 'flex',
                                                            //                     flexDirection: 'column',
                                                            //                     justifyContent: 'center'
                                                            //                 }}>
                                                            //                     <Editor
                                                            //                         key={idx}
                                                            //                         handlePastedText={() => false}
                                                            //                         editorStyle={{
                                                            //                             border: '1px solid black',
                                                            //                             padding: '5px',
                                                            //                             borderRadius: '2px',
                                                            //                             height: '200px',
                                                            //                             width: '100%'
                                                            //                         }}
                                                            //                         placeholder='Detail Meaning Of English And Nepali'
                                                            //                         editorState={item.editorState}
                                                            //                         wrapperClassName='demo-wrapper'
                                                            //                         editorClassName='demo-editor'
                                                            //                         onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, idx, 'detail')}
                                                            //                         toolbar={{
                                                            //                             inline: { inDropdown: true },
                                                            //                             list: { inDropdown: true },
                                                            //                             textAlign: { inDropdown: true },
                                                            //                             link: { inDropdown: true },
                                                            //                             history: { inDropdown: true },
                                                            //                             image: {
                                                            //                                 uploadCallback: (file) => this.uploadImageCallBack(file),
                                                            //                                 previewImage: true,
                                                            //                                 defaultSize: {
                                                            //                                     width: '100%',
                                                            //                                     height: 'auto'
                                                            //                                 }
                                                            //                             }
                                                            //                         }}
                                                            //                     />
                                                            //                 </FormGroup>
                                                            //             </Col>
                                                            //         </Row>
                                                            //         <Row>
                                                            //             <Col xl={6}>
                                                            //                 <FormGroup style={{
                                                            //                     marginTop: '12px',
                                                            //                     marginBottom: '12px',
                                                            //                     display: 'flex',
                                                            //                     flexDirection: 'column',
                                                            //                     justifyContent: 'center'
                                                            //                 }}>
                                                            //                     <Editor
                                                            //                         key={idx}
                                                            //                         handlePastedText={() => false}
                                                            //                         editorStyle={{
                                                            //                             border: '1px solid black',
                                                            //                             padding: '5px',
                                                            //                             borderRadius: '2px',
                                                            //                             height: '200px',
                                                            //                             width: '100%'
                                                            //                         }}
                                                            //                         placeholder='Examples For English'
                                                            //                         editorState={item.exampleMeaningEnglishEditorState}
                                                            //                         wrapperClassName='demo-wrapper'
                                                            //                         editorClassName='demo-editor'
                                                            //                         onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, idx, 'english')}
                                                            //                         toolbar={{
                                                            //                             inline: { inDropdown: true },
                                                            //                             list: { inDropdown: true },
                                                            //                             textAlign: { inDropdown: true },
                                                            //                             link: { inDropdown: true },
                                                            //                             history: { inDropdown: true },
                                                            //                             image: {
                                                            //                                 uploadCallback: (file) => this.uploadImageCallBack(file),
                                                            //                                 previewImage: true,
                                                            //                                 defaultSize: {
                                                            //                                     width: '100%',
                                                            //                                     height: 'auto'
                                                            //                                 }
                                                            //                             }
                                                            //                         }}
                                                            //                     />
                                                            //                 </FormGroup>
                                                            //             </Col>
                                                            //             <Col xl={6}>
                                                            //                 <FormGroup style={{
                                                            //                     marginTop: '12px',
                                                            //                     marginBottom: '12px',
                                                            //                     display: 'flex',
                                                            //                     flexDirection: 'column',
                                                            //                     justifyContent: 'center'
                                                            //                 }}>
                                                            //                     <Editor
                                                            //                         key={idx}
                                                            //                         handlePastedText={() => false}
                                                            //                         editorStyle={{
                                                            //                             border: '1px solid black',
                                                            //                             padding: '5px',
                                                            //                             borderRadius: '2px',
                                                            //                             height: '200px',
                                                            //                             width: '100%'
                                                            //                         }}
                                                            //                         placeholder='Examples For Nepali'
                                                            //                         editorState={item.exampleMeaningNepaliEditorState}
                                                            //                         wrapperClassName='demo-wrapper'
                                                            //                         editorClassName='demo-editor'
                                                            //                         onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, idx, 'nepali')}
                                                            //                         toolbar={{
                                                            //                             inline: { inDropdown: true },
                                                            //                             list: { inDropdown: true },
                                                            //                             textAlign: { inDropdown: true },
                                                            //                             link: { inDropdown: true },
                                                            //                             history: { inDropdown: true },
                                                            //                             image: {
                                                            //                                 uploadCallback: (file) => this.uploadImageCallBack(file),
                                                            //                                 previewImage: true,
                                                            //                                 defaultSize: {
                                                            //                                     width: '100%',
                                                            //                                     height: 'auto'
                                                            //                                 }
                                                            //                             }
                                                            //                         }}
                                                            //                     />
                                                            //                 </FormGroup>
                                                            //             </Col>
                                                            //         </Row>
                                                            //         <Row>
                                                            //             <Col xl={6}>
                                                            //                 <FormGroup className={'input-array'}>
                                                            //                     <TagsInput
                                                            //                         value={item?.meaningsynonyms}
                                                            //                         onChange={(event) => this.handleSynonyms(event, idx)}
                                                            //                         name='synonyms'
                                                            //                         placeHolder='Enter Synonyms'
                                                            //                     />
                                                            //                 </FormGroup>
                                                            //             </Col>
                                                            //             <Col xl={6}>
                                                            //                 <FormGroup className='input-array'>
                                                            //                     <TagsInput
                                                            //                         className={'aaa'}
                                                            //                         name={'antonyms'}
                                                            //                         value={item?.meaningantonyms}
                                                            //                         onChange={(event) => this.handleAntonyms(event, idx)}
                                                            //                         placeHolder='Enter Antonyms'
                                                            //                     />
                                                            //                 </FormGroup>
                                                            //             </Col>
                                                            //         </Row>
                                                            //     </div>
                                                            // </>
                                                        ))}
                                                    </>
                                                )
                                            }}
                                            options={{
                                                //   exportButton: true,
                                                // exportAllData: true,
                                                actionsColumnIndex: -1,
                                                pageSize: 20,
                                                pageSizeOptions: [20, 50, 100],
                                                headerStyle: {
                                                    backgroundColor: '#6772E5',
                                                    color: '#FFF',
                                                    searchFieldAlignment: 'left'
                                                }

                                            }}
                                            components={{
                                                Toolbar: props => (
                                                    <div>
                                                        <MTableToolbar {...props} />
                                                    </div>
                                                )
                                            }}
                                        />
                                    :
                                    <>
                                        {this.state.addFormView && !(this.state.editFormView) ?
                                            <>
                                                <div className='d-flex mt-3'>
                                                    <div className='mr-auto ml-4 mt-2'><h2>Dictionary</h2></div>
                                                    <div className='p-2'>
                                                        <Button className='btn-icon btn-3' color='primary' type='button'
                                                                onClick={() => {
                                                                    this.toggleState('addFormView')
                                                                    this.toggleState('courseSelectContainer')
                                                                }}>
                                        <span className='btn-inner--icon'>
                                            <i className='ni ni-bag-17' />
                                        </span>
                                                            <span className='btn-inner--text'>Back</span>
                                                        </Button>
                                                    </div>
                                                </div>
                                                <hr className='solid'></hr>
                                                <DictionaryForm copyStatus={this.state.copyStatus}
                                                                editorState={this.state.editorStateCopied}
                                                                editorContentHtml={this.state.editorContentHtmlCopied}
                                                                onSuccess={(id, selectedOption) => {
                                                                    this.setState({
                                                                        addFormView: false,
                                                                        selectedOption: selectedOption
                                                                    })
                                                                    this.getLessonList()
                                                                    this.toggleState('courseSelectContainer')
                                                                }}></DictionaryForm>
                                            </> :
                                            <>
                                                <div className='d-flex mt-3'>
                                                    <div className='mr-auto ml-4 mt-2'><h2>Update Dictionary</h2></div>
                                                    <div className='p-2'>
                                                        <Button className='btn-icon btn-3' color='primary' type='button'
                                                                onClick={() => {
                                                                    this.toggleState('editFormView')
                                                                }}>
                                        <span className='btn-inner--icon'>
                                            <i className='ni ni-bag-17' />
                                        </span>
                                                            <span className='btn-inner--text'>Back</span>
                                                        </Button>
                                                    </div>
                                                </div>

                                                <hr className='solid'></hr>
                                                <Form encType='multipart/form-data' role='form' style={{
                                                    paddingLeft: 50,
                                                    paddingRight: 20
                                                    // marginTop: '100px'
                                                }}>
                                                    <Row>
                                                        <Col xl={12} style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <FormGroup style={{
                                                                marginBottom: '0px',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                width: '30%',
                                                                alignItems: 'center'
                                                            }}>
                                                                <InputGroup className='input-group-alternative mb-3'>
                                                                    <InputGroupAddon addonType='prepend'>
                                                                        <InputGroupText>
                                                                            <i className='ni ni-hat-3' />
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input placeholder='Main Word' type='text'
                                                                           name='title'
                                                                           value={this.state.title}
                                                                           onChange={this.handleInputChange} />
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {this.state?.link?.itemList && this.state?.link?.itemList?.map((item, idx) => (
                                                        <div style={{
                                                            border: '2px solid lightgrey',
                                                            marginTop: '10px',
                                                            padding: '10px'
                                                        }} key={idx}>

                                                            <Row>
                                                                <Col xl={3}>
                                                                    <FormGroup style={{
                                                                        marginBottom: '0px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <InputGroup
                                                                            className='input-group-alternative mb-3'>
                                                                            <InputGroupAddon addonType='prepend'>
                                                                                <InputGroupText>
                                                                                    <i className='ni ni-hat-3' />
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input placeholder='Meaning' type='text'
                                                                                   name='meaning'
                                                                                   value={item.meaning}
                                                                                   onChange={(event) => this.editItem(event, idx)} />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col xl={3}>
                                                                    <FormGroup style={{
                                                                        marginBottom: '0px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'space-between'
                                                                    }}>
                                                                        <Input placeHolder={'Select Part Of Speech'}
                                                                               type={'select'}
                                                                               name={'partOfSpeech'}
                                                                               value={item.partOfSpeech}
                                                                               onChange={(event) => this.editItem(event, idx)}>
                                                                            <option>Select Parts Of Speech</option>
                                                                            <option value={'noun'}>Noun</option>
                                                                            <option value={'Pronoun'}>Pronoun</option>
                                                                            <option value={'verb'}>Verb</option>
                                                                            <option value={'article'}>Article</option>
                                                                            <option value={'adjective'}>Adjective
                                                                            </option>
                                                                            <option value={'preposition'}>Preposition
                                                                            </option>
                                                                            <option value={'conjunction'}>Conjunction
                                                                            </option>
                                                                            <option value={'adverb'}>Adverb</option>
                                                                        </Input>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col xl={3}>
                                                                    <FormGroup style={{
                                                                        marginBottom: '0px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <InputGroup
                                                                            className='input-group-alternative mb-3'>
                                                                            <InputGroupAddon addonType='prepend'>
                                                                                <InputGroupText>
                                                                                    <i className='ni ni-hat-3' />
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input placeholder='BrE Pronunciation'
                                                                                   type='text' name='brePronounciation'
                                                                                   value={item.brePronounciation}
                                                                                   onChange={(event) => this.editItem(event, idx)} />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col xl={3}>
                                                                    <FormGroup style={{
                                                                        marginBottom: '0px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <InputGroup
                                                                            className='input-group-alternative mb-3'>
                                                                            <InputGroupAddon addonType='prepend'>
                                                                                <InputGroupText>
                                                                                    <i className='ni ni-hat-3' />
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input placeholder='AmE Pronunciation'
                                                                                   type='text' name='amePronounciation'
                                                                                   value={item.amePronounciation}
                                                                                   onChange={(event) => this.editItem(event, idx)} />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl={6}>
                                                                    <FormGroup style={{
                                                                        marginBottom: '0px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <Input rows='3' placeholder='Meaning In English'
                                                                               type='textarea'
                                                                               name='meaningEnglish'
                                                                               value={item.meaningEnglish}
                                                                               onChange={(event) => this.editItem(event, idx)} />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col xl={6}>
                                                                    <FormGroup style={{
                                                                        marginBottom: '0px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <Input rows='3' placeholder='Meaning In Nepali'
                                                                               type='textarea'
                                                                               name='meaningNepali'
                                                                               value={item.meaningNepali}
                                                                               onChange={(event) => this.editItem(event, idx)} />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl={12}>
                                                                    <FormGroup style={{
                                                                        marginTop: '12px',
                                                                        marginBottom: '12px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <Editor
                                                                            key={idx}
                                                                            handlePastedText={() => false}
                                                                            editorStyle={{
                                                                                border: '1px solid black',
                                                                                padding: '5px',
                                                                                borderRadius: '2px',
                                                                                height: '200px',
                                                                                width: '100%'
                                                                            }}
                                                                            placeholder='Detail Meaning Of English And Nepali'
                                                                            editorState={item.editorState}
                                                                            wrapperClassName='demo-wrapper'
                                                                            editorClassName='demo-editor'
                                                                            onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, idx, 'detail')}
                                                                            toolbar={{
                                                                                inline: { inDropdown: true },
                                                                                list: { inDropdown: true },
                                                                                textAlign: { inDropdown: true },
                                                                                link: { inDropdown: true },
                                                                                history: { inDropdown: true },
                                                                                image: {
                                                                                    uploadCallback: (file) => this.uploadImageCallBack(file),
                                                                                    previewImage: true,
                                                                                    defaultSize: {
                                                                                        width: '100%',
                                                                                        height: 'auto'
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl={6}>
                                                                    <FormGroup style={{
                                                                        marginTop: '12px',
                                                                        marginBottom: '12px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <Editor
                                                                            key={idx}
                                                                            handlePastedText={() => false}
                                                                            editorStyle={{
                                                                                border: '1px solid black',
                                                                                padding: '5px',
                                                                                borderRadius: '2px',
                                                                                height: '200px',
                                                                                width: '100%'
                                                                            }}
                                                                            placeholder='Examples For English'
                                                                            editorState={item.exampleMeaningEnglishEditorState}
                                                                            wrapperClassName='demo-wrapper'
                                                                            editorClassName='demo-editor'
                                                                            onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, idx, 'english')}
                                                                            toolbar={{
                                                                                inline: { inDropdown: true },
                                                                                list: { inDropdown: true },
                                                                                textAlign: { inDropdown: true },
                                                                                link: { inDropdown: true },
                                                                                history: { inDropdown: true },
                                                                                image: {
                                                                                    uploadCallback: (file) => this.uploadImageCallBack(file),
                                                                                    previewImage: true,
                                                                                    defaultSize: {
                                                                                        width: '100%',
                                                                                        height: 'auto'
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col xl={6}>
                                                                    <FormGroup style={{
                                                                        marginTop: '12px',
                                                                        marginBottom: '12px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <Editor
                                                                            key={idx}
                                                                            handlePastedText={() => false}
                                                                            editorStyle={{
                                                                                border: '1px solid black',
                                                                                padding: '5px',
                                                                                borderRadius: '2px',
                                                                                height: '200px',
                                                                                width: '100%'
                                                                            }}
                                                                            placeholder='Examples For Nepali'
                                                                            editorState={item.exampleMeaningNepaliEditorState}
                                                                            wrapperClassName='demo-wrapper'
                                                                            editorClassName='demo-editor'
                                                                            onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, idx, 'nepali')}
                                                                            toolbar={{
                                                                                inline: { inDropdown: true },
                                                                                list: { inDropdown: true },
                                                                                textAlign: { inDropdown: true },
                                                                                link: { inDropdown: true },
                                                                                history: { inDropdown: true },
                                                                                image: {
                                                                                    uploadCallback: (file) => this.uploadImageCallBack(file),
                                                                                    previewImage: true,
                                                                                    defaultSize: {
                                                                                        width: '100%',
                                                                                        height: 'auto'
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl={6}>
                                                                    <FormGroup className={'input-array'}>
                                                                        <TagsInput
                                                                            value={item?.synonyms}
                                                                            onChange={(event) => this.handleSynonyms(event, idx)}
                                                                            name='synonyms'
                                                                            placeHolder='Enter Synonyms'
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col xl={6}>
                                                                    <FormGroup className='input-array'>
                                                                        <TagsInput
                                                                            className={'aaa'}
                                                                            name={'antonyms'}
                                                                            value={item?.antonyms}
                                                                            onChange={(event) => this.handleAntonyms(event, idx)}
                                                                            placeHolder='Enter Antonyms'
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl={12} className={'mt-3'}
                                                                     style={{ paddingLeft: '30px' }}>
                                                                    <Button onClick={() => this.removeItem(idx)}
                                                                            className={'btn btn-danger'}><i
                                                                        className={'fa fa-minus'} />&nbsp;&nbsp;Remove
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    ))}
                                                    <Row className={'mt-2'}>
                                                        <Col xl={12}>
                                                            <Button onClick={() => this.addItem()}
                                                                    className={'mb-2 btn btn-success'}><i
                                                                className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                                                        </Col>
                                                    </Row>
                                                    <div className='pb-4'>
                                                        <Button className='mt-4' color='primary' type='button'
                                                                onClick={this.handleUpdate}>
                                                            Update
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </>
                                        }
                                    </>
                                }
                            </Card>
                        </div>
                    </Row>
                </div>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.confirmModal}
                    toggle={() => this.toggleState('confirmModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Are you sure you want to delete?</h4>
                        <Form>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                                onClick={
                                    () => {
                                        Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                            Services.deleteDictionary(this.state.selectedRow.id).then((response) => {
                                                this.getLessonList()
                                                this.toggleState('confirmModal')
                                                this.props.enqueueSnackbar('Successfully deleted', {
                                                        variant: 'success',
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'right'
                                                        }
                                                    }
                                                )
                                            })
                                                .catch((error) => {
                                                    this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                            variant: 'warning',
                                                            anchorOrigin: {
                                                                vertical: 'bottom',
                                                                horizontal: 'right'
                                                            }
                                                        }
                                                    )
                                                })

                                        }).catch((error) => {
                                            this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                                    variant: 'warning',
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }
                                                }
                                            )
                                        })


                                    }
                                }>
                            Yes
                        </Button>
                    </div>
                </Modal>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.passwordModal}
                    toggle={() => this.toggleState('passwordModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Enter password to perform this action.</h4>
                        <Form onSubmit={
                            (e) => {
                                e.preventDefault()
                                Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                    this.handleCreateLessonOpen()
                                }).catch((error) => {
                                    this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                    )
                                })
                            }}>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' onClick={
                            () => {
                                Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                    this.handleCreateLessonOpen()
                                }).catch((error) => {
                                    this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                    )
                                })
                            }
                        }>
                            Enter
                        </Button>
                    </div>
                </Modal>
            </>

        )
    }
}

export default withSnackbar(Dictionary)