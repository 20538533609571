import React, { useState, useEffect } from 'react'
import Header from 'components/Headers/Header.js'
import { withSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'
import MaterialTable, { MTableToolbar } from 'material-table'
import {
    Card,
    Container,
    Row,
    Button,
    Modal,
    Form,
    Input, Col
} from 'reactstrap'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import './PaymentPage.css'

const Services = require('../../RemoteServices/RemoteServices.js')


const PaymentPage = (props) => {

    let [paymentLoading, setPaymentLoading] = useState(true)
    let [rowData, setRowData] = useState({})
    let [approveModal, toggleApproveModal] = useState(false)
    let [detailsModal, toggleDetailsModal] = useState(false)
    let [billNumber, setBillNumber] = useState('')
    let [paymentData, setPaymentData] = useState([])
    let [showPayment, setShowPayment] = useState('new')


    useEffect(() => {
        fetchPayments(true)
    }, [])


    const handlePaymentsToFetchChange = (e) => {
        const { name } = e.target
        // eslint-disable-next-line no-lone-blocks
        {
            name === 'new' ? showPaymentType('new', true) : showPaymentType('history', false)
        }
    }

    const showPaymentType = (statusWord, publishStatus) => {
        setPaymentLoading(true)
        setShowPayment(statusWord)
        fetchPayments(publishStatus)
    }

    const fetchPayments = (publishStat) => {
        publishStat ?
            Services.getPaymentHistory().then((response) => {
                setPaymentData(response)
                setPaymentLoading(false)
            }).catch((error) => {
                console.log(error)
            })

            :

            Services.getBillHistory().then((response) => {
                setPaymentData(response)
                setPaymentLoading(false)
            }).catch((error) => {
                console.log(error)
            })
    }

    const handleApprove = () => {
        let data = {
            bill_no: billNumber,
            payment: rowData.id
        }
        Services.approveStudentBill(data).then((response) => {
            props.enqueueSnackbar('Bill Approved Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }
            )
            fetchPayments(true)
            toggleApproveModal(false)
            setBillNumber('')
        }).catch((error) => {
            props.enqueueSnackbar('Something went wrong. Please try again later.', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }
            )
        })
    }

    return (
        <>
            <Header page='lesson' />
            <Container className='mt--7'>
                <div className='paymentPageContainer'>
                    <div className='paymentSwitchButtonDiv'>
                        <button onClick={(e) => handlePaymentsToFetchChange(e)} name='new'
                            className={`paymentButtonNew ${showPayment === 'new' ? 'active' : ''} `}>New Payment
                        </button>
                        <button onClick={(e) => handlePaymentsToFetchChange(e)} name='history'
                            className={`paymentButtonHistory ${showPayment === 'history' ? 'active' : ''}`}>History
                        </button>
                    </div>
                    {paymentLoading ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress style={{
                            margin: 50,
                            alignSelf: 'center'
                        }} /></div> :
                        <div className='paymentTableContainer'>
                            <MaterialTable
                                title='Payments'
                                columns={[
                                    {
                                        title: 'Date',
                                        field: 'paid_at',
                                        render: rowData => <> {moment(rowData.paid_at).format('dddd, MMMM Do YYYY, h:mm:ss a')}</>
                                    },
                                    {
                                        title: 'Name', field: 'user_name',
                                        render: rowData => <span>{rowData.user_name || rowData.full_name}
                                            &nbsp;
                                            {rowData?.email_verified && <i style={{ color: 'blue', fontSize: 18 }} className={' fa fa-check-circle'}></i>}
                                        </span>
                                    },
                                    { title: 'User ID', field: 'id' },
                                    {
                                        title: 'Amount',
                                        field: 'amount_paid',
                                        render: rowData => <b>{rowData.amount_paid}</b>
                                    },
                                    { title: 'Party', field: 'payment_method' }
                                ]}
                                data={paymentData}
                                actions={[
                                    // {
                                    //   icon: 'check_circle',
                                    //   tooltip: 'Approve Payment',
                                    //   onClick: (event, rowData) => {
                                    //     setRowData(rowData)
                                    //     toggleApproveModal(true)
                                    //   },
                                    //   disabled: showPayment === 'history'
                                    // },
                                    {
                                        icon: 'visibility',
                                        tooltip: 'View',
                                        onClick: async (event, rowData) => {
                                            setRowData(rowData)
                                            toggleDetailsModal(true)
                                        }
                                        // disabled: showPayment === 'new'
                                    }
                                ]}
                                options={{
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: '#6772E5',
                                        color: '#FFF'
                                    }
                                }}

                            />
                        </div>
                    }
                </div>
            </Container>
            <Modal
                size='lg'
                className='modal-dialog-centered paymentDetailModal'
                isOpen={approveModal}
                toggle={() => toggleApproveModal(!approveModal)}
                style={{ maxWidth: '1000px', width: '100%' }}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Approve Bill
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleApproveModal(!approveModal)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body'>
                    <div className={'payment-transaction'} style={{ width: '100%' }}>
                        <Col xs={12} className='mb-3'>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                gap: '40px'
                            }}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row',
                                    borderBottom: '1px solid lightgrey',
                                    width: '100%'
                                }}>
                                    <h2>Transaction Details</h2>
                                </div>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    width: '100%'
                                }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Transaction Code</span>
                                        <span>{rowData?.transaction_id}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Date/Time</span>
                                        <span>{moment(rowData?.paid_at).format('DD/MM/YYYY hh:mm:ss')}</span>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                        <span className={'title'}>Service Name</span>
                                        <span>Basic-Advanced English</span>
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start'
                                }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Full Name</span>
                                        <span>{rowData?.full_name}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Email</span>
                                        <span>{rowData?.email}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                        <span className={'title'}>Phone Number</span>
                                        <span>{rowData?.phone_number}</span>
                                    </div>

                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'fiex-start'
                                }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Total Amount</span>
                                        <span>Rs. {rowData?.actual_amount}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Vat Amount</span>
                                        <span>Rs. {rowData?.vat_amount}</span>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                        <span className={'title'}>Grand Total</span>
                                        <span>Rs. {rowData?.amount_paid}</span>
                                    </div>

                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start'
                                }}>
                                    {/*<div style={{display: 'flex', flexDirection: 'column', width: '35%'}}>*/}
                                    {/*    <span>Transaction Amount</span>*/}
                                    {/*    <span>Rs. {rowData.paymentData.amount_paid}</span>*/}
                                    {/*</div>*/}
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Status</span>
                                        <span><i className={'fa fa-check-circle'}
                                            style={{ color: 'green' }} />&nbsp; Success</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Payment Method</span>
                                        <span>{rowData?.payment_method}</span>
                                    </div>
                                </div>

                            </div>
                            <Form className={'mt-4'}>
                                <Input
                                    id='exampleFormControlTextarea1'
                                    placeholder='Bill Number'
                                    rows='3'
                                    type='text'
                                    name='billno' value={billNumber} onChange={(e) => setBillNumber(e.target.value)}
                                />
                            </Form>
                        </Col>
                    </div>

                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleApproveModal(false)}
                    >
                        Close
                    </Button>
                    <Button color='primary' type='button'
                        onClick={
                            () => {
                                handleApprove()
                            }
                        }>
                        Approve
                    </Button>
                </div>
            </Modal>


            {/* ------------------------------------------- */}
            <Modal
                size='lg'
                className='modal-dialog-centered paymentDetailModal'
                isOpen={detailsModal}
                toggle={() => toggleDetailsModal(!detailsModal)}
                style={{ maxWidth: '1000px', width: '100%' }}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Approved Bill Details
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleDetailsModal(!detailsModal)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body'>
                    <div className={'payment-transaction'} style={{ width: '100%' }}>
                        <Col xs={12} className='mb-3'>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                gap: '40px'
                            }}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row',
                                    borderBottom: '1px solid lightgrey',
                                    width: '100%'
                                }}>
                                    <h2>Transaction Details</h2>
                                </div>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start'
                                }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                                        <span className={'title'}>Full Name</span>
                                        <span>{rowData?.full_name || rowData?.user_name || ''}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '10%' }}>
                                        <span className={'title'}>User Id</span>
                                        <span>{rowData.user}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Email</span>
                                        <span>{rowData?.email}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                        <span className={'title'}>Phone Number</span>
                                        <span>{rowData?.phone_number}</span>
                                    </div>

                                </div>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    width: '100%'
                                }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Transaction Code</span>
                                        <span>{rowData?.transaction_id}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Date/Time</span>
                                        <span>{moment(rowData?.paid_at).format('DD/MM/YYYY hh:mm:ss')}</span>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                        <span className={'title'}>Service Name</span>
                                        <span>Basic-Advanced English</span>
                                    </div>
                                </div>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'fiex-start'
                                }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Total Amount</span>
                                        <span>Rs.  {((rowData?.amount_paid) - ((rowData.amount_paid * 13) / 113)).toFixed(2)}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Vat Amount</span>
                                        <span>Rs. {((rowData.amount_paid * 13) / 113).toFixed(2)}</span>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                        <span className={'title'}>Grand Total</span>
                                        <span>Rs. {rowData?.amount_paid?.toFixed(2)}</span>
                                    </div>

                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start'
                                }}>
                                    {/*<div style={{display: 'flex', flexDirection: 'column', width: '35%'}}>*/}
                                    {/*    <span>Transaction Amount</span>*/}
                                    {/*    <span>Rs. {rowData.paymentData.amount_paid}</span>*/}
                                    {/*</div>*/}
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Status</span>
                                        <span><i className={'fa fa-check-circle'}
                                            style={{ color: 'green' }} />&nbsp; Success</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                        <span className={'title'}>Payment Method</span>
                                        <span>{rowData?.payment_method}</span>
                                    </div>
                                    {showPayment !== 'new' &&
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                            <span className={'title'}>Bill No.</span>
                                            <span>{rowData?.bill_no}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            {showPayment === 'new' &&
                                <Form className={'mt-4'}>
                                    <Input
                                        id='exampleFormControlTextarea1'
                                        placeholder='Bill Number'
                                        rows='3'
                                        type='text'
                                        name='billno' value={billNumber} onChange={(e) => setBillNumber(e.target.value)}
                                    />
                                </Form>
                            }
                        </Col>
                    </div>
                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleDetailsModal(false)}
                    >
                        Close
                    </Button>
                    {showPayment === 'new' &&
                        <Button color='primary' type='button'
                            onClick={
                                () => {
                                    handleApprove()
                                }
                            }>
                            Approve
                        </Button>
                    }
                </div>
            </Modal>
        </>
    )
}

export default withSnackbar(PaymentPage)