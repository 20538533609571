import React from 'react'
import Header from 'components/Headers/Header.js'
import Icon from '@material-ui/core/Icon'

import { Button, Input } from 'reactstrap'
import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack'
import { TablePagination } from '@material-ui/core'
import avatar from '../../assets/img/dummyUser.png'
import ReferralDetails from '../../components/Referral/ReferralDetails'
import moment from 'moment'
import StudentDetails from '../../components/Student/StudentDetails'
import ChatStudent from 'components/Student/ChatStudent'

const Services = require('../../RemoteServices/RemoteServices.js')


class PromoCode extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addFormView: false,
            passwordModal: false,
            data: [],
            coursesLookup: {},
            updatePasswordView: false,
            newPassword1: '',
            newPassword2: '',
            referralListShow: false,
            selectedRow: null,
            count: null,
            next: '',
            previous: '',
            pageNumber: 0,
            searchText: '',
            detailsPanel: false,
            searchType: '',
            searchTypeText: '',
            normalDropDownOpen: false,
            dateDropDown: false,
            dateText: '',
            dropdownOptions: [],
            selectedCourse: '',
            selectedCourseId: '',
            courseLessonList: [],
            courseLessonOptions: [],
            selectedLesson: '',
            selectedLessionId: '',
            rowsPerPage: 50,
            rowsPerpage2: 20,
            masterPassword: '',
            confirmModal: false,
            statsData: '',
            promoCodeDetails:[],
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.submitChangePassword = this.submitChangePassword.bind(this)
        this.onSearchChange = this.onSearchChange.bind(this)
        this.changeSearchType = this.changeSearchType.bind(this)
        this.renderCoursesFilters = this.renderCoursesFilters.bind(this)
        this.onSearchWithTypes = this.onSearchWithTypes.bind(this)
        this.PatchedPagination = this.PatchedPagination.bind(this)
    }


    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    toggleBasicDropdown = () => {
        this.setState({
            normalDropDownOpen: !this.state.normalDropDownOpen
        })
    }
    getPromoCodeDetail=async ()=> {
        await Services.getPromoCodes().then((response) => {
            this.setState({promoCodeDetails:response})
        }).catch((e)=>{
            console.log('asdf',e)
        })
    }

    async componentDidMount() {
        const params = new URLSearchParams(this.props.location.search)
        if (params.get('id')) {
            const searchType = params.get('id')
            this.setState({
                detailsPanel: true,
                studentData: {
                    id: searchType
                }
            })
        }
        this.getReferralStat()
        this.getPromoCodeDetail()
    }

    getUsersList() {
        this.setState({ loading: true })
        Services.getStudents().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getRequestedUsers() {
        this.setState({ loading: true })
        Services.getRequestedStudents().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getPaidUsers() {
        this.setState({ loading: true })
        Services.getActiveReferral().then((response) => {
            this.setState({
                data: response,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getReferralStat() {
        Services.getPromoCodeStats().then((response) => {
            this.setState({
                statsData: response
            })
        }).catch((error) => {
            console.log('error', error)
        })
    }

    getHasReferralsUsers() {
        this.setState({ loading: true })
        Services.getPaidStudents().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getYoutubeUsers() {
        this.setState({ loading: true })
        Services.getYoutubeUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getNonYoutubeUsers() {
        this.setState({ loading: true })
        Services.getNonYoutubeUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getTestUsers() {
        this.setState({ loading: true })
        Services.getTestUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getDueUsers() {
        this.setState({ loading: true })
        Services.getDueUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    async getCourses() {
        let coursesLookup = {}
        await Services.getCourses().then((response) => {
            (response).forEach((item) => {
                // console.log(item)
                let itemId = item.id
                coursesLookup[itemId] = item.title
            })
        })
        // console.log(coursesLookup)
        await this.setState({ coursesLookup: coursesLookup })
    }

    submitChangePassword() {
        let userId = this.state.selectedRow.user.id
        let data = {
            password1: this.state.newPassword1,
            password2: this.state.newPassword2
        }
        Services.updatePassword(userId, data).then((response) => {
            this.setState({ updatePasswordView: false })
            this.props.enqueueSnackbar('Password Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            })
    }

    async onSearchChange(search) {
        await this.setState({ searchTextValue: search.target.value })
        this.state.searchType !== '' ?
            await Services.getStudentsWithSearchType(this.state.searchType, this.state.searchTextValue).then((response) => {
                this.setState({
                    data: response.results,
                    loading: false,
                    count: response.count,
                    next: response.next,
                    previous: response.previous
                })
            }) :
            await Services.getStudentsSearch(this.state.searchTextValue).then((response) => {
                this.setState({
                    data: response.results,
                    loading: false,
                    count: response.count,
                    next: response.next,
                    previous: response.previous
                })
            })
    }

    changeSearchType = async (type, typeText) => {
        this.setState({ searchType: type, searchTypeText: typeText, searchText: '' })
        if (type === '') {
            Services.getStudents().then((response) => {
                this.setState({
                    data: response.results,
                    loading: false,
                    count: response.count,
                    next: response.next,
                    previous: response.previous
                })
            })

        }
    }

    AddOrSubractDays = (startingDate, number) => {
        return new Date(new Date().setDate(startingDate.getDate() - number)).toLocaleDateString()
    }

    formatDate = (date) => {
        const dateParts = date.split('/')
        const outputDate = dateParts[2] + '-' + ('0' + dateParts[0]).slice(-2) + '-' + ('0' + dateParts[1]).slice(-2)
        return outputDate
    }

    changeDateType = async (event) => {
        this.setState({ dateType: event.target.value })
        await Services.getStudentsWithDate(
            event.target.value === 'All' ? '' : this.formatDate(new Date().toLocaleDateString()),
            event.target.value === 'today' ? this.formatDate(new Date().toLocaleDateString()) : event.target.value === '1' ? this.formatDate(this.AddOrSubractDays(new Date(), 1)) : event.target.value === '2' ? this.formatDate(this.AddOrSubractDays(new Date(), 2)) : event.target.value === '3' ? this.formatDate(this.AddOrSubractDays(new Date(), 3)) : event.target.value === '7' ? this.formatDate(this.AddOrSubractDays(new Date(), 7)) : event.target.value === '30' ? this.formatDate(this.AddOrSubractDays(new Date(), 30)) : ''
        ).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    async onSearchWithTypes(search) {
        await this.setState({ searchTextValue: search.target.value })
        await Services.getStudentsWithSearchType(this.state.searchType, this.state.searchText).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getCourseList() {
        Services.getCourses().then((response) => {
            this.setState({ courseList: response })
            this.populateSelectDropdown()
        })
    }

    populateSelectDropdown() {
        let data = this.state.courseList
        data.forEach((item) => {
            this.state.dropdownOptions.push({ value: item.id, label: item.title })
        })
    }

    populateLessonsDropdown() {
        this.setState({ courseLessonOptions: [] })
        let data = this.state.courseLessonList
        data.forEach((item) => {
            this.state.courseLessonOptions.push({ value: item.id, label: item.order + `. ${item.title}` })
        })


    }

    handleCourseSelect = async selectedCourse => {
        this.setState(
            { selectedCourse, selectedCourseId: selectedCourse.value }
        )
        await Services.filterByCourseLesson(selectedCourse.value, this.state.selectedLessionId).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
        await Services.getCourseLessons(selectedCourse.value).then((response) => {
            this.setState({
                courseLessonList: response
            })
        })
        this.populateLessonsDropdown()
    }

    handleLessonSelect = async (selectedLesson) => {
        this.setState({
            selectedLesson: selectedLesson.label, selectedLessonId: selectedLesson.value
        })
        await Services.filterByCourseLesson(this.state.selectedCourseId, selectedLesson.value).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })

    }

    renderCoursesFilters() {
        let coursesLookup = this.state.coursesLookup
        let str = ''
        // console.log(coursesLookup)
        for (let courseId in coursesLookup) {
            // console.log(courseId)
            // str = str + "<b>test</b>"
            str = str + '<Chip avatar={<Avatar style={{backgroundColor: \'blue\', color: \'white\'}}>OS</Avatar>} label=\'Only Test\' style={{marginRight: 10}} onClick={this.getTestUsers.bind(this)}  />'
        }
        return (
            <>
                {str}
            </>
        )
    }


    PatchedPagination = function(props) {
        const {
            ActionsComponent,
            onChangePage,
            onChangeRowsPerPage,
            count,
            page,
            rowsPerPage,
            ...tablePaginationProps
        } = props

        return (
            <TablePagination
                {...tablePaginationProps}
                // @ts-expect-error onChangePage was renamed to onPageChange
                onPageChange={(e, page) => {
                    if (page > this.state.pageNumber) {
                        this.setState({ pageNumber: this.state.pageNumber + 1, loading: false })
                        Services.getCustom(this.state.next).then((response) => {
                            this.setState({
                                data: response.results,
                                loading: false,
                                count: response.count,
                                next: response.next,
                                previous: response.previous
                            })
                        })
                    } else {
                        this.setState({ pageNumber: this.state.pageNumber - 1, loading: false })
                        Services.getCustom(this.state.previous).then((response) => {
                            this.setState({
                                data: response.results,
                                loading: false,
                                count: response.count,
                                next: response.next,
                                previous: response.previous
                            })
                        })
                    }
                }}
                onRowsPerPageChange={(e, page) => {
                    this.onRowsPerPageChange()
                    this.setState({ rowsPerPage: page.props.value })
                }}
                rowsPerPage={this.state.rowsPerPage}
                count={this.state.count}
                page={this.state.pageNumber}
                ActionsComponent={(subprops) => {
                    const { onPageChange, onRowsPerPageChange, ...actionsComponentProps } = subprops
                    return (
                        // @ts-expect-error ActionsComponent is provided by material-table
                        <ActionsComponent
                            {...actionsComponentProps}
                            onChangePage={onPageChange}
                            onChangeRowsPerPage={onRowsPerPageChange}
                        />
                    )
                }}
            />
        )
    }

    handleCreateStudentOpen() {
        this.toggleState('passwordModal')
        this.toggleState('addFormView')
    }

    handleUserUpdate(oldData, newData) {

    }

    render() {
        return (
            <>
                <Header page='lesson' />
                <div className='container-fluid'>
                    <div className={'statistics-section'}>
                        <div className={'box-card'}>
                            <strong className={'heading'}>Total</strong>
                            <strong className={'content'}>{this.state?.statsData?.Promocode_count || 0}</strong>
                        </div>
                        <div className={'box-card'}>
                            <strong className={'heading'}>Active</strong>
                            <strong className={'content'}>{this.state?.statsData?.Active_count || 0}</strong>
                        </div>
                        <div className={'box-card'}>
                            <strong className={'heading'}>Inactive</strong>
                            <strong
                                className={'content'}>{this.state?.statsData?.Inactive_count || 0}</strong>
                        </div>
                        <div className={'box-card'}>
                            <strong className={'heading'}>Total Code Used</strong>
                            <strong className={'content'}>{this.state?.statsData?.Used_count || 0}</strong>
                        </div>
                        <div className={'box-card'}>
                            <strong className={'heading'}>Max Allowed %</strong>
                            <strong className={'content'}>{this.state?.statsData?.Max_Promo_Discount || 0}</strong>
                        </div>
                    </div>
                    <div className='paymentTableContainer'>
                        <MaterialTable
                            title='Promo Code'
                            columns={[
                                {
                                    title: 'Avatar',
                                    field: 'imageUrl',
                                    render: rowData =><div style={{ position: 'relative', width: 40, height: 40 }}>
                                    <img
                                      src={rowData.user_image?window.config.base.concat(rowData.user_image) :avatar}
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'fill',
                                        borderRadius: '50%'
                                      }}
                                    />
                                    <span
                                      style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                        width: 12,
                                        height: 12,
                                        borderRadius: '50%',
                                        backgroundColor: rowData?.is_online ? 'green' : 'lightgrey',
                                        border: '2px solid white'
                                      }}
                                    />
                                  </div>
                                },
                                {
                                    title: 'Full Name',
                                    render: rowData => <span style={{ cursor: 'pointer' }}
                                                             onClick={async () => {
                                                                 await this.setState({
                                                                     detailsPanel: true,
                                                                     selectedRow: rowData.student_id
                                                                 })
                                                             }
                                                            
                                                             }
                                                             >{rowData.user_name||'Backend Left'}</span>
                                },
                                // { title: 'Email', render: rowData => <span>{rowData.user_email||'Backend Left'}</span> },
                                {
                                    title: 'Created At',
                                    field: 'created_at',
                                    render: rowData =>
                                        <span>{moment(rowData.created_at).format('DD-MM-YYYY')}</span>
                                },
                                {
                                    title: 'Code',
                                    field: 'code',
                                    render: rowData => <span>{rowData.code}</span>
                                },
                                {
                                    title: 'Discount',
                                    field: 'discount'
                                },
                                {
                                    title: 'Total Used Count',
                                    field: 'used_count'
                                },
                                {
                                    title: 'Status',
                                    field: 'status',
                                    render: rowData =>
                                        <>
                                            {rowData.status === true &&
                                                <Icon style={{ color: 'green' }}>check</Icon>
                                            }
                                            {
                                                rowData.status === false &&
                                                <Icon style={{ color: 'red' }}>cancel</Icon>
                                            }
                                        </>,
                                    lookup: { true: "Active", false: 'Inactive' }
                                },
                                {
                                    title: 'Modified At',
                                    field: 'modified_at',
                                    render: rowData =>
                                        <span>{moment(rowData.modified_at).format('DD-MM-YYYY')}</span>
                                },
                            ]}
                            options={{
                                pageSize: 10,
                                actionsColumnIndex: -1,
                                headerStyle: {
                                    backgroundColor: '#6772E5',
                                    color: '#FFF'
                                }
                            }}
                            data={this.state.promoCodeDetails}
                            // actions={[
                            //     {
                            //         icon: 'info',
                            //         tooltip: 'Details',
                            //         isFreeAction: false,
                            //         onClick: (event, rowData) => {
                            //             this.toggleState('historyDetail')
                            //             this.setState({ studentHistoryDetail: rowData })
                            //         }
                            //     }
                            // ]}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            Services.updatePromoCode(oldData.id, {
                                                status:newData.status,
                                                code: newData.code.toUpperCase(),
                                                discount:Number(newData.discount),
                                                user:newData.user,
                                                modified_at:new Date()
                                            }).then((response) => {
                                                this.props.enqueueSnackbar('Promo Code Updated Successfully', {
                                                    variant: 'success',
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }
                                                })
                                                this.getPromoCodeDetail();
                                            })
                                                .catch((error) => {
                                                    this.props.enqueueSnackbar(error.message, {
                                                        variant: 'warning',
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'right'
                                                        }
                                                    })
                                                })
                                            resolve()
                                        }, 300)
                                    })
                            }}
                        />
                    </div>
                    {
              this.state.detailsPanel &&
              <ChatStudent studentData={this.state.selectedRow} closePanel={() => {
                this.setState({ detailsPanel: false })
              }}></ChatStudent>
            }

                </div>
            </>
        )
    }
}

export default withSnackbar(PromoCode)
