/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import MaterialTable, { MTableToolbar } from 'material-table';
import React from "react";

// react component that copies the given text inside your clipboard
// reactstrap components
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import Select from 'react-select';
import {
  Button,
  Card,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row
} from 'reactstrap';

// core components
import Header from "components/Headers/Header.js";
import StudentDetailsAssignment from "components/Student/StudentDetailsAssignment";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import {
  customChunkRenderer,
} from 'draft-js-helpers';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from "html-to-draftjs";
import moment from 'moment';
import { Editor } from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactHtmlParser from 'react-html-parser';
import Viewer from 'react-viewer';
import TeacherSupportModal from '../../components/Modal/TeacherSupportModal';
import './Assignments.css';
import { TablePagination } from '@material-ui/core';
import avatar from '../../assets/img/dummyUser.png'

const Services = require('../../RemoteServices/RemoteServices.js');



class Assignments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: null,
      next: '',
      previous: '',
      pageNumber: 0,
      rowsPerPage: 50,
      remarksModal: false,
      snackBar: false,
      remarks: '',
      isViewerOpen: false,
      courseList: [],
      currentImage: 0,
      dropdownOptions: [],
      selectedOption: null,
      selectedOptionId: null,
      data: [],
      selectedRow: null,
      assignmentStatus: null,
      status: 'Pending',
      loading: true,
      assignmentImages: [],
      lessonRemarksLoading: true,
      lessonRemarks: [],
      imagesModal: false,
      detailsPanel: false,
      lessonViewType: true,
      disableSubmit: false,
      updateSupport: false,
      showAllButton: true,
      showText: false,
      showImage: false,
      showVideo: false,
      video: '',
      notification: false,
      link: {
        tag: '',
        tags: '',
        lessonNo: '',
        title: '',
        description: '',
        videoLink: '',
        selectedOptionId: '',
        dropdownOptions: [],
        editorState: EditorState.createEmpty(),
        editorStateCopied: EditorState.createEmpty(),
        editorContentHtmlCopied: '',
        imagePreview: '',
        image: '',
        remarks: [],
        uniqueId: '',
        editorContentHtml: '',
        video: '',
        itemList: [{
          showEditorCode: false,
          editorState: EditorState.createEmpty(),
          editorStateCopied: EditorState.createEmpty(),
          editorContentHtmlCopied: '',
          imagePreview: '',
          editorContentHtml: '',
          image: '',
          description: '',
          thumbnail: '',
          video: '',
          order: '',
          orientation: '',
          buttonLink: '',
          buttonName: '',
          subTitle: '',
          imageBlob: '',
          showAllButton: true,
          addButton: true,
          showText: false,
          showImage: false,
          showVideo: false,
          showTable: false,
          showSubTitle: false,
          showQuiz: false,
          quizData: '',
          rawStr: '',
          data: [],
          tableArray: '',
          notification: '',
          paymentCategory: false
        }]
      },

    }
    this.handleInitialEditorText = this.handleInitialEditorText.bind(this)
  }

  handleReset = () => {
    this.setState({
      remarksModal: false,
      snackBar: false,
      remarks: '',
      isViewerOpen: false,
      courseList: [],
      currentImage: 0,
      dropdownOptions: [],
      selectedOption: null,
      selectedOptionId: null,
      data: [],
      selectedRow: null,
      assignmentStatus: null,
      status: 'Pending',
      loading: true,
      assignmentImages: [],
      lessonRemarksLoading: true,
      lessonRemarks: [],
      imagesModal: false,
      detailsPanel: false,
      lessonViewType: true,
      disableSubmit: false,
      updateSupport: false,
      showAllButton: true,
      showText: false,
      showImage: false,
      showVideo: false,
      video: '',
      notification: false,
      link: {
        tag: '',
        tags: '',
        lessonNo: '',
        title: '',
        description: '',
        videoLink: '',
        selectedOptionId: '',
        dropdownOptions: [],
        editorState: EditorState.createEmpty(),
        editorStateCopied: EditorState.createEmpty(),
        editorContentHtmlCopied: '',
        imagePreview: '',
        image: '',
        remarks: [],
        uniqueId: '',
        editorContentHtml: '',
        video: '',
        itemList: [{
          showEditorCode: false,
          editorState: EditorState.createEmpty(),
          editorStateCopied: EditorState.createEmpty(),
          editorContentHtmlCopied: '',
          imagePreview: '',
          editorContentHtml: '',
          image: '',
          description: '',
          thumbnail: '',
          video: '',
          order: '',
          orientation: '',
          buttonLink: '',
          buttonName: '',
          subTitle: '',
          imageBlob: '',
          showAllButton: true,
          addButton: true,
          showText: false,
          showImage: false,
          showVideo: false,
          showTable: false,
          showSubTitle: false,
          showQuiz: false,
          quizData: '',
          rawStr: '',
          data: [],
          tableArray: '',
          notification: '',
          paymentCategory: false
        }]
      },
    })
  }

  handlePasteImage = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const blob = items[i].getAsFile();
        let reader = new FileReader()
        reader.onload = () => {
          let binaryData = reader.result
          let base64String = btoa(binaryData)
          this.setState({ image: base64String })
        }
        reader.readAsBinaryString(blob)
        this.setState({ imagePreview: URL.createObjectURL(blob), image: blob });
        // If you want to upload the image, you can use a function like this.uploadImage(blob)
        // this.uploadImage(blob);
      }
    }
  };

  handleTableChange = (e, idx, rowIndex, colIndex) => {
    const newData = [...this.state.link.itemList]
    newData[idx]['tableArray'][rowIndex][colIndex] = e.target.value
    console.log('asdfasfd', ...newData)
    this.setState(prevState => ({
      ...prevState,
      link: {
        ...prevState.link,
        itemList: [...newData]
      }
    }))
  }

  tableRow = (data, idx) => (
    <table className={'mt-2 mb-2 table table-bordered table-responsive'}>
      {data.map((row, i) => (
        <tr style={{ width: 'auto' }} key={i}>
          {row.map((col, j) => (
            <td key={j}><input
              style={{ border: 'none' }}
              type='text'
              value={col ? col : ''}
              onChange={(e) => this.handleTableChange(e, idx, i, j)}
            /></td>
          ))}
        </tr>
      ))}
    </table>
  )

  countQuotes = (str) => {
    return str.split('"').length - 1
  }

  parseClip = (str) => {
    let r,
      rlen,
      rows,
      arr = [],
      a = 0,
      c,
      clen,
      multiline,
      last
    rows = str
      .split('\r\n')
      .reduce((acc, item) => acc.concat(item.split('\n')), [])
      .reduce((acc, item) => acc.concat(item.split('\r')), [])
    if (rows.length > 1 && rows[rows.length - 1] === '') {
      rows.pop()
    }
    for (r = 0, rlen = rows.length; r < rlen; r += 1) {
      rows[r] = rows[r].split('\t')
      for (c = 0, clen = rows[r].length; c < clen; c += 1) {
        if (!arr[a]) {
          arr[a] = []
        }
        if (multiline && c === 0) {
          last = arr[a].length - 1
          arr[a][last] = arr[a][last] + '\n' + rows[r][0]
          if (multiline && this.countQuotes(rows[r][0]) & 1) {
            //& 1 is a bitwise way of performing mod 2
            multiline = false
            arr[a][last] = arr[a][last]
              .substring(0, arr[a][last].length - 1)
              .replace(/""/g, '"')
          }
        } else {
          if (
            c === clen - 1 &&
            rows[r][c].indexOf('"') === 0 &&
            this.countQuotes(rows[r][c]) & 1
          ) {
            arr[a].push(rows[r][c].substring(1).replace(/""/g, '"'))
            multiline = true
          } else {
            arr[a].push(rows[r][c].replace(/""/g, '"'))
            multiline = false
          }
        }
      }
      if (!multiline) {
        a += 1
      }
    }
    return arr
  }
  getExtension = (filename) => {
    var parts = filename.split('.')
    return parts[parts.length - 1]
  }

  isImage = (filename) => {
    var ext = this.getExtension(filename)
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'gif':
      case 'bmp':
      case 'png':
      case 'jpeg':
        //etc
        return true
    }
    return false
  }
  handleImageSelect = async (e, idx) => {
    let file = e.target.files[0]
    let filename = e.target.files[0].name
    if (this.isImage(filename)) {
      let itemTransaction = [...this.state.link.itemList]
      // itemTransaction[idx]['image'] = e.target.files[0]
      // itemTransaction[idx]['imagePreview'] = URL.createObjectURL(e.target.files[0])
      // this.setState(prevState => ({
      //     ...prevState,
      //     link: {
      //         itemList: [...itemTransaction]
      //     }
      // }))
      // let reader = new FileReader()
      // reader.onloadend = function() {
      //     let data = (reader.result).split(',')[1]
      //     let binaryBlob = atob(data)
      //     itemTransaction[idx]['imageBlob'] = binaryBlob
      //     this.setState(prevState => ({
      //         ...prevState,
      //         link: {
      //             itemList: [...itemTransaction]
      //         }
      //     }))
      // }.bind(this)
      // reader.readAsDataURL(file)
      let token = localStorage.getItem('token')
      return new Promise(
        (resolve, reject) => {
          const xhr = new XMLHttpRequest()
          const serverUrl = window.config.apiUrl + 'common/image-upload'
          xhr.open('POST', serverUrl)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)
          const data = new FormData()
          data.append('image', file)
          xhr.send(data)
          xhr.addEventListener('load', () => {
            const response = JSON.parse(xhr.responseText)
            resolve(response)
            itemTransaction[idx]['image'] = response.data.link
            itemTransaction[idx]['imagePreview'] = response.data.link
            this.setState(prevState => ({
              ...prevState,
              link: {
                itemList: [...itemTransaction]
              }
            }))
          })
          xhr.addEventListener('error', () => {
            const error = JSON.parse(xhr.responseText)
            reject(error)
          })
        }
      )

    } else {
      this.props.enqueueSnackbar('Please choose a correct image format', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }

  }

  handleImageRemove = async (e, idx) => {
    let itemTransaction = [...this.state.link.itemList]
    itemTransaction[idx]['imagePreview'] = ''
    itemTransaction[idx]['image'] = ''
    await this.setState(prevState => ({
      ...prevState,
      link: {
        itemList: [...itemTransaction]
      }
    }))
  }

  changeState = (state) => {
    this.setState((prevState) => ({
      ...prevState,
      [state]: !this.state[state]

    }))
  }


  addButtonHandler = (state, idx) => {
    let item = [...this.state.link.itemList]
    item[idx][state] = !this.state.link.itemList[idx][state]
    this.setState((prevState) => ({
      ...prevState,
      link: {
        ...prevState.link,
        itemList: [...item]
      }
    }))

  }

  editTable = async (event, idx) => {
    if (event) {
      console.log('asdfasdf', event)
      // let itemTransaction = [...this.state.link.itemList]
      // itemTransaction[idx]['tableData'] = event
      // await this.setState(prevState => ({
      //     ...prevState,
      //     link: {
      //         ...prevState.link,
      //         itemList: [...itemTransaction]
      //     }
      // }))
    }

  }

  addItem = async (idx) => {
    const { link } = this.state
    let item = {
      showEditorCode: false,
      editorState: EditorState.createEmpty(),
      editorStateCopied: EditorState.createEmpty(),
      editorContentHtmlCopied: '',
      imagePreview: '',
      editorContentHtml: '',
      image: '',
      description: '',
      video: '',
      order: '',
      orientation: '',
      buttonLink: '',
      buttonName: '',
      subTitle: '',
      thumbnail: '',
      imageBlob: '',
      paymentButtonName: '',
      paymentCategory: false,
      paymentAmount: '',
      showAllButton: true,
      addButton: true,
      showText: false,
      showImage: false,
      showTable: false,
      showVideo: false,
      showButton: false,
      showSubTitle: false,
      showQuiz: false,
      quizData: '',
      rawStr: '',
      table: {
        rows: [],
        columns: []
      },
      data: '',
      tableArray: ''
    }
    await this.setState({
      showAllButton: true,
      link: {
        ...link,
        itemList: [...link.itemList, item]
      }

    })
    let itemUpdated = [...this.state.link.itemList]
    itemUpdated[idx]['addButton'] = false
    await this.setState(prevState => ({
      ...prevState,
      link: {
        ...prevState.link,
        itemList: [...itemUpdated]
      }
    }))

  }
  editItem = (event, idx, type) => {

    let name = event.target.name
    let value = event.target.value
    let itemTransaction = [...this.state.link.itemList]
    itemTransaction[idx][name] = value
    if (type === 'paymentCategory') {
      itemTransaction[idx]['paymentCategory'] = event.target.checked
      itemTransaction[idx]['paymentAmount'] = ''
      this.setState({
        itemList: [...itemTransaction]
      })
    }
    if (type === 'table') {
      itemTransaction[idx]['rawStr'] = event.target.value
      itemTransaction[idx]['data'] = this.parseClip(event.target.value)
      itemTransaction[idx]['tableArray'] = this.parseClip(event.target.value)
      this.setState({
        itemList: [...itemTransaction]
      })
    }
    this.setState(prevState => ({
      ...prevState,
      link: {
        ...prevState.link,
        itemList: [...itemTransaction]
      }
    }))

  }
  removeItem = async (idx) => {
    if (idx > 0) {
      let item = [...this.state.link.itemList]
      item.splice(idx, 1)
      await this.setState(prevState => ({
        ...prevState,
        link: {
          ...prevState.link,
          itemList: item
        }
      }))
      let itemUpdated = [...this.state.link.itemList]
      itemUpdated[idx - 1]['addButton'] = true
      await this.setState(prevState => ({
        ...prevState,
        link: {
          ...prevState.link,
          itemList: [...itemUpdated]
        }
      }))
    } else if (idx === 0 && this.state.link.itemList.length >= 2) {
      let item = [...this.state.link.itemList]
      item.splice(idx, 1)
      await this.setState(prevState => ({
        ...prevState,
        showAllButton: true,
        link: {
          ...prevState.link,
          itemList: item
        }
      }))
    } else {
      let item = [...this.state.link.itemList]
      item.splice(idx, 1)
      await this.setState(prevState => ({
        ...prevState,
        showAllButton: true,
        link: {
          ...prevState.link,
          itemList: [{
            image: '',
            description: '',
            video: '',
            order: '',
            orientation: '',
            thumbnail: '',
            showAllButton: true,
            addButton: true,
            tableData: '',
            buttonLink: '',
            buttonName: '',
            subTitle: ''
          }]
        }
      }))
    }
  }


  addTable = async (idx) => {
    const { itemList } = this.state.link
    let item = {
      rows: [],
      columns: []
    }
    await this.setState({
      showAllButton: true,
      itemList: {
        ...itemList,
        tableArray: [...itemList.tableArray, item]
      }

    })
  }
  editTable = (event, idx, type) => {
    let name = event.target.name
    let value = event.target.value
    let itemTransaction = [...this.state.link.itemList.tableArray]
    itemTransaction[idx][name] = value
    this.setState(prevState => ({
      ...prevState,
      link: {
        ...prevState.link,
        itemList: [...itemTransaction]
      }
    }))

  }
  removeTable = async (idx) => {
    let item = [...this.state.link.itemList.tableArray]
    item.splice(idx, 1)
    await this.setState(prevState => ({
      ...prevState,
      showAllButton: true,
      itemList: {
        ...prevState.itemList,
        tableArray: [{
          rows: [],
          column: []
        }]
      }
    }))
  }


  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  setRemarksModal() {
    this.setState({ remarksModal: true })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  componentDidMount() {
    this.getCourseList()
    this.getPendingAssignments('students/assigment/?status=Pending')
  }

  getCourseList() {
    Services.getCourses().then((response) => {
      this.setState({ courseList: response })
      this.populateSelectDropdown()
    })
  }

  populateSelectDropdown() {
    let data = this.state.courseList
    data.forEach((item) => {
      this.state.dropdownOptions.push({ value: item.id, label: item.title })
    })
  }

  handleChange = selectedOption => {
    this.setState(
      { selectedOption, selectedOptionId: selectedOption.value, loading: true }
    );
    Services.getPendingAssignments(selectedOption.value).then((response) => {
      this.setState({ data: response, loading: false })
    })
  };

  getAssignmentList = () => {
    this.getPendingAssignments('students/assigment/?status=Pending')
  }

  getPendingAssignments = (urls) => {
    this.setState({ loading: true })
    let courseId = this.state.selectedOption ? this.state.selectedOption.value : null
    Services.getPendingAssignments(courseId, urls).then((response) => {
      this.setState({ data: response, status: 'Pending', loading: false })
    }).catch((error) => {
      console.log('asdfasf', error)
    })
  }

  getApprovedAssignments = (urls) => {
    this.setState({ loading: true })
    let courseId = this.state.selectedOption ? this.state.selectedOption.value : null
    Services.getApprovedAssignments(courseId, urls).then((response) => {
      this.setState({ data: response, status: 'Approved', loading: false })
    })
  }

  getDeniedAssignments = (urls) => {
    this.setState({ loading: true })
    let courseId = this.state.selectedOption ? this.state.selectedOption.value : null
    Services.getDeniedAssignments(courseId, urls,).then((response) => {
      this.setState({ data: response, status: 'Denied', loading: false })
    })
  }

  returnHtmlBlock(text) {
    if (this.state.lessonViewType === true) {
      const processedHTML = htmlToDraft(text, customChunkRenderer)
      return ContentState.createFromBlockArray(processedHTML);
    } else {
      const processedHTML = convertFromHTML(text);
      return ContentState.createFromBlockArray(processedHTML);
    }
  }


  handleInitialEditorText(text) {
    let editorState
    if (text.trim() !== "") {
      editorState = EditorState.createWithContent(this.returnHtmlBlock(text));
    }
    else {
      editorState = EditorState.createEmpty();
    }
    this.setState({ editorContentHtml: text })
    return editorState
  }

  onLinkEditorStateChange = async (editorState, idx) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    let itemTransaction = [...this.state.link.itemList]
    itemTransaction[idx]['editorContentHtml'] = draftToHtml(rawContentState)
    itemTransaction[idx]['editorState'] = editorState
    await this.setState(prevState => ({
      ...prevState,
      link: {
        itemList: [...itemTransaction]
      }
    }))

  }


  onEditorStateChange(remarks) {
    const rawContentState = convertToRaw(remarks.getCurrentContent());
    this.setState({
      remarks,
      editorContentHtml: draftToHtml(rawContentState)
    });
  };

  async openImagesInNewTab() {
    const images = []
    if (this.state.selectedRow) {

      let userAssignmentImages = this.state.selectedRow.images
      userAssignmentImages.forEach((image) => {
        images.push({ src: image.image, alt: '' })
        // window.open(image.image, "_blank")
      })
      this.setState({ assignmentImages: images })
    }
  }

  async setRowData(rowData) {
    await this.setState({ selectedRow: rowData })
    await this.openImagesInNewTab()
  }

  renderLessonRemarks(lessonId) {
    Services.getLessonRemarks(lessonId).then((data) => {
      this.setState({ lessonRemarksLoading: false, lessonRemarks: data })
    })

  }

  handleSetRemarks = (customRemarks) => {
    this.setState({
      remarks: this.handleInitialEditorText(customRemarks)
    })
  }


  uploadImageCallBack(file) {
    let token = localStorage.getItem('token')

    return new Promise(
      (resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const serverUrl = window.config.apiUrl + 'common/image-upload'
        xhr.open('POST', serverUrl);
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        const data = new FormData();
        data.append('image', file);
        xhr.send(data);
        xhr.addEventListener('load', () => {
          const response = JSON.parse(xhr.responseText);
          resolve(response);
        });
        xhr.addEventListener('error', () => {
          const error = JSON.parse(xhr.responseText);
          reject(error);
        });
      }
    );
  }

  handleYoutubeImageSelect = async (e, idx) => {
    let file = e.target.files[0]
    let filename = e.target.files[0].name
    if (this.isImage(filename)) {
      let itemTransaction = [...this.state.link.itemList]
      let token = localStorage.getItem('token')
      return new Promise(
        (resolve, reject) => {
          const xhr = new XMLHttpRequest()
          const serverUrl = window.config.apiUrl + 'common/image-upload'
          xhr.open('POST', serverUrl)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)
          const data = new FormData()
          data.append('image', file)
          xhr.send(data)
          xhr.addEventListener('load', () => {
            const response = JSON.parse(xhr.responseText)
            resolve(response)
            itemTransaction[idx]['thumbnail'] = response.data.link
            this.setState(prevState => ({
              ...prevState,
              link: {
                itemList: [...itemTransaction]
              }
            }))
          })
          xhr.addEventListener('error', () => {
            const error = JSON.parse(xhr.responseText)
            reject(error)
          })
        }
      )

    } else {
      this.props.enqueueSnackbar('Please choose a correct image format', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }

  }
  handleYoutubeImageRemove = async (e, idx) => {
    let itemTransaction = [...this.state.link.itemList]
    itemTransaction[idx]['thumbnail'] = ''
    await this.setState(prevState => ({
      ...prevState,
      link: {
        itemList: [...itemTransaction]
      }
    }))
  }

  handleObjects = () => {
    return this.state.link.itemList.map((item, index) => {
      if (item.editorContentHtml !== '' && item.quizData === '' && item.video === '' && item.image === '' && item.buttonLink === '' && item.tableArray === '' && item.subTitle == '') {
        return {
          order: item.order || index + 1,
          orientation: item.orientation || 'left',
          remark_text: item.editorContentHtml,
          remark_type: 'text'
        };
      } else if (item.video !== '' && item.editorContentHtml === '' && item.quizData === '' && item.image === '' && item.buttonLink === '' && item.tableArray === '' && item.subTitle == '') {
        return {
          video_link: item.video,
          order: item.order || index + 1,
          orientation: item.orientation || 'left',
          thumbnail_image: item.thumbnail ? item.thumbnail : null,
          remark_type: 'video'
        };
      } else if (item.image !== '' && item.editorContentHtml === '' && item.quizData === '' && item.video === '' && item.buttonLink === '' && item.tableArray === '') {
        return {
          order: item.order || index + 1,
          orientation: item.orientation || 'left',
          image: item.image,
          remark_type: 'image'
        };
      } else if (item.buttonLink !== '' && item.editorContentHtml === '' && item.quizData === '' && item.video === '' && item.image === '' && item.tableArray === '' && item.subTitle == '') {
        return {
          order: item.order || index + 1,
          orientation: item.orientation || 'left',
          button_link: item.buttonLink,
          button_name: item.buttonName,
          remark_type: 'button'
        };
      } else if (item.tableArray !== '' && item.editorContentHtml === '' && item.quizData === '' && item.video === '' && item.image === '' && item.subTitle == '') {
        return {
          order: item.order || index + 1,
          orientation: item.orientation || 'left',
          table_data: item.tableArray,
          remark_type: 'table'
        };
      } else if (item.subTitle !== '') {
        return {
          link: '',
          order: item.order || index + 1,
          orientation: item.orientation || 'left',
          title: item.subTitle
        };
      } else {
        // If none of the conditions match, return an empty array
        console.log('asdfasdfasdfdsf', null)
        return null;

      }
    })
  }


  handleApproveDeny(option) {
    this.setState({ disableSubmit: true })
    let data = {
      remarks: this.state.editorContentHtml,
      status: option === 1 && this.state.assignmentStatus !== 0 ? 'Approved' : 'Denied',
      objects:
        this.state.link.itemList.map((item, index) => {
          if (item.editorContentHtml !== '' && item.quizData === '' && item.video === '' && item.image === '' && item.buttonLink === '' && item.tableArray === '' && item.subTitle == '') {
            return {
              link: '',
              order: item.order || index + 1,
              orientation: item.orientation || 'left',
              text: item.editorContentHtml
            };
          } else if (item.video !== '' && item.editorContentHtml === '' && item.quizData === '' && item.image === '' && item.buttonLink === '' && item.tableArray === '' && item.subTitle == '') {
            return {
              link: '',
              youtube_link: item.video,
              order: item.order || index + 1,
              orientation: item.orientation || 'left',
              thumbnail: item.thumbnail ? item.thumbnail : null
            };
          } else if (item.image !== '' && item.editorContentHtml === '' && item.quizData === '' && item.video === '' && item.buttonLink === '' && item.tableArray === '') {
            return {
              link: '',
              order: item.order || index + 1,
              orientation: item.orientation || 'left',
              image: item.image
            };
          } else if (item.buttonLink !== '' && item.editorContentHtml === '' && item.quizData === '' && item.video === '' && item.image === '' && item.tableArray === '' && item.subTitle == '') {
            return {
              link: '',
              order: item.order || index + 1,
              orientation: item.orientation || 'left',
              button_link: item.buttonLink,
              button_name: item.buttonName
            };
          } else if (item.tableArray !== '' && item.editorContentHtml === '' && item.quizData === '' && item.video === '' && item.image === '' && item.subTitle == '') {
            return {
              table: '',
              order: item.order || index + 1,
              orientation: item.orientation || 'left',
              table_array: item.tableArray
            };
          } else if (item.subTitle !== '') {
            return {
              link: '',
              order: item.order || index + 1,
              orientation: item.orientation || 'left',
              title: item.subTitle
            };
          } else {
            // If none of the conditions match, return an empty array
            return null;
          }
        })
    }
    Services.updateAssignment(this.state.selectedRow.id, data).then((response) => {
      // this.setState({data: response})
      this.getAssignmentList()
      this.setState({ remarks: '', detailsPanel: false })
      this.setState({ disableSubmit: false })
      this.props.enqueueSnackbar('Successfully Submitted', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }
      }
      )
    })
      .catch((error) => {
        this.setState({ disableSubmit: false })
        this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
        )
      })

  }




  render() {
    const options = this.state.dropdownOptions
    // console.log(this.state.remarksModal)
    // if(this.state.selectedRow && !this.state.remarksModal && !this.state.remarks) {
    //   let userAssignmentImages = this.state.selectedRow.images
    //   userAssignmentImages.forEach((image)=>{
    //     images.push(image.image)
    //     window.open(image.image, "_blank")
    //   })
    //   // this.setState({selectedRow: null})
    // }

    return (
      <>
        <Header onlineCount={this.props.onlineCount} onlineUsers={this.props.onlineUsers} />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ padding: '10px 10px', zIndex: '100', width: '35%' }}>
                    <Select options={options} placeholder="Select Course" value={this.state.selectedOption} onChange={this.handleChange.bind(this)} />
                  </div>
                  <div onClick={() => this.toggleModal("updateSupport")} style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', marginRight: '10px', cursor: 'pointer' }}>
                    <i className={'fa fa-edit'}></i>
                    <span>Update Support</span>
                  </div>
                </div>
                {/* <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Icons</h3>
                </CardHeader> */}{
                  this.state.loading ?
                    <CircularProgress style={{
                      margin: 50,
                      alignSelf: 'center'
                    }} />
                    :
                    <MaterialTable
                      title="Assignments"
                      columns={[
                        {
                          title: 'Avatar',
                          field: 'imageUrl',
                          render: rowData => (
                            <div style={{ position: 'relative', width: 40, height: 40 }}>
                              <img
                                src={rowData.profile_picture?window.config.base.concat(rowData.profile_picture) :avatar}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'fill',
                                  borderRadius: '50%'
                                }}
                              />
                              <span
                                style={{
                                  position: 'absolute',
                                  bottom: 0,
                                  right: 0,
                                  width: 12,
                                  height: 12,
                                  borderRadius: '50%',
                                  backgroundColor: rowData?.is_online ? 'green' : 'lightgrey',
                                  border: '2px solid white'
                                }}
                              />
                            </div>
                          )
                        },
                        {
                          title: 'Student', field: 'by', render: rowData => <span>{rowData.student}
                            &nbsp;
                            {rowData?.email_verified && <i style={{ color: 'blue', fontSize: 18 }} className={' fa fa-check-circle'}></i>}

                          </span>
                        },
                        { title: 'Lesson', field: 'lesson_name' },
                        { title: 'Course', field: 'course' },


                        { title: 'Date', field: 'created_at', render: rowData => <> {moment(rowData.created_at).format("dddd, MMMM Do YYYY, h:mm:ss a")}</> },
                        { title: 'Status', field: 'status' },
                        {
                          title: 'File', field: 'image', render: rowData =>
                            <>
                              <Button
                                onClick={async () => {
                                  // this.setState({ selectedRow: rowData})
                                  await this.setRowData(rowData)
                                  await this.toggleModal("imagesModal")
                                  // console.log(rowData)
                                  // this.openImagesInNewTab()
                                }
                                }
                                class="btn btn-primary btn-sm">
                                <i class="ni ni-zoom-split-in"></i>
                              </Button>
                              <Chip label={rowData.images.length} style={{ marginRight: 10, marginLeft: 10 }} />
                            </>
                        },
                      ]}
                      data={this.state.data.results}
                      actions={[
                        {
                          icon: 'check_circle',
                          tooltip: 'Approve Assignment',
                          onClick: (event, rowData) => {
                            this.toggleModal("remarksModal")
                            this.setState({ assignmentStatus: 1, selectedRow: rowData })
                            this.renderLessonRemarks(rowData.lesson.id)

                          },
                          disabled: this.state.status !== "Pending"
                        },
                        {
                          icon: 'cancel',
                          tooltip: 'Decline Assignment',
                          onClick: (event, rowData) => {
                            this.toggleModal("remarksModal")
                            this.setState({ assignmentStatus: 2, selectedRow: rowData })
                          },
                          disabled: this.state.status !== "Pending"
                        },
                        {
                          icon: 'visibility',
                          tooltip: 'View ',
                          onClick: async (event, rowData) => {
                            await this.setRowData(rowData)
                            await this.setState({ detailsPanel: true })
                            this.renderLessonRemarks(rowData.lesson.id)

                          },
                          disabled: this.state.status !== "Pending"
                        },
                      ]}
                      options={{
                        actionsColumnIndex: -1,
                        pageSize: 50,
                        headerStyle: {
                          backgroundColor: '#6772E5',
                          color: '#FFF'
                        }
                      }}
                      components={{
                        Pagination: props => (
                          <>
                            <TablePagination
                              {...props}
                              rowsPerPageOptions={[]}
                              rowsPerPage={this.state.rowsPerPage}
                              count={this.state.data.count}
                              page={this.state.pageNumber}
                              onChangeRowsPerPage={(e, page) => {
                                this.setState({ rowsPerPage: page.props.value })
                                this.getUsersList(page.props.value)

                              }}
                              onChangePage={(e, page) => {
                                if (page > this.state.pageNumber) {
                                  this.setState({
                                    pageNumber: this.state.pageNumber + 1,
                                    loading: true
                                  })
                                  if (this.state.status === 'Approved' || this.state.status === 'Denied') {
                                    if (this.state.status === 'Approved') {
                                      this.getApprovedAssignments(this.state.data.next)
                                    } else {
                                      this.getDeniedAssignments(this.state.data.next)
                                    }
                                  } else {
                                    this.getPendingAssignments(this.state.data.next)

                                  }

                                } else {
                                  this.setState({
                                    pageNumber: this.state.pageNumber - 1,
                                    loading: true
                                  })
                                  if (this.state.status === 'Approved' || this.state.status === 'Denied') {
                                    if (this.state.status === 'Approved') {
                                      this.getApprovedAssignments(this.state.data.previous)
                                    } else {
                                      this.getDeniedAssignments(this.state.data.previous)
                                    }
                                  } else {
                                    this.getPendingAssignments(this.state.data.previous)

                                  }
                                }
                              }
                              }

                            >
                            </TablePagination>
                          </>
                        ),
                        Toolbar: props => {
                          return (
                            <div>
                              <MTableToolbar {...props} />
                              <div style={{ padding: '10px 10px', marginLeft: 10, paddingBottom: 20 }}>Filter By:
                                <Chip avatar={<Avatar>P</Avatar>} label="Pending" style={{ marginRight: 10, marginLeft: 10 }} onClick={()=>this.getPendingAssignments(`students/assigment/?status=Pending`)} />
                                <Chip avatar={<Avatar style={{ backgroundColor: 'green', color: 'white' }}>A</Avatar>} label="Approved" style={{ marginRight: 10 }} onClick={() => this.getApprovedAssignments('students/assigment/?status=Approved')} />
                                <Chip avatar={<Avatar style={{ backgroundColor: 'red', color: 'white' }}>D</Avatar>} label="Denied" style={{ marginRight: 10 }} onClick={() => this.getDeniedAssignments('students/assigment/?status=Denied')} />
                              </div>
                            </div>

                          )

                        },
                      }}
                    />
                }

              </Card>
            </div>
          </Row>
          {/* Modal */}
          <Modal
            size='lg'
            className="modal-dialog-centered"
            isOpen={this.state.remarksModal}
            toggle={() => this.toggleModal("remarksModal")}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Assignment Remarks
              </h5>

              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("remarksModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.lessonRemarks.length > 0 &&
                <>
                  <h4> Remarks Options:</h4>
                  <div className="assignmentRemarksOptionContainer">

                    {this.state.lessonRemarks.map((val) => {
                      return (
                        <div className="assignmentRemarksOptionDiv" onClick={() => this.handleSetRemarks(val.text)}>
                          {ReactHtmlParser(val.text)}
                        </div>
                      )
                    })}
                  </div>
                </>
              }

              <Editor
                editorStyle={{
                  border: '1px solid black',
                  padding: '5px',
                  borderRadius: '2px',
                  height: '400px',
                  width: '100%',
                }}
                placeholder="Description / Notes  [NEW Layout]"
                editorState={this.state.remarks}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={this.onEditorStateChange.bind(this)}
                toolbar={{
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                  image: {
                    uploadCallback: this.uploadImageCallBack,
                    previewImage: true,
                    defaultSize: { width: '100%', height: 'auto' }
                  },
                }}
              />
              <br />

              {!this.state.showAllButton &&
                <Row>
                  <Col xl={12}>
                    <Button onClick={() => this.addItem()}
                      className={'mb-2 btn'}><i
                        className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                  </Col>
                </Row>
              }
              {this.state.link.itemList && this.state.link.itemList.map((item, idx) => (
                <div>
                  {this.state.link.itemList[idx].showText &&
                    <div className={'mt-3'}>
                      <h4>Text</h4>
                      <Editor
                        key={idx}
                        handlePastedText={() => false}
                        editorStyle={{
                          border: '1px solid black',
                          padding: '5px',
                          borderRadius: '2px',
                          minHeight: '300px',
                          height: 'auto',
                          width: '100%',
                          fontSize: '18px'
                        }}
                        placeholder='Description / Notes  [NEW Layout]'
                        editorState={item.editorState}
                        wrapperClassName='demo-wrapper'
                        editorClassName='demo-editor'
                        onEditorStateChange={(editorState) => this.onLinkEditorStateChange(editorState, idx)}
                        toolbar={{
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                          image: {
                            uploadCallback: (file) => this.uploadImageCallBack(file),
                            previewImage: true,
                            defaultSize: { width: '100%', height: 'auto' }
                          }
                        }}
                      />
                      <FormGroup className={'mt-1'} style={{
                        display: 'flex',
                        width: '50%',
                        justifyContent: 'space-between'
                      }}>
                        <InputGroup className='input-group-alternative mb-3'>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <i className='ni ni-hat-3' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder='Order' type='text' name='order' value={item.order}
                            onChange={(event) => this.editItem(event, idx)} />
                        </InputGroup>
                        <Input className={'ml-2'} type={'select'} name={'orientation'}
                          value={item.orientation} onChange={(event) => this.editItem(event, idx)}>
                          <option>Select Orientation</option>
                          <option value={'left'}>Left</option>
                          <option value={'center'}>Center</option>
                          <option value={'right'}>Right</option>
                        </Input>

                      </FormGroup>

                    </div>
                  }
                  {this.state?.link?.itemList[idx].showImage &&
                    <>
                      <div>
                        <FormGroup className={'mb-0'}>
                          <h4>Upload Image</h4>
                          <input className='thumbnail_upload_button' accept='png/jpeg' type='file'
                            onChange={(e) => this.handleImageSelect(e, idx)} />
                          <br />
                          {item.imagePreview && <div className='thumbnailContainerAssignment'><i
                            onClick={(e) => this.handleImageRemove(e, idx)}
                            class='far fa-times-circle'></i> <img
                              className='lessonFormThumbnailImage' src={item.imagePreview} />
                          </div>}

                        </FormGroup>
                        <FormGroup style={{
                          display: 'flex',
                          width: '50%',
                          justifyContent: 'space-between'
                        }}>
                          <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                              <InputGroupText>
                                <i className='ni ni-hat-3' />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Order' type='text' name='order'
                              value={item.order}
                              onChange={(event) => this.editItem(event, idx)} />
                          </InputGroup>
                        </FormGroup>
                      </div>
                    </>
                  }

                  {this.state?.link?.itemList[idx]?.showVideo &&
                    <>
                      <FormGroup className={'mt-3 mb-0'}
                        style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <InputGroup className='input-group-alternative mb-3'>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <i className='ni ni-hat-3' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder='Video Link' type='text' name='video'
                            value={item.video}
                            onChange={(event) => this.editItem(event, idx)} />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <h4>Upload Thumbnail Image</h4>
                        <input className='thumbnail_upload_button' accept='png/jpeg'
                          type='file'
                          onChange={(e) => this.handleYoutubeImageSelect(e, idx)} />
                        <br />
                        {item.thumbnail &&
                          <div className='thumbnailContainerAssignment'>
                            <i
                              onClick={(e) => this.handleYoutubeImageRemove(e, idx)}
                              class='far fa-times-circle'></i>
                            <img
                              className='lessonFormThumbnailImage'
                              src={item.thumbnail} />
                          </div>}

                      </FormGroup>


                      <FormGroup style={{
                        display: 'flex',
                        width: '50%',
                        justifyContent: 'space-between',
                        marginTop: '0px'
                      }}>
                        <InputGroup className='input-group-alternative mb-3'>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <i className='ni ni-hat-3' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder='Order' type='text' name='order' value={item.order}
                            onChange={(event) => this.editItem(event, idx)} />
                        </InputGroup>
                      </FormGroup>
                    </>
                  }

                  {this.state?.link?.itemList[idx]?.showButton &&
                    <>

                      <div className={'d-flex justify-content-between'}>
                        <h4>Button Link </h4>
                        {item.buttonName.length > 0 && <span style={{ fontSize: 18 }}
                          className={'font-weight-bold'}>{item.buttonName.length}/100</span>}
                      </div>
                      <FormGroup className={'mt-3 mb-0'}
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '10px'
                        }}>
                        <div style={{ display: 'flex', width: '50%' }}>
                          <InputGroup
                            className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                              <InputGroupText>
                                <i className='ni ni-hat-3' />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Button Name'
                              type='text'
                              name='buttonName'
                              value={item.buttonName}
                              onChange={(event) => this.editItem(event, idx)} />
                          </InputGroup>
                        </div>
                        <InputGroup
                          className='input-group-alternative mb-3'>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <i className='ni ni-hat-3' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder='Button Link' type='text'
                            name='buttonLink'
                            value={item.buttonLink}
                            onChange={(event) => this.editItem(event, idx)} />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup style={{
                        display: 'flex',
                        width: '50%',
                        justifyContent: 'space-between',
                        marginTop: '0px',
                        gap: '10px'
                      }}>
                        <InputGroup
                          className='input-group-alternative mb-3'>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <i className='ni ni-hat-3' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder='Order' type='text'
                            name='order' value={item.order}
                            onChange={(event) => this.editItem(event, idx)} />

                        </InputGroup>
                        <Input type={'select'}
                          name={'orientation'}
                          value={item.orientation}
                          onChange={(event) => this.editItem(event, idx)}>
                          <option>Select Orientation</option>
                          <option value={'left'}>Left</option>
                          <option value={'center'}>Center</option>
                          <option value={'right'}>Right</option>
                        </Input>

                      </FormGroup>
                    </>
                  }
                  {this.state?.link?.itemList[idx]?.showTable &&
                    <>
                      <h4>Table Data</h4>

                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <textarea
                          rows='3'
                          placeholder='Paste your excel form data here...'
                          // onPaste={this.handlePaste}
                          onChange={(event) => this.editItem(event, idx, 'table')}
                          value={this.state.link.itemList[idx]?.rawStr}
                        />
                        {this.state.link.itemList[idx]?.tableArray && this.tableRow(this.state.link.itemList[idx]?.tableArray, idx)}

                      </div>

                      <FormGroup style={{
                        display: 'flex',
                        width: '50%',
                        justifyContent: 'space-between',
                        marginTop: '0px'
                      }}>
                        <InputGroup className='input-group-alternative mb-3'>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <i className='ni ni-hat-3' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder='Order' type='text' name='order' value={item.order}
                            onChange={(event) => this.editItem(event, idx)} />
                        </InputGroup>
                      </FormGroup>
                    </>
                  }

                  <Row key={idx}>
                    {this.state?.link?.itemList[idx]?.showAllButton &&
                      <>
                        <Col xl={12} className={'mb-2'}>

                          {!this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showText && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                            <Button onClick={() => this.addButtonHandler('showText', idx)}
                              className={'btn btn-primary'}><i
                                className={'fa fa-plus'} />&nbsp;&nbsp; Add Text</Button>
                          }
                          {this.state.link.itemList[idx].showText && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                            <div style={{
                              display: 'flex',
                              alignItems: 'flex-start'
                            }}>
                              {!this.state.link.itemList.length <= 1 &&
                                <>
                                  <Button onClick={() => {
                                    this.addButtonHandler('showText', idx)
                                    this.removeItem(idx)
                                  }}
                                    style={{ backgroundColor: 'red', color: 'white' }}
                                    className={'btn'}><i
                                      className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                                </>
                              }
                              {this.state.link.itemList[idx].addButton &&
                                <Button onClick={() => this.addItem(idx)}
                                  className={'btn'}><i
                                    className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                              }
                            </div>
                          }

                          {!this.state?.link?.itemList[idx]?.showImage && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showVideo &&
                            <Button onClick={() => this.addButtonHandler('showImage', idx)}
                              className={'btn btn-primary'}><i
                                className={'fa fa-plus'} />&nbsp;&nbsp; Add Image</Button>

                          }
                          {this.state.link.itemList[idx].showImage && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showVideo &&
                            <div style={{
                              display: 'flex',
                              alignItems: 'flex-start'
                            }}>
                              <Button onClick={() => {
                                this.addButtonHandler('showImage', idx)
                                this.removeItem(idx)
                              }}
                                className={'btn'}
                                style={{ backgroundColor: 'red', color: 'white' }}><i
                                  className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                              {this.state.link.itemList[idx].addButton &&
                                <Button onClick={() => this.addItem(idx)}
                                  className={'btn'}><i
                                    className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                              }
                            </div>
                          }


                          {!this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showVideo && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage &&
                            <Button onClick={() => this.addButtonHandler('showVideo', idx)}
                              className={'btn btn-primary'}><i
                                className={'fa fa-plus'} />&nbsp;&nbsp; Add Video</Button>
                          }
                          {this.state.link.itemList[idx].showVideo && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage &&
                            <div style={{
                              display: 'flex',
                              alignItems: 'flex-start'
                            }}>
                              <Button onClick={() => {
                                this.addButtonHandler('showVideo', idx)
                                this.removeItem(idx)
                              }}
                                className={'btn'}
                                style={{ backgroundColor: 'red', color: 'white' }}><i
                                  className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                              {this.state.link.itemList[idx].addButton &&
                                <Button onClick={() => this.addItem(idx)}
                                  className={'btn'}><i
                                    className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                              }
                            </div>

                          }

                          {!this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showTable && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showVideo && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage &&
                            <Button onClick={() => this.addButtonHandler('showTable', idx)}
                              className={'btn btn-primary'}><i
                                className={'fa fa-plus'} />&nbsp;&nbsp; Add Table</Button>
                          }
                          {this.state.link.itemList[idx].showTable && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                            <div style={{
                              display: 'flex',
                              alignItems: 'flex-start'
                            }}>
                              <Button onClick={() => {
                                this.addButtonHandler('showTable', idx)
                                this.removeItem(idx)
                              }}
                                className={'btn'}
                                style={{ backgroundColor: 'red', color: 'white' }}><i
                                  className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                              {this.state.link.itemList[idx].addButton &&
                                <Button onClick={() => this.addItem(idx)}
                                  className={'btn'}><i
                                    className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                              }
                            </div>

                          }

                          {!this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showButton && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state.link.itemList[idx].showVideo && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showTable &&
                            <Button onClick={() => this.addButtonHandler('showButton', idx)}
                              className={'btn btn-primary'}><i
                                className={'fa fa-plus'} />&nbsp;&nbsp; Add Button</Button>
                          }
                          {this.state.link.itemList[idx].showButton && !this.state?.link?.itemList[idx]?.showQuiz && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                            <div style={{
                              display: 'flex',
                              alignItems: 'flex-start'
                            }}>
                              <Button onClick={() => {
                                this.addButtonHandler('showButton', idx)
                                this.removeItem(idx)
                              }}
                                className={'btn'}
                                style={{ backgroundColor: 'red', color: 'white' }}><i
                                  className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                              {this.state.link.itemList[idx].addButton &&
                                <Button onClick={() => this.addItem(idx)}
                                  className={'btn'}><i
                                    className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                              }
                            </div>
                          }


                        </Col>
                      </>
                    }
                  </Row>
                </div>
              ))}
              {/* <Form>
            <Input
              id="exampleFormControlTextarea1"
              placeholder="Write your remarks here..."
              rows="3"
              type="textarea"
              name="remarks" value={this.state.remarks} onChange={this.handleInputChange.bind(this)}
            />
          </Form> */}
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("remarksModal")}
              >
                Close
              </Button>
              <Button color="primary" type="button"
                onClick={
                  () => {
                    this.setState({ disableSubmit: true })
                    let data = {
                      remarks: this.state.editorContentHtml,
                      status: this.state.assignmentStatus === 1 && this.state.assignmentStatus !== 0 ? 'Approved' : 'Denied',
                      objects: this.handleObjects()?.[0] === null ? [] : this.handleObjects()
                    }
                    Services.updateAssignment(this.state.selectedRow.id, data).then((response) => {
                      // this.setState({data: response})
                      this.getAssignmentList()
                      this.toggleModal("remarksModal");
                      this.handleReset();
                      this.setState({ remarks: '' })
                      this.setState({ disableSubmit: false })
                      this.props.enqueueSnackbar('Successfully Submitted', {
                        variant: 'success',
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        }
                      }
                      )
                    })
                      .catch((error) => {
                        this.setState({ disableSubmit: false })
                        this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                          variant: 'warning',
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                          }
                        }
                        )
                      })

                  }
                } disabled={this.state.disableSubmit}>
                Submit
              </Button>
            </div>
          </Modal>
        </Container>

        {
          this.state.detailsPanel &&
          <StudentDetailsAssignment
            selectedRow={this.state.selectedRow}
            getPendingAssignment={this.getPendingAssignments}
            getAssignmentList={this.state.getAssignmentList}
            disableSubmit={this.state.disableSubmit} handleSetRemarks={this.handleSetRemarks} lessonRemarks={this.state.lessonRemarks} handleApproveDeny={this.handleApproveDeny.bind(this)} remarks={this.state.remarks} uploadImageCallBack={this.uploadImageCallBack} onEditorStateChange={this.onEditorStateChange.bind(this)} images={this.state?.assignmentImages} page="assignment" studentData={this.state.selectedRow} closePanel={() => {
              this.setState({ detailsPanel: false })
            }}></StudentDetailsAssignment>
        }


        <Viewer
          zoomSpeed={0.8}
          visible={this.state.imagesModal}
          onClose={() => this.setState({ imagesModal: false })}
          images={this.state.assignmentImages}
        />

        {this.state.updateSupport &&
          <TeacherSupportModal
            updateSupport={this.state.updateSupport}
            toggleState={this.toggleModal} />

        }

      </>
    );
  }
}
export default withSnackbar(Assignments);
