/* eslint-disable default-case */
import React, { useState } from 'react'
import Avatar from '../../assets/img/theme/avatar.png'
import CommentComponent from './PostComment'
import LikeComponent from './PostLikes'
import { Modal, Button } from 'reactstrap'
import moment from 'moment'
import { withSnackbar } from 'notistack'
const Services = require('../../RemoteServices/RemoteServices.js');

const SharedPost = (props) => {

    let clickHandler = null
    let [openPostMenu, setPostMenu] = useState(false)


    let [commentsModal, setCommentsModal] = useState(false)
    let [likesModal, setLikesModal] = useState(false)
    let [editModal, setEditModal] = useState(false)
    let [imageChange, setImageChange] = useState(false)


    let [commentsList, setCommentsList] = useState([])
    let [likesList, setLikesList] = useState([])
    let [commentsLoading, setCommentsLoading] = useState(true)
    let [likesLoading, setLikesLoading] = useState(true)
    let [wordLimit, setWordLimit] = useState(1000)
    let [deleteCommentConformModal, setDeleteConformModal] = useState(false)

    let [imagePreview, setImagePreview] = useState('')

    let { data } = props
    let { comment_count, message, id, image, pin, like_count, user_full_name, user_profile_picture, created_at, share_count } = data

    let [editPostData, setEditPostData] = useState({
        message: '',
        image: ""
    })

    const formatTime = (time) => {
        return (`${moment(time).fromNow()}, ${moment(time).format('MM/DD/YYYY')}`)
    }

    const fetchPostComments = () => {
        Services.getPostComments(id).then((response) => {
            setCommentsList(response.results)
            setCommentsLoading(false)
        })
    }

    const fetchPostLikes = () => {
        Services.getPostLikes(id).then((response) => {
            setLikesList(response.results)
            setLikesLoading(false)
        })
    }

    const handlePinUnpinPost = () => {
        Services.pinUnpinPost({ pin: !pin }, id).then((response) => {
            props.enqueueSnackbar(`Post ${pin ? 'Unpinned' : 'Pinned'} Successfully`, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            }
            )
            props.getCommunityPosts()
        })
    }

    const changeWordLimit = () => {
        wordLimit === 1000 ? setWordLimit(3000) : setWordLimit(1000)
    }

    const deletePost = () => {
        Services.deleteCommunityPost(id).then((response) => {
            toggleDeleteCommentModal()
            props.getCommunityPosts()
            props.enqueueSnackbar('Successfully deleted', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            }
            )

        })
    }

    const deleteComment = (commentId) => {
        Services.deleteCommunityPostComment(commentId).then((response) => {
            props.getCommunityPosts()
            setCommentsModal(false)
            props.enqueueSnackbar('Comment successfully deleted', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            }
            )

        })
    }

    const deleteReply = (replyId) => {
        Services.deleteCommunityPostCommentReply(replyId).then((response) => {
            props.getCommunityPosts()
            setCommentsModal(false)
            props.enqueueSnackbar('Reply successfully deleted.', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            }
            )

        })
    }

    const toggleCommentsModal = () => {
        setCommentsModal(!commentsModal)
    }

    const toggleLikesModal = () => {
        setLikesModal(!likesModal)
    }

    const toggleDeleteCommentModal = () => {
        setDeleteConformModal(!deleteCommentConformModal)
    }

    const toggleEditModal = () => {
        setImagePreview(image)
        setEditPostData({
            message: message,
            image: image ? image : ""
        })
        setEditModal(!editModal)
    }

    const getExtension = (filename) => {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    }

    const isImage = (filename) => {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
            case 'gif':
            case 'bmp':
            case 'png':
                //etc
                return true;
        }
        return false;
    }


    const handleImageSelect = (e) => {
        const { files } = e.target
        let filename = e.target.files[0].name
        if (isImage(filename)) {
            setImagePreview()
            setImageChange(true)
            var reader = new FileReader();
            reader.readAsDataURL(files[0]);
            setImagePreview(window.URL.createObjectURL(files[0]))
            reader.onload = function () {
                console.log(reader.result)
                setEditPostData({ ...editPostData, image: reader.result })
            }
        }
        else {
            props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            })

        }
    }

    const handlePostUpdate = () => {
        Services.updateCommunityPost(imageChange ? editPostData : { message: editPostData.message }, id).then((response) => {
            props.getCommunityPosts()
            toggleEditModal()
            props.enqueueSnackbar('Successfully updated.', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            }

            )
        })
    }


    return (
        <div className="communityPostContainer">
            <div className="communityPostTopDiv">
                <div className="communityPostUserImageDiv">
                    <img alt="avatar" src={user_profile_picture !== null ? user_profile_picture : Avatar}></img>
                </div>
                <div className="communityPostNameTimeDiv">
                    <span className="communityPostUsername">{user_full_name}</span>
                    <span>{formatTime(created_at)}</span>
                </div>
                <div className="pinIconDiv">
                </div>

                {openPostMenu &&

                    <div className="postMenuOptions" onMouseLeave={() => setPostMenu(!openPostMenu)}>
                        <ul>
                            <li onClick={toggleEditModal}>Edit</li>
                            <li onClick={toggleDeleteCommentModal}>Delete</li>
                            <li onClick={handlePinUnpinPost}>{pin ? 'Unpin' : 'Pin To Top'} </li>
                        </ul>
                    </div>}

            </div>
            <div className="communityPostTextArea">
                {message.length < 1000 ? <span>{message}</span> : <span>{message.slice(0, wordLimit)} <span className="seeMoreSpan" onClick={changeWordLimit}> See  {wordLimit === 1000 ? 'More' : 'Less'}</span></span>}

            </div>
            {image !== null &&
                <div className="communityPostImageDiv">
                    <img alt="postimg" src={image !== null ? image : Avatar}></img>
                </div>}

            <div className="likeCommentShareDiv">
                <span onClick={() => { toggleLikesModal(); fetchPostLikes() }}>{like_count} {like_count < 2 ? 'Like' : 'Likes'}</span>
                <span onClick={() => { toggleCommentsModal(); fetchPostComments() }}> {comment_count} Comments</span>
                <span> {share_count} Shares</span>
            </div>

            <Modal
                className="modal-dialog-centered"
                isOpen={commentsModal}
                toggle={toggleCommentsModal}
            >
                <div className="modal-header">
                    <h3 className="modal-title" id="exampleModalLabel">
                        Post Comments
                    </h3>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleCommentsModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    {commentsLoading ? 'Loading' :
                        commentsList.length !== 0 ?
                            commentsList.map((data, index) => {
                                return (
                                    <CommentComponent deleteComment={deleteComment} data={data} />
                                )
                            })

                            : 'No comments'
                    }


                </div>
                <div className="modal-footer">
                </div>
            </Modal>
            <Modal
                className="modal-dialog-centered"
                isOpen={likesModal}
                toggle={toggleLikesModal}
            >
                <div className="modal-header">
                    <h3 className="modal-title" id="exampleModalLabel">
                        Post Likes
                    </h3>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleLikesModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    {likesLoading ? 'Loading' :
                        likesList.length !== 0 ?
                            likesList.map((data, index) => {
                                return (
                                    <LikeComponent data={data} />
                                )
                            })

                            : 'No Likes'
                    }


                </div>
                <div className="modal-footer">
                </div>
            </Modal>
            <Modal
                className="modal-dialog-centered"
                isOpen={deleteCommentConformModal}
                toggle={toggleDeleteCommentModal}
            >
                <div className="modal-header">
                    <h3 className="modal-title" id="exampleModalLabel">
                        Confirmation
                    </h3>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleDeleteCommentModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <h4>Are you sure you want to delete this post?</h4>


                </div>
                <div className="modal-footer">
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleDeleteCommentModal}
                    >
                        Cancel
                    </Button>
                    <Button color="danger" type="button"
                        onClick={() => deletePost()}>
                        Delete
                    </Button>
                </div>
            </Modal>

            {/* Edit Modal */}

            <Modal
                className="modal-dialog-centered editpostmodal"
                isOpen={editModal}
                toggle={toggleEditModal}
                size='lg'
            >
                <div className="modal-header">
                    <h3 className="modal-title" id="exampleModalLabel">
                        Edit Post
                    </h3>
                    <input
                        type='file'
                        name='logo'
                        hidden={true}
                        onChange={handleImageSelect}
                        ref={(input) => {
                            clickHandler = input
                        }}
                    />
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleEditModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    {image &&

                        <div className="editPostImageContainer">
                            <img alt="postPic" className="editPostImage" src={editPostData.image}></img>
                            <div>
                                <Button type="button" color="primary" onClick={() => clickHandler.click()}>Change Image</Button>
                            </div>

                        </div>}

                    <div>
                        <textarea className="editPostTextArea" type="textarea" onChange={(e) => setEditPostData({ ...editPostData, message: e.target.value })} value={editPostData.message}></textarea>
                    </div>


                </div>
                <div className="modal-footer">
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleEditModal}
                    >
                        Cancel
                    </Button>
                    <Button color="primary" type="button"
                        onClick={() => handlePostUpdate()}>
                        Update
                    </Button>
                </div>
            </Modal >

        </div >
    )
}

export default withSnackbar(SharedPost)