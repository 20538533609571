import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { withSnackbar } from 'notistack'
import { Component } from 'react'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap'
import Services from '../../RemoteServices/RemoteServices'

class CallLink extends Component {
    state = {
        link: '',
        videoLink: '',
        id:''
    }

    getChatConfig = async () => {
        await Services.getChatConfig().then((response) => {
            this.setState({ id:response?.[0]?.id,link: response?.[0]?.audio_link, videoLink: response?.[0]?.video_link })
        })
            .catch((error) => {
                throw error
            })
    }

    updateChatConfig = async () => {
        let reqDto={
            audio_link:this.state.link,
            video_link:this.state.videoLink
        }
        await Services.updateChatConfig(this.state.id,reqDto).then((response) => {
            this.props.enqueueSnackbar('Meet Link Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            this.props.toggleState('callLink')
        })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
                throw error
            })
    }

    componentDidMount() {
        this.getChatConfig();
    }

    handleSubmit = () => {
        let newData = {
            status: this.state.status === 'Active' ? true : false,
            code: this.state.promoCode.toUpperCase(),
            discount: Number(this.state.discount),
            user: this.props.studentData.user.id
        }

        Services.createPromoCode(newData).then((response) => {
            this.props.enqueueSnackbar('Promo Code Created Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            this.props.toggleState('promoCode')
            this.props.getPromoCodeDetail(this.props.studentData.user.id);
        })
            .catch((error) => {
                this.props.enqueueSnackbar(error.message, {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })
    }


    handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    render() {
        const { open, toggleState, studentData, type } = this.props
        return (
            <Dialog PaperProps={{ style: { width: '800px', minHeight: '300px', height: 'auto' } }}
                open={open} onClose={() => toggleState('callLink')}>
                <div className='modal-header mb-0'>
                    <h3 className='modal-title' id='exampleModalLabel'>
                        Update Call Link
                    </h3>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('callLink')}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body mt-0 pt-0'>
                    <Form>
                        <>
                            <FormGroup>
                                <Label>Call Link</Label>
                                <Input
                                    type={'text'} name={'link'}
                                    value={this.state.link}
                                    onChange={this.handleInputChange}
                                    placeholder={'Enter Audio Link..'}>
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>Video Link</Label>
                                <Input
                                    type={'text'} name={'videoLink'}
                                    value={this.state.videoLink}
                                    onChange={this.handleInputChange}
                                    placeholder={'Enter Video Link..'}>
                                </Input>
                            </FormGroup>

                        </>
                    </Form>
                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('callLink')}
                    >
                        Cancel
                    </Button>
                    <Button color='primary' type='button' onClick={() => this.updateChatConfig()}>
                        Update
                    </Button>
                </div>
            </Dialog>
        )
    }
}

export default withSnackbar(CallLink)
