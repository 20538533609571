import React from "react";
import { withSnackbar } from 'notistack';

import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
  } from "reactstrap";
const Services = require('../../../RemoteServices/RemoteServices.js');


class UpdateCurrentLesson extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
     open:true,
     amount: '',
     disableSubmit:false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
 
   render(){
    const studentID = this.props.studentData.id
       return (
           <> 
            <div>
            <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Lesson Number" type="text" name="amount" value={this.state.amount} onChange={this.handleInputChange} />
                  </InputGroup>
                </FormGroup>
                <Button size="sm"  color="primary" type="button" onClick={()=>{
                  this.setState({disableSubmit:true})
                  let data ={
                    order: parseInt(this.state.amount),
                    student: studentID
                  }
                  Services.updateCurrentLesson(data).then((response)=>{
                    this.props.onclose()
                    this.setState({disableSubmit:false})
                    this.props.enqueueSnackbar('Successfully Updated', { 
                        variant: 'success',
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        }
                      }
                    )
                  })
                }} disabled={this.state.disableSubmit}>
                    UPDATE
                  </Button>
                  <Button size="sm"  color="secondary" type="button" onClick={()=> {
                      this.props.onclose()
                  }}>
                    CANCEL
                  </Button>
              </Form>
             </div>
           </>
       )
   }
}

export default withSnackbar(UpdateCurrentLesson)