import React from 'react'
import Header from 'components/Headers/Header.js'
import Icon from '@material-ui/core/Icon'

import {
    Card,
    Table,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Button,
    Modal,
    UncontrolledTooltip
} from 'reactstrap'
import MaterialTable from 'material-table'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
import UsersForm from 'components/Forms/UsersForm.js'
import avatar from '../../assets/img/dummyUser.png'


const Services = require('../../RemoteServices/RemoteServices.js')

class Staff extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addFormView: false,
            data: [],
            updatePasswordView: false,
            newPassword1: '',
            newPassword2: '',
            selectedRow: null,
            loading: true,
            masterPassword: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.submitChangePassword = this.submitChangePassword.bind(this)
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    async componentDidMount() {
        await this.getUsersList()
    }

    async getUsersList() {
        this.setState({ loading: true })
        await Services.getStaffs().then((response) => {
            this.setState({ data: response, loading: false })
        })
    }

    submitChangePassword() {
        let userId = this.state.selectedRow.id
        let data = {
            password1: this.state.newPassword1,
            password2: this.state.newPassword2
        }
        Services.updatePassword(userId, data).then((response) => {
            this.setState({ updatePasswordView: false })
            this.props.enqueueSnackbar('Password Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })
    }

    handleCreateStaffOpen() {
        this.toggleState('addFormView')
        this.toggleState('passwordModal')
    }

    render() {
        return (
            <>
                <Header onlineCount={this.props.onlineCount} onlineUsers={this.props.onlineUsers} />
                <Container className='mt--7' fluid>
                    <Row>
                        <div className=' col'>
                            <Card className=' shadow'>
                                {!(this.state.addFormView) ?
                                    this.state.loading ?
                                        <CircularProgress style={{
                                            margin: 50,
                                            alignSelf: 'center'
                                        }} />
                                        :
                                        <MaterialTable
                                            columns={[
                                                {
                                                    title: 'S.N',
                                                    field: 'sn',
                                                    render: rowData => <>{rowData.tableData.id + 1} </>,
                                                    editable: false
                                                },
                                                {
                                                    title: 'Avatar',
                                                    field: 'imageUrl',
                                                    render: rowData => (
                                                        <div style={{ position: 'relative', width: 40, height: 40 }}>
                                                            <img
                                                                src={rowData.tableData.profile_picture || avatar}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'fill',
                                                                    borderRadius: '50%'
                                                                }}
                                                            />
                                                            <span
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: 0,
                                                                    right: 0,
                                                                    width: 12,
                                                                    height: 12,
                                                                    borderRadius: '50%',
                                                                    backgroundColor: rowData.tableData.is_online ? 'green' : 'lightgrey',
                                                                    border: '2px solid white'
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                },
                                                { title: 'Full Name', field: 'full_name' },
                                                { title: 'Email', field: 'email' },
                                                { title: 'Phone', field: 'phone_number' },
                                                {
                                                    title: 'Role', field: 'role_type',
                                                    lookup: {
                                                        'Superuser': 'Super Admin',
                                                        'Admin': 'Admin',
                                                        'Basic': 'Basic (SML)',
                                                        'Medium': 'Medium (SMLAC)',
                                                        'Pro Ext': 'Pro Ext (SMLAC.Ext)',
                                                        'Pro Int': 'Pro Int (SMLAC.Int)',
                                                    }
                                                },
                                                {
                                                    title: 'Last Login',
                                                    field: 'last_login',
                                                    editable: false,
                                                    render: rowData => <>{new Date(rowData?.last_login).toLocaleString('en-US', {
                                                        weekday: 'long',
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        second: 'numeric'
                                                    })}</>
                                                }
                                            ]}
                                            data={this.state.data}
                                            title='Staff List'
                                            actions={[
                                                {
                                                    icon: 'delete',
                                                    tooltip: 'Delete Lesson ',
                                                    onClick: (event, rowData) => {
                                                        // Do save operation
                                                        this.setState({ confirmModal: true, selectedRow: rowData })
                                                    }
                                                },
                                                {
                                                    icon: 'add',
                                                    tooltip: 'Add Staff',
                                                    isFreeAction: true,
                                                    onClick: (event) => this.toggleState('passwordModal')
                                                },
                                                {
                                                    icon: 'update',
                                                    tooltip: 'Update Password ',
                                                    onClick: (event, rowData) => {
                                                        this.setState({
                                                            updatePasswordView: true,
                                                            selectedRow: rowData
                                                        })
                                                        // Do save operation
                                                    }
                                                }
                                            ]}
                                            options={{
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    backgroundColor: '#6772E5',
                                                    color: '#FFF'
                                                }
                                            }}
                                            editable={{
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise((resolve, reject) => {
                                                        setTimeout(() => {
                                                            {
                                                                Services.updateStaff(oldData.id, {
                                                                    full_name: newData.full_name,
                                                                    email: newData.email,
                                                                    phone_number: newData.phone_number,
                                                                    role_type: newData.role_type
                                                                }).then((response) => {
                                                                    this.getUsersList()
                                                                    this.props.enqueueSnackbar('User Updated Successfully', {
                                                                        variant: 'success',
                                                                        anchorOrigin: {
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right'
                                                                        }
                                                                    })
                                                                })
                                                                    .catch((error) => {
                                                                        this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                                            variant: 'warning',
                                                                            anchorOrigin: {
                                                                                vertical: 'bottom',
                                                                                horizontal: 'right'
                                                                            }
                                                                        }
                                                                        )
                                                                    })
                                                            }
                                                            resolve()
                                                        }, 300)
                                                    })
                                            }}
                                        />
                                    :
                                    <>
                                        <div class='d-flex mt-3'>
                                            <div class='mr-auto ml-4 mt-2'><h2>Staff List</h2></div>
                                            <div class='p-2'>
                                                <Button className='btn-icon btn-3' color='primary' type='button'
                                                    onClick={() => this.toggleState('addFormView')}>
                                                    <span className='btn-inner--icon'>
                                                        <i className='ni ni-bag-17' />
                                                    </span>
                                                    <span className='btn-inner--text'>Back</span>
                                                </Button>
                                            </div>
                                        </div>

                                        <hr class='solid'></hr>
                                        <UsersForm onSuccess={() => {
                                            this.setState({ addFormView: false })
                                            this.getUsersList()
                                        }}></UsersForm>
                                    </>
                                }
                            </Card>
                        </div>
                    </Row>
                </Container>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.confirmModal}
                    toggle={() => this.toggleState('confirmModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Are you sure you want to delete?</h4>
                        <Form>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                            onClick={
                                () => {
                                    Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                        Services.deleteStaff(this.state.selectedRow.id).then((response) => {
                                            this.getUsersList()
                                            this.toggleState('confirmModal')
                                            this.props.enqueueSnackbar('Successfully deleted', {
                                                variant: 'success',
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'right'
                                                }
                                            }
                                            )
                                        })
                                            .catch((error) => {
                                                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                    variant: 'warning',
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }
                                                }
                                                )
                                            })


                                    }).catch((error) => {
                                        this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                        )
                                    })


                                }
                            }>
                            Yes
                        </Button>
                    </div>
                </Modal>
                <Dialog open={this.state.updatePasswordView} onClose={() => this.toggleState('updatePasswordView')}>
                    <DialogTitle id='form-dialog-title'>Change Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter new password to change.
                        </DialogContentText>
                        <FormGroup>
                            <InputGroup className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <i className='ni ni-hat-3' />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Enter Password' type='text' name='newPassword1'
                                    value={this.state.newPassword1} onChange={this.handleInputChange.bind(this)} />
                            </InputGroup>
                        </FormGroup>

                        <FormGroup>
                            <InputGroup className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <i className='ni ni-hat-3' />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Enter Password Again' type='text' name='newPassword2'
                                    value={this.state.newPassword2} onChange={this.handleInputChange.bind(this)} />
                            </InputGroup>
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.toggleState('updatePasswordView')} color='primary'>
                            Cancel
                        </Button>
                        <Button onClick={this.submitChangePassword.bind(this)} color='primary'>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.passwordModal}
                    toggle={() => this.toggleState('passwordModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Enter password to perform this action.</h4>
                        <Form onSubmit={
                            (e) => {
                                e.preventDefault()
                                Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                    this.handleCreateStaffOpen()
                                }).catch((error) => {
                                    this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                        variant: 'warning',
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }
                                    }
                                    )
                                })
                            }
                        }>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                            onClick={
                                () => {
                                    Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                        this.handleCreateStaffOpen()
                                    }).catch((error) => {
                                        this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                        )
                                    })


                                }
                            }>
                            Enter
                        </Button>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withSnackbar(Staff)