import React from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col
} from 'reactstrap'
import { withSnackbar } from 'notistack'
import Select from 'react-select'
import { DatePicker } from 'antd'


const Services = require('../../RemoteServices/RemoteServices.js')


class StudentForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fullName: '',
            email: '',
            phone: '',
            password1: '',
            password2: '',
            referralCode: '',
            selectedRow: {},
            formModal: false,
            gender: '',
            dob: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

     convertDateFormat=(originalDateString)=> {
        const originalDate = new Date(originalDateString);

        const year = originalDate.getFullYear();
        const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(originalDate.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    handleSubmit() {
        let user = {
            full_name: this.state.fullName,
            email: this.state.email,
            password1: this.state.password1,
            password2: this.state.password2,
            phone_number: this.state.phone,
            referral_code: this.state.referralCode,
            gender: this.state.gender,
            dob: this.convertDateFormat(this.state.dob)
        }
        Services.createStudent(user).then((response) => {
            this.props.enqueueSnackbar('Student Created Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })

            this.props.onSuccess()
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            })
    }


    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    handleDatePickerChange = (date, dateString, id) => {
        this.setState({
            dob: date
        })
    }

    render() {
        return (
            <>
                <Form role='form' style={{
                    paddingLeft: 20,
                    paddingRight: 20
                }}>
                    <FormGroup>
                        <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Full name' type='text' name='fullName' value={this.state.fullName}
                                   onChange={this.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Email' type='email' name='email' value={this.state.email}
                                   onChange={this.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <div style={{ width: '30%' }}><Input type='select' name='gender' value={this.state.gender}
                                                                 onChange={this.handleInputChange}>
                                <option value='' hidden>Select Gender</option>
                                <option value={'Male'}>Male</option>
                                <option value={'Female'}>Female</option>
                            </Input>
                            </div>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                                <DatePicker style={{ width: 400 }} name={'dob'} value={this.state.dob} onChange={(date, dateString) => this.handleDatePickerChange(date, dateString, 1)}/>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Phone' type='text' name='phone' value={this.state.phone}
                                   onChange={this.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Password' type='password' name='password1' value={this.state.password1}
                                   onChange={this.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Password Again' type='password' name='password2'
                                   value={this.state.password2} onChange={this.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Referral Code' type='text' name='referralCode'
                                   value={this.state.referralCode} onChange={this.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                    {/* <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                        id="exampleFormControlTextarea1"
                        placeholder="Description / Notes"
                        rows="3"
                        type="textarea"
                        name="description"
                        value={this.state.description} 
                        onChange={this.handleInputChange}
                    />
                  </InputGroup>
                </FormGroup> */}
                    <div className='pb-4'>
                        <Button className='mt-4' color='primary' type='button' onClick={this.handleSubmit}>
                            Create
                        </Button>
                    </div>
                </Form>

            </>
        )
    }
}

export default withSnackbar(StudentForm)