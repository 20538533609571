/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap'

const Services = require('../../RemoteServices/RemoteServices.js')

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            totalUsers: 0,
            approvedUsers: 0,
            pendingAssignments: 0,
            totalAssignments: 0
        }
    }


    componentDidMount() {
        Services.getDashboardData().then((response) => {
            this.setState({
                totalUsers: response.total,
                totalAssignments: response.assignment,
                approvedUsers: response.approved,
                pendingAssignments: response.pending
            })
        })
    }

    render() {
        let { page } = this.props
        let userType = JSON.parse(localStorage.getItem('user')).user_type

        return (
            <>

                <div className={`header bg-gradient-info ${page === 'lesson' ? 'pb-8' : 'pb-8 pt-5 pt-md-8'} `}>
                    {page === 'lesson' ? '' :
                        <Container fluid>
                            {userType === 'Superuser' &&
                                <div className='header-body'>
                                    {/* Card stats */}
                                    <Row>
                                        <Col lg='6' xl='3'>
                                            <Card className='card-stats mb-4 mb-xl-0'>
                                                <CardBody>
                                                    <Row>
                                                        <div className='col'>
                                                            <CardTitle
                                                                tag='h5'
                                                                className='text-uppercase text-muted mb-0'
                                                            >
                                                                Total Users
                                                            </CardTitle>
                                                            <span className='h2 font-weight-bold mb-0'>
                            {this.state.totalUsers}
                          </span>
                                                        </div>
                                                        <Col className='col-auto'>
                                                            <div
                                                                className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                                                                <i className='fas fa-chart-bar' />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <p className='mt-3 mb-0'>
                                                        {/* <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "} */}
                                                        <span style={{fontWeight:'bold'}}>Online: {this?.props?.onlineUsers?.length>0?this?.props?.onlineUsers?.length:this?.props?.onlineCount}</span>
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg='6' xl='3'>
                                            <Card className='card-stats mb-4 mb-xl-0'>
                                                <CardBody>
                                                    <Row>
                                                        <div className='col'>
                                                            <CardTitle
                                                                tag='h5'
                                                                className='text-uppercase text-muted mb-0'
                                                            >
                                                                Approved users
                                                            </CardTitle>
                                                            <span className='h2 font-weight-bold mb-0'>
                            {this.state.approvedUsers}
                          </span>
                                                        </div>
                                                        <Col className='col-auto'>
                                                            <div
                                                                className='icon icon-shape bg-warning text-white rounded-circle shadow'>
                                                                <i className='fas fa-users' />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <p className='mt-3 mb-0 text-muted text-sm'>
                                                        {/* <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 3.48%
                        </span>{" "} */}
                                                        <span className='text-nowrap'>Overall</span>
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg='6' xl='3'>
                                            <Card className='card-stats mb-4 mb-xl-0'>
                                                <CardBody>
                                                    <Row>
                                                        <div className='col'>
                                                            <CardTitle
                                                                tag='h5'
                                                                className='text-uppercase text-muted mb-0'
                                                            >
                                                                Assignments
                                                            </CardTitle>
                                                            <span
                                                                className='h2 font-weight-bold mb-0'>{this.state.totalAssignments}</span>
                                                        </div>
                                                        <Col className='col-auto'>
                                                            <div
                                                                className='icon icon-shape bg-yellow text-white rounded-circle shadow'>
                                                                <i className='fas fa-chart-pie' />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <p className='mt-3 mb-0 text-muted text-sm'>
                                                        {/* <span className="text-warning mr-2">
                          <i className="fas fa-arrow-down" /> 1.10%
                        </span>{" "} */}
                                                        <span className='text-nowrap'>Submitted In Total</span>
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg='6' xl='3'>
                                            <Card className='card-stats mb-4 mb-xl-0'>
                                                <CardBody>
                                                    <Row>
                                                        <div className='col'>
                                                            <CardTitle
                                                                tag='h5'
                                                                className='text-uppercase text-muted mb-0'
                                                            >
                                                                Assignment Pending
                                                            </CardTitle>
                                                            <span className='h2 font-weight-bold mb-0'>
                            {this.state.pendingAssignments}
                          </span>
                                                        </div>
                                                        <Col className='col-auto'>
                                                            <div
                                                                className='icon icon-shape bg-info text-white rounded-circle shadow'>
                                                                <i className='fas fa-chart-bar' />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <p className='mt-3 mb-0 text-muted text-sm'>
                                                        {/* <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 12%
                        </span>{" "} */}
                                                        <span className='text-nowrap'>Action Needed</span>
                                                    </p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Container>}

                </div>
            </>
        )
    }
}

export default Header
