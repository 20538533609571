/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withSnackbar } from 'notistack';
// reactstrap components
import {
  Form,
  FormGroup,
  Label,
  Input,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Modal
} from "reactstrap";
import './AdminNavbar.css'
const Services = require('../../RemoteServices/RemoteServices')

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutModal: false,
      passwordModal: false,
      currentPassword: '',
      password1: '',
      password2: '',
      visPass1: false,
      visPass2: false,
    };
    this.toggleModal = this.toggleModal.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
  }

  toggleModal(state) {
    console.log(state)
    this.setState({ [state]: !this.state[state] })
  }

  handleLogout() {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    this.props.history.push('/auth/login')
  }

  handlePasswordChange() {
    let data = {
      password: this.state.password2,
    }
    if (this.state.password1 !== this.state.password2) {
      this.props.enqueueSnackbar('Sorry your new password do not match. Please try again', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }
      }
      )
    } else {
      if (data.password.length < 5) {
        this.props.enqueueSnackbar('Password must be greater than 5 characters.', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
        )

      }
      else {
        Services.changeMasterPassword(data).then((response) => {
          this.props.enqueueSnackbar('Password changed successfully', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            }
          }
          )
          this.toggleModal('passwordModal')
        }).catch((error) => {
          this.props.enqueueSnackbar('Something went wrong, please try again.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            }
          }
          )
        })

      }

    }
  }

  countUniqueUsersByFullName = (messages) => {
    console.log('countttttt', messages)
    // Check if messages array is empty
    if (messages.length === 0) {
      return 0;
    }

    // Use a Set to store unique full_name values
    const uniqueUsers = new Set();

    // Iterate through each message
    messages.forEach(message => {
      // Add the full_name to the Set (duplicates will be ignored)
      uniqueUsers.add(message.full_name);
    });

    // Return the size of the Set, which represents the count of unique users
    return uniqueUsers.size;
  }



  render() {
    const userType = JSON.parse(localStorage.getItem('user')).user_type
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link>
            {userType === 'Superuser' &&
              <button onClick={() => this.toggleModal('passwordModal')} className="masterPasswordButton"></button>
            }
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              {console.log('asdfasdf', this.props.message)}
              {/* <Link to={'/admin/messages'} style={{ display: 'flex', flexDirection: 'row', gap: 10,cursor:'pointer' }}>
                <div style={{display:'flex'}}>
                  <i className={'fa fa-comments'} style={{ color: 'white', fontSize: 20 }} />
                  <div style={{marginTop:'-12px',display:'flex',flexDirection:'row',backgroundColor:'white',borderRadius:3,maxHeight:'15px',minWidth:'15px',justifyContent:'center',alignItems:'center',padding:2}}>
                  <span style={{fontSize:'12px',color:'indigo',fontWeight:'bold'}}>{this.props.location.pathname==='/admin/messages'?0:new Set(this.props.message.map(message => message.user)).size}</span>
                  </div>
                </div>
                </Link>&nbsp; */}
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("assets/img/theme/avatar.png")}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {JSON.parse(localStorage.getItem('user'))?.full_name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  {/* <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Settings</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Support</span>
                  </DropdownItem>
                  <DropdownItem divider /> */}
                  <DropdownItem href="#pablo" onClick={() => this.toggleModal('logoutModal')}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.logoutModal}
          toggle={() => this.toggleModal('logoutModal')}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Confirmation
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal('logoutModal')}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <h4>Are you sure you want to Log Out?</h4>


          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal('logoutModal')}
            >
              Cancel
            </Button>
            <Button color="danger" type="button"
              onClick={this.handleLogout}>
              Log Out
            </Button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.passwordModal}
          toggle={() => this.toggleModal('passwordModal')}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Change master password for English Hub
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal('passwordModal')}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              {/* <FormGroup>
                                      <Label for="exampleEmail">Current master password</Label>
                                      <Input  onChange={(e) => {this.setState({currentPassword:e.target.value})}} type='password'  placeholder="Current master password" />
                                      </FormGroup> */}
              <FormGroup>
                <Label for="exampleEmail">New password</Label>
             <div style={{
              display:'flex',
              flexDirection:'row',
              border:'1px solid #ffffff00',
              boxShadow:'0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
              alignItems:'center' ,
              borderRadius:'0.375rem'

             }}>
             <Input style={{
              border:'none',
              boxShadow:'none'
             }} onChange={(e) => { this.setState({ password1: e.target.value }) }} type={`${this.state.visPass1 ? 'text' : 'password'}`} placeholder="New password" />
             <span  className="mx-3"><i class={`fa ${this.state.visPass1 ? 'fa-eye' : 'fa-eye-slash'}`} aria-hidden="true" onClick={() => this.toggleModal('visPass1')} ></i></span>
             </div>
              </FormGroup>
              <Label for="exampleEmail">Confirm password</Label>
              <FormGroup>
             <div style={{
              display:'flex',
              flexDirection:'row',
              border:'1px solid #ffffff00',
              boxShadow:'0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)' ,
              alignItems:'center' ,
              borderRadius: '0.375rem'           }}>
             <Input style={{
              border:'none',
              boxShadow:'none'
             }} onChange={(e) => { this.setState({ password2: e.target.value }) }} type={`${this.state.visPass2 ? 'text' : 'password'}`} placeholder="Confirm new password" />
             <span className="mx-3">  <i class={`fa ${this.state.visPass2 ? 'fa-eye' : 'fa-eye-slash'}`} aria-hidden="true" onClick={() => this.toggleModal('visPass2')} ></i></span>
             </div>


              </FormGroup>


            </Form>


          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal('passwordModal')}
            >
              Cancel
            </Button>
            <Button color="primary" type="button"
              onClick={this.handlePasswordChange}>
              Change Password
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(withSnackbar(AdminNavbar));
