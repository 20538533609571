import React from "react";
import Header from "components/Headers/Header.js";
import {
  Card,
  Container,
  Row,
  Button,
  Modal,
  Form,
  FormGroup,
  Input
} from "reactstrap";
import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import CoursesForm from "components/Forms/CoursesForm.js";
const Services = require('../../RemoteServices/RemoteServices.js');

class Courses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addFormView: false,
      data: [],
      confirmModal: false,
      selectedRow: {},
      loading: true,
      masterPassword: '',
      passwordModal: false,
    }
    this.handleCreateCourseOpen = this.handleCreateCourseOpen.bind(this)
  }


  toggleState = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  componentDidMount() {
    this.getCourseList()
  }

  getCourseList() {
    this.setState({ loading: true })
    Services.getCourses().then((response) => {
      console.log(response)
      this.setState({ data: response, loading: false })
    })
  }

  handleCreateCourseOpen = () => {
    this.toggleState('passwordModal')
    this.toggleState('addFormView');
  }

  render() {
    return (
      <>
        <Header onlineCount={this.props.onlineCount} onlineUsers={this.props.onlineUsers} />
        <Container className="mt--7" fluid>
          <Row>
            <div className=" col">
              <Card className=" shadow">
                {!(this.state.addFormView) ?
                  this.state.loading ?
                    <CircularProgress style={{
                      margin: 50,
                      alignSelf: 'center'
                    }} />
                    :
                    <MaterialTable
                      columns={[
                        { title: 'S.N', field: 'id', editable: 'never', render: rowData => <>{rowData.tableData.id + 1}</> },
                        { title: 'Course Name', field: 'title', render: rowData => <b>{rowData.title}</b> },
                        { title: 'Description', field: 'description' },
                      ]}
                      data={this.state.data}
                      title="Courses"
                      actions={[
                        {
                          icon: 'delete',
                          tooltip: 'Delete Lesson ',
                          onClick: (event, rowData) => {
                            this.setState({ confirmModal: true, selectedRow: rowData })
                          }
                        },
                        {
                          icon: 'add',
                          tooltip: 'Add Course',
                          isFreeAction: true,
                          onClick: (event) => this.toggleState('passwordModal')
                        }
                      ]}
                      options={{
                        actionsColumnIndex: -1,
                        headerStyle: {
                          backgroundColor: '#6772E5',
                          color: '#FFF'
                        }
                      }}
                      editable={{
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              {
                                Services.updateCourse(oldData.id, newData).then((response) => {
                                  this.getCourseList()
                                  this.props.enqueueSnackbar('Course Updated Successfully', {
                                    variant: 'success',
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }
                                  })
                                })
                                  .catch((error) => {
                                    this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                      variant: 'warning',
                                      anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      }
                                    }
                                    )
                                  })
                              }
                              resolve();
                            }, 300);
                          }),
                      }}
                    />
                  :
                  <>
                    <div class="d-flex mt-3">
                      <div class="mr-auto ml-4 mt-2"><h2>Courses</h2></div>
                      <div class="p-2">
                        <Button className="btn-icon btn-3" color="primary" type="button" onClick={() => this.toggleState("addFormView")}>
                          <span className="btn-inner--icon">
                            <i className="ni ni-bag-17" />
                          </span>
                          <span className="btn-inner--text">Back</span>
                        </Button>
                      </div>
                    </div>

                    <hr class="solid"></hr>
                    <CoursesForm onSuccess={() => {
                      this.setState({ addFormView: false })
                      this.getCourseList()
                    }}></CoursesForm>
                  </>
                }
              </Card>
            </div>
          </Row>
        </Container>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.confirmModal}
          toggle={() => this.toggleState("confirmModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Confirmation
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleState("confirmModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <h4>Are you sure you want to delete?</h4>
            <Form>
              <FormGroup>
                <Input type="password" onChange={(e) => { this.setState({ masterPassword: e.target.value }) }} placeholder="Enter master password"></Input>
              </FormGroup>
            </Form>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleState("confirmModal")}
            >
              Cancel
            </Button>
            <Button color="primary" type="button"
              onClick={
                () => {
                  let selectedRowId = this.state.selectedRow.id
                  Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                    Services.deleteCourse(selectedRowId).then((response) => {
                      this.getCourseList()
                      this.toggleState("confirmModal")
                      this.props.enqueueSnackbar('Successfully deleted', {
                        variant: 'success',
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        }
                      }
                      )
                    })
                      .catch((error) => {
                        this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                          variant: 'warning',
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                          }
                        }
                        )
                      })

                  }).catch((error) => {
                    this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                      variant: 'warning',
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      }
                    }
                    )
                  })
                }
              }>
              Yes
            </Button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.passwordModal}
          toggle={() => this.toggleState("passwordModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Confirmation
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleState("passwordModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <h4>Enter password to perform this action.</h4>
            <Form>
              <FormGroup>
                <Input type="password" onChange={(e) => { this.setState({ masterPassword: e.target.value }) }} placeholder="Enter master password"></Input>
              </FormGroup>
            </Form>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleState("passwordModal")}
            >
              Cancel
            </Button>
            <Button color="primary" type="button"
              onClick={
                () => {
                  Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                    this.handleCreateCourseOpen()
                  }).catch((error) => {
                    this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                      variant: 'warning',
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      }
                    }
                    )
                  })


                }
              }>
              Enter
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default withSnackbar(Courses)