import React from 'react'
import 'image-upload-react/dist/index.css'
import '../../views/examples/Lessons.css'
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row
} from 'reactstrap'
import { withSnackbar } from 'notistack'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { customChunkRenderer } from 'draft-js-helpers'
import { TagsInput } from 'react-tag-input-component'

const Services = require('../../RemoteServices/RemoteServices.js')


class DictionaryForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            synonyms: [],
            antonyms: [],
            link_slug: '',
            link_image: '',
            lessonNo: '',
            title: '',
            description: '',
            videoLink: '',
            selectedOptionId: '',
            dropdownOptions: [],
            editorState: EditorState.createEmpty(),
            editorStateCopied: EditorState.createEmpty(),
            editorContentHtmlCopied: '',
            imagePreview: '',
            image: '',
            remarks: [],
            uniqueId: '',
            editorContentHtml: '',
            showEditorCode: false,
            showAllButton: true,
            showText: false,
            showImage: false,
            showVideo: false,
            video: '',
            link: {
                synonyms: [],
                antonyms: [],
                meaning: '',
                partOfSpeech: '',
                brePronounciation: '',
                amePronounciation: '',
                meaningEnglish: '',
                meaningNepali: '',
                detailMeaning: '',
                exampleMeaningEnglishEditorState: EditorState.createEmpty(),
                exampleMeaningEnglishStateCopied: EditorState.createEmpty(),
                exampleMeaningEnglishEditorContentHtmlCopied: '',
                exampleMeaningEnglishEditorContentHtml:'',
                exampleMeaningNepaliEditorState: EditorState.createEmpty(),
                exampleMeaningNepaliStateCopied: EditorState.createEmpty(),
                exampleMeaningNepaliEditorContentHtmlCopied: '',
                exampleMeaningNepaliEditorContentHtml:'',

                editorState: EditorState.createEmpty(),
                editorStateCopied: EditorState.createEmpty(),
                editorContentHtmlCopied: '',
                imagePreview: '',
                image: '',
                remarks: [],
                uniqueId: '',
                editorContentHtml: '',
                video: '',
                itemList: [{
                    showEditorCode: false,
                    editorState: EditorState.createEmpty(),
                    editorStateCopied: EditorState.createEmpty(),
                    editorContentHtmlCopied: '',
                    synonyms: [],
                    antonyms: [],
                    meaning: '',
                    partOfSpeech: '',
                    brePronounciation: '',
                    amePronounciation: '',
                    meaningEnglish: '',
                    meaningNepali: '',
                    detailMeaning: '',
                    exampleMeaningEnglishEditorState: EditorState.createEmpty(),
                    exampleMeaningEnglishStateCopied: EditorState.createEmpty(),
                    exampleMeaningEnglishEditorContentHtmlCopied: '',
                    exampleMeaningNepaliEditorState: EditorState.createEmpty(),
                    exampleMeaningNepaliStateCopied: EditorState.createEmpty(),
                    exampleMeaningNepaliEditorContentHtmlCopied: ''
                }]
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handlePaste = this.handlePaste.bind(this)
    }

    changeState = (state) => {
        this.setState((prevState) => ({
            ...prevState,
            [state]: !this.state[state]

        }))
    }


    addItem = async (idx) => {
        const { link } = this.state
        let item = {
            showEditorCode: false,
            editorState: EditorState.createEmpty(),
            editorStateCopied: EditorState.createEmpty(),
            editorContentHtmlCopied: '',
            synonyms: [],
            antonyms: [],
            meaning: '',
            partOfSpeech: '',
            brePronounciation: '',
            amePronounciation: '',
            meaningEnglish: '',
            meaningNepali: '',
            detailMeaning: '',
            exampleMeaningEnglishEditorState: EditorState.createEmpty(),
            exampleMeaningEnglishStateCopied: EditorState.createEmpty(),
            exampleMeaningEnglishEditorContentHtmlCopied: '',
            exampleMeaningEnglishEditorContentHtml:'',
            exampleMeaningNepaliEditorState: EditorState.createEmpty(),
            exampleMeaningNepaliStateCopied: EditorState.createEmpty(),
            exampleMeaningNepaliEditorContentHtmlCopied: '',
            exampleMeaningNepaliEditorContentHtml:'',
        }
        await this.setState({
            showAllButton: true,
            link: {
                ...link,
                itemList: [...link.itemList, item]
            }

        })
    }


    addButtonHandler = (state, idx) => {
        let item = [...this.state.link.itemList]
        item[idx][state] = !this.state.link.itemList[idx][state]
        this.setState((prevState) => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...item]
            }
        }))

    }

    editItem = (event, idx, type) => {
        let name = event.target.name
        let value = event.target.value
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx][name] = value
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))
    }
    removeItem = async (idx) => {
        let item = [...this.state.link.itemList]
        item.splice(idx, 1)
        await this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: item
            }
        }))
    }

    closeAllState = () => {
        this.setState((prevState) => ({
            ...prevState,
            showAllButton: false,
            showText: false,
            showImage: false,
            showVideo: false
        }))
    }

    handleSubmit() {
        let newData = {
            // description: this.state.editorContentHtml,
            words: this.state.title,
            is_archived:false,
            word:
                this.state.link.itemList.map((item, index) => (
                        {
                            meaningantonyms: item.antonyms.map((items)=>({
                                antonyms: items,
                                meaning:''

                            })),
                            meaningsynonyms: item.synonyms.map((items)=>({
                                synonyms: items,
                                meaning:''
                            })),
                            meaning: item.meaning,
                            part_of_speech:item.partOfSpeech,
                            brepronounce: item.brePronounciation,
                            amepronounce:item.amePronounciation,
                            meaning_english:item.meaningEnglish,
                            meaning_nepali:item.meaningNepali,
                            detailed_meaning:item.editorContentHtml,
                            english_example:item.exampleMeaningEnglishEditorContentHtml,
                            nepali_example:item.exampleMeaningNepaliEditorContentHtml,
                            word:'hello',
                        }
                ))
        }

        Services.createDictionary(newData).then((response) => {
            if (response.status === 200) {
                this.props.enqueueSnackbar('Dictionary Created Successfully', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
                this.props.onSuccess(this.state.selectedOption)
            } else {
                this.props.enqueueSnackbar(response.msg, {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
            }
        })
            .catch((error) => {
                console.log('asdfasdf', error)
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            })
    }

    componentDidMount() {
        // this.getCourseList()
    }

    getCourseList() {
        Services.getCourses().then((response) => {
            this.setState({ courseList: response })
            this.populateSelectDropdown()
        })
    }

    populateSelectDropdown() {
        let data = this.state.courseList
        data.forEach((item) => {
            this.state.dropdownOptions.push({ value: item.id, label: item.title })
        })
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    handleSynonyms = async(event,idx) => {
        console.log('asdfasdf',event)
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['synonyms'] = event
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))
    }
    handleAntonyms = async(event,idx) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['antonyms'] = event
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))
        console.log('asdf',this.state.link.itemList)
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOptionId: selectedOption.value, selectedOption }
        )
    }

    onEditorStateChange = async (editorState, idx,state) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        let itemTransaction = [...this.state.link.itemList]
        if(state==='detail') {
            itemTransaction[idx]['editorContentHtml'] = draftToHtml(rawContentState)
            itemTransaction[idx]['editorState'] = editorState
        }else if(state==='nepali'){
            itemTransaction[idx]['exampleMeaningNepaliEditorContentHtml'] = draftToHtml(rawContentState)
            itemTransaction[idx]['exampleMeaningNepaliEditorState'] = editorState
        }else if(state==='english'){
            itemTransaction[idx]['exampleMeaningEnglishEditorContentHtml'] = draftToHtml(rawContentState)
            itemTransaction[idx]['exampleMeaningEnglishEditorState'] = editorState
        }
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))

    }

    uploadImageCallBack = (file) => {
        let token = localStorage.getItem('token')
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest()
                const serverUrl = window.config.apiUrl + 'common/image-upload'
                xhr.open('POST', serverUrl)
                xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                const data = new FormData()
                data.append('image', file)
                xhr.send(data)
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText)
                    resolve(response)
                })
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText)
                    reject(error)
                })
            }
        )
    }

    //Image selection and cases

    getExtension = (filename) => {
        var parts = filename.split('.')
        return parts[parts.length - 1]
    }

    isImage = (filename) => {
        var ext = this.getExtension(filename)
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'gif':
            case 'bmp':
            case 'png':
            case 'jpeg':
                //etc
                return true
        }
        return false
    }

    handleImageSelect = async (e, idx) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let itemTransaction = [...this.state.link.itemList]
            // itemTransaction[idx]['image'] = e.target.files[0]
            // itemTransaction[idx]['imagePreview'] = URL.createObjectURL(e.target.files[0])
            // this.setState(prevState => ({
            //     ...prevState,
            //     link: {
            //         itemList: [...itemTransaction]
            //     }
            // }))
            // let reader = new FileReader()
            // reader.onloadend = function() {
            //     let data = (reader.result).split(',')[1]
            //     let binaryBlob = atob(data)
            //     itemTransaction[idx]['imageBlob'] = binaryBlob
            //     this.setState(prevState => ({
            //         ...prevState,
            //         link: {
            //             itemList: [...itemTransaction]
            //         }
            //     }))
            // }.bind(this)
            // reader.readAsDataURL(file)
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        itemTransaction[idx]['image'] = response.data.link
                        itemTransaction[idx]['imagePreview'] = response.data.link
                        this.setState(prevState => ({
                            ...prevState,
                            link: {
                                itemList: [...itemTransaction]
                            }
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }
    handleLinkImageSelect = async (e) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        console.log('asdf', response)
                        this.setState(prevState => ({
                            ...prevState,
                            link_image: response.data.link
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }
    handleLinkImageRemove = async (e) => {
        await this.setState(prevState => ({
            ...prevState,
            link_image: ''
        }))
    }

    handleYoutubeImageSelect = async (e, idx) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let itemTransaction = [...this.state.link.itemList]
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        itemTransaction[idx]['thumbnail'] = response.data.link
                        this.setState(prevState => ({
                            ...prevState,
                            link: {
                                itemList: [...itemTransaction]
                            }
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }
    handleYoutubeImageRemove = async (e, idx) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['thumbnail'] = ''
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))
    }


    handleImageRemove = async (e, idx) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['imagePreview'] = ''
        itemTransaction[idx]['image'] = ''
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))
    }


    handleRemarksOptionChange = (optionArray) => {
        this.setState({ remarks: optionArray })
    }


    handlePaste = () => {
        this.setState({
            editorState: this.props.editorState,
            editorContentHtml: this.props.editorContentHtml
        })
    }

    onEditEditorHTML = (idx, e) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['editorContentHtml'] = e.target.value
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))
        // this.setState({ editorContentHtml: e.target.value })
    }
    toggleEditorCode = async (idx) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['showEditorCode'] = !itemTransaction[idx].showEditorCode
        await this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))
        // this.setState({ showEditorCode: !this.state.showEditorCode })
    }
    returnHtmlBlock = (text) => {
        if (this.state.lessonViewType === true) {
            const processedHTML = htmlToDraft(text, customChunkRenderer)
            return ContentState.createFromBlockArray(processedHTML)
        } else {
            const processedHTML = convertFromHTML(text)
            return ContentState.createFromBlockArray(processedHTML)
        }
    }
    addHtmlToEditor = (idx) => {
        const { editorContentHtml } = this.state.link.itemList[idx]
        let editorState
        if (editorContentHtml.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(editorContentHtml))
        } else {
            editorState = EditorState.createEmpty()
        }
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['editorState'] = editorState
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))

        // this.setState({ editorState })
    }

    getUploadParams = ({ meta }) => {
        return { url: 'https://httpbin.org/post' }
    }

    handleChangeStatus(meta, file, idx) {
        // this.setState({image:file})
        let reader = new FileReader()
        reader.onloadend = function() {
            // console.log('RESULT', reader.result)
            // this.setState({ image: reader.result })
            let itemTransaction = [...this.state.link.itemList]
            itemTransaction[idx]['image'] = reader.result
            this.setState(prevState => ({
                ...prevState,
                link: {
                    ...prevState.link,
                    itemList: [...itemTransaction]
                }
            }))
        }.bind(this)
        reader.readAsDataURL(file)
        //  console.log(this.state)

    }

    render() {
        const ShowEditorCode = (props) => (
            <div className='rdw-option-wrapper' onClick={() => this.toggleEditorCode(props.idx)}>
                {this.state.link.itemList[props.idx].showEditorCode
                    ? 'Hide Code'
                    : 'Show Code'}
            </div>
        )
        const options = this.state.dropdownOptions
        return (
            <>
                <Form encType='multipart/form-data' role='form' style={{
                    paddingLeft: 50,
                    paddingRight: 20,
                    // marginTop: '100px'
                }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {this.props.copyStatus &&
                            <Button type='button' color='secondary' onClick={() => this.handlePaste()}>Paste
                                Data</Button>}
                    </div>
                    <Row>
                        <Col xl={12} style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            <FormGroup style={{
                                marginBottom: '0px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                width: '30%',
                                alignItems: 'center'
                            }}>
                                <InputGroup className='input-group-alternative mb-3'>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <i className='ni ni-hat-3' />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder='Main Word' type='text' name='title'
                                           value={this.state.title}
                                           onChange={this.handleInputChange} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    {this.state.link.itemList && this.state.link.itemList.map((item, idx) => (
                        <div style={{ border: '2px solid lightgrey', marginTop: '10px', padding: '10px' }} key={idx}>

                            <Row>
                                <Col xl={3}>
                                    <FormGroup style={{
                                        marginBottom: '0px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}>
                                        <InputGroup className='input-group-alternative mb-3'>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <i className='ni ni-hat-3' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder='Meaning' type='text' name='meaning'
                                                   value={item.meaning}
                                                   onChange={(event) => this.editItem(event, idx)} />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col xl={3}>
                                    <FormGroup style={{
                                        marginBottom: '0px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between'
                                    }}>
                                        <Input placeHolder={'Select Part Of Speech'} type={'select'}
                                               name={'partOfSpeech'} value={item.partOfSpeech}
                                               onChange={(event) => this.editItem(event, idx)}>
                                            <option>Select Parts Of Speech</option>
                                            <option value={'noun'}>Noun</option>
                                            <option value={'Pronoun'}>Pronoun</option>
                                            <option value={'verb'}>Verb</option>
                                            <option value={'article'}>Article</option>
                                            <option value={'adjective'}>Adjective</option>
                                            <option value={'preposition'}>Preposition</option>
                                            <option value={'conjunction'}>Conjunction</option>
                                            <option value={'adverb'}>Adverb</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col xl={3}>
                                    <FormGroup style={{
                                        marginBottom: '0px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}>
                                        <InputGroup className='input-group-alternative mb-3'>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <i className='ni ni-hat-3' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder='BrE Pronunciation' type='text' name='brePronounciation'
                                                   value={item.brePronounciation}
                                                   onChange={(event) => this.editItem(event, idx)} />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col xl={3}>
                                    <FormGroup style={{
                                        marginBottom: '0px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}>
                                        <InputGroup className='input-group-alternative mb-3'>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <i className='ni ni-hat-3' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder='AmE Pronunciation' type='text' name='amePronounciation'
                                                   value={item.amePronounciation}
                                                   onChange={(event) => this.editItem(event, idx)} />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <FormGroup style={{
                                        marginBottom: '0px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}>
                                        <Input rows='3' placeholder='Meaning In English' type='textarea'
                                               name='meaningEnglish'
                                               value={item.meaningEnglish}
                                               onChange={(event) => this.editItem(event, idx)} />
                                    </FormGroup>
                                </Col>
                                <Col xl={6}>
                                    <FormGroup style={{
                                        marginBottom: '0px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}>
                                        <Input rows='3' placeholder='Meaning In Nepali' type='textarea'
                                               name='meaningNepali'
                                               value={item.meaningNepali}
                                               onChange={(event) => this.editItem(event, idx)} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <FormGroup style={{
                                        marginTop:'12px',
                                        marginBottom: '12px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}>
                                        <Editor
                                            key={idx}
                                            handlePastedText={() => false}
                                            editorStyle={{
                                                border: '1px solid black',
                                                padding: '5px',
                                                borderRadius: '2px',
                                                height: '200px',
                                                width: '100%'
                                            }}
                                            placeholder='Detail Meaning Of English And Nepali'
                                            editorState={item.editorState}
                                            wrapperClassName='demo-wrapper'
                                            editorClassName='demo-editor'
                                            onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, idx,'detail')}
                                            toolbar={{
                                                inline: { inDropdown: true },
                                                list: { inDropdown: true },
                                                textAlign: { inDropdown: true },
                                                link: { inDropdown: true },
                                                history: { inDropdown: true },
                                                image: {
                                                    uploadCallback: (file) => this.uploadImageCallBack(file),
                                                    previewImage: true,
                                                    defaultSize: { width: '100%', height: 'auto' }
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <FormGroup style={{
                                        marginTop:'12px',
                                        marginBottom: '12px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}>
                                        <Editor
                                            key={idx}
                                            handlePastedText={() => false}
                                            editorStyle={{
                                                border: '1px solid black',
                                                padding: '5px',
                                                borderRadius: '2px',
                                                height: '200px',
                                                width: '100%'
                                            }}
                                            placeholder='Examples For English'
                                            editorState={item.exampleMeaningEnglishEditorState}
                                            wrapperClassName='demo-wrapper'
                                            editorClassName='demo-editor'
                                            onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, idx,'english')}
                                            toolbar={{
                                                inline: { inDropdown: true },
                                                list: { inDropdown: true },
                                                textAlign: { inDropdown: true },
                                                link: { inDropdown: true },
                                                history: { inDropdown: true },
                                                image: {
                                                    uploadCallback: (file) => this.uploadImageCallBack(file),
                                                    previewImage: true,
                                                    defaultSize: { width: '100%', height: 'auto' }
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xl={6}>
                                    <FormGroup style={{
                                        marginTop:'12px',
                                        marginBottom: '12px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}>
                                        <Editor
                                            key={idx}
                                            handlePastedText={() => false}
                                            editorStyle={{
                                                border: '1px solid black',
                                                padding: '5px',
                                                borderRadius: '2px',
                                                height: '200px',
                                                width: '100%'
                                            }}
                                            placeholder='Examples For Nepali'
                                            editorState={item.exampleMeaningNepaliEditorState}
                                            wrapperClassName='demo-wrapper'
                                            editorClassName='demo-editor'
                                            onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, idx,'nepali')}
                                            toolbar={{
                                                inline: { inDropdown: true },
                                                list: { inDropdown: true },
                                                textAlign: { inDropdown: true },
                                                link: { inDropdown: true },
                                                history: { inDropdown: true },
                                                image: {
                                                    uploadCallback: (file) => this.uploadImageCallBack(file),
                                                    previewImage: true,
                                                    defaultSize: { width: '100%', height: 'auto' }
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <FormGroup className={'input-array'}>
                                        <TagsInput
                                            value={item.synonyms}
                                            onChange={(event)=>this.handleSynonyms(event,idx)}
                                            name='synonyms'
                                            placeHolder='Enter Synonyms'
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xl={6}>
                                    <FormGroup className='input-array'>
                                        <TagsInput
                                            className={'aaa'}
                                            name={'antonyms'}
                                            value={item.antonyms}
                                            onChange={(event)=>this.handleAntonyms(event,idx)}
                                            placeHolder='Enter Antonyms'
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} className={'mt-3'} style={{ paddingLeft: '30px' }}>
                                    <Button onClick={() => this.removeItem(idx)}
                                            className={'btn btn-danger'}><i
                                        className={'fa fa-minus'} />&nbsp;&nbsp;Remove </Button>
                                </Col>
                            </Row>
                        </div>
                    ))}
                    <Row className={'mt-2'}>
                        <Col xl={12}>
                            <Button onClick={() => this.addItem()}
                                    className={'mb-2 btn btn-success'}><i
                                className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                        </Col>
                    </Row>
                    <div className='pb-4'>
                        <Button className='mt-4' color='primary' type='button' onClick={this.handleSubmit}>
                            Create
                        </Button>
                    </div>
                </Form>
            </>
        )
    }
}


export default withSnackbar(DictionaryForm)