import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { withSnackbar } from 'notistack'
import { Component } from 'react'
import { Button, ButtonGroup, Form, FormGroup, Input, Label } from 'reactstrap'
import Services from '../../RemoteServices/RemoteServices'

class BulkMessage extends Component {
    state = {
        message: '',
        selectedUserList:[
            {label:'All',value:'all'},
            {label:'UnPaid',value:'unpaid'},
            {label:'Paid',value:'paid'},
        ],
        selectedUser:'all'
    }

    handleSubmit = () => {
        this.props.socket.send(
            JSON.stringify({
                message: this.state.message,
                me_id: JSON.parse(localStorage.getItem('user')).id,
                action: 'bulk_message',
                delivery_type: 'sent',
                full_name: JSON.parse(localStorage.getItem('user')).full_name,
                to_user_type:this.state.selectedUser
            })
        )
        
        this.props.toggleTab('currentUserId', null)
        this.props.toggleTab('showMessage', false)
        this.props.toggleTab('messages',[])
        this.props.toggleTab('userDetail','')
        this.props.getChatUsersList();
        this.props.toggleState('bulkMessage');
        this.props.enqueueSnackbar(`Bulk Message Sent  to ${this.state.selectedUser} user Successfully`, {
            variant: 'success',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            }
        })
        // window.location.reload()

    }


    handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    render() {
        const { open, toggleState, studentData, type } = this.props
        return (
            <Dialog PaperProps={{ style: { width: '800px', minHeight: '300px', height: 'auto' } }}
                open={open} onClose={() => toggleState('bulkMessage')}>
                <div className='modal-header mb-0'>
                    <h3 className='modal-title' id='exampleModalLabel'>
                        Send Bulk Messages
                    </h3>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('bulkMessage')}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body mt-0 pt-0'>
                    <Form>
                        <>
                            <FormGroup> 
                                <div style={{
                                    display:'flex',
                                    justifyContent:'space-between',
                                    width:'100%'
                                }}>
                                <Label style={{
                                    fontWeight:'800',
                                    fontSize:'18px'
                                }} >Message</Label>
                                    <div style={{                            
                                     gap:'8px',
                                     alignItems:'center',
                                     justifyContent:'center'    
                                            }}>
                                          {this.state.selectedUserList.map((item, index) => (
                                            <Button
                                              key={index}
                                              onClick={() => this.setState({ selectedUser: item.value })}
                                              active={this.state.selectedUser===item.value}
                                              style={{
                                                height:'fit-content',
                                                padding:'6px',
                                                borderRadius:'8px'
                                              }}
                                            >
                                              {item.label}
                                            </Button>
                                          ))}
                                        </div>
                                </div>
                                <Input
                                    type={'textarea'} name={'message'}
                                    value={this.state.message}
                                    onChange={this.handleInputChange}
                                    rows={5}
                                    placeholder={`Enter the message to ${this.state.selectedUser} user`}>
                                </Input>
                            </FormGroup>

                        </>
                    </Form>
                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggleState('bulkMessage')}
                    >
                        Cancel
                    </Button>
                    <Button color='primary' type='button' onClick={() => this.handleSubmit()}>
                        Submit
                    </Button>
                </div>
            </Dialog>
        )
    }
}

export default withSnackbar(BulkMessage)
