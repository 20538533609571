import { AppBar, FormGroup, IconButton, Input, Toolbar } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography } from 'antd'
import { withSnackbar } from 'notistack'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap'
import { Editor, EditorState } from 'draft-js'
import moment from 'moment'


const Services = require('../../RemoteServices/RemoteServices')


class LeadsStageDetail extends React.Component {
    state = {
        filterData: [],
        tab: '',
    }

    filterDataAsUserClick = (filter) => {
        this.setState({
            tab: filter,
            filterData: this.props.leadsDetail.student_leads_content.filter(item => item.content_type === filter)
        });
    }

    handleInitialEditorText = (text) => {
        let editorState
        if (text && text !== null) {
            if (text.trim() !== '' || text.trim() !== null) {
                editorState = EditorState.createWithContent(this.returnHtmlBlock(text))
            } else {
                editorState = EditorState.createEmpty()
            }
            return editorState
        }
    }

    render() {
        const leadsDetail = this.props.leadsDetail
        console.log('asdfasdf', this.state.filterData)
        return (
            <>
                <div>
                    <Dialog maxWidth='lg' PaperProps={{ style: { width: '1000px', minHeight: '600px', height: 'auto' } }}
                        open={this.props.open} onClose={() => this.toggleModal('updatePasswordView')}>
                        <div className='modal-header mb-0'>
                            <h3 className='modal-title' id='exampleModalLabel'>
                                Leads Stage (Stage-{leadsDetail.stage_no})
                            </h3>
                            <button
                                aria-label='Close'
                                className='close'
                                data-dismiss='modal'
                                type='button'
                                onClick={() => this.props.toggleState('detailsPanel')}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: '10px' }}>
                                <div className='card shadow p-3' style={{ width: '15%', display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, alignItems: 'center' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Call</span>
                                    {leadsDetail.is_call === 'Yes' ?
                                        <i className='fa fa-check' style={{ color: 'green' }} /> :
                                        leadsDetail.is_call === 'No' ?
                                            <i className='fa fa-times' style={{ color: 'red' }} /> :
                                            leadsDetail.is_call === 'Invalid' ?
                                                <i className='fa fa-ban' style={{ color: 'red' }} /> :
                                                <i className='fa fa-arrow-up' style={{ color: 'indigo' }} />
                                    }
                                </div>
                                <div onClick={() => this.filterDataAsUserClick('Notification')} className='card shadow p-3' style={{ cursor: 'pointer', width: '15%', display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, alignItems: 'center', borderColor: this.state.tab === 'Notification' ? 'indigo' : 'white' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Notification</span>
                                    {leadsDetail.is_notification === 'Yes' ?
                                        <i className='fa fa-check' style={{ color: 'green' }} /> :
                                        leadsDetail.is_notification === 'No' ?
                                            <i className='fa fa-times' style={{ color: 'red' }} /> :
                                            leadsDetail.is_notification === 'Invalid' ?
                                                <i className='fa fa-ban' style={{ color: 'red' }} /> :
                                                <i className='fa fa-arrow-up' style={{ color: 'indigo' }} />
                                    }
                                </div>
                                <div onClick={() => this.filterDataAsUserClick('Email')} className='card shadow p-3' style={{ cursor: 'pointer', width: '15%', display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, alignItems: 'center', borderColor: this.state.tab === 'Email' ? 'indigo' : 'white' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Email</span>
                                    {leadsDetail.is_email === 'Yes' ?
                                        <i className='fa fa-check' style={{ color: 'green' }} /> :
                                        leadsDetail.is_email === 'No' ?
                                            <i className='fa fa-times' style={{ color: 'red' }} /> :
                                            leadsDetail.is_email === 'Invalid' ?
                                                <i className='fa fa-ban' style={{ color: 'red' }} /> :
                                                <i className='fa fa-arrow-up' style={{ color: 'indigo' }} />
                                    }
                                </div>
                                <div onClick={() => this.filterDataAsUserClick('SMS')} className='card shadow p-3' style={{ cursor: 'pointer', width: '15%', display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, alignItems: 'center', borderColor: this.state.tab === 'SMS' ? 'indigo' : 'white' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>SMS</span>
                                    {leadsDetail.is_sms === 'Yes' ?
                                        <i className='fa fa-check' style={{ color: 'green' }} /> :
                                        leadsDetail.is_sms === 'No' ?
                                            <i className='fa fa-times' style={{ color: 'red' }} /> :
                                            leadsDetail.is_sms === 'Invalid' ?
                                                <i className='fa fa-ban' style={{ color: 'red' }} /> :
                                                <i className='fa fa-arrow-up' style={{ color: 'indigo' }} />
                                    }
                                </div>
                                <div onClick={() => this.filterDataAsUserClick('Viber')} className='card shadow p-3' style={{ cursor: 'pointer', width: '15%', display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, alignItems: 'center', borderColor: this.state.tab === 'Viber' ? 'indigo' : 'white' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Viber</span>
                                    {leadsDetail.is_viber === 'Yes' ?
                                        <i className='fa fa-check' style={{ color: 'green' }} /> :
                                        leadsDetail.is_viber === 'No' ?
                                            <i className='fa fa-times' style={{ color: 'red' }} /> :
                                            leadsDetail.is_viber === 'Invalid' ?
                                                <i className='fa fa-ban' style={{ color: 'red' }} /> :
                                                <i className='fa fa-arrow-up' style={{ color: 'indigo' }} />
                                    }
                                </div>
                                <div onClick={() => this.filterDataAsUserClick('Whatapp')} className='card shadow p-3' style={{ cursor: 'pointer', width: '15%', display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, alignItems: 'center', borderColor: this.state.tab === 'Whatapp' ? 'indigo' : 'white' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>WhatsApp</span>
                                    {leadsDetail.is_whatapp === 'Yes' ?
                                        <i className='fa fa-check' style={{ color: 'green' }} /> :
                                        leadsDetail.is_whatapp === 'No' ?
                                            <i className='fa fa-times' style={{ color: 'red' }} /> :
                                            leadsDetail.is_whatapp === 'Invalid' ?
                                                <i className='fa fa-ban' style={{ color: 'red' }} /> :
                                                <i className='fa fa-arrow-up' style={{ color: 'indigo' }} />
                                    }
                                </div>

                            </div>
                            <br />
                            {this.state.filterData.length > 0 ?
                                <div style={{ paddingLeft: 10, paddingRight: 10, border: '1px solid lightgrey', borderRadius: 10 }}>
                                    <>
                                        {(this.state.tab === 'Notification' || this.state.tab === 'Email') ?
                                            <FormGroup>
                                                <Label style={{ fontWeight: 'bold', fontSize: '20px' }}>{this.state.tab === 'Notification' ? 'Title' : 'Subject'}</Label>
                                                <span>{this.state.filterData[0].title}</span>
                                                <br />
                                            </FormGroup>

                                            :

                                            ''
                                        }

                                        {this.state.tab === 'Notification' ?

                                            <FormGroup>
                                                <Label style={{ fontWeight: 'bold', fontSize: '20px' }}>Short Description</Label>
                                                <span>{this.state.filterData[0].short_description || 'N/A'}</span>
                                                <br />
                                            </FormGroup>
                                            :
                                            ''
                                        }

                                        <FormGroup>
                                            {this.state.tab === 'Viber' || this.state.tab === 'Whatapp' || this.state.tab === 'SMS' ?
                                                <>

                                                    <FormGroup>
                                                        <Label style={{ fontWeight: 'bold', fontSize: '20px' }}>Description</Label>
                                                        <span>{this.state.filterData[0].content}</span>
                                                        <br />
                                                    </FormGroup>
                                                </>
                                                :
                                                <>

                                                    <Label style={{ fontWeight: 'bold', fontSize: '20px' }}>Description</Label>
                                                    <div
                                                        dangerouslySetInnerHTML={{ __html: this.state?.filterData[0]?.content }}
                                                    />
                                                    <br />
                                                </>
                                            }
                                        </FormGroup>
                                    </>
                                    <Label style={{ fontWeight: 'bold', fontSize: '20px' }}>Overall Comment</Label>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: this.props?.leadsDetail?.overall_comment }}
                                    />
                                </div>
                                :
                                <div style={{ paddingLeft: 10, paddingRight: 10, border: '1px solid lightgrey', borderRadius: 10 }}>
                                    <Label style={{ fontWeight: 'bold', fontSize: '20px' }}>Overall Comment</Label>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: this.props?.leadsDetail?.overall_comment }}
                                    />
                                </div>
                            }
                            {JSON.parse(localStorage.getItem('user')).role_type.toUpperCase() === 'SUPERUSER' && this.state.filterData[0] &&
                                <>
                                    <br />
                                    <div>
                                        <span className='font-weight-bold'>Entered By:</span>&nbsp;
                                        <span>{this.state?.filterData[0]?.created_by || ''}</span>
                                    </div>
                                    <div>
                                        <span className='font-weight-bold'>Entered Time:</span>&nbsp;
                                        <span>{this.state?.filterData[0]?.created_at ? moment(this.state?.filterData[0]?.created_at).format('YYYY-MM-DD') : ''}</span>
                                    </div>
                                    <br />
                                </>
                            }

                        </div>
                    </Dialog>
                </div>
            </>
        )
    }
}

export default withSnackbar(LeadsStageDetail)