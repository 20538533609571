/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Redirect } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { withSnackbar } from 'notistack';
import './Login.css'


const Services = require('../../RemoteServices/RemoteServices.js');

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      snackBar: false,
      loggingIn: false,
      showPassword: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this)
    this.handleFacebookLogin = this.handleFacebookLogin.bind(this)
  }

  componentDidMount() {
    if (localStorage.getItem("user")) {
      this.props.history.push('/admin/index')
    }
  }

  togglePassword = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ loggingIn: true })
    let user = {
      email: this.state.username,
      password: this.state.password
    }
    await Services.sendLogin(user).then((response) => {
      console.log(response)
      if (response.access && response.user) {
        localStorage.setItem('token', response.access);
        localStorage.setItem('refresh', response.refresh)
        localStorage.setItem('user', JSON.stringify(response.user));
        this.props.history.push('/admin/index')
      } else {
        this.setState({ snackBar: true, loggingIn: false })
        this.props.enqueueSnackbar('Username or Password is incorrect', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          }
        })
      }
    })
      .catch((error) => {
        this.setState({ snackBar: true, loggingIn: false })
        this.props.enqueueSnackbar('Username or Password is incorrect', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          }
        })
      })

  }

  responseGoogle() {
    console.log('here')
  }

  async handleFacebookLogin(response) {
    console.log('Facebook login', response)
    let { type, token } = response
    let tokenData = {
      access_token: token
    }
    await Services.createExchangeToken(tokenData).then((response) => {
      localStorage.setItem('token', response.access);
      localStorage.setItem('user', JSON.stringify(response.user));
      this.props.history.push('/admin/index')
    })
      .catch((error) => {
        this.setState({ snackBar: true, loggingIn: false })
        this.props.enqueueSnackbar('Username or Password is incorrect', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          }
        })
      })

  }


  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            {/* <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader> */}
            <CardBody className="px-lg-5 py-lg-5 text-center">
              <img
                alt="..."
                src={require("assets/img/brand/argon-logo.png")}
              />
              <div className="text-center text-muted mb-4 mt-4">
                <small>sign in with credentials</small>
              </div>
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="text" name="username" autoComplete="new-email" value={this.state.username} onChange={this.handleInputChange} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      name="password"
                      value={this.state.password}
                      onChange={this.handleInputChange}
                      required
                      errorMessage="Please enter password"
                    />

                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className={this.state.showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} color="secondary"
                          onClick={() => this.togglePassword('showPassword')}>
                        </i>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
                {/* <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div> */}
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    {this.state.loggingIn ? 'Signing In ...' : 'Sign In'}
                  </Button>
                </div>
                {/* <div className='socialLoginDiv'>
                <GoogleLogin
                    clientId="737555203672-4n453vuqglt2lv26ce5fn2mgkpta51at.apps.googleusercontent.com"
                    buttonText="Login"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={'single_host_origin'}
                  />
                    <FacebookLogin
                        appId="382077912876655"
                        autoLoad={true}
                        fields="name,email"
                        // onClick={this.handleFacebookLogin}
                        callback={this.handleFacebookLogin} />
                </div> */}
              </Form>
            </CardBody>
          </Card>
          {/* <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row> */}
        </Col>
      </>
    );
  }
}

export default withSnackbar(Login);;
